import React, { useState, useEffect } from "react"
import {backendApi} from "../api/calls"
import { PageContext } from "../../context/pageContext";
import { QuerybarContext } from "../../context/querybarContext"

// MUI V5
import Button from '@mui/material/Button';


export function Ping() {
    let [ping, setPing] = useState("loading");

    const PContext = React.useContext(PageContext);
    const QueryContext = React.useContext(QuerybarContext);

    async function requestPing(){
        return await backendApi.get("/api/test/ping").then((res) => {
            console.log("response was:", res)
            return res;
        })
    }

    React.useEffect(() => {
        console.log("DEBUG: PING useEffect (all conditions) ran ")
    })
    useEffect(() => {
        QueryContext.setQuerybarActive(true)
        requestPing().then(d => {
            setPing(JSON.stringify(d.data))
            QueryContext.setQuerybarActive(false)

        }).catch(e => {
            console.log(e)
            console.log("Error requesting ping")
            QueryContext.setQuerybarActive(false)
        })
        console.log("called useEffect in the PING function")
    }, [])

    const handleLink = () => {
        PContext.setPage("dataviewer")
    }

return <div>
    {ping === "loading" ? "" : <p>{ping}</p>}
    <Button variant="contained" onClick={handleLink}>Go to data viewer</Button>

    </div>
}