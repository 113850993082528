import React, {useContext} from 'react';

// OWN
import { SeismicDataContext } from "../../../../context/seismicDataContext";
import { AuthContext } from "../../../../context/auth-context"

// MUI imports
import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';

// Icons
import ClearIcon from '@mui/icons-material/Clear';
import TaskIcon from '@mui/icons-material/Task';

export default function RightClickMenu(props) {

  let {
    contextMenu,
    setContextMenu,
    setSelectionModel,
    lastClickedInstrument,
    handleSohDialog,
  } = props;

  const SeisDataContext = React.useContext(SeismicDataContext);
  const authcontext   = useContext(AuthContext);
  // use authcontext to render based on permissions
  // it is safe bacause permissions are checked server side on requests


  const handleCloseContextMenu = () => {
    setContextMenu(null);
    // console.log(authcontext.state.user.permissions.downloadFiles)
  };

    return (
      <>
        <MuiMenu
          open={contextMenu !== null}
          onClose={handleCloseContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem 
            onClick={() => {
              handleCloseContextMenu()
              handleSohDialog(lastClickedInstrument.serialnumber)
            }} 
          >
            <ListItemIcon>
                <TaskIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              Health Report
            </ListItemText>
          </MenuItem>
        </MuiMenu>
        </>
    );
  }