import React, {useEffect, useState, useRef, useCallback} from 'react';
import Dygraph from "dygraphs";
import TimeAgo from 'timeago-react'; // var TimeAgo = require('timeago-react');
import "./latestEventfile.css";

// OWN
import Widget from "../widget"
import { INTERFACE_downloadFile } from '../../fileExplorer/interface';
import { removeBlockette2000, Blockette2000Payload, blockette2000WasMadeBySeisodin, blockette2000WasMadeBySeisodinAndContainsGainInformation, splitBlockette2000HeadersInArrays } from "../../dataViewer/viewers/miniseedViewer/utils/blockette2000.js"
import {tsToRMS, minMaxOfTs} from "../../dataViewer/viewers/miniseedViewer/utils/statisticsFunctions.js"

// MUI V5
import IconButton from "@mui/material/IconButton"
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


// ICONS
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SensorsIcon from '@mui/icons-material/Sensors';

const seisplot = require("seisplotjs");
// http://crotwell.github.io/seisplotjs/api/index.html


const WidgetLatestEventFileSimple = (props) => {

    let {
      lastEvent,
      loadingLastEventFile,
    } = props;

    useEffect(() => {
      console.log("lastEvent changed")
      console.log(lastEvent)

    }, [lastEvent])

    return (
    <React.Fragment>
        <Widget 
            backgroundColor="" 
            icon={<SensorsIcon />}
            actionButton={<IconButton variant="outlined" size="small"><MoreHorizIcon/></IconButton>}
            title="Latest Event"
        >
          <Typography variant ="h5"><TimeAgo datetime={lastEvent?.timestamp} locale='en_short'/></Typography>
          <Typography variant ="button" color="gray">{lastEvent?.instrument ?? "not available"}</Typography>           
        </Widget>
    </React.Fragment>
    );
};


export default WidgetLatestEventFileSimple;