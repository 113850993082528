import React, {useState, useEffect, useCallback, useContext, useRef} from 'react';

// Test for interface rewrite:
import { 
  INTERFACE_deleteFile, 
  INTERFACE_deleteMultipleFiles,
  // INTERFACE_getUrl,
  // INTERFACE_getMiniseed,
  INTERFACE_downloadFile,
  INTERFACE_downloadZip,
  INTERFACE_targetConfiguration,
} from "./interface.js"

// Own
import Menu from "./subComponents/Menu.js"
import BreadCrumbs from './subComponents/Breadcrumbs.js';
import RightClickMenu from "./subComponents/RightClickMenu.js"
import { PageContext } from "../../../context/pageContext";
import { SeismicDataContext } from "../../../context/seismicDataContext";
import { QuerybarContext } from '../../../context/querybarContext';
import RefreshButton from "./subComponents/RefreshButton"
import FileSelectionChip from './subComponents/FileSelectionChip.js';
import DeleteDialog from "./subComponents/Dialog.js"
import OpenMultipleFilesButton from './subComponents/openMultipleFiles.js';

// MUI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DataGrid, GridFooterContainer, GridPagination} from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// Icons
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';


function CustomNoRowsOverlay() {
  return (
    <Box sx={{ mt: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", }}>
        <FolderIcon sx={{ fontSize: 80, color: "#cfd8dc", marginTop: "100px" }}/>
        <Typography variant="body1" sx={{color: "#cfd8dc", marginBottom: "100px"}}>No files in this folder</Typography>

    </Box>
  );
}

function CustomFooter() {

  const SeisDataContext = useContext(SeismicDataContext);

  let [fileCount, setFileCount] = useState(0);

  useEffect(() => {
    countSelectedFiles()
  }, [SeisDataContext.pathArr])

  function countSelectedFiles(){
    let count = 0;
    SeisDataContext.pathArr.forEach((v, i) => {
      count = count + v.files.length
    })
    setFileCount(count)
  }

  return (
    <GridFooterContainer>
      <div style={{marginLeft: "10px", display: INTERFACE_targetConfiguration.enableDataViewerMenu ? "" : "none"}}>
        <OpenMultipleFilesButton count={fileCount} setOpenFilesFlipper={SeisDataContext.setOpenMultipleFiles} openFilesFlipper={SeisDataContext.openMultipleFiles}/>
      </div>
      <GridPagination />
    </GridFooterContainer>
  );
}


export default function FileBrowser(props) {

  const [rows, setRows] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [filePath, setFilePath] = useState("");
  const [lastClickedFile, setLastClickedFile] = useState({});
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [selectedFilesCount, setSelectedFilesCount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmFileDelete, setConfirmFileDelete] = useState(false);
  const [confirmMultipleFileDelete, setConfirmMultipleFileDelete] = useState(false);
  const [downloadInProgess, setDownloadInProgess] = useState(false)
  const [remainingFilesToDelete, setRemainingFilesToDelete] = useState(0)

  let {
    dirList,
    currentPath,
    setCurrentPath,
    setPathRefresh,
    pathRefresh,
    hideLeftClickMenu,
    setErrorOpen,
    setError
  } = props;

  const stateRef = useRef();
  stateRef.current = remainingFilesToDelete;
  const stateRefConfirmMultipleFileDelete = useRef();
  stateRefConfirmMultipleFileDelete.current = confirmMultipleFileDelete


  const PContext = useContext(PageContext);
  const SeisDataContext = useContext(SeismicDataContext);
  const QueryContext = useContext(QuerybarContext);

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  
  const columns = [
    { 
      field: 'kind', 
      headerName: '', 
      width: 20, 
      renderCell: (params) => {
        return params.row.kind === "directory" ? < FolderIcon color="action" sx={{cursor: "pointer"}} onClick={params.row.kind === "directory" ? () => dirClick(params.row.fileName) : () => {}}/> :  <DescriptionIcon color="action" style={{cursor: "pointer"}} onClick={params.row.kind === "directory" ? () => dirClick(params.row.fileName) : () => {}}/>
      }
    },
    { 
      field: 'fileName', 
      headerName: 'Name', 
      flex: 1,
      // minWidth: 150,
      maxWidth: 500,
      renderCell: (params) => {
        return <p style={{cursor: "pointer"}} onClick={params.row.kind === "directory" ? () => dirClick(params.row.fileName) : ()=>{}}>{params.row.fileName}</p>;
      }

    },
    {
      field: 'size',
      headerName: 'Size',
      type: 'number',
      // width: 90,
    },
    { 
      field: 'date', 
      headerName: 'Date', 
      // flex: 1,
      // width: 130 
    },
  ];

  useEffect(() => {
    // DESCRIPTION: /////////////////////////////////////////////////////////////////
    // updates rows for data grid every time the dirList changes
    /////////////////////////////////////////////////////////////////////////////////
    setRefreshLoading(false)
    
    let newArr = [];
    if(dirList && dirList !== undefined && dirList.length > 0 && dirList !== null){
      dirList.forEach((file,i) => {
        newArr.push({
          id: i, 
          fileName: file.name, 
          date: '', 
          kind: file.kind, size: (file.kind === "directory" ? "" : file.size+"kb")
        })
      })
      setRows(newArr)
    } else {
      setRows(newArr)
    }
    setSelectionModel([]);
  }, [dirList])

  useEffect(() => {
    // DESCRIPTION: /////////////////////////////////////////////////////////////////
    // updates pathArr when new files are selected
    /////////////////////////////////////////////////////////////////////////////////
    let currentPathString = currentPathToString();
    let newArr = [...SeisDataContext.pathArr];

    // search for an arr entry with current path name
    // if none exists, obj will be -1
    let obj = newArr.findIndex(d => d.path === currentPathString);

    // make a fileList of all the selected files in this dir
    // if(selectionModel.length > 0){
      let fileList = []
      selectionModel.forEach((v,i) => {
        // the selection model contains the Numbers of the indexes in the dirList which were selected.
        fileList.push(dirList[v]);
      })
  
      // if path name is not yet in the array, then create it and push the file list
      if(obj === -1){
        newArr.push({
          path: currentPathString,
          files: fileList
        })
        SeisDataContext.setPathArr(newArr)
      } else {
        // if the path name is already in the list, then we need to: 
        // 1: overwrite the files array with the new one
        newArr[obj].files = fileList;
        SeisDataContext.setPathArr(newArr)
      }
    // }
  }, [selectionModel])

  useEffect(() => {
    // DESCRIPTION: /////////////////////////////////////////////////////////////////
    // counts number of selected files and updates state
    /////////////////////////////////////////////////////////////////////////////////
    countSelectedFiles();
  }, [SeisDataContext.pathArr])

  useEffect(() => {
    // DESCRIPTION: /////////////////////////////////////////////////////////////////
    // makes sure that previous selections are marked when you navigate between folders
    // 1: find current path in pathArr
    // 2: extract files names stored in pathArr under that path
    // 3: search dirList for entries where name matches for each of the file names stored in pathArr
    // 4: if there is a match between the two, then update selection model
    // 5: if there is a name in pathArr which is not in dirList, it will be removed
    /////////////////////////////////////////////////////////////////////////////////
    let currentPathString = currentPathToString();
    // newArr holds a copy of pathArr
    let newArr = [...SeisDataContext.pathArr];
    // look for the currentPath in pathArr
    let indexOfPath = newArr.findIndex(d => d.path === currentPathString);
    // if the current path was found in pathArr (!== -1)
    if(indexOfPath !== -1){
      // extract the array of files which were once selected under this path
      let selectedFiles = newArr[indexOfPath].files;
      // create a new (empty) selectionModel which we can add to
      let recalledSelectionModel = [];
      // for each file in the dirList, look for a match in the list of prev selected files

      selectedFiles.forEach((selectedFile,indexInSelectedFiles) => {
        if(dirList !== undefined && dirList !== null && dirList.length > 0 ){
          let indexInDirList = dirList.findIndex( d => d.name === selectedFile.name)
          if(indexInDirList !== -1){
            // if match was found (!==-1) then push the index to the new selection model
            recalledSelectionModel.push(indexInDirList)
            setSelectionModel(recalledSelectionModel)
          } else {
            // File from selection list was not found in dirList,
            // so it should be removed from the selection list to avoid
            // problems down the line
            let updatedSelectedFiles = selectedFiles;

            // 1 remove file from extracted list of selected files
            if (indexInSelectedFiles > -1) { // only splice array when item is found
              updatedSelectedFiles.splice(indexInSelectedFiles, 1); // 2nd parameter means remove one item only
              // SeisDataContext.setPathArr()
            }

            // 2 update the list of files in the local copy of pathArr
            let updatedPathArr = newArr
            updatedPathArr[indexOfPath].files = updatedSelectedFiles;

            // 3 update pathArr
            SeisDataContext.setPathArr(updatedPathArr)
          }
        }
      })
    } else {
      // path not present in pathArr, so nothing should be done
    }
  }, [dirList])

  useEffect(() => {
    // DESCRIPTION: /////////////////////////////////////////////////////////////////
    // keeps a record of the last file which the user has clicked
    /////////////////////////////////////////////////////////////////////////////////
    if(lastClickedFile !== undefined && lastClickedFile !== null && lastClickedFile.kind === "file"){
      let pathFileIsIn = currentPathToString();
      let path = pathFileIsIn+lastClickedFile.name;
      setFilePath(path)
    }
  }, [lastClickedFile])

  useEffect(() => {
    // DESCRIPTION: /////////////////////////////////////////////////////////////////
    // updates context with filepath every time filePath changes
    /////////////////////////////////////////////////////////////////////////////////
      SeisDataContext.setPath(filePath)
  }, [filePath])

  useEffect(() => {
    // DESCRIPTION: /////////////////////////////////////////////////////////////////
    // Checks if user has agreed to delete file, then deletes it
    /////////////////////////////////////////////////////////////////////////////////
    if(confirmFileDelete === true){
      deleteFile().then(d => {
        // success
        setConfirmFileDelete(false)
        setOpenDialog(false)
      }).catch(e => {
        console.log("no permission")
        console.log(e)
      })
    }
  }, [confirmFileDelete])

  useEffect(() => {
    // DESCRIPTION: /////////////////////////////////////////////////////////////////
    // Checks if user has agreed to delete file, then deletes it
    /////////////////////////////////////////////////////////////////////////////////
    if(confirmMultipleFileDelete === true){
      deleteMultipleFiles().then(d => {
        // success
        setConfirmMultipleFileDelete(false)
        setOpenDialog(false)
      }).catch(e => {
        console.log("no permission")
        console.log(e)
      })
    }
  }, [confirmMultipleFileDelete])

  const deleteFile = useCallback (async () => {
    if(SeisDataContext.path !== undefined && SeisDataContext.path !== null && SeisDataContext.path !== "") {
      QueryContext.setQuerybarActive(true)
      return await INTERFACE_deleteFile(SeisDataContext.path).then(res => {
        refreshDirList()
        setContextMenu(null)
        QueryContext.setQuerybarActive(false)
        return res.data
      }).catch(e => {
        console.log("error deleting file")
        console.log(e.message)
        QueryContext.setQuerybarActive(false)
      })
    } else {
      // do nothing
      QueryContext.setQuerybarActive(false)
    }
  }, [SeisDataContext.path])

  const deleteMultipleFiles = useCallback (async () => {
    setContextMenu(null)

    let numberOfFiles = selectedFilesCount;
    let timeout = 10000 + (2000*numberOfFiles); // 10 seconds + 20 seconds per file
    setRemainingFilesToDelete(numberOfFiles)

    if(SeisDataContext.pathArr !== undefined && SeisDataContext.pathArr !== null && SeisDataContext.pathArr !== "" && selectedFilesCount > 0) {
      QueryContext.setQuerybarActive(true)
      return await INTERFACE_deleteMultipleFiles(SeisDataContext.pathArr, timeout, updateDeleteProgress, shouldIAbort).then(res => {
        if(res.allFilesDeleted !== undefined && res.allFilesDeleted===true){
          // if all files and dirs were processed, then clear the pathArr
          SeisDataContext.setPathArr([])
        } else {
          console.log("something went wrong when deleting files")
        }
      }).then(res => {
        refreshDirList()
        QueryContext.setQuerybarActive(false)
        setRemainingFilesToDelete(0)
      }).catch(e => {
        refreshDirList()
        console.log(e)
        QueryContext.setQuerybarActive(false)
        setRemainingFilesToDelete(0)
      })
    } 
  }, [SeisDataContext.pathArr, selectedFilesCount])


  function updateDeleteProgress() {
    let remaining = stateRef.current
    let newRemaining = remaining - 1;
    setRemainingFilesToDelete(newRemaining)
  }

  function shouldIAbort() {
    let state = stateRefConfirmMultipleFileDelete.current
    return state
  }


function x() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('done!');
    },5000);
  });
}

async function waiting() {
  const result = await x();
  console.log(result); // --> 'done!';
}

 


  const downloadZipFile = useCallback (async () => {
    let pathsArray = [];

    if(SeisDataContext.pathArr !== undefined && SeisDataContext.pathArr !== null && SeisDataContext.pathArr !== "" && SeisDataContext.pathArr.length > 0) {
      QueryContext.setQuerybarActive(true)
      setDownloadInProgess(true)
      let numberOfFiles = selectedFilesCount;
      let timeout = 10000 + (20000*numberOfFiles); // 10 seconds + 20 seconds per file

      Promise.all(SeisDataContext.pathArr.map(async (dir,dirIndex) => {

        return Promise.all(dir.files.map(async (file,fileIndex) => {
          console.log("downloading fileIndex " + fileIndex + " from dirIndex " + dirIndex)
          let completePath = dir.path + file.name
          pathsArray.push({path: completePath, name: file.name})
          return true
        }))
      })).then(async d => {
        // when all files in all dirs have returned their promise
        let result = await INTERFACE_downloadZip(pathsArray, timeout)
        let blob = result.data;
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          "SesisodinData.zip",
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        QueryContext.setQuerybarActive(false)
      }).then(d => {
        setDownloadInProgess(false)
      })
    
    }
      
  }, [SeisDataContext.pathArr, selectedFilesCount])

  const downloadMultipleFiles = useCallback (async () => {

    let status = [];

    if(SeisDataContext.pathArr !== undefined && SeisDataContext.pathArr !== null && SeisDataContext.pathArr !== "" && SeisDataContext.pathArr.length > 0) {
      QueryContext.setQuerybarActive(true)
      
      let numberOfFiles = selectedFilesCount;
      let timeout = 10000 + (20000*numberOfFiles); // 10 seconds + 20 seconds per file

      Promise.all(SeisDataContext.pathArr.map(async (dir,dirIndex) => {

        status.push([])

        return Promise.all(dir.files.map(async (file,fileIndex) => {

          status[dirIndex].push(null)

          let completePath = dir.path + file.name
          
          let result = await INTERFACE_downloadFile(completePath, timeout)
          let blob = result.data;

          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            file.name,
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);

          status[dirIndex][fileIndex] = true;

          return true

          // return await INTERFACE_downloadFile(completePath, timeout).then((response) => response.data)
          // .then((blob) => {
          //     console.log(fileIndex + " blob complete")


          //     // Create blob link to download
          //     const url = window.URL.createObjectURL(
          //       new Blob([blob]),
          //     );
          //     const link = document.createElement('a');
          //     link.href = url;
          //     link.setAttribute(
          //       'download',
          //       file.name,
          //     );
          //     document.body.appendChild(link);
          //     link.click();
          //     link.parentNode.removeChild(link);
          //     QueryContext.setQuerybarActive(false)
          //     return true
          // }).then(d => {
          //   status[dirIndex][fileIndex] = true;
          // }).catch(e => {
          //   console.log("error downloading multi files")
          //   console.log(e)
          //   QueryContext.setQuerybarActive(false)
          //   status[dirIndex][fileIndex] = false;
          // })
          
        }))
        
      })).then(d => {
        // when all files in all dirs have returned their promise
        QueryContext.setQuerybarActive(false)
      })
    
    }
      
  }, [SeisDataContext.pathArr, selectedFilesCount])

  async function handleFileDownload(){
    if(SeisDataContext.path !== undefined && SeisDataContext.path !== null && SeisDataContext.path !== "") {
      QueryContext.setQuerybarActive(true)
      return await INTERFACE_downloadFile(SeisDataContext.path).then((response) => response.data)
      .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            lastClickedFile.name,
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setContextMenu(null)
          QueryContext.setQuerybarActive(false)
      }).catch(e => {
        console.log("error downloading single file")
        console.log(e)
        QueryContext.setQuerybarActive(false)
      })
    
    }
  }


  const handleDataViewer = () => {
    // change pages and include url
    SeisDataContext.setPath(filePath)
    PContext.setPage("dataviewer")
    setContextMenu(null)
  }

  const handleLogfileViewer = () => {
    // change pages and include url
    PContext.setPage("logfileviewer")
    setContextMenu(null)
  }

  function handleMultipleFileDownload(){
    downloadMultipleFiles()
  }

  function handleZipDownload(){
    downloadZipFile()
  }

  function handleOpenDeleteDialog(){
    // opens Dialog
    setContextMenu(null)
    setOpenDialog(true)
  }

  const dirClick = (dirName) => {
    // const newPath = currentPath+dirName;
    let newState = currentPath;
    newState.push(dirName);
    setCurrentPath([...newState])
    // make getrequest for the new path
    // if request is acknowledged and response delivered, 
    // then update currentPath and set rows to the returned data
    // then call dirPushHistory to add the new navigation event to the array
  }

  // const fileClick = (event) => {
  //   setLastClickedFile(dirList[Number(event.currentTarget.getAttribute('data-id'))])
  //   setContextMenu(
  //     contextMenu === null
  //       ? {
  //           mouseX: event.clientX + 2,
  //           mouseY: event.clientY - 6,
  //         }
  //       : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
  //         // Other native context menus might behave different.
  //         // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
  //         null,
  //   );
  // }

  const handleRowClick = (params, event) => {
    // console.log(params.row.fileName + " clicked");

    if(dirList[Number(params.row.id)].kind !== "directory"){
      setLastClickedFile(dirList[Number(params.row.id)])
      if(hideLeftClickMenu === true){
        // don't do anything if left click menu is disabled
      } else {
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
              }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
              null,
        );
      }

    } else {
      // do nothing
      event.preventDefault();
    }

  };

  const handleContextMenu = (event) => {
    if(dirList[Number(event.currentTarget.getAttribute('data-id'))].kind !== "directory"){
      event.preventDefault();
      setLastClickedFile(dirList[Number(event.currentTarget.getAttribute('data-id'))])
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
      );
    } else {
      // do nothing
      event.preventDefault();
    }

  };

  function refreshDirList(){
    setRefreshLoading(true)
    setPathRefresh(!pathRefresh)
  }

  function currentPathToString(){
    let pathString = "";
    currentPath.forEach((v, i) => {
      pathString = pathString.concat(currentPath[i]+"/")
    })

    return pathString
  }

  function countSelectedFiles(){
    let count = 0;
    SeisDataContext.pathArr.forEach((v, i) => {
      count = count + v.files.length
    })
    setSelectedFilesCount(count)
  }

  function removeFileFromSelection(dir, fileName){
    // 1: make local copy of selection list
    let copyOfSelectionList = SeisDataContext.pathArr;
    // 2: iterate to entry with matching directory
    let dirIndex = copyOfSelectionList.findIndex(d => d.path === dir)
    // 3: in that entry, iterate to matching file
    let fileIndex = copyOfSelectionList[dirIndex].files.findIndex(d => d.name === fileName)
    // 4: cut out the file
    copyOfSelectionList[dirIndex].files.splice(fileIndex, 1);
    // 5: update selection list from the local copy
    // SeisDataContext.setPathArr(copyOfSelectionList)
    countSelectedFiles()
    // 6: update selection model
      // 6.1 make local copy of selection model
      let updatedSelectionModel = selectionModel;
      // 6.2 search dirList for the index of the filename matching the removed fileName
      let dirListIndex = dirList.findIndex(d => d.name === fileName)
      // 6.3 cut out the entries in selectionModel which contain that number
      if(dirListIndex !== -1){
        let updatedModelIndex = updatedSelectionModel.findIndex(d => d === dirListIndex)
        updatedSelectionModel.splice(updatedModelIndex, 1);
        // 6.4 update selectionModel
        setSelectionModel(updatedSelectionModel)
        // hack 
        refreshDirList()
      }
  }




    return (
      <>
      {/* <Menu/> */}
        <Stack direction="column" spacing={0} style={{padding: 0, margin: "-24px -24px", height: "100%"}}>
          {/* <Box style={{padding: 0, margin: 0, borderStyle: "solid", borderWidth: "1px 1px 1px 0px", borderColor: "#D3D3D3"}}>
            <Menu />
          </Box> */}
          <Stack direction="row" spacing={0} style={{padding: 0, margin: 0, height: "100%"}}>
            {/* <Box style={{height: "100%", padding: 0, margin: 0, borderStyle: "solid", borderWidth: "0px 1px 1px 0px", borderColor: "#D3D3D3"}}>
              <Tree dirList={dirList}/>
            </Box> */}
            <Box style={{height: "100%", padding: 0, margin: 0, flexGrow: 1}}>
              {/* https://mui.com/components/data-grid/style/ */}
              {/* https://stackoverflow.com/questions/66607099/how-do-i-remove-the-outline-on-focus-in-datagrid */}
              <Grid container sx={{width: "100%", height: "100%", marginTop: "0px"}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{height: "100%"}}>
                    <Stack direction="row" spacing={0}>
                      <BreadCrumbs currentPath={currentPath} setCurrentPath={setCurrentPath}/>
                      <div style={{borderStyle: "solid", borderWidth: "0px 0px 1px 0px", borderColor: "#D3D3D3",}} >
                        <FileSelectionChip 
                          selectedFilesCount={selectedFilesCount} 
                          setSelectionModel={setSelectionModel} 
                          removeFileFromSelection={removeFileFromSelection} 
                          handleOpenDeleteDialog={handleOpenDeleteDialog}
                        />
                      </div>
                      <div style={{borderStyle: "solid", borderWidth: "0px 1px 1px 0px", borderColor: "#D3D3D3",}} >
                        <RefreshButton refreshDirList={refreshDirList} refreshLoading={refreshLoading}/>
                      </div>
                    </Stack>
                    <div style={{ height: "100%", width: '100%'}} >
                      <DataGrid
                          rows={rows}
                          density="compact"
                          // autoPageSize
                          columns={columns}
                          SelectionUnit="FullRow"
                          hideFooterPagination={false}
                          // pageSize={100}
                          rowsPerPageOptions={[100]}
                          checkboxSelection
                          disableSelectionOnClick={true}
                          onRowClick={handleRowClick}
                          hideFooterSelectedRowCount
                          isRowSelectable={
                            (params) => params.row.kind !== "directory"
                          }
                          components={{
                            // Toolbar: CustomToolbar,
                            Footer: CustomFooter,
                            NoRowsOverlay: CustomNoRowsOverlay,
                          }}
                          onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                          }}
                          selectionModel={selectionModel}
                          columnVisibilityModel={{
                            // Hide columns status and traderName, the other columns will remain visible
                            size: largeScreen ? true : false,
                            date: largeScreen ? true : false,
                          }}
                          componentsProps={{
                            row: {
                              onContextMenu: handleContextMenu,
                              style: { cursor: 'context-menu' },
                            },
                          }}
                          
                          sx={{
                            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                              outline: 'none'},
                            // marginTop: "-30px",
                            borderRadius: "0",
                            borderWidth: "0px 1px 1px 0px", 
                            borderColor: "#D3D3D3",
                          }}
                      />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Stack>
        <RightClickMenu 
            contextMenu={contextMenu}
            setContextMenu={setContextMenu}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            handleDataViewer={handleDataViewer}
            handleLogfileViewer={handleLogfileViewer}
            handleFileDownload={handleFileDownload}
            handleOpenDeleteDialog={handleOpenDeleteDialog}
            selectedFilesCount={selectedFilesCount}
            handleMultipleFileDownload={handleMultipleFileDownload}
            handleZipDownload={handleZipDownload}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            targetConfiguration={INTERFACE_targetConfiguration}
            downloadInProgess={downloadInProgess}
        />
        <DeleteDialog 
          openDialog={openDialog} 
          setOpenDialog={setOpenDialog} 
          setConfirmFileDelete={setConfirmFileDelete}
          setConfirmMultipleFileDelete={setConfirmMultipleFileDelete}
          confirmMultipleFileDelete={confirmMultipleFileDelete}
          selectedFilesCount={selectedFilesCount}
          remainingFilesToDelete={remainingFilesToDelete}
        />
      </>
    );
  }