import React, { useState, useEffect, useContext } from 'react';

// OWN
import { PageContext } from "../../../../context/pageContext";
import { AuthContext } from "../../../../context/auth-context"

// MUI V5
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';

export default function OpenMultipleFilesButton(props){

    const [disableButton, setDisableButton] = useState(false)

    const PContext = useContext(PageContext);
    const authcontext   = useContext(AuthContext);
    // use authcontext to render based on permissions
    // it is safe bacause permissions are checked server side on requests

    let { 
        count,
        setOpenFilesFlipper,
        openFilesFlipper,
    } = props;

    useEffect(() => {
        if(authcontext.state.user.permissions.downloadFiles){
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }
    },[])

    useEffect(() => {
        if(count > 3){
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    },[count])

    function openFiles(){
        setOpenFilesFlipper(!openFilesFlipper)
        setDisableButton(true)
        PContext.setPage("dataviewer")
    }

    
    return (
        <Slide direction="right" in={Number(count) > 0 ? true : false} mountOnEnter unmountOnExit>
            <Button size="small" disabled={disableButton} disableElevation variant="contained" onClick={openFiles}>
                {
                    count > 3 ?
                    "Maximum 3 files can be opened at once"
                    : "Open " + count + " files"
                }
            </Button>
        </Slide>
    )
}