import React, { useEffect, useState, useRef, useCallback, useContext, useLayoutEffect } from "react";
import Dygraph from "dygraphs";
import { synchronize } from "./dygraphsExtra/synchronization.js" // eslint-disable-line
import _ from "lodash"

// OWN
import { PageContext } from "../../../../../context/pageContext";
import { strIsDefined  } from "../../../helpers.js";
import GraphHeader from "./graphSubComponents/GraphHeader.js"
import {colorArr} from "./utils/colors.js"
import { QuerybarContext } from '../../../../../context/querybarContext';
import { tsToRMS, minMaxOfTs } from "./utils/statisticsFunctions.js";
import RightClickMenu from "./graphSubComponents/RightClickMenuTimeseries.js";

import "./dygraph.css";

// MUI V5
import Box from '@mui/material/Box';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';


function legendFormatter(data) {
  console.log("legend formatter")
  console.log(data.series[0])
  console.log(data)
  if (data.x == null) {
    // This happens when there's no selection and {legend: 'always'} is set.
    return +data.series
      .map(function (series) {
        return series.dashHTML + " " + series.labelHTML;
      })
      .join();
  }

  var html = "<b>" + data.xHTML + "</b>";
  data.series.forEach((series) => {
    if (!series.isVisible) return;

    var labeledData = series.labelHTML + " <b>" + series.y + "</b>";
    // var labeledData = series.labelHTML + " <b>" + series.yHTML + "</b>";

    if (series.isHighlighted) {
      labeledData = "<b>" + labeledData + "</b>";
    }

    html +=
      "<div class='dygraph-legend-row'>" +
      series.dashHTML +
      "<div>" +
      labeledData +
      "</div></div>";
  });
  return html;
}

export default function Graph(props) {

  const PContext = useContext(PageContext);
  const QueryContext = useContext(QuerybarContext);

  // State
  let { data, 
        metaData, 
        linkTimeScales, 
        splitChannels, 
        setSplitChannels,
        configurableGraphSettings, 
        resetZoom, 
        // setResetZoom,
        fft,
        graphRows,
        // setGraphRows,
        visibleChannels,
        setVisibleChannels,
        uid,
        // positionOnPage,
        loadingGraphData,
        setLoadingGraphData,
        units,
        updateUnitsState,
        graphHeight,
        printMode,
      } = props;
  
  let [dataRefresh, setDataRefresh] = useState(true)
  let [dygraphState, setDygraphState] = useState([])
  let [syncState, setSyncState] = useState()
  let [dummyRerenderState, setDummyRerenderState] = useState(true)
  let [graphWidth, setGraphWidth] = useState("calc(100% - 240px)")
  // let [zoomRange, setZoomRange] = useState([])
  // let [zoomFlipper, setZoomFlipper] = useState([])
  let [zoomRanges, setZoomRanges] = useState([[]])
  // let [zoomRanges, setZoomRanges] = useState([[],[]])
  let [graphSettings, setGraphSettings] = useState(
    {
      axes: {
        x: {
          drawGrid: false,
          drawAxis: true,
          axisLineColor: "white",
          axisLineWidth: 1.5,
          gridLineColor: "#d1d1d1",
          // gridLinePattern: [5, 5],
        },
        y: {
          drawGrid: true,
          drawAxis: true,
          gridLineWidth: 1.5,
          gridLineColor: "#eee",
          gridLinePattern: [5, 5],
          axisLineColor: "white",
          axisLineWidth: 1,
        }
      },
      logscale: false,
      // maxNumberWidth: 6,
      // sigFigs: 2,
      // digitsAfterDecimal: 10,
      rollPeriod: 0,
      highlightCircleSize: 5,
      labels: ["X", "Amplitude"],
      // timingName: "x",
      legendFormatter: legendFormatter,
      legend: "follow",
      strokeWidth: 1, // any other width than 1 will slow down rendering by 4-6 times
      fillGraph: true,
      colors: colorArr,
      visibility: [true],
      animatedZooms: true,
      hideOverlayOnMouseOut: true,
      drawPoints: false,
      labelsUTC: true,
      width: -1,
      connectSeparatedPoints: true,
      zoomCallback: function(minX, maxX) {
        // setZoomFlipper(!zoomFlipper)
        allGraphsZoomChangeCallback()
        console.log("zoomCallback")
      },
      drawCallback: (dygraph, first) => {
        // make sure stats are updated for all channels if they are time linked
        if(first){
          zoomChangeCallback(dygraph, first)
        }
      },
      interactionModel: Object.assign({}, Dygraph.defaultInteractionModel, {
        // This is a hack to perform an action when panning is finished
        // We are only observing if the mouse button goes up. 
        // That means the action will be called on any interaction that involves the mouse going up
        mouseup: (event, g, context) => {
          console.log("mouse up")
          Dygraph.endPan(event, g, context);
          context.isPanning = false;
          allGraphsZoomChangeCallback()
        },
        touchend: (event, g, context) => {
          console.log("touch up")
          Dygraph.endPan(event, g, context);
          context.isPanning = false;
          allGraphsZoomChangeCallback()
        },
      }),
    }
  );

  // refs are dynamically assigned
  // https://stackoverflow.com/questions/57810378/how-to-create-dynamic-refs-in-functional-component-using-useref-hook
  let refArr = useRef([]);

  // const theme = useTheme();
  // const mediumScreen = useMediaQuery(theme.breakpoints.up('sm'));

  //
  //////////////////////////////////////////////////////////
  useEffect(() => {
    console.log("units changed, so recalculating stats")
    // allGraphsZoomChangeCallback()
  },[units])


  //
  //////////////////////////////////////////////////////////
  useEffect(() => {
    setDummyRerenderState(!dummyRerenderState)

    if(printMode){ // If user is trying to print on paper
        setGraphWidth("100%")
    } else if(PContext.sidebarOpen === true){ // If not a paper print scenario
      if(graphRows === true){
        setGraphWidth("calc(100% - 240px)")
      } else {
        setGraphWidth("calc(50% - 120px)")
      }
    } else {
      if(graphRows === true){
        setGraphWidth("calc(100%)")
      } else {
        setGraphWidth("calc(50%)")
      }
      
    }
  }, [PContext.sidebarOpen, graphRows, printMode])

  // Resizes the graph if any of the paramateters change
  //////////////////////////////////////////////////////////
  useEffect(() => {
    // https://groups.google.com/g/dygraphs-users/c/4RmtLwwrhGU
    console.log("DEBUG: graphWidth or visibleChannels changed, thefore running resize() on all dygraph instances")
    dygraphState.forEach((v,i) => {
      dygraphState[i].resize()
    })
  }, [printMode, graphWidth, graphHeight, JSON.stringify(visibleChannels), splitChannels])

  //
  //////////////////////////////////////////////////////////
  useEffect(() => {
    console.log("DEBUG: loaded GRAPH component")
    return function cleanup() {
      dygraphState.forEach((v,i) => {
        dygraphState[i].destroy(); 
        console.log("destroyed a dygraph instance")
      })
    };
  }, [])

  //
  //////////////////////////////////////////////////////////
  useEffect(() => {
    console.log("dygraphState")
    console.log(dygraphState)
    dygraphState.forEach((v,i) => {
      dygraphState[i].resetZoom()
    })
  }, [resetZoom])

  //
  //////////////////////////////////////////////////////////
  useEffect(() => {
    console.log("DEBUG: [configurableGraphSettings or splitChannels] changed")
    dygraphState.forEach((v,i) => {
      dygraphState[i].updateOptions({
        drawPoints: configurableGraphSettings.drawPoints,
        fillGraph: configurableGraphSettings.fillGraph,
        labelsUTC: configurableGraphSettings.labelsUTC,
        axes: {
          x: {
            drawGrid: configurableGraphSettings.axes.x.drawGrid
          },
          y: {
            drawGrid: configurableGraphSettings.axes.y.drawGrid
          }
        }
      })
    })

  }, [JSON.stringify(configurableGraphSettings), splitChannels])

  //
  //////////////////////////////////////////////////////////
  useEffect(() => {
    console.log("DEBUG: [graphSettings] changed")
    dygraphState.forEach(graph => {
      graph.updateOptions(graphSettings)
    })

  }, [graphSettings]);


  //
  //////////////////////////////////////////////////////////
  useEffect(() => {
    console.log("DEBUG: [data] changed, hence resetting dygraphState, setting splitChannels to true and toggle dataRefresh")
    
  // QueryContext.setQuerybarActive(true)
    // Empty the array of Dygraphs before rendering anything new. 
    // Helps prevent issue where 3ch file can't be shown properly 
    // after showing a file with just 1 channel
  // setDygraphState([]);   
    
    // Reset splitChannels to true
    // Helps prevent issue where a 1ch split plot prevents
    // 3ch plot from rendering properly when opened afterwards
    // setSplitChannels(true)

    console.log("DEBUG: splitChannels changed")

    // Flip a switch to let the system know that we can start rendering the new data
    // otherwise the old data will re-render and the new will not show

  setDataRefresh(prevState => !prevState)
    console.log("DEBUG: changing [dataRefresh]")
  }, [data, setDataRefresh, setDygraphState, setSplitChannels])

  // Show/Hide channels inside a Dygraph
  // This was made to allow hiding data series inside a 
  // dygraph with multiple graphs. 
  // That means a combined graph (splitchannels===false).
  //////////////////////////////////////////////////////////
  useEffect(() => {
    console.log("DEBUG: visibleChannels changed, so updating visibility settings for all dygraph instances if splitchannels is true")

    if(!splitChannels){
      console.log("DEBUG: splitchannels was not true")
      dygraphState.forEach((dg, i) => {
        dg.setVisibility(visibleChannels)
      })
    } else {
      console.log("DEBUG: splitchannels was true, so not doing anything")
    }
    
    // Regarding the dependency on a deep array
    // https://github.com/facebook/react/issues/14476#issuecomment-471199055
  }, [JSON.stringify(visibleChannels)])

  //
  //////////////////////////////////////////////////////////
  useEffect(() => {
    // setDygraphState([]);  //clean slate

    console.log("DEBUG: dataRefresh, linkTimeScales or visibleChannels changed " + dataRefresh + " " + linkTimeScales + " " + visibleChannels)
    if(splitChannels){
      console.log("DEBUG: creating split graphs because splitChannels is true & [dataRefresh, linkTimeScales, visibleChannels] changed")
      createSplitGraphs(data)      
      allGraphsZoomChangeCallback()
    } else if(splitChannels === false && fft === undefined) {
      console.log("DEBUG: creating combined graphs because splitChannels is false")
        createCombinedGraphs(concatGraphs(...data))
        // createCombinedGraphs(concatGraphs(...data), data.length)
        allGraphsZoomChangeCallback()
    } else {
      console.log("DEBUG: creating split graphs because dataset is FFT")
      // ensures FFT is rendered even if timeseries are combined (splitchannels===false)
      createSplitGraphs(data)
      allGraphsZoomChangeCallback()
    }

    if(linkTimeScales){
      console.log("DEBUG: syncing graphs")
      syncGraphs()
    } else {
      console.log("DEBUG: unsyncing graphs")
      unsyncGraphs()
    }

    setFftScales();

    // this useEffect must not depend on splitChannels!!
    // there is a separate useEffect for this, which modifies graphSettings
    // and triggers THIS useEffect
  }, [
    // dataRefresh, 
    data,
    linkTimeScales, 
    visibleChannels,
    configurableGraphSettings
  ]);

  // Stop the query bar here
  //////////////////////////////////////////////////////////
  useEffect(() => {
    // console.log("ran data refresh useEffect")
    setLoadingGraphData(false)
    // QueryContext.setQuerybarActive(false)
  }, [dataRefresh])

  //
  //////////////////////////////////////////////////////////
  useEffect(() => {

    console.log("DEBUG: splitChannels changed")
  // IF THIS IS SPLIT CHANNEL OR AN FFT
    if(splitChannels || fft !== undefined){

      createSplitGraphs(data)

      dygraphState.forEach((v,i) => {
        
        // generate new labels
        let label = "";
        label = "Amplitude "+"("+ strIsDefined(metaData[i] ? metaData[i].yUnit : "") +")"

        // if(fft !== undefined) {
        //   label = "Amplitude "+"("+ strIsDefined(metaData[i] ? metaData[i].yUnit : "") +")"
        //   // label = "Amplitude"
        // } else {
        //   label ="Amplitude"
        // }

        // makes sure that Log-scales settings are applied to fft
        if(fft !== undefined){
          dygraphState.forEach((v,i) => {
            dygraphState[i].updateOptions({
              logscale: fft.logscaleY,
              axes: {
                x: {
                  logscale: fft.logscaleX
                }
              }
            })
          })
        }
        
        // dygraphState[i].updateOptions({
        //   // labels: ["X", "Amplitude "+"("+(fft !== undefined) ? "" : strIsDefined(metaData[i].yUnit)+")"],
        //   labels: ["X", label],
        //   file: data[i],
        // })
      })

  // IF THIS IS NOT AN FFT AND NOT A SPLIT CHANNEL (hence a combined view)
    } else if(splitChannels === false && fft === undefined) {

      createCombinedGraphs(concatGraphs(...data))
      console.log("DEBUG: splitChannels changed, and appears to be FALSE")
      // change input data via function call first
      // x in first index represents time axis
      let labels = ["X"]
      // visibility: array of booleans to show or hide each graph
      let visibility = [] // this used to be only []
      // let visibility = visibleChannels // this used to be only []
      
      // This forces all channels to be VISIBLE if visibility is used
      metaData.forEach((v, i) => {
        labels[i+1] = metaData[i].channelCode + " (" + metaData[i].yUnit + ")"
        visibility[i] = true;
      })

      dygraphState.forEach((v,i) => {
        dygraphState[i].updateOptions({
          visibility: [...visibleChannels],
          // visibility: [...visibility],
          labels: [...labels],
          file: concatGraphs(...data),
        })
      })
    } 

  }, [splitChannels])

  // sets the z and y log-scales for fft plots
  //////////////////////////////////////////////////////////
  useEffect(() => {
    setFftScales();
  }, [fft])

  function setFftScales(){
    if(fft !== undefined){
      dygraphState.forEach((v,i) => {
        dygraphState[i].updateOptions({
          logscale: fft.logscaleY,
          axes: {
            x: {
              logscale: fft.logscaleX
            }
          }
        })
      })
    }
  }


  function concatGraphs(...arrs){
    const start = Date.now();
    console.log(Date.now() +" [START] concatGraphs")

    console.log("DEBUG: running concatGraphs")
    const result = {};
    for(var i=0;i<arrs.length;i++){
      for(let [ts,val] of arrs[i]){
          // note: date types are converted to unix time stamps
          // in order to treat them as integers in the array.
          // they must be converted back later.
          result[ts.getTime()] = result[ts.getTime()] || new Array(arrs.length + 1).fill(null);
          result[ts.getTime()][0] = ts.getTime();
          result[ts.getTime()][i+1] = val;
      }    
    }
    let temp = Object.values(result).sort( (a,b) => a[0] - b[0]);

    // Convert unix time stamps back to Date types
    temp.forEach((v, i) => {
      temp[i][0] = new Date(v[0])
    })

    const millis = Date.now() - start;
    console.log("DEBUG: [END] concatGraphs "+ Math.floor(millis) + "ms")
    
    return temp
  }

  function createSplitGraphs(dataset){
    const start = Date.now();

    (data !== undefined && data.length > 0) ? data.forEach((v,i) => {
      // QueryContext.setQuerybarActive(true)
      console.log(Date.now() +" [START] running createSplitGraphs")
      if(!dygraphState[i]){
        console.log("DEBUG: creating new dygraph state")
        // generate new labels
        let label = "";
        label = "Amplitude "+"("+ strIsDefined(metaData[i] ? metaData[i].yUnit : "") +")"

        let tempState = dygraphState;
        tempState[i] = new Dygraph(refArr.current[i], dataset[i], graphSettings);

        tempState[i].updateOptions({
          labels: ["X", label],
          file: dataset[i],
        })
        
        setDygraphState(tempState)
        dygraphState[i].ready(() => {
          console.log(Date.now() + " Dygraph " + i +" is ready")
        });
        
      } else {
        console.log("DEBUG: updating existing dygraph state instead of creating a new one")

        let label = "";
        label = "Amplitude "+"("+ strIsDefined(metaData[i] ? metaData[i].yUnit : "") +")"

        dygraphState[i].updateOptions({
          labels: ["X", label],
          visibility: [true],
          file: dataset[i]
        })
      }

     
    })
    : <></>

    const millis = Date.now() - start;
    console.log("DEBUG: [END] createSplitGraphs "+ Math.floor(millis) + "ms")
  }

  function createCombinedGraphs(dataset){    
    let start = Date.now()
    console.log("DEBUG: [start] syncGraphs")

    console.log("dygraphState.length: " + dygraphState.length)
      if(!Array.isArray(dygraphState) || dygraphState.length<1){
        console.log("Creating new Dygraph instance")
        let tempState = dygraphState;
        tempState[0] = new Dygraph(refArr.current[0], dataset, graphSettings);

        let labels = ["X"]

        // This forces all channels to be VISIBLE if visibility is used
        metaData.forEach((v, i) => {
          labels[i+1] = metaData[i].channelCode + " (" + metaData[i].yUnit + ")"
        })

        tempState[0].updateOptions({
          visibility: [...visibleChannels],
          // visibility: [...visibility],
          labels: [...labels],
          file: concatGraphs(...data),
        })

        setDygraphState(tempState)
      } else {
        console.log("Updating existing dygraph instance")
        console.log("visibleChannels")
        console.log(visibleChannels)

        let labels = ["X"]
        let visibility = []

        // This forces all channels to be VISIBLE if visibility is used
        metaData.forEach((v, i) => {
          labels[i+1] = metaData[i].channelCode + " (" + metaData[i].yUnit + ")"
          visibility.push(true)
        })

        dygraphState[0].updateOptions({
          visibility: [...visibility],
          // visibility: [...visibleChannels],
          labels: [...labels],
          file: dataset
        })
      }

      allGraphsZoomChangeCallback()

      const millis = Date.now() - start;
      console.log("DEBUG: [END] createCombinedGraphs "+ Math.floor(millis) + "ms")
  }

  function syncGraphs(){
    let start = Date.now()
    console.log("DEBUG: [start] syncGraphs")

    if(dygraphState.length > 1) {
      setSyncState(Dygraph.synchronize(dygraphState, {
        selection: false,
        zoom: true,
        range: false,
      }))
    }

    const millis = Date.now() - start;
    console.log("DEBUG: [END] syncGraphs "+ Math.floor(millis) + "ms")
  }

  function unsyncGraphs(){
    let start = Date.now()
    console.log("DEBUG: [start] unsyncGraphs")

    if(syncState && syncState !== undefined && syncState !== 0){
      syncState.detach();
      setSyncState(undefined)
    }  
    const millis = Date.now() - start;
    console.log("DEBUG: [END] unsyncGraphs "+ Math.floor(millis) + "ms")
  }

  function destroyDygraphState(){
    dygraphState.forEach((v,i) => {
      dygraphState[i].destroy(); 
      console.log("destroyed a dygraph instance")
    })
  }

  const zoomChangeCallback = useCallback((dygraph, first) => {

    let start = Date.now()

    console.log("DEBUG: [start] zoomChangeCallback")

    // [["div1",[0,999]],[],[]]
    let graphDivName = String(dygraph.maindiv_.id)
    let newZoomRange = dygraph.boundaryIds_;

    let zoomRangesCopy = [...zoomRanges]
    let slicedYData = dygraph.file_.slice(newZoomRange?.[0]?.[0], newZoomRange?.[0]?.[1]); // der skal måske +1 på sidste index, ellers er det ikke inklusiv
    
    // Extract the time series data for each channel
    // made this way to support dygraphs with any number of datasets per graph
    let zoomedYData = [];
    slicedYData[0].slice(1,slicedYData[0].length).forEach((d,channelIndex) => {
      zoomedYData.push([]); // add a new sub-array for each channel
      slicedYData.forEach((d,i) => {
        zoomedYData[channelIndex].push(d[channelIndex+1])
      })
    })

    // Generate stats on the zoomed area for each channel
    // made this way to support dygraphs with any number of datasets per graph
    let zoomStatistics = [];
    zoomedYData.forEach((YData, channelIndex) => {
      zoomStatistics.push({
        zoomedRMS: tsToRMS(YData),
        zoomedMax: minMaxOfTs(YData).max,
        zoomedMin: minMaxOfTs(YData).min,
      })
    })

    console.log("zoomStatistics")
    console.log(zoomStatistics)

    const isMatch = (element) => element[0] === graphDivName;
    let testResult = zoomRangesCopy[0].findIndex(isMatch)

    console.log("zoomRanges")
    console.log(zoomRanges)
    // look if the div is already registered
    if(testResult !== -1 && testResult !== undefined && testResult !== null){
      // if the name already exists
      zoomRangesCopy[0][testResult][1] = newZoomRange
      zoomRangesCopy[0][testResult][2] = zoomStatistics
      setZoomRanges(zoomRangesCopy)
    } else {
      // if the name does not exist
      // let newPositionInArray = zoomRangesCopy.length
      zoomRangesCopy[0].push([graphDivName, newZoomRange, zoomStatistics])
      setZoomRanges(zoomRangesCopy)
    }

    const millis = Date.now() - start;
    console.log("DEBUG: [END] zoomChangeCallback "+ Math.floor(millis) + "ms")
  
  }, [zoomRanges, setZoomRanges])

  const allGraphsZoomChangeCallback = useCallback(() => {
    console.log("dygraphState length: " + dygraphState.length)
      dygraphState.forEach((dg,i) => {
        console.log(dg)
        zoomChangeCallback(dg, false)
      })

  }, [splitChannels, zoomChangeCallback])


  return (
    <Box key={"graphBoxOuter"+uid} style={{height: "100%", width: "100%"}}>   
      <Box key={"graphBox"+uid} style={{height: "100%", width: "100%"}}>
        {
          (data !== undefined) ? data.map((v, channelIndex) => {
            return (
              <React.Fragment key={"fragment"+channelIndex+uid}>
              <Box 
                key={"outerbox"+channelIndex+uid} 
                id={"outerbox"+channelIndex+uid} 
                sx={{
                    width: "100%", 
                    height: printMode ? "120px" : "calc("+graphHeight+"vh - "+116/(100/graphHeight)+"px)",
                    display: visibleChannels !== undefined ? (channelIndex !== 0 && splitChannels === false && fft === undefined) ? "none" : (fft !== undefined && fft.firstRender === false) ? "none" : (splitChannels === true && visibleChannels[channelIndex] === false) ? "none" : "block" : ""
                  }}
              >
                {/* <div><p>{JSON.stringify(channelIndex)}</p></div> */}
                {/* <div><p>{JSON.stringify(zoomRanges)}</p></div> */}
                <GraphHeader key={"graphheader"+channelIndex+uid} zoomDetails={zoomRanges[0] || "unknown"} loadingGraphData={loadingGraphData} zoomRanges={zoomRanges} graphDivName={channelIndex} channelIndex={channelIndex} metaData={metaData} visibleChannels={visibleChannels} uid={uid} fft={fft} splitChannels={splitChannels} colorArr={colorArr} graphRows={graphRows} linkTimeScales={linkTimeScales}/>   
                  <Box 
                    id="graphbox" 
                    style={{
                      height: "calc(100% - 20px)", 
                      position: "static", 
                      width: "100%", 
                    }}
                  >
                    {
                      loadingGraphData ? 
                      <Skeleton height="100%" key={"skeleton1"+channelIndex+uid}/>
                      : <></>
                    }
                    <div 
                      key={"graphDiv"+channelIndex+uid} 
                      id={"graphDiv"+channelIndex+uid} 
                      style={{
                        position: printMode ? "relative" : "absolute",
                        width: graphWidth,
                        marginBottom: "20px",                        
                        height: printMode ? "100px" : "calc("+graphHeight+"vh - "+(116/(100/graphHeight)+20)+"px)",
                        visibility: loadingGraphData ? "hidden" : "visible"
                      }}
                      ref={(el) => (refArr.current[channelIndex] = el)} 

                    />
                  </Box>
                {/* </RightClickMenu> */}
              </Box>
              </React.Fragment>
            )
          })
          : <></>
        }
      </Box>
    </Box>
  );
}
