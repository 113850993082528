import React, {useState, useEffect} from "react"
import ProtoFrontpage from "./models/ProtoFrontpage";
import ProtoSingleFileGraph from "./models/ProtoSingleGraph";
import UnknownPage from "./models/UnknownPage";

export default function PaperContent(props){

    let { 
        page, 
        pageCurrent,
        localCopyOfPageArray,
        setLocalCopyOfPageArray,
        paperDimensions, 
        paperFormat,
        margins,
        localCopyOfPageArrayConfig,
        setLocalCopyOfPageArrayConfig,
    } = props;

    const getPageCss = () => {
        return `@page {
            break-after: page; 
            padding: 0 !important; 
            margin: ${margins?.top+margins?.unit} ${margins?.right+margins?.unit} ${margins?.bottom+margins?.unit} ${margins?.left+margins?.unit} !important; 
            size: ${paperDimensions?.[paperFormat]?.width+paperDimensions?.[paperFormat]?.unit} ${paperDimensions?.[paperFormat]?.height+paperDimensions?.[paperFormat]?.unit} !important; 
        }`;
      };


    return (      
        <div>
            <style>{getPageCss()}</style>
            {/* {pageType} */}
            {page?.type === "ProtoFrontpage" ? <ProtoFrontpage page={page} localCopyOfPageArrayConfig={localCopyOfPageArrayConfig} setLocalCopyOfPageArrayConfig={setLocalCopyOfPageArrayConfig}/> : <React.Fragment></React.Fragment>}
            {page?.type === "ProtoSingleFileGraph" ? <ProtoSingleFileGraph page={page} pageCurrent={pageCurrent} localCopyOfPageArray={localCopyOfPageArray} setLocalCopyOfPageArray={setLocalCopyOfPageArray} localCopyOfPageArrayConfig={localCopyOfPageArrayConfig} paperFormat={paperFormat} setLocalCopyOfPageArrayConfig={setLocalCopyOfPageArrayConfig} /> : <React.Fragment></React.Fragment>}
        </div>
    )
}