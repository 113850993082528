import React from 'react'
import { useState} from 'react'


const PageContext = React.createContext()

function PageProvider({ children }) {
    const [page, setPage] = useState("dashboard");
    const [sidebarOpen, setSidebarOpen] = useState(true)

    const value = { page, setPage, sidebarOpen, setSidebarOpen }


    return (
        <PageContext.Provider value={value}>
            {children}
        </PageContext.Provider>
    )
}


export { PageProvider, PageContext }