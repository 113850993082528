import React from "react";
import exportPng from "../utils/PNGexporter.js"

// MUI V5
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

// ICONS
import IosShareIcon from '@mui/icons-material/IosShare';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import DescriptionIcon from '@mui/icons-material/Description';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    // minWidth: 100,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function ToolbarExport(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{marginLeft: "10px"}}>
      <Tooltip title="Export data">
        <span>
          <Button
            id="customized-button"
            variant="contained"
            disableElevation
            disabled={props.disableMenuButton || props.printMode}
            onClick={handleClick}
            style={{paddingLeft: "6px", paddingRight: "6px", minHeight: 0, minWidth: 0}}
            // endIcon={<KeyboardArrowDownIcon />}
          >
            <IosShareIcon />
            {/* Export */}
          </Button>
        </span>
      </Tooltip>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <ExportMenuItems {...props}/>
      </StyledMenu>
    </div>
  );
}

export function ExportMenuItems(props) {
  const [printDialogOpen, setPrintDialogOpen] = React.useState(false)

  let {
    printMode,
    togglePrintMode
  } = props;

  function exportPngReport(){
    exportPng()
  }

  return (
    <React.Fragment>
    <MenuItem key="print" onClick={() => togglePrintMode()} disabled={printMode}>
      <DescriptionIcon />
      Report
    </MenuItem>
    <MenuItem key="png" onClick={() => exportPngReport()}>
      <ScreenshotMonitorIcon/>
      PNG
    </MenuItem>
    </React.Fragment>
  );
}