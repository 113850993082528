import React, {useState, useEffect, useContext} from "react"

// OWN
import FileBrowser from "./FileExplorer.js"
import { QuerybarContext } from '../../../context/querybarContext';
import ErrorDialog from "../../layout/ErrorDialog.js"

// Test for interface rewrite:
import {INTERFACE_getDirList} from "./interface.js"


export function FileExplorer(props) {

    const [errorOpen, setErrorOpen] = React.useState(false);
    const [error, setError] = React.useState({title: "test", e: ""});
    const [dirList, setDirList] = useState({})
    const [currentPath, setCurrentPath] = useState([""])
    const [pathRefresh, setPathRefresh] = useState(false);

    const QueryContext = useContext(QuerybarContext);

    let { 
      hideLeftClickMenu,
    } = props;
        
    async function getDirList(pathToGet){
      QueryContext.setQuerybarActive(true)
      return await INTERFACE_getDirList(pathToGet).then((res) => {
          setDirList(res)
          QueryContext.setQuerybarActive(false)
          return res.data;
        }).catch(e => { 
          console.log("error requesting share list", e) 
          QueryContext.setQuerybarActive(false)
          setError({ title: "Error lisiting files in share", e: e});
          setErrorOpen(true)
        })
  }


    useEffect(() => {
        getDirList(currentPath[0])
    }, [])

    useEffect(() => {
      getDirList(buildCurrentPathString())
    }, [currentPath, pathRefresh])



    function buildCurrentPathString(){
      let path = "";
      currentPath.forEach((dir,i) => {
        if(i === 0 || i === 1){
          path = dir;
        } else {
          path = path.concat("/"+dir)
        }
      })
      return path
    }
  
    return (
      <React.Fragment>
        <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
        <div style={{height: "100%"}}>
          <FileBrowser 
            dirList={dirList} 
            currentPath={currentPath} 
            setCurrentPath={setCurrentPath} 
            setPathRefresh={setPathRefresh} 
            pathRefresh={pathRefresh} 
            hideLeftClickMenu={hideLeftClickMenu}
            setErrorOpen={setErrorOpen} 
            setError={setError}
          />
        </div>
      </React.Fragment>

    );
  }

  export default function FileExplorerContainer(props) {

    return (
      <div style={{height: "calc(100vh - 180px)"}}>
        <FileExplorer hideLeftClickMenu={false} />
      </div>
    );
  }
  