import { backendApi } from "../../api/calls"

//  INTERFACE_getDirList
//  @param {string} pathToGet   Path to request from API w/o leading or trailing slash (ex: "44448888/test")
//  @return {array}             Directory List as array of Objects (ex: [{kind: "file", name: "test.msd", size: 128},{...},{...}])
////////////////////////////////////////////////////////////////////////////////
export async function INTERFACE_getDirList(pathToGet){
    return await backendApi.get("/api/files/listshare", {params: {path: pathToGet}}).then((res) => {
            return res.data
        }).catch(e => { 
            throw e
        })
}


//  INTERFACE_deleteFile
//  @param {string} pathToDelete    Path to request that API deletes incl leading slash (ex: "/44448888/test.mseed")
//  @return {object}                ?? not important
////////////////////////////////////////////////////////////////////////////////
export async function INTERFACE_deleteFile(pathToDelete){
    if(pathToDelete !== undefined && pathToDelete !== null && pathToDelete !== "") {
        return await backendApi.get("/api/files/delete", {params : { path: pathToDelete }}).then(res => {
          return res
      }).catch(e => {
          return e
      })
      } else {
        // there is no path to handle, so do nothing
      }
}

//  INTERFACE_deleteMultipleFiles
//  @param {array}      pathArr       Array of directories and files to delete (ex: [{path: "/123", files: [{name: "test.msd"},{...},{...}]},{...},{...}])
//  @param {int}        timeout       Number of miliseconds to wait before timing out the request
//  @param {function}   updateProgess Function to be called every time one request has been handled
//  @param {function}   shouldIAbort  Returns bool. function to check if remaining requests should be aborted
//  @return {object}                  {allFilesDeleted: <Bool>}
////////////////////////////////////////////////////////////////////////////////
export async function INTERFACE_deleteMultipleFiles(pathArr, timeout, updateProgess, shouldIAbort){

    // 1: get list of file paths (path and filename already combined in one string)
    let tempArr = pathArr;
    let dirsProcessed = 0;
    let errors = undefined;

    // 2: iterate over the list with a call to backend every time (this way it is not required to implement a seperate endpoint)   
    if(pathArr !== undefined && pathArr !== null && pathArr !== "") {
      
      return Promise.all(tempArr.map(async (dir,dirIndex) => {

        // This used to be a promise, but it must be executed sequentially for
        // the user to be able to cancel remaining entries. It also takes load off the server this way
        for(let [fileIndex, file] of dir.files.entries()){
          let completePath = dir.path + file.name
          let abort = !shouldIAbort();

          if(abort){
            throw new Error("Aborted")
          }

          await backendApi.get("/api/files/delete", {params : { path: completePath }, timeout: timeout === undefined ? 10000 : timeout}).then(res => {
            updateProgess()
            return res
          }).catch(e => {
              errors = e;
              return e
          })
        }

        dirsProcessed++;

      })).then(d => {
        if(dirsProcessed === tempArr.length && errors === undefined){
            // if all files and dirs were processed,
            // then set a flag to indicate that all were deleted
            return {allFilesDeleted: true}
        } else {
            return {allFilesDeleted: false}
        }
      }).catch(e => {
        console.log(e)
        if(dirsProcessed === tempArr.length && errors === undefined){
            // if all files and dirs were processed,
            // then set a flag to indicate that all were deleted
            return {allFilesDeleted: true}
        } else {
            return {allFilesDeleted: false}
        }
      })
    }
  }


//  INTERFACE_downloadFile
//  @param {string} path    Path of the file to download (ex: "/12345678/test.msd")
//  @return {arrayBuffer}   - streams the file (arrayBuffer)
////////////////////////////////////////////////////////////////////////////////
export async function INTERFACE_downloadFile(path, timeout){

  const clientGetMiniseedFileConfig = {
    responseType: 'arraybuffer',
    params: {
        path: path
    },
    headers: {
        'Content-Type': 'application/vnd.fdsn.mseed',
        'Accept': 'application/msd'
    },
    timeout: timeout === undefined ? 10000 : timeout,
  }

  if(path !== undefined && path !== null && path !== "") {
      return await backendApi.get("/api/files/download", clientGetMiniseedFileConfig).then(res => {
        return res
    }).catch(e => {
        // because we request an array buffer, the json of the error response is returned as an array buffer
        var enc = new TextDecoder("utf-8");
        let errorMessage = JSON.parse(enc.decode(e.response.data)).message;
        e.message = errorMessage;
        throw e
    })
    } else {
      // there is no path to handle, so do nothing
    }
}

//  INTERFACE_downloadZip
////////////////////////////////////////////////////////////////////////////////
export async function INTERFACE_downloadZip(paths, timeout){

  const clientGetMiniseedFileConfig = {
    responseType: 'arraybuffer',
    params: {
        paths: "test"
        // paths: JSON.stringify(paths)
    },
    headers: {'Content-Type': 'application/json'},
    // headers: {
    //   'Content-Type': 'application/zip',
    //   'Accept': 'application/zip'
    // },
    timeout: timeout === undefined ? 10000 : timeout,
  }

  if(paths !== undefined && paths !== null && paths !== "") {
      return await backendApi.post("/api/files/downloadzip", JSON.stringify({paths: paths}), clientGetMiniseedFileConfig).then(res => {
        return res
    }).catch(e => {
        // because we request an array buffer, the json of the error response is returned as an array buffer
        // var enc = new TextDecoder("utf-8");
        // let errorMessage = JSON.parse(enc.decode(e.response.data)).message;
        // e.message = errorMessage;
        console.log(e)
        throw e
    })
    } else {
      // there is no path to handle, so do nothing
    }
}

//  INTERFACE_targetConfiguration
//  returns a list of configuration parameters which can differ
//  between deployment targets, like embedded and cloud
////////////////////////////////////////////////////////////////////////////////

export const INTERFACE_targetConfiguration = {
    enableDataViewerMenu: true,
}