import React, {useState, useEffect} from 'react'

// OWN
import { strIsDefined  } from "../../../../helpers.js";

// MUI V5
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// ICONS
import InfoIcon from '@mui/icons-material/Info';

export function IndividualStatChips(props){

    let {
        loadingGraphData,
        metaData,
        channelIndex,
        colorArr,
        zoomDetails,
    } = props;

    return (
        <React.Fragment>
            <Tooltip title={zoomDetails?.[channelIndex]?.[2]?.[0]?.zoomedRMS || ""}>
                <Chip 
                    style={{fontSize: "12px", padding: "0px", height: "16px", margin: "2px 5px 2px 0px", paddingTop: "2px"}}
                    label={
                        loadingGraphData ? 
                        <Skeleton animation="wave" height={10} width="80px"/>
                        : <>
                            <Typography variant="caption" style={{opacity: "0.8", color: colorArr[0]}}>{"RMS "}</Typography>
                            <Typography variant="caption" style={{opacity: "0.8"}}>{zoomDetails?.[channelIndex]?.[2]?.[0]?.zoomedRMS.toFixed(2) || ""}</Typography>
                        </>
                }
                />
            </Tooltip>
            <Tooltip title={zoomDetails?.[channelIndex]?.[2]?.[0]?.zoomedMin || ""}>
            <Chip 
                style={{fontSize: "12px", padding: "0px", height: "16px", margin: "2px 5px 2px 0px", paddingTop: "2px"}}
                label={
                    loadingGraphData ? 
                    <Skeleton animation="wave" height={10} width="80px"/>
                    : <>
                        <Typography variant="caption" style={{opacity: "0.8", color: colorArr[0]}}>{"MIN "}</Typography>
                        <Typography variant="caption" style={{opacity: "0.8"}}>{strIsDefined(zoomDetails?.[channelIndex]?.[2]?.[0]?.zoomedMin.toFixed(2))}</Typography>
                    </>
            }
            />
            </Tooltip>
            <Tooltip title={zoomDetails?.[channelIndex]?.[2]?.[0]?.zoomedMax || ""}>
            <Chip 
                style={{fontSize: "12px", padding: "0px", height: "16px", margin: "2px 5px 2px 0px", paddingTop: "2px"}}
                label={
                    loadingGraphData ? 
                    <Skeleton animation="wave" height={10} width="80px"/>
                    : <>
                        <Typography variant="caption" style={{opacity: "0.8", color: colorArr[0]}}>{"MAX "}</Typography>
                        <Typography variant="caption" style={{opacity: "0.8"}}>{strIsDefined(zoomDetails?.[channelIndex]?.[2]?.[0]?.zoomedMax.toFixed(2))}</Typography>
                    </>
                }
            />
            </Tooltip>
            <Tooltip title={zoomDetails?.[channelIndex]?.[2]?.[0]?.zoomedMax - zoomDetails?.[channelIndex]?.[2]?.[0]?.zoomedMin || ""}>
            <Chip 
                style={{fontSize: "12px", padding: "0px", height: "16px", margin: "2px 5px 2px 0px", paddingTop: "2px"}}
                label={
                    loadingGraphData ? 
                    <Skeleton animation="wave" height={10} width="80px"/>
                    : <>
                        <Typography variant="caption" style={{opacity: "0.8", color: colorArr[0]}}>{"PP "}</Typography>
                        <Typography variant="caption" style={{opacity: "0.8"}}>{strIsDefined((zoomDetails?.[channelIndex]?.[2]?.[0]?.zoomedMax - zoomDetails?.[channelIndex]?.[2]?.[0]?.zoomedMin).toFixed(2))}</Typography>
                    </>
                }
            />
            </Tooltip>
        </React.Fragment>
    )
}

export default function ChannelStatsChip(props){

    const [anchorPopoverEl, setAnchorPopoverEl] = useState(null);
    const [copyNotice, setCopyNotice] = useState(false)

    let {
       loadingGraphData,
       metaData,
       channelIndex,
       colorArr,
       graphRows,
       zoomDetails,
    } = props;

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    const handlePopoverOpen = (event) => {
        setAnchorPopoverEl(event.currentTarget);
    };    
    
    const handlePopoverClose = () => {
        setAnchorPopoverEl(null);
    };  
    
    const open = Boolean(anchorPopoverEl);
    const id = open ? 'simple-popover' : undefined;
    
    return (
        <React.Fragment>
            {
                largeScreen && graphRows ? 
                <IndividualStatChips {...props}/>
                : <></>
            }
            {/* <Chip 
                icon={<InfoIcon sx={{fontSize: "14px", marginTop: "-2px"}}/>}
                style={{fontSize: "12px", padding: "0px", height: "16px", margin: "2px 5px 2px 0px", paddingTop: "2px"}}
                onClick={handlePopoverOpen} 
                label={
                    loadingGraphData ? 
                    <Skeleton animation="wave" height={10} width="80px"/>
                    : <>
                        <Typography variant="caption" style={{opacity: "0.8"}}>Stats</Typography>
                    </>
                }
            />
            <Popover
                id={id}
                elevation={1}
                open={open}
                onClose={handlePopoverClose}
                anchorEl={anchorPopoverEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >{
                metaData !== undefined ?
                <List dense={true} hover={true} component="nav" >
                    <ListItemButton
                        key={1} 
                        onClick={() => {
                            navigator.clipboard.writeText(String(metaData[channelIndex].rms))
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={String(metaData[channelIndex].rms)} secondary="RMS" />
                    </ListItemButton>
                    <ListItemButton
                        key={1} 
                        onClick={() => {
                            navigator.clipboard.writeText(String(metaData[channelIndex].max))
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={String(metaData[channelIndex].max)} secondary="Maximum" />
                    </ListItemButton>
                    <ListItemButton
                        key={1} 
                        onClick={() => {
                            navigator.clipboard.writeText(String(metaData[channelIndex].min))
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={String(metaData[channelIndex].min)} secondary="Minimum" />
                    </ListItemButton>
                    <ListItemButton
                        key={1} 
                        onClick={() => {
                            navigator.clipboard.writeText(String(metaData[channelIndex].mean))
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={String(metaData[channelIndex].mean)} secondary="Mean" />
                    </ListItemButton>
                </List>
                : <></>
            }

            </Popover>
            <Snackbar
                open={copyNotice}
                autoHideDuration={1000}
                onClose={() => {setCopyNotice(false)}}
                message="Copied"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                ContentProps={{
                    sx: {
                    display: 'block',
                    textAlign: "center"
                    }
                }}
            /> */}
        </React.Fragment>

    )
}