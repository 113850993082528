import React, { useEffect, useContext } from 'react';

// OWN 
import { backendApi, clientConfigApplication } from "../../api/calls"
import { AuthContext } from "../../../context/auth-context"
import { QuerybarContext } from '../../../context/querybarContext';
import ShowPermissions from "./ShowPermissions"
import DeleteButton from "./DeleteButton"
import AddButton from "./AddButton"
import Tags from "./Tags"
import { AdminBadge } from "../../layout/AdminBadge"
import SnackBar from "../../layout/SnackBar"
import "../../styles/checkmarkStyles.css"
import ErrorDialog from "../../layout/ErrorDialog.js"


// MUI V5
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';

// ICONS
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EmailIcon from '@mui/icons-material/Email';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LabelIcon from '@mui/icons-material/Label';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonIcon from '@mui/icons-material/Person';

export const userListContext = React.createContext();

async function getUsers(){
    return await backendApi.get("/api/organization/users", clientConfigApplication)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err
        })
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <React.Fragment key={row._id+"rowfrag"}>
            <TableRow key={row._id+"header"} hover={true} sx={{cursor: "pointer"}} onClick={() => setOpen(!open)}>
                <TableCell key={row._id+"avatarcell"} sx={{padding: "0px 10px"}}>
                    <Badge invisible={!row.admin || largeScreen} badgeContent={<VerifiedUserIcon fontSize="small" sx={{marginTop: "8px", marginRight: "4px"}} color="primary"/>}>
                        {/* <Avatar sx={{ width: 30, height: 30, fontSize: "20px", backgroundColor: "gray" }}>{row.name !== undefined ? row.name.charAt(0) : ""}</Avatar> */}
                        <PersonIcon sx={{color: "gray"}}/>
                    </Badge>
                </TableCell>
                <TableCell component="th" scope="row" key={row._id + "namecell"}>
                    {row.name}
                </TableCell>
                {
                    largeScreen ?
                    <>
                        <TableCell key={row._id + "emailcell"}>{row.email}</TableCell>
                        <TableCell key={row._id + "tagcell"}>
                            <Tags row={row} />
                        </TableCell>
                        <TableCell align="right">
                            <DeleteButton id={row._id} user={row}/>
                        </TableCell>
                        <TableCell key={row._id+"expandcell"} align="right">
                            <IconButton edge="end"  aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    </>
                    : 
                    <>
                        <TableCell align="right">
                            <DeleteButton id={row._id} user={row}/>
                        </TableCell>
                        <TableCell key={row._id+"expandcell"} align="right">
                            <IconButton edge="end" aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    </>
                }
            </TableRow>
            <TableRow key={row._id+"detail"}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ width: '100%', typography: 'body1', marginBottom: "20px"}}>
                        <Grid container spacing={2} sx={{marginTop: "30px"}}>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl="auto">
                            <Card variant="outlined" sx={{ height: "100%", minWidth: "250px" }}>
                                <CardContent>
                                <Typography sx={{ fontSize: 14, color: "primary.main", mb: "15px" }} gutterBottom>
                                    Personal Details
                                </Typography>
                                    <List sx={{padding: "0px"}}>
                                        <ListItem sx={{padding: "0px"}}>
                                            <List sx={{padding: "0px"}}>
                                                <ListItem key={row._id+"nameitem"} sx={{padding: "5px 0px"}}>
                                                    <ListItemIcon>
                                                        <EmailIcon />
                                                    </ListItemIcon>
                                                    <ListItemText id={row.email} primary={row.email} disableTypography={true}/>
                                                </ListItem>
                                                <ListItem key={row._id + "phoneitem"} sx={{padding: "5px 0px"}}>
                                                    <ListItemIcon>
                                                        <PhoneIphoneIcon />
                                                    </ListItemIcon>
                                                    <ListItemText id={row.phone} primary={row.phone} disableTypography={true}/>
                                                </ListItem>
                                                <ListItem key={row._id + "tagitem"} sx={{padding: "5px 0px"}}>
                                                    <ListItemIcon>
                                                        <LabelIcon />
                                                    </ListItemIcon>
                                                    <Tags row={row} />
                                                </ListItem>
                                            </List>
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl="auto">
                                <Card sx={{ height: "100%", minWidth: "300px" }} variant="outlined">
                                <CardContent>
                                    <Typography sx={{ fontSize: 14, color: "primary.main" }} gutterBottom>
                                    Permissions
                                    </Typography>
                                    {/* <List sx={{padding: "0px"}}>
                                        <ListItem sx={{padding: "0px"}}> */}
                                            <ShowPermissions user={row} key={row._id+"permissions"}/>
                                        {/* </ListItem>
                                    </List> */}
                                </CardContent>
                                </Card>   
                            </Grid> 
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


export default function CollapsibleTable() {
    const [users, setUsers] = React.useState([""]);
    const [mySnackBar, setMySnackBar] = React.useState({ display: false, type: "", message: "" });
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [error, setError] = React.useState({title: "test", e: ""});

    const context = useContext(AuthContext);
    const QueryContext = React.useContext(QuerybarContext);
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    function getOneUser() {
        QueryContext.setQuerybarActive(true)
        const user = getUsers().then(users => {
            setUsers(users.data.users)
            return users.data.users
        }).then(users => {
            QueryContext.setQuerybarActive(false)
            return users
        }).catch(e => {
            QueryContext.setQuerybarActive(false)
            let errorMessage = e.response.data.message || e.message;
            setMySnackBar({ display: true, type: "error", message: `${errorMessage ? `${errorMessage}.` : ""} ` });
            setTimeout(function () {
                setMySnackBar({ display: false, type: "", message: "" });
            }, 5000);
        })
        
        return user
    }

    useEffect(() => {
        getOneUser();
    }, []);
    
    return (
        <React.Fragment>
            <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
            <div key="usertablediv">
                <userListContext.Provider value={{ state: users, update: setUsers }}>
                    {
                        mySnackBar.display === true && mySnackBar.type === "error" ?
                            <>
                            <SnackBar type={mySnackBar.type} message={mySnackBar.message} />
                            </>
                            : ""
                    }
                        <MuiAppBar
                            style={{
                                position: "-webkit-sticky",
                                position: "sticky", // eslint-disable-line
                                top: largeScreen ? theme.spacing(8) : theme.spacing(7),
                                marginTop: "-78px"
                            }} 
                            elevation={0} 
                            sx={{background: "transparent"}}
                        >

                            <Toolbar variant="dense">
                                <Box style={{flexGrow: 1}}>
                                </Box>
                                <AddButton organization={context.state.user.organization}/>
                            </Toolbar>
                        </MuiAppBar>

                        {/* <Grid container direction="row"  > */}
                            <Box sx={{ flexGrow: 1, marginTop: "2rem" }}>
                                <AdminBadge />
                            </Box>
                        {/* </Grid> */}

                        <Card sx={{ width: "100%", marginTop: "1rem"}}>
                            <CardContent >
                                <CardHeader 
                                    style={{        
                                        padding: 0,
                                        marginTop: "-5px",
                                        marginBottom: "5px",
                                        color: "#a1a1a1",
                                        textColor: "#a1a1a1"
                                    }} 
                                    avatar={<PeopleAltIcon style={{ marginTop: "4px" }} />} 
                                    title="User Management" 
                                />
                            </CardContent>
                            {/* <TableContainer component={Paper} sx={{marginTop: "2rem"}}> */}
                            <Table aria-label="collapsible table" size="small">
                                <TableHead>
                                    <TableRow key="headerrow">
                                        <TableCell style={{width: "50px"}}></TableCell>
                                        <TableCell key="name" style={{width: "250px"}}>Name</TableCell>
                                        {
                                            largeScreen ?
                                                <>
                                                    <TableCell key="email" style={{width: "250px"}}>Email</TableCell>
                                                    <TableCell key="tags" style={{width: "250px"}}>Tags</TableCell>
                                                    <TableCell key="actions" align="right">Actions</TableCell>
                                                    <TableCell key="expander" align="right" style={{width: "80px"}}>More</TableCell>
                                                </>
                                                :
                                                <>
                                                    <TableCell key="actions" align="right">Actions</TableCell>
                                                    <TableCell key="expander" align="right" style={{width: "80px"}}>More</TableCell>
                                                </>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((row) => (
                                        <Row key={row._id+"row"} row={row} />
                                    ))}
                                </TableBody>
                            </Table>
                        {/* </TableContainer> */}
                        </Card>
                    

                </userListContext.Provider>
            </div>
        </React.Fragment>
        

    );
}