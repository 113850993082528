import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// OWN 
import { backendApi, clientConfigApplication } from "../../api/calls"
import SnackBar from "../../layout/SnackBar"
import { Transition } from "../../styles/SlideTransition"
import { CheckMarkDialog } from "../../layout/CheckMark"
import "../../styles/checkmarkStyles.css"
import SvgInstrumentComponent from "../../../graphics/instrument"
import ErrorDialog from "../../layout/ErrorDialog.js"

// MUI V5
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

// ICONS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import FileCopyIcon from '@mui/icons-material/FileCopy';

export default function AddButton(props) {
    const [open, setOpen] = React.useState(false);
    const [openCodeDialog, setOpenCodeDialog] = React.useState(false);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [error, setError] = React.useState({title: "test", e: ""});
    const [activationCode, setActivationCode] = React.useState("");
    const [serialnumber, setSerialnumber] = React.useState("");
    const [mySnackBar, setMySnackBar] = React.useState({ display: false, type: "", message: "" });

    function seperateByFour(str, n){
        var ret = [];
        var i;
        var len;

        for (i = 0, len = str.length; i < len; i += n) {
            ret.push(str.substr(i, n))
        }

        return ret
    }

    function handleRequestCode() {
        backendApi.get(`/api/instruments/activationcode/create/${serialnumber}`, clientConfigApplication)
            .then((res) => {
                setOpen(false);
                setActivationCode(res.data.activationcode)
                setOpenCodeDialog(true)
                // here: open a new dialog which shows the code

                return res;
            })
            .catch((e) => {
                console.log(e)
                setError({ title: "Error adding a new instrument", e: e});
                setErrorOpen(true)
                setOpen(false)
            })
    }

    function handleDialogClose() {
        setOpen(false);
    }

    function handleCodeDialogClose() {
        setActivationCode("");
        setOpenCodeDialog(false)
    }

    function setSuccessSnackbar(msg){
        setMySnackBar({ display: true, type: "success", message: "copied!" });
        setTimeout(function () {
            setMySnackBar({ display: false, type: "", message: "" });
        }, 2000);
    }


    return (
        <React.Fragment>
            <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
            {
                mySnackBar.display === true && mySnackBar.type === "success" ?
                    <CheckMarkDialog message={mySnackBar.message} />
                    : ""
            }
            <Dialog 
                open={open} 
                TransitionComponent={Transition} 
                keepMounted onClose={handleDialogClose} 
            >
                <Box sx={{width: "100%", backgroundColor: "primary.light", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Stack>
                        {/* <AddCircleIcon style={{fontSize: 80}} sx={{ color: "primary.contrastText", marginTop: "20px", marginLeft: "auto", marginRight: "auto" }} /> */}
                        <SvgInstrumentComponent fill="#1565c0" secondcolor="#42a5f5" style={{width: "80px", marginLeft: "auto", marginRight: "auto", marginTop: "20px"}}></SvgInstrumentComponent>
                        <DialogTitle id="alert-dialog-title" sx={{color: "primary.contrastText"}}> Add New Instrument </DialogTitle>
                    </Stack>
                </Box>
                <DialogContent>
                    <DialogContentText>
                        Enter the 8-digit serial number of the instrument.<br />
                        A verification code will be returned.
                </DialogContentText>
                    <TextField 
                        margin="normal" 
                        inputProps={{ minLength: 8, maxLength: 8 }} 
                        name="Serialnumber" 
                        id="Serialnumber" 
                        label="Serialnumber" 
                        type="tel" 
                        fullWidth 
                        variant="outlined" 
                        onChange={(e) => setSerialnumber(e.target.value)} 
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleRequestCode} color="primary" variant="contained" startIcon={<VpnKeyIcon />} disabled={serialnumber === "" || serialnumber === undefined ? true : false}>
                        Get Key
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCodeDialog} TransitionComponent={Transition} keepMounted onClose={handleCodeDialogClose}>
                <DialogTitle id="code-dialog">{`Validation Code`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the following validation code in instrument <b>{serialnumber}</b>.<br/>
                        Please take note of the code. It is valid for <b>1 hour</b> only.<br/> 
                        The code can not be recalled after closing this dialog.<br/>
                        The instrument will appear under "passive instruments" once verified.
                    </DialogContentText>
                    <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "center"}}>
                        <Typography variant="h6" color="primary">{seperateByFour(activationCode.toUpperCase(), 4).join(" ")}</Typography>
                        <CopyToClipboard text={activationCode.toUpperCase()} onCopy={() => { setSuccessSnackbar() }}>
                            <FileCopyIcon style={{marginLeft: "15px", color: "lightgrey", cursor: "hand"}}/>
                        </CopyToClipboard>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCodeDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Tooltip title="Add a new instrument">
                <Button onClick={() => setOpen(true)} color="primary" variant="contained" startIcon={<AddCircleIcon />} style={{ marginRight: "-1rem" }}>
                    instrument
                </Button>
            </Tooltip>
        </React.Fragment>
    )
}
