import React, {useContext, useState} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// OWN 
import { backendApi, clientConfigApplication } from "../../../api/calls"
import { Transition } from "../../../styles/SlideTransition"
import "../../../styles/checkmarkStyles.css"
import SvgInstrumentComponent from "../../../../graphics/instrument"
import ErrorDialog from "../../../layout/ErrorDialog.js"
import { instrumentListContext } from "../index"



// MUI V5
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// ICONS
import LinkOffIcon from '@mui/icons-material/LinkOff';
import CloudOffIcon from '@mui/icons-material/CloudOff';

export default function DialogDeleteInstrument(props) {
    const [open, setOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [error, setError] = useState({title: "test", e: ""});
    const [serialnumber, setSerialnumber] = useState("");

    let {selectedSerialNumber} = props;

    let instrumentsContext = useContext(instrumentListContext);



    function handleDelete() {
        backendApi.get(`/api/instruments/delete/${selectedSerialNumber}`, clientConfigApplication)
            .then((res) => {
                setOpen(false);
                updateInstrumentList(selectedSerialNumber)
                // here: open a new dialog which shows the code

                return res;
            })
            .catch((e) => {
                console.log(e)
                setError({ title: "Error adding a new instrument", e: e});
                setErrorOpen(true)
                setOpen(false)
            })
    }

    function handleDialogClose() {
        setOpen(false);
        setSerialnumber("");
    }

    function updateInstrumentList(serialnumberToRemove){
        let list = instrumentsContext.instruments;

        instrumentsContext.instruments.map((instrument, indexOfInstrument) => {
            console.log(instrument)
            if(instrument.serialNumber == serialnumberToRemove){
                list.splice(indexOfInstrument, 1);
            }
        })

        console.log(list)
        instrumentsContext.setInstruments([...list])

    }


    return (
        <React.Fragment>
        <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
            <Dialog 
                open={open} 
                TransitionComponent={Transition} 
                keepMounted onClose={handleDialogClose} 
            >
                <Box sx={{width: "100%", backgroundColor: "primary.light", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Stack>
                        <CloudOffIcon style={{fontSize: 80}} sx={{ color: "primary.contrastText", marginTop: "20px", marginLeft: "auto", marginRight: "auto" }} />
                        <DialogTitle id="alert-dialog-title" sx={{color: "primary.contrastText"}}> Disconnect {selectedSerialNumber} from the cloud  </DialogTitle>
                    </Stack>
                </Box>
                <DialogContent>
                    <DialogContentText>
                        You are about to disconnect the instrument from the cloud. <br /><br />
                    </DialogContentText>
                    <DialogContentText>
                        Are you sure you want to disconnect the instrument from the cloud?<br />
                        Enter the 8-digit serial number of the instrument to confirm.
                    </DialogContentText>
                    <TextField 
                        margin="normal" 
                        inputProps={{ minLength: 8, maxLength: 8 }} 
                        name="Serialnumber" 
                        id="Serialnumber" 
                        label="Serialnumber" 
                        type="tel" 
                        fullWidth 
                        variant="outlined" 
                        onChange={(e) => setSerialnumber(e.target.value)} 
                    />
                        <Typography variant="caption" sx={{color: "gray"}}>
                            Disconnecting an instrument from the cloud will not delete the stored files, but reconnecting it to the cloud later may require physical access to the instrument depending on your setup.
                        </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={serialnumber !== selectedSerialNumber} onClick={handleDelete} color="primary" variant="contained" startIcon={<CloudOffIcon />} >
                        Disconnect {selectedSerialNumber}
                    </Button>
                </DialogActions>
            </Dialog>
            <Button onClick={() => setOpen(true)} variant="outlined" size="small" disableElevation style={{height: "24px"}} >
                <CloudOffIcon />
            </Button>
        </React.Fragment>
    )
}
