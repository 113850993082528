import React, { useState, useEffect, useCallback, useContext} from "react"


// OWN
import { DygraphsDataViewer } from "./dygraphsDataviewer.js"
import ToolbarGraphSettings from "./toolbars/ToolbarGraphSettings.js"
import {SettingsMenuItems} from "./toolbars/ToolbarGraphSettings.js"
import ToolbarGraphZoom from "./toolbars/ToolbarGraphZoom.js"
import ToolbarAnalyze from "./toolbars/toolbarAnalyze.js"
import ToolbarFile from "../../toolbars/ToolbarFile.js"
import {AnalyzeMenuItems} from "./toolbars/toolbarAnalyze.js"
import MobileMenu from "./toolbars/toolbarMobileMenu.js"
import ToolbarChannels from "./toolbars/ToolbarChannels.js"
import {ChannelsMenuItems} from "./toolbars/ToolbarChannels.js"
import ToolbarUnits, { UnitsMenuItems } from "./toolbars/ToolbarUnits.js"
import ToolbarExport from "./toolbars/ToolbarExport.js"
import {ExportMenuItems} from "./toolbars/ToolbarExport.js"
import {INTERFACE_downloadFile} from "../../../fileExplorer/interface.js"
import { SeismicDataContext } from "../../../../../context/seismicDataContext.js";
import { QuerybarContext } from '../../../../../context/querybarContext.js';
import ErrorDialog from "../../../../layout/ErrorDialog.js"
import { removeBlockette2000, Blockette2000Payload, blockette2000WasMadeBySeisodin } from "./utils/blockette2000.js"

// MUI V5
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';

// ICONS
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const seisplot = require("seisplotjs");
// http://crotwell.github.io/seisplotjs/api/index.html

export function PlotData(props){
    const [errorOpen, setErrorOpen] = useState(false);
    const [error, setError] = useState({title: "test", e: ""});
    const [collapseOpen, setcollapseOpen] = useState({units: false, channels: false, analyze: false, settings: false, export: false})
    const [data, setData] = useState([]);
    const [splitChannels, setSplitChannels] = useState(true);
    const [linkTimeScales, setLinkTimeScales] = useState(false);
    const [resetZoom, setResetZoom] = useState(true);
    const [removeOffset, setRemoveOffset] = useState(false);
    const [recallOriginalData, setRecallOriginalData] = useState(false);
    const [graphRows, setGraphRows] = useState(true);
    const [graphHeight, setGraphHeight] = useState(33);
    const [visibleChannels, setVisibleChannels] = useState([]);
    const [disableMenuButton, setDisableMenuButton] = useState(true);
    const [loadingGraphData, setLoadingGraphData] = useState(false);
    const [physicalUnits, setPhysicalUnits] = useState(false); // used for temporary implementation
    const [printMode, setPrintMode] = useState(false);
    const [units, setUnits] = useState({prev: [], current: []}); // [{hasSeisodinBlockette2000: true, applyPhysicalUnits: false},{hasSeisodinBlockette2000: true, applyPhysicalUnits: false}..]
    const [fft, setFft] = useState({
        stateFlipper: false,
        firstRender: false,
        window: "HANNING",
        windowlength: 0.05,
        logscaleY: false,
        logscaleX: false,
        live: true,
    })
    const [filter, setFilter] = useState({
        stateFlipper: false,
        type: "bp", 
        name: "butterworth",
        poles: 2,
        epsilon: 0.5,
        lowCorner: 0.5,
        highCorner: 10,
    })
    const [configurableGraphSettings, setConfigurableGraphSettings] = useState({
        // in this list, only configurable settings are handled
        drawPoints: false,
        fillGraph: true,
        labelsUTC: true,
        axes: {
            x: {
              drawGrid: false,
            },
            y: {
              drawGrid: true,
            }
          }
    })

    let { path, pathArr } = props;

    const SeisDataContext = useContext(SeismicDataContext); 
    const QueryContext = useContext(QuerybarContext);
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    // Helps correctly update the units state
    // with both the previous and the current value
    /////////////////////////////////////////////////
    function updateUnitsState(oldState, newState){
        let updatedState = {prev: oldState, current: newState}
        setUnits(updatedState)
    }

    const getDataFromPath = useCallback (async () => {
        console.log("DEBUG: getDataFromPath callback was run (depends on path)")
        QueryContext.setQuerybarActive(true)
        setLoadingGraphData(true)
        return await INTERFACE_downloadFile(path).then(res => {
            // parse arrayBuffer into an array of DataRecords
            var datarecords = seisplot.miniseed.parseDataRecords(res.data)
                        
            // handle possible blockette2000
            var datarecordsWithBlockette2000Extracted = removeBlockette2000(datarecords)
             
            // splits the DataRecords by channel and creates a single Seismogram for each channel.
            var seismogramsperchannel = seisplot.miniseed.seismogramPerChannel(datarecordsWithBlockette2000Extracted.recordsWithoutBlockette2000)

            if(seismogramsperchannel.length < 1 || !Array.isArray(seismogramsperchannel)){
                throw new Error("File has no data channels")
            }

            // flags
            let nonContiguousDataRemovedFromFile = false;
            let blockette2000WasFoundInFile = false;
            let b2000Payload = {};

            // remove channels which are non-contigous
            seismogramsperchannel.forEach((d, i) => {
                // if channel is non-contigous, then remove it from the array
                if(!d.isContiguous()){
                    console.log("non-contiguous data found:")
                    console.log(d)
                    seismogramsperchannel.splice(i,1)
                    nonContiguousDataRemovedFromFile = true;
                }
            })

            // set a flag if Blockette2000 data was found in the file
            if(datarecordsWithBlockette2000Extracted.blockette2000Records.length > 0){
                blockette2000WasFoundInFile = true
                b2000Payload = Blockette2000Payload(datarecordsWithBlockette2000Extracted.blockette2000Records, datarecordsWithBlockette2000Extracted.blockette2000ByteOffset)
            } else {
                blockette2000WasFoundInFile = false
            }

            // add the filename into the seismogram so it can be used later
            seismogramsperchannel.forEach((d, i) => {
                    seismogramsperchannel[i].fileName = path.substring(path.lastIndexOf("/") + 1);
                    seismogramsperchannel[i].nonContiguousDataRemovedFromFile = nonContiguousDataRemovedFromFile;
                    seismogramsperchannel[i].blockette2000WasFoundInFile = blockette2000WasFoundInFile;
                    seismogramsperchannel[i].blockette2000Payload = b2000Payload;
                    seismogramsperchannel[i].blockette2000BySeisodin = blockette2000WasFoundInFile ? blockette2000WasMadeBySeisodin(b2000Payload) : false;
            })

            QueryContext.setQuerybarActive(false)
            return seismogramsperchannel
        }).catch(e => {
            QueryContext.setQuerybarActive(false)
            // console.log("Error getting file data")
            // console.log(e.message)
            // setError({ title: "Error getting file data1", e: e});
            // setErrorOpen(true)
            throw Error(e)
        })

    }, [path])

    const getDataFromPathArr = useCallback (async () => {
        let seismogramArray = []
        let channelVisibilityArr = []
        let channelUnitArr = []
        let counter = 0;

        if(countSelectedFiles()>0){
            QueryContext.setQuerybarActive(true)
            setLoadingGraphData(true)
            // const asyncRes = await Promise.all(pathArr.map(async (dir, dirIndex) => {
            await Promise.all(pathArr.map(async (dir, dirIndex) => {
                return await Promise.all(dir.files.map(async (file, fileIndex) => {
                    return await INTERFACE_downloadFile(dir.path+file.name).then(res => {
                        // parse arrayBuffer into an array of DataRecords
                        var datarecords = seisplot.miniseed.parseDataRecords(res.data)

                        // handle possible blockette2000
                        var datarecordsWithBlockette2000Extracted = removeBlockette2000(datarecords)
                        
                        // splits the DataRecords by channel and creates a single Seismogram for each channel.
                        var seismogramsperchannel = seisplot.miniseed.seismogramPerChannel(datarecordsWithBlockette2000Extracted.recordsWithoutBlockette2000)

                        if(seismogramsperchannel.length < 1 || !Array.isArray(seismogramsperchannel)){
                            throw new Error("File has no data channels")
                        }
                        
                        // Flags
                        let nonContiguousDataRemovedFromFile = false;
                        let blockette2000WasFoundInFile = false;
                        let b2000Payload = {};
            
                        // remove channels which are non-contigous
                        seismogramsperchannel.forEach((d, i) => {
                            // if channel is non-contigous, then remove it from the array
                            if(!d.isContiguous()){
                                seismogramsperchannel.splice(i,1)
                                nonContiguousDataRemovedFromFile = true;
                            }
                        })

                        // set a flag if Blockette2000 data was found in the file
                        if(datarecordsWithBlockette2000Extracted.blockette2000Records.length > 0){
                            blockette2000WasFoundInFile = true
                            b2000Payload = Blockette2000Payload(datarecordsWithBlockette2000Extracted.blockette2000Records, datarecordsWithBlockette2000Extracted.blockette2000ByteOffset)
                        } else {
                            blockette2000WasFoundInFile = false
                        }

                        // add the filename into the seismogram so it can be used later
                        seismogramsperchannel.forEach((d, i) => {
                            seismogramsperchannel[i].fileName = file.name;
                            seismogramsperchannel[i].nonContiguousDataRemovedFromFile = nonContiguousDataRemovedFromFile;
                            seismogramsperchannel[i].blockette2000WasFoundInFile = blockette2000WasFoundInFile;
                            seismogramsperchannel[i].blockette2000Payload = b2000Payload;
                            seismogramsperchannel[i].blockette2000BySeisodin = blockette2000WasFoundInFile ? blockette2000WasMadeBySeisodin(b2000Payload) : false;
                        })
                        seismogramArray.push(seismogramsperchannel)
    
                        let channelToShowInThisFile = []
                        let tempUnitArray = [];

                        seismogramsperchannel.forEach((seismogram,seismogramIndex) => {
                            channelToShowInThisFile.push(true);
                            tempUnitArray.push({hasSeisodinBlockette2000: seismogram.blockette2000BySeisodin !== undefined ? seismogram.blockette2000BySeisodin : false, applyPhysicalUnits: false})
                        })
    
                        channelVisibilityArr.push(channelToShowInThisFile);
                        channelUnitArr.push(tempUnitArray);
                        
                        counter++

                        QueryContext.setQuerybarActive(false)

                        return seismogramsperchannel
                    }).catch(e => {
                        QueryContext.setQuerybarActive(false)
                        // setError({ title: "Error getting file data2", e: e});
                        // setErrorOpen(true)
                        throw Error(e)
                    })
                }))
            }))
    
            setData(seismogramArray)
            console.log("DEBUG: VisibleChannels set from multiple files effect")
            console.log(channelVisibilityArr)
            setVisibleChannels(channelVisibilityArr)
            // setUnits(channelUnitArr)
            // updateUnitsState(channelUnitArr, channelUnitArr)
            updateUnitsState([], channelUnitArr) // try not having a prev state if first load
        } 
    }, [SeisDataContext.openMultipleFiles])

    // Handles selecting multiple files on first open
    // This ensures ability to open multiple files
    // from the file explorer.
    ////////////////////////////////////////////
    useEffect(() => {
        getDataFromPathArr().then(d => {
            SeisDataContext.setOpenMultipleFiles(prevState => !prevState)
        }).catch(e => {
            e = {...e, response: {data: { message: e.message}}};
            setError({ title: "Error getting file data", e: e});
            setErrorOpen(true)
            setLoadingGraphData(false)
        })

        // to ensure that multiple files open when selected directly from the file explorer
        // it is necessary to attempt getting data from pathArr immediately at startup 
        // of the component. The function call to getDataFromPathArr checks under 
        // the hood if any files are actually selected.
    }, [])


    // Handles selecting multiple files
    ////////////////////////////////////////////
    useEffect(() => {
        getDataFromPathArr().then(d => {
            
        }).catch(e => {
            e = {...e, response: {data: { message: e.message}}};
            setError({ title: "Error getting file data", e: e});
            setErrorOpen(true)
            setLoadingGraphData(false)
        })
    }, [SeisDataContext.openMultipleFiles, getDataFromPathArr])


    // Handles selecting a single file
    ////////////////////////////////////////////
    useEffect(() => {
        if(path && path !== undefined && path !== null && path !== "" && path.length > 0){
            console.log("DEBUG: path changed, therefore executing getDataFromPath")
            getDataFromPath().then(d => {
                console.log("DEBUG: succesfully ran getDataFromPath and now setting state _data_ & _visibleChannels_")
                let seismogramArray = []
                seismogramArray.push(d);
                // seismogramArray[0] = d;
                setData(seismogramArray)
                let newVisibilityArray = [];
                let tempUnitArray = [];
                d.forEach((v,i) => {
                    newVisibilityArray.push(true);
                    tempUnitArray.push({hasSeisodinBlockette2000: v.blockette2000BySeisodin !== undefined ? v.blockette2000BySeisodin : false, applyPhysicalUnits: false})
                })

                console.log("DEBUG: VisibleChannels set from single files effect")
                console.log([newVisibilityArray])

                setVisibleChannels([newVisibilityArray])
                console.log("newVisibilityArray")
                console.log([newVisibilityArray])
                // setUnits([tempUnitArray])
                updateUnitsState([tempUnitArray],[tempUnitArray])

            }).catch(e => {
                e = {...e, response: {data: { message: e.message}}};
                setError({ title: "Error getting file data", e: e});
                setErrorOpen(true)
                setLoadingGraphData(false)
            })
        }
    }, [path, getDataFromPath])

    // Makes sure that certain menu buttons can't
    // be clicked if no file data is loaded.
    /////////////////////////////////////////////
    useEffect(() => {
        console.log("DEBUG: data or path changed, so checking to see if the menu button should be disabled or enabled")
        if(data.length > 0){
            setDisableMenuButton(false)
        } else {
            setDisableMenuButton(true)
        }
    }, [path, data])

    // DEBUG
    // DEBUG
    /////////////////////////////////////////////
    useEffect(() => {
        console.log("DEBUG: state _data_ was changed")
    }, [data])

    // Counts the number of files
    //////////////////////////////////////////////
    function countSelectedFiles(){
        let count = 0;
        pathArr.forEach((v, i) => {
            if(v.files.length !== undefined){
                count = count + v.files.length
            } else {
                count = count + 0;
            }
        })
        return count
    }

    // Toggles the printMode state
    //////////////////////////////////////////////
    function togglePrintMode(){
        setPrintMode(!printMode)
    }

    let toolbarPropsSettings = {
        configurableGraphSettings,
        setConfigurableGraphSettings,
        splitChannels,
        setSplitChannels,
        linkTimeScales,
        setLinkTimeScales,
        graphRows,
        setGraphRows,
        disableMenuButton,
        data,
        graphHeight,
        setGraphHeight,
        printMode,
        togglePrintMode
    }

    let toolbarPropsAnalyze = {
        removeOffset,
        setRemoveOffset,
        recallOriginalData,
        setRecallOriginalData,
        filter,
        setFilter,
        fft,
        setFft,
        disableMenuButton,
        printMode,
        togglePrintMode
    }

    let toolbarPropsChannels = {
        data,
        visibleChannels,
        setVisibleChannels,
        disableMenuButton,
    }

    let toolbarPropsUnits = {
        data,
        disableMenuButton,
        units, 
        setUnits,
        updateUnitsState,
    }

    let toolbarPropsExport = {
        disableMenuButton,
        printMode,
        togglePrintMode
    }

    return (
        <React.Fragment>
        <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
        <Box>
            <MuiAppBar 
                style={{
                    position: "-webkit-sticky",
                    position: "sticky", // eslint-disable-line
                    top: largeScreen ? theme.spacing(8) : theme.spacing(7),
                    marginTop: "-78px"
                }} 
                elevation={0} 
                sx={{background: "transparent"}}
            >    
              <Toolbar variant="dense">
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    {/* Data Inspector */}
                </Typography>
                <ToolbarGraphZoom 
                    resetZoom={resetZoom}
                    setResetZoom={setResetZoom}
                    disableMenuButton={disableMenuButton}
                />
                {
                    largeScreen ? 
                    <>
                        <ToolbarUnits {...toolbarPropsUnits} />
                        <ToolbarChannels {...toolbarPropsChannels} />
                        <ToolbarAnalyze {...toolbarPropsAnalyze} />
                        <ToolbarGraphSettings {...toolbarPropsSettings} />
                        <ToolbarFile />
                        <ToolbarExport {...toolbarPropsExport} Component={
                                <div>
                                    <DygraphsDataViewer 
                                        seismicData={data} 
                                        configurableGraphSettings={configurableGraphSettings}
                                        splitChannels={splitChannels}
                                        setSplitChannels={setSplitChannels}
                                        linkTimeScales={linkTimeScales}
                                        resetZoom={resetZoom}
                                        setResetZoom={setResetZoom}
                                        removeOffset={removeOffset}
                                        recallOriginalData={recallOriginalData}
                                        filter={filter}
                                        setFilter={setFilter}
                                        fft={fft}
                                        setFft={setFft}
                                        graphRows={graphRows}
                                        setGraphRows={setGraphRows}
                                        visibleChannels={visibleChannels}
                                        setVisibleChannels={setVisibleChannels}
                                        loadingGraphData={loadingGraphData}
                                        setLoadingGraphData={setLoadingGraphData}
                                        units={units}
                                        graphHeight={graphHeight}
                                        updateUnitsState={updateUnitsState}
                                        printMode={printMode}
                                        togglePrintMode={togglePrintMode}
                                    />
                                </div>
                        }/>
                    </>
                    : <>
                        <MobileMenu>
                            <MenuItem key="unitsmenu" onClick={() => {setcollapseOpen(prevState => ({...prevState, units: !collapseOpen.units}))}}>
                                { collapseOpen.units ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                                Units
                            </MenuItem>
                            <Collapse key="unitscollapse" in={collapseOpen.units}>
                                <UnitsMenuItems {...toolbarPropsUnits} />
                            </Collapse>
                            <MenuItem key="channelsmenu" onClick={() => {setcollapseOpen(prevState => ({...prevState, channels: !collapseOpen.channels}))}}>
                                { collapseOpen.channels ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                                Channels
                            </MenuItem>
                            <Collapse key="channelscollapse" in={collapseOpen.channels}>
                                <ChannelsMenuItems {...toolbarPropsChannels} />
                            </Collapse>
                            <MenuItem key="analyzemenu" onClick={() => {setcollapseOpen(prevState => ({...prevState, analyze: !collapseOpen.analyze}))}}>
                                { collapseOpen.analyze ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                                Analyze
                            </MenuItem>
                            <Collapse key="analyzecollapse" in={collapseOpen.analyze}>
                                <AnalyzeMenuItems {...toolbarPropsAnalyze} />
                            </Collapse>
                            <MenuItem key="settingsmenu" onClick={() => {setcollapseOpen(prevState => ({...prevState, settings: !collapseOpen.settings}))}}>
                                { collapseOpen.settings ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                                Settings
                            </MenuItem>
                            <Collapse key="settingscollapse" in={collapseOpen.settings}>
                                <SettingsMenuItems {...toolbarPropsSettings} />
                            </Collapse>
                            <MenuItem key="exportmenu" onClick={() => {setcollapseOpen(prevState => ({...prevState, export: !collapseOpen.export}))}}>
                                { collapseOpen.export ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                                Export
                            </MenuItem>
                            <Collapse key="exportscollapse" in={collapseOpen.export}>
                                <ExportMenuItems {...toolbarPropsExport} />
                            </Collapse>
                        </MobileMenu>
                        <ToolbarFile />
                    </>
                }

              </Toolbar>
            </MuiAppBar>
            <Box>
                <DygraphsDataViewer 
                    seismicData={data} 
                    configurableGraphSettings={configurableGraphSettings}
                    splitChannels={splitChannels}
                    setSplitChannels={setSplitChannels}
                    linkTimeScales={linkTimeScales}
                    resetZoom={resetZoom}
                    setResetZoom={setResetZoom}
                    removeOffset={removeOffset}
                    recallOriginalData={recallOriginalData}
                    filter={filter}
                    setFilter={setFilter}
                    fft={fft}
                    setFft={setFft}
                    graphRows={graphRows}
                    setGraphRows={setGraphRows}
                    visibleChannels={visibleChannels}
                    setVisibleChannels={setVisibleChannels}
                    loadingGraphData={loadingGraphData}
                    setLoadingGraphData={setLoadingGraphData}
                    units={units}
                    graphHeight={graphHeight}
                    updateUnitsState={updateUnitsState}
                    printMode={printMode}
                    togglePrintMode={togglePrintMode}
                />
            </Box>
        </Box>
        </React.Fragment>
    )
}

export const SeismicDataPlot = (props) => {
    return (
        <Box sx={{ marginRight: "-24px"}}>
            <PlotData key="plotdata" path={props.path} pathArr={props.pathArr}/>
        </Box>
    )
}