import React, {useEffect, useContext} from 'react';

// OWN
import { SeismicDataContext } from "../../../../context/seismicDataContext";
import { AuthContext } from "../../../../context/auth-context"


// MUI V5
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

// ICONS
import ClearIcon from '@mui/icons-material/Clear';

export default function FileSelectionChip(props){

    let {
        selectedFilesCount, 
        setSelectionModel, 
        removeFileFromSelection,
        handleOpenDeleteDialog
    } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const SeisDataContext = useContext(SeismicDataContext);
    const authcontext= useContext(AuthContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    function clearSelections(){
        handleClose()
        setSelectionModel([])
        SeisDataContext.setPathArr([])     
    }

    useEffect(() => {
        // close dialog if no files are selected
        if(selectedFilesCount < 1){
            handleClose()
        }
    }, [selectedFilesCount])

    return(
        <>
            <Chip 
                label={selectedFilesCount + " file" + (selectedFilesCount>1 ? "s" : "") + " selected"} 
                onDelete={clearSelections} 
                size="small" 
                style={{marginTop: "4px", marginRight: "10px", display: selectedFilesCount<=0 ? "none" : ""}} 
                onClick={handleClick}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                sx={{
                    // this is a hack to avoid that the popover
                    // renders in the corner of the screen for a couple of 100ms
                    // after closing it.
                    display: selectedFilesCount < 1 ? "none" : ""
                }}
            >
                <List 
                    dense 
                    component="nav" 
                    subheader={
                        <ListSubheader component="div" id="selected-files-header">
                            Selected Files
                        </ListSubheader>
                    }
                >
                {

                    SeisDataContext.pathArr.map(directory => (
                        <React.Fragment key={directory.path}>
                            {
                                directory.files.map(file => (
                                    <ListItemButton dense key={file.name}>
                                        <ListItemText sx={{fontSize: "12px"}}>
                                            {file.name.slice(0,35)}
                                        </ListItemText>
                                        <IconButton size="small" disableRipple onClick={() => removeFileFromSelection(directory.path, file.name)} >
                                            <ClearIcon color="disabled" />
                                        </IconButton>
                                    </ListItemButton>
                                ))
                            }
                        </React.Fragment>
                    ))
                }
                </List>
                <Stack direction="row" width="100%" justifyContent="space-evenly" sx={{paddingRight: "15px"}}>
                    <Button fullWidth variant="outlined" size="small" disabled sx={{marginBottom: "10px", marginLeft: "15px", visibility: "hidden"}}>Open</Button>
                    <Button fullWidth variant="outlined" size="small" onClick={() => {clearSelections()}} sx={{marginBottom: "10px", marginLeft: "15px"}}>Clear</Button>
                    <Button fullWidth variant="outlined" size="small" disabled={authcontext.state.user.permissions.downloadFiles === true ? false : true} onClick={handleOpenDeleteDialog} sx={{marginBottom: "10px", marginLeft: "15px"}}>Delete</Button>
                </Stack>
            </Popover>
        </>
    )
}