import * as React from 'react';

// MUI V5
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import Slider from '@mui/material/Slider';

// ICONS
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ViewAgendaRoundedIcon from '@mui/icons-material/ViewAgendaRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import HeightIcon from '@mui/icons-material/Height';

  // Draw Points
  import TimelineIcon from '@mui/icons-material/Timeline';
  import ShowChartIcon from '@mui/icons-material/ShowChart';

  // Fill
  import ScatterPlotOutlinedIcon from '@mui/icons-material/ScatterPlotOutlined';
  import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function ToolbarGraphSettings(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{marginLeft: "10px"}}>
      <Tooltip title="Plot settings">
        <span>
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            disabled={props.disableMenuButton || props.printMode}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            <SettingsIcon />
          </Button>
        </span>
      </Tooltip>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <SettingsMenuItems {...props}/>
      </StyledMenu>
    </div>
  );
}

export function SettingsMenuItems(props) {
  let { 
    configurableGraphSettings, 
    setConfigurableGraphSettings,
    splitChannels,
    setSplitChannels,
    linkTimeScales,
    setLinkTimeScales,
    graphRows,
    setGraphRows,
    graphHeight,
    setGraphHeight,
    // disableMenuButton,
    data
  } = props;


  function handleGraphSettingsUTC() {
    setConfigurableGraphSettings( prevState => ({
      ...prevState,
      labelsUTC: !configurableGraphSettings.labelsUTC
    }))
  }

  function handleGraphSettingsXGrid() {
    setConfigurableGraphSettings( prevState => ({
      ...prevState,
      axes: {
        ...prevState.axes,
        x: {
          ...prevState.axes.x,
          drawGrid: !configurableGraphSettings.axes.x.drawGrid
        }
      }
    }))
  }

  function handleGraphSettingsYGrid() {
    setConfigurableGraphSettings( prevState => ({
      ...prevState,
      axes: {
        ...prevState.axes,
        y: {
          ...prevState.axes.y,
          drawGrid: !configurableGraphSettings.axes.y.drawGrid
        }
      }
    }))
  }

  function handleGraphSettingsDrawPoints() {
    setConfigurableGraphSettings( prevState => ({
      ...prevState,
      drawPoints: !configurableGraphSettings.drawPoints
    }))
  }

  function handleGraphSettingsFill() {
    setConfigurableGraphSettings( prevState => ({
      ...prevState,
      fillGraph: !configurableGraphSettings.fillGraph
    }))
  }

  const handleSplitChannels = () => {
    setSplitChannels(!splitChannels)
  }

  const handleLinkTimescales = () => {
    setLinkTimeScales(!linkTimeScales)
  }

  const handleGraphRows = () => {
    setGraphRows(!graphRows)
  }

  const handleSliderChange = (event, newValue) => {
    setGraphHeight((100/newValue));
  };

  const resetGraphHeight = () => {
    setGraphHeight(33);
  };


  return (
    <>
        <Divider sx={{ my: 0.5 }}>Time</Divider>
        <MenuItem onClick={handleSplitChannels}>
          {
            splitChannels ? 
            "Combine Channels"
            : "Split Channels"
          }
        </MenuItem>
        <MenuItem onClick={handleLinkTimescales}>
        {
            linkTimeScales ? 
            "Unlink Timescales"
            : "Link Timescales"
          }
        </MenuItem>
        <MenuItem>
          <FormGroup>
            <FormControlLabel 
              control={<Switch checked={configurableGraphSettings.labelsUTC} onChange={handleGraphSettingsUTC}/>} 
              label="UTC Time" 
            />
          </FormGroup>
        </MenuItem>
        <Divider sx={{ my: 0.5 }}>Visual</Divider>
        <MenuItem onClick={handleGraphSettingsDrawPoints}>
          <FormGroup>
            <FormControlLabel 
              key="Points"
              control={<Switch checked={configurableGraphSettings.drawPoints} onChange={handleGraphSettingsDrawPoints}/>} 
              label="Points" 
            />
          </FormGroup> 
        </MenuItem>
        <MenuItem onClick={handleGraphSettingsFill}>
        <FormGroup>
            <FormControlLabel 
              key="Fill"
              control={<Switch checked={configurableGraphSettings.fillGraph} onChange={handleGraphSettingsFill}/>} 
              label="Fill" 
            />
          </FormGroup> 
        </MenuItem>
        <MenuItem onClick={handleGraphRows}>
          <FormGroup>
            <FormControlLabel 
              key="2Columns"
              control={<Switch checked={!graphRows} onChange={handleGraphRows}/>} 
              label="Columns" 
            />
          </FormGroup> 
        </MenuItem>
        <MenuItem>
          <FormGroup>
            <FormControlLabel 
              key="XGRID"
              control={<Switch checked={configurableGraphSettings.axes.x.drawGrid} onChange={handleGraphSettingsXGrid}/>} 
              label="X Grid" 
            />
          </FormGroup>
        </MenuItem>
        <MenuItem>
          <FormGroup>
            <FormControlLabel
              key="YGRID" 
              control={<Switch checked={configurableGraphSettings.axes.y.drawGrid} onChange={handleGraphSettingsYGrid}/>} 
              label="Y Grid" 
            />
          </FormGroup>
        </MenuItem>
        <MenuItem >
          <HeightIcon onClick={resetGraphHeight}/>
          <Slider 
            defaultValue={Math.round(100/graphHeight)} 
            valueLabelDisplay="auto" 
            size="small" 
            onChange={handleSliderChange} 
            step={1}
            marks
            min={1}
            max={12}
          />
        </MenuItem>
    </>
  );
}