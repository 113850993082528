import React, {useEffect, useState, useRef, useCallback, useContext} from 'react';
import qs from "querystring"
import TimeAgo from 'timeago-react'; // var TimeAgo = require('timeago-react');
import {prefix} from 'metric-prefix' // const { prefix } = require('metric-prefix')
import "./latestEventfile.css";

// OWN
import Widget from "../widget"
import OptionsMenu from '../optionMenu';
import { PageContext } from '../../../../context/pageContext';
import { SeismicDataContext } from '../../../../context/seismicDataContext';
import { INTERFACE_downloadFile } from '../../fileExplorer/interface';
import { removeBlockette2000, Blockette2000Payload, blockette2000WasMadeBySeisodin, blockette2000WasMadeBySeisodinAndContainsGainInformation, splitBlockette2000HeadersInArrays } from "../../dataViewer/viewers/miniseedViewer/utils/blockette2000.js"
import { backendApi, clientConfigApplication } from "../../../api/calls"
import ErrorDialog from "../../../layout/ErrorDialog.js"

// MUI V5
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import IconButton from "@mui/material/IconButton"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';


// ICONS
import SensorsIcon from '@mui/icons-material/Sensors';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


const seisplot = require("seisplotjs");
// http://crotwell.github.io/seisplotjs/api/index.html


const WidgetLatestEventFileWithStats = (props) => {

    const [path, setPath] = useState("");
    const [seisDataArr, setSeisDataArr] = useState([]);
    const [PGA, setPGA] = useState([]);
    const [foundPga, setFoundPga] = useState(false)
    const [ack, setAck] = useState(false)
    const [open, setOpen] = useState(false);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [error, setError] = React.useState({title: "test", e: ""});

    let {
      lastEvent,
      loadingLastEventFile,
    } = props;

    const Pcontext = useContext(PageContext);
    const SeismicContext = useContext(SeismicDataContext);

    useEffect(() => {
      console.log("lastEvent changed")
      console.log(lastEvent)

      if(lastEvent.path !== undefined){
        console.log("lastEvent.path is not undefined")
        setPath(lastEvent.path)
      } else {
        console.log("lastEvent.path is undefined")
      }

    }, [lastEvent])

    useEffect(() => {
      console.log("path changed")
      if(lastEvent.path !== undefined){

        // Check for acknowledge of new event
        if(lastEvent.ack !== undefined){
          setAck(lastEvent.ack)
        } else {
          setAck(false)
        }

        // Check if lastEvent contains PGA information yet
        if(lastEvent.pga === undefined || lastEvent.pga === null || lastEvent.pga.length < 1){
          // if no PGA information, then:
          console.log("DID NOT FIND PGA")
          console.log(lastEvent)
          setFoundPga(false)
          // 1: Calculate PGA (saving to dB is handled seperately)
          getEventFileAndCalculateStats()
        } else {
          // if PGA information present, then do nothing
          console.log("FOUND PGA")
          console.log(lastEvent)
          setFoundPga(true)
          setPGA(lastEvent.pga)
        }
      }
    }, [path])

    useEffect(() => {
      // pass the calculated PGA array to the backend, for saving to dB
      if(lastEvent.path !== undefined && foundPga === false){
        updateDbWithLatestEventPga()
        setFoundPga(true)
      }
    }, [PGA])

    async function updateDbWithLatestEventPga(){
      console.log("calling backend to save PGA")
      let payload = { pga: JSON.stringify(PGA) };
      try {
        const res = await backendApi.post(`/api/organization/latestPga`, qs.stringify(payload), clientConfigApplication);
        return res;
      } catch (e) {
        console.log(e);
        setError({ title: "Error updating PGA in database", e: e });
        setErrorOpen(true);
        setOpen(false);
      }
    }

    async function updateDbWithLatestEventAck(){
      console.log("calling backend to save ACK")
      let payload = { ack: JSON.stringify(true) };
      try {
        const res = await backendApi.post(`/api/organization/latestAck`, qs.stringify(payload), clientConfigApplication);
        setAck(true)
        return res;
      } catch (e) {
        console.log(e);
        setError({ title: "Error updating ACK in database", e: e });
        setErrorOpen(true);
        setOpen(false);
      }
    }

    const getDataFromPath = useCallback (async () => {
      console.log("DEBUG: getDataFromPath callback was run (depends on path)")
      return await INTERFACE_downloadFile(path).then(res => {
          // parse arrayBuffer into an array of DataRecords
          var datarecords = seisplot.miniseed.parseDataRecords(res.data)
                      
          // handle possible blockette2000
          var datarecordsWithBlockette2000Extracted = removeBlockette2000(datarecords)
           
          // splits the DataRecords by channel and creates a single Seismogram for each channel.
          var seismogramsperchannel = seisplot.miniseed.seismogramPerChannel(datarecordsWithBlockette2000Extracted.recordsWithoutBlockette2000)
    
          if(seismogramsperchannel.length < 1 || !Array.isArray(seismogramsperchannel)){
              throw new Error("File has no data channels")
          }
    
          // flags
          let nonContiguousDataRemovedFromFile = false;
          let blockette2000WasFoundInFile = false;
          let b2000Payload = {};
    
          // remove channels which are non-contigous
          seismogramsperchannel.forEach((d, i) => {
              // if channel is non-contigous, then remove it from the array
              if(!d.isContiguous()){
                  console.log("non-contiguous data found:")
                  console.log(d)
                  seismogramsperchannel.splice(i,1)
                  nonContiguousDataRemovedFromFile = true;
              }
          })
    
          // set a flag if Blockette2000 data was found in the file
          if(datarecordsWithBlockette2000Extracted.blockette2000Records.length > 0){
              blockette2000WasFoundInFile = true
              b2000Payload = Blockette2000Payload(datarecordsWithBlockette2000Extracted.blockette2000Records, datarecordsWithBlockette2000Extracted.blockette2000ByteOffset)
          } else {
              blockette2000WasFoundInFile = false
          }
    
          // add the filename into the seismogram so it can be used later
          seismogramsperchannel.forEach((d, i) => {
                  seismogramsperchannel[i].fileName = path.substring(path.lastIndexOf("/") + 1);
                  seismogramsperchannel[i].nonContiguousDataRemovedFromFile = nonContiguousDataRemovedFromFile;
                  seismogramsperchannel[i].blockette2000WasFoundInFile = blockette2000WasFoundInFile;
                  seismogramsperchannel[i].blockette2000Payload = b2000Payload;
                  seismogramsperchannel[i].blockette2000BySeisodin = blockette2000WasFoundInFile ? blockette2000WasMadeBySeisodin(b2000Payload) : false;
                  seismogramsperchannel[i].blockette2000SeisodinSensitivity = blockette2000WasMadeBySeisodinAndContainsGainInformation(b2000Payload) !== -1 ? JSON.parse(b2000Payload[blockette2000WasMadeBySeisodinAndContainsGainInformation(b2000Payload)]).countsPerUnit[i] : 1;
                  seismogramsperchannel[i].blockette2000SeisodinPhysicalUnit = blockette2000WasMadeBySeisodinAndContainsGainInformation !== -1 ? JSON.parse(b2000Payload[blockette2000WasMadeBySeisodinAndContainsGainInformation(b2000Payload)]).units[i] : -1;
          })
    
          return seismogramsperchannel
      }).catch(e => {
          throw Error(e)
      })
    
    }, [path])

    function getEventFileAndCalculateStats() {
      let data = getDataFromPath().then(d => {
        let results = [];
        d.forEach((channel, channelIndex) => {
          let SeismogramDisplayData = seisplot.seismogram.SeismogramDisplayData.fromSeismogram(channel)
          let channelPGA = Math.max(Math.abs(SeismogramDisplayData.min), Math.abs(SeismogramDisplayData.max))
          results.push({
            pga: channelPGA, 
            unit: SeismogramDisplayData.seismogram.blockette2000SeisodinPhysicalUnit !==  -1 ? SeismogramDisplayData.seismogram.blockette2000SeisodinPhysicalUnit : SeismogramDisplayData.seismogram.yUnit, 
            sensitivity: SeismogramDisplayData.seismogram.blockette2000SeisodinSensitivity, 
            channel: SeismogramDisplayData.channelCode,
          })
        })
        setPGA(results)
      }).catch(e => {
        console.log("error getting timeseries data")
      })
    }

    const handleOpenFile = (path) => {
        SeismicContext.setPath(path)
        Pcontext.setPage("dataviewer")
    };

    const handleDownloadFile = async (path) => {

      return await INTERFACE_downloadFile(path).then((response) => response.data)
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                  new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                  'download',
                  path.replace(/^.*[\\\/]/, ''),
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                // QueryContext.setQuerybarActive(false)
            }).catch(e => {
              console.log("error downloading multi files")
              console.log(e)
              // QueryContext.setQuerybarActive(false)
            })

    };

    return (
    <React.Fragment>
        <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
        <Widget 
            backgroundColor={
              ""
              // ack !== true && lastEvent?.ack !== undefined && lastEvent?.path !== undefined ?
              // "error" 
              // : ""
            } 
            icon={<SensorsIcon />}
            actionButton={
              lastEvent.path === undefined ? 
              <IconButton variant="outlined" size="small" disabled={true}><MoreHorizIcon/></IconButton>
              : <OptionsMenu actionArray={[
                {title: "Open", fn: () => {handleOpenFile(lastEvent?.path)}}, 
                {title: "Download", fn: () => {handleDownloadFile(lastEvent?.path)}},
                {title: ack ? "Acknowledged" : "Acknowledge", disabled: ack,fn: () => {updateDbWithLatestEventAck()}},
              ]}/>
            }
            title="Latest Event"
        >

          <Box sx={{ width: '100%' }}>    
            <Table sx={{width: "100%", borderWidth: "0px", marginTop: "1rem"}}>
              <TableBody>
                  <TableRow sx={{justifyContent: "center"}}>
                  {
                    lastEvent.path === undefined ?
                    <Typography variant ="h6">No event has occured</Typography>
                    : <TableCell 
                        align="center" 
                        sx={{
                          width: "25%", 
                          color: ack !== true && lastEvent?.path !== undefined ? "white" : "gray", 
                          borderWidth: "2px", 
                          borderColor: ack !== true && lastEvent?.path !== undefined ? "error.light" : "primary.light", 
                          backgroundColor: ack !== true && lastEvent?.path !== undefined ? "error.main" : ""
                          }}
                        >
                        <Tooltip title={lastEvent?.instrument + " - " + lastEvent?.timestamp}>
                        {/* <Tooltip title={lastEvent?.timestamp}> */}
                          <Typography variant ="h6"><TimeAgo datetime={lastEvent?.timestamp} locale='en_short'/></Typography>
                        </Tooltip>
                        <Typography variant="caption">TIME</Typography>
                      </TableCell>
                  }
                  {
                    PGA.length < 1?
                    <TableCell 
                      align="center" 
                      sx={{
                        width: "25%", 
                        color: "gray", 
                        borderWidth: "0px", 
                        borderColor: "", 
                        backgroundColor: ""}}
                    >
                    </TableCell>
                    : PGA.map((channel, channelIndex) => (
                      <TableCell 
                        align="center" 
                        sx={{
                          width: "25%", 
                          color: "gray", 
                          borderWidth: "2px", 
                          borderColor: ack !== true && lastEvent?.path !== undefined ? "error.main" : "primary.main", 
                          backgroundColor: ack !== true && lastEvent?.path !== undefined ? "#fff5f5" : "#e6f2ff"
                        }} 
                        key={"pga"+channelIndex}
                      >
                        <Typography variant="h6" align="center">{PGA[channelIndex] !== undefined ? prefix(PGA[channelIndex].pga/PGA[channelIndex].sensitivity) + PGA[channelIndex].unit : "no data yet"}</Typography>
                        <Typography variant="caption">{PGA[channelIndex] !== undefined ? PGA[channelIndex]?.channel+ " PGA" : "?"}</Typography>
                      </TableCell>
                    ))
                }
                  </TableRow>
              </TableBody>
            </Table>
          </Box>
          

          
        </Widget>
    </React.Fragment>
    );
};


export default WidgetLatestEventFileWithStats;