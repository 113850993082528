import React, { useState } from "react"

// MUI V5
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { green } from '@mui/material/colors';

// Own
import { Transition } from "../styles/SlideTransition"


//////////////// INSTRUCTIONS ////////////////
// Expects the following props:
// type:        error || success
// message:     String
//////////////////////////////////////////////

export default function SimpleSnackbar(props) {
    const [open, setOpen] = useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                style={{ marginRight: "100px" }}
                TransitionComponent={Transition}
                open={open}
                autoHideDuration={props.type === "error" ? 6000 : 2000}
                onClose={handleClose}
                message={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>{props.type === "error" ? <ErrorOutlineIcon color="error" sx={{marginRight: "4px"}}/> : <CheckCircleOutlineIcon style={{ color: green[500] }} sx={{marginRight: "4px"}}/>}{" " + props.message}</div>}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </div>
    );
}