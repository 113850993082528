import React, {useState, useEffect} from 'react';

// OWN 
import {printExpiryDate, printTimeToExpiry} from "../helpers.js"
import LinearProgressWithLabel from './subComponents.js/LinearProgressWithLabel.js';

// MUI V5
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';

// ICONS
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CloudIcon from '@mui/icons-material/Cloud';

const columns = [
//   { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'icon',
    headerName: '',
    width: 40,
    renderCell: (params) => {
        // return <SvgLogoComponent fill="gray" />
        return <CloudIcon color="action" sx={{cursor: "pointer"}} />
      }
  },
  {
    field: 'name',
    headerName: 'Plan',
    width: 150,
    editable: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => {
        return <Chip label={params.row.status} size="small" sx={{backgroundColor: params.row.exp*1000 > Date.now() ? "primary.main" : "gray", color: "white"}} avatar={params.row.exp*1000 > Date.now() ? <CheckCircleIcon style={{color: "white"}}/> : <AccessTimeFilledIcon style={{color: "white"}}/>}/>
    }
  },
  {
    field: 'exp',
    headerName: 'Expiry Date',
    width: 200,
    renderCell: (params) => {
        return <Stack direction="column"><Tooltip title={new Date(params.row.exp*1000).toUTCString()}><Typography variant="button" style={{cursor: "pointer"}}>{printTimeToExpiry(params.row.exp)}</Typography></Tooltip><Typography variant="caption">{printExpiryDate(params.row.exp)}</Typography></Stack>
    }
  },
  {
    field: 'storage',
    headerName: 'Storage',
    width: 200,
    renderCell: (params) => {
        let pct = (Number(params.row.storage)/Number(params.row.maxstorage))*100;
        return <Tooltip title={(params.row.storage) + "GB used (" + ((Number(params.row.storage)/Number(params.row.maxstorage))*100).toFixed(2) + "%)" }><span style={{width: "100%"}}><LinearProgressWithLabel labelFirst={false} disabled={params.row.exp*1000 > Date.now() ? false : true} value={pct > 100 ? 100 : pct } variant="determinate" sx={{width: "100%"}} color={pct >= 100 ? "error" : pct >= 80 ? "warning" : "primary"} text={pct >= 100 ? "FULL" : params.row.storage.toFixed(1) + "/" + params.row.maxstorage +"GB"}/></span></Tooltip>
    }
  },
  {
    field: 'instruments',
    headerName: 'Instruments',
    width: 175,
    renderCell: (params) => {
        return <span style={{width: "100%"}}><LinearProgressWithLabel labelFirst={false} disabled={params.row.exp*1000 > Date.now() ? false : true} value={(Number(params.row.instruments)/Number(params.row.maxinstruments))*100} variant="determinate" sx={{width: "100%"}} text={params.row.instruments + "/" + params.row.maxinstruments}/></span>
    }
  },
  {
    field: 'users',
    headerName: 'Users',
    width: 168,
    renderCell: (params) => {
        return <span style={{width: "100%"}}><LinearProgressWithLabel labelFirst={false} disabled={params.row.exp*1000 > Date.now() ? false : true} value={(Number(params.row.users)/Number(params.row.maxusers))*100} variant="determinate" sx={{width: "100%"}} text={params.row.users + "/" + params.row.maxusers}/></span>
    }
  },
  {
    field: 'autoRenew',
    headerName: 'Auto Renewal',
    type: 'number',
    width: 110,
    editable: true,
  },
  {
    field: 'price',
    headerName: 'Price',
    type: 'number',
    width: 110,
    renderCell: (params) => {
        return "$" + params.row.price
    }
  },

];


function DataTable(props) {

    const [rows, setRows] = useState([
        { id: 1, icon: "", name: 'Seisodin Cloud', status: "undetermined", exp: 0, storage: 0, instruments: 0, users: 0, maxstorage: 0, maxinstruments: 0, maxusers: 0, autoRenew: false, price: 0 },
    ])

    let { subscriptionData } = props;

    useEffect(() => {
        console.log(subscriptionData)
        if(subscriptionData.settings !== undefined){
            setRows([{
                id: 1, 
                icon: "", 
                name: 'Seisodin Cloud', 
                status: (subscriptionData.settings.subscription.exp*1000) > Date.now() ? "active" : "expired", 
                exp: subscriptionData.settings.subscription.exp,
                storage: (subscriptionData.usedStorage.shareUsageBytes/1000000000),
                instruments: subscriptionData.instrumentCount, 
                users: subscriptionData.userCount, 
                maxstorage: subscriptionData.settings.storage.quota,
                maxinstruments: subscriptionData.settings.maxInstruments, 
                maxusers: subscriptionData.settings.maxUsers, 
                autoRenew: false, 
                price: 0
            }])
        }
    }, [subscriptionData])


  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none'},
            borderRadius: "0",
            borderWidth: "0px 0px 0px 0px", 
            borderColor: "#D3D3D3",
        }}
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        hideFooterPagination
        hideFooterSelectedRowCount
        autoHeight={true}
      />
    </Box>
  );
}


export default function SubscriptionTable(props) {
    
    const { subscriptionData } = props

    return (
        <Card sx={{ width: "100%"}}>
            <CardContent >
                <CardHeader 
                    style={{        
                        padding: 0,
                        marginTop: "-5px",
                        marginBottom: "5px",
                        color: "#a1a1a1",
                        textColor: "#a1a1a1"
                    }} 
                    avatar={<CardMembershipIcon style={{ marginTop: "4px" }} />} 
                    title="Subscription" 
                />
            </CardContent>
            <DataTable subscriptionData={subscriptionData}/>
        </Card>
    )
}