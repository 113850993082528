import React from 'react';
import qs from "querystring"
import { backendApi, clientConfigApplication } from "../api/calls"

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { Transition } from "../styles/SlideTransition"
import SnackBar from "../layout/SnackBar"
import { CheckMarkDialog } from "../layout/CheckMark"
import "../styles/checkmarkStyles.css"


export default function ForgottenPasswordDialog() {
    const [open, setOpen] = React.useState(true);
    const [emailState, setEmailState] = React.useState("");
    const [resetButtonActive, setResetButtonActive] = React.useState(true);
    const [mySnackBar, setMySnackBar] = React.useState({ display: false, type: "", message: "" });


    const onChangeForm = e => {
        setEmailState(e.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleResetPassword = (event) => {
        event.preventDefault();
        // request to backend here
        
        const url = `/api/forgot/password`;

        const requestBody = {
            email: emailState
        }

        backendApi.post(url, qs.stringify(requestBody), clientConfigApplication)
            .then((res) => {
                if (res.status === 200) {
                    // do something
                    setResetButtonActive(false);
                    setOpen(false);
                    setMySnackBar({ display: true, type: "success", message: "Email sent" });
                    setTimeout(function () {
                        setMySnackBar({ display: false, type: "", message: "" });
                    }, 2000);
                } else {
                    // show an error message of some kind
                    throw new Error(res.status + " : " + res.message)
                }
            })
            .catch((e) => {
                // Do somthing
                console.log(e)
                let errorMessage = e.response.data.message;
                setMySnackBar({ display: true, type: "error", message: `${errorMessage ? `${errorMessage}.` : ""}` });
                setTimeout(function () {
                    setMySnackBar({ display: false, type: "", message: "" });
                }, 5000);
            })
        // if success:
        // setOpen(false);
        // if fail: 
            // error message
    }

    return (
        <div>
            {
                mySnackBar.display === true && mySnackBar.type === "error" ?
                    <>
                        <SnackBar type={mySnackBar.type} message={mySnackBar.message} />
                    </>
                    : ""
            }
            {
                mySnackBar.display === true && mySnackBar.type === "success" ?
                    <CheckMarkDialog message="Email sent" />
                    : ""
            }
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
                <DialogTitle id="form-dialog-title">Forgotten Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        A reset link will be sent to the provided email address.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="emailFormInput"
                        label="Email Address"
                        type="email"
                        variant="outlined"
                        fullWidth
                        onChange={onChangeForm}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleResetPassword} disabled={resetButtonActive ? false : true} color="primary">
                        Reset Password
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}