import React, {useEffect, useState} from 'react';

// OWN
import Widget from "../widget"

// MUI V5
import IconButton from "@mui/material/IconButton"
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip'

// ICONS
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CircleIcon from '@mui/icons-material/Circle';

const WidgetConnectionStatus = (props) => {

    let {
        connectionStatus,
        socketId,
        loading,
    } = props;

    let quotaWarningPercentage = 80;

    return (
    <React.Fragment>
        <Widget 
            backgroundColor="" 
            icon={<CloudSyncIcon />}
            actionButton={<IconButton variant="outlined" size="small" disabled={true}><MoreHorizIcon/></IconButton>}
            title="Connection Status"
            fixedHeight="100%"
        >
                <Box sx={{ width: '100%', mr: 1 }}>
                    <Stack direction="row">
                        <CircleIcon 
                                sx={{marginRight: "0.5rem"}} 
                                color={connectionStatus === true ? "success" : "error"}
                        />
                        <Stack direction="column">
                        <Typography variant="button" >
                            {
                                connectionStatus ? "Connected" : loading ? "Establishing connection" : "Not Connected"
                            }
                        </Typography>
                        <Typography variant ="caption" sx={{color: "gray"}}>{connectionStatus ? socketId : ""}</Typography>
                        </Stack>
                    </Stack>
                </Box>
        </Widget>
    </React.Fragment>
    );
};

export default WidgetConnectionStatus;