import React, {useEffect, useState} from "react"

// MUI V5
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


export default function FrontpageSettings(props) {
    const [frontPage, setFrontPage] = useState(false);


    let {
        localCopyOfPageArrayConfig,
        setLocalCopyOfPageArrayConfig,
        internalPageArray,
        setInternalPageArray,
    } = props;

    let pageType = "ProtoFrontpage";

    useEffect(() => {
        let fixedCopy = handleMissingConfigParameters()
        setLocalCopyOfPageArrayConfig(fixedCopy)
    }, [])

    useEffect(() => {
        console.log("localCopyOfPageArray changed in FRONTPAGESETTINGS")
        let fixedCopy = handleMissingConfigParameters()
        setLocalCopyOfPageArrayConfig(fixedCopy)
    }, [internalPageArray])  // <-- NOT IDEAL SOLUTION. VERY LARGE OBJECT TO COMPARE !!

    function handleMissingConfigParameters(){
        let tempCopy = {...localCopyOfPageArrayConfig}
        if(tempCopy[pageType] === undefined){
            tempCopy[pageType] = {};
        }
        if(tempCopy[pageType].title === undefined){
            tempCopy[pageType].title = "Title";
        }
        if(tempCopy[pageType].subtitles === undefined){
            tempCopy[pageType].subtitles = "Subtitle";
        }
        if(tempCopy[pageType].author === undefined){
            tempCopy[pageType].author = "Name";
        }
        if(tempCopy[pageType].additionalText === undefined){
            tempCopy[pageType].additionalText = "";
        }
        if(tempCopy[pageType].logo === undefined){
            tempCopy[pageType].logo = "";
        }
        if(tempCopy[pageType].date === undefined){
            tempCopy[pageType].date = new Date(Date.now()).toDateString();
        }
        if(tempCopy[pageType].elementLayout === undefined){
            tempCopy[pageType].elementLayout = [
                { w: 1, h: 3, x: 1, y: 0, minW: 1, minH: 3 }, 
                { w: 1, h: 2, x: 1, y: 1, minW: 1, minH: 2 }, 
                { w: 1, h: 1, x: 1, y: 2, minW: 1, minH: 1 }, 
                { w: 1, h: 1, x: 1, y: 3, minW: 1, minH: 1 }, 
                { w: 1, h: 1, x: 1, y: 4, minW: 1, minH: 1 }, 
                { w: 1, h: 1, x: 1, y: 5, minW: 1, minH: 1 }, 
            ];
        }
        return tempCopy
    }

    function handleTitle(event){
        let fixedCopy = handleMissingConfigParameters()
        fixedCopy[pageType][event.target.id] = event.target.value;
        fixedCopy[pageType].date = new Date(Date.now()).toDateString();
        setLocalCopyOfPageArrayConfig(fixedCopy)
    }

    const handleFrontPage = (event) => {
        setFrontPage(event.target.checked);
        if(event.target.checked){
            addFrontPage()
        } else {
            removeFrontPage()
        }
      };


    function addFrontPage(){
        let tempCopy = [...internalPageArray]
        if(!frontPage){
            // only add a front page if there isn't already one
            let reversed = tempCopy.reverse()
            reversed.push({type: "ProtoFrontpage", payload: {}, config: {desiredPosition: 0}})
            tempCopy = tempCopy.reverse()
            setInternalPageArray(tempCopy)
        }
    }

    function removeFrontPage(){
        let tempCopy = [...internalPageArray]
        if(frontPage){
            let reversed = tempCopy.reverse()
            reversed.pop()
            tempCopy = tempCopy.reverse()
            setInternalPageArray(tempCopy)
        }
    }

    return (
        <div>
            <FormControlLabel 
                sx={{marginBottom: "1rem"}} 
                control={<Switch checked={frontPage} onChange={handleFrontPage}/>} 
                label={frontPage ? "Frontpage enabled" : "Frontpage disabled"} 
            />
            {
                frontPage ? 
                <div>
                    <TextField id="title" label="Title" onChange={handleTitle} variant="outlined" fullWidth sx={{marginBottom: "1rem"}} defaultValue={localCopyOfPageArrayConfig?.[pageType]?.title}/>
                    <TextField id="subtitles" label="Subtitle" onChange={handleTitle} variant="outlined" fullWidth sx={{marginBottom: "1rem"}} defaultValue={localCopyOfPageArrayConfig?.[pageType]?.subtitles}/>
                    <TextField id="author" label="Author" onChange={handleTitle} variant="outlined" fullWidth sx={{marginBottom: "1rem"}} defaultValue={localCopyOfPageArrayConfig?.[pageType]?.author}/>
                    <TextField id="additionalText" label="Other text" onChange={handleTitle} variant="outlined" fullWidth sx={{marginBottom: "1rem"}} defaultValue={localCopyOfPageArrayConfig?.[pageType]?.additionalText}/>
                    <TextField id="logo" label="Logo URL" onChange={handleTitle} variant="outlined" fullWidth sx={{marginBottom: "1rem"}} defaultValue={localCopyOfPageArrayConfig?.[pageType]?.logo}/>
                </div>
                : <React.Fragment></React.Fragment>
            }
        </div>
    )
}