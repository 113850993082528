import React from 'react'
import { useState, useContext, useEffect} from 'react'
import jwtDecode from "jwt-decode";
import UnauthenticatedApp from "../UnauthenticatedApp"
import setAuthToken from "../components/auth/setAuthToken"

const asyncLocalStorage = {
    getItem: async function (key) {
        await null;
        return sessionStorage.getItem(key);
        // return localStorage.getItem(key);
    }
};

var getUser = () => {
    // the function returns another function which is a promise
    return asyncLocalStorage.getItem("seisodinCloudJwtToken").then(token => {
        if (token) {
            const { id, name, organization, email, phone, date, permissions, admin, exp } = jwtDecode(token)
            // check if the token has expired
            if (Date.now() >= exp * 1000) { return null; }
            return { name: name, id: id, organization: organization, email: email, phone: phone, date: date, permissions: permissions, admin: admin, token: token }
        } else {
            // throw new Error("failed to log in for some reason")
            return null
        }
    }).catch(e => {
        console.log("Unable to get token from local storage")
    });
};

const AuthContext = React.createContext()

function AuthProvider({ children }) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [subscriptionInfo, setSubscriptionInfo] = useState({exp: 1, active: false})
    const [state, setState] = useState({
        status: null,
        error: null,
        user: null,
    })

    useEffect(() => {
        console.log("subscriptionInfo changed")
        console.log(subscriptionInfo)
    }, [subscriptionInfo])

    
    const login = () => {
        getUser().then(
            user => { 
                setState({ ...state, status: 'success', error: null, user }); 
                setLoggedIn(true); 
                setAuthToken(user.token)
            },
            error => setState({ ...state, status: 'error', error, user: null }),
        )
    }

    const logout = () => {
        sessionStorage.removeItem("seisodinCloudJwtToken");
        // localStorage.removeItem("seisodinCloudJwtToken");
        sessionStorage.removeItem("seisodinGraphQLToken");
        // localStorage.removeItem("seisodinGraphQLToken");
        setState({ ...state, status: null, error: null, user: null })
        setSubscriptionInfo({exp: "1", active: false})
        setLoggedIn(false);
    }

    useEffect(() => {
        // at startup, check if there is already a token on the device
        getUser().then(
            user => {
                // if a user is returned, then update state. Else reset state, just to make sure.
                user ? setState(state => ({...state, status: 'success', error: null, user })) : (console.log("No active user"))
            },
            error => {
                setState(state => ({ ...state, status: 'error', error, user: null }))
            },
        ).catch(e => {
            console.log("2 - error geting user from local storage at startup")
        })
    }, []);


    const value = { state, setState, subscriptionInfo, setSubscriptionInfo, login, logout}

    return (
        <AuthContext.Provider value={value}>
            {state.status === 'pending' ? (
                'Loading...'
            ) : state.status === 'error' ? (
                <div>
                    Oh no {loggedIn}
                    <div>
                        <pre>{state.error.message}</pre>
                    </div>
                </div>
            ) : state.status === null ? (
                    <UnauthenticatedApp />
            ) : (
                    children
                )}
        </AuthContext.Provider>
    )
}

function useAuthState() {
    const { state } = useContext(AuthContext)
    const isPending = state.status === 'pending'
    const isError = state.status === 'error'
    const isSuccess = state.status === 'success'
    const isAuthenticated = state.user && isSuccess
    return {
        ...state,
        isPending,
        isError,
        isSuccess,
        isAuthenticated,
    }
}

export { AuthProvider, useAuthState, AuthContext }