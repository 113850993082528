import React from "react"
import qs from "querystring"

// OWN
import { backendApi, clientConfigApplication } from "../../api/calls"
import SnackBar from "../../layout/SnackBar"
import { AdminBadge } from "../../layout/AdminBadge"


// MUI V5
import Chip from '@mui/material/Chip';

// ICONS
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


export default function Tags (props){
    const [mySnackBar, setMySnackBar] = React.useState({ display: false, type: "", message: "" });
    const [linkWasResent, setLinkWasResent] = React.useState(false);
    const { row } = props;
    let expiryDate = row.forgottenPasswordExpires
    let isExpired = Date.now() > expiryDate;

    const handleResendActivationLink = (event) => {
        event.preventDefault();
        setLinkWasResent(true)

        backendApi.post("/api/forgot/password", qs.stringify({email: row.email}), clientConfigApplication)
            .then((res) => {
                if (res.status === 200) {
                    // do something
                } else {
                    // show an error message of some kind
                    throw new Error(res.status + " : " + res.message)
                }
            })
            .catch((e) => {
                // Do somthing
                console.log(e)
                let errorMessage = e.response.data.message;
                setMySnackBar({ display: true, type: "error", message: `${errorMessage ? `${errorMessage}.` : ""}` });
                setTimeout(function () {
                    setMySnackBar({ display: false, type: "", message: "" });
                }, 5000);
            })
    }

    return (
        <>
            {
                mySnackBar.display === true && mySnackBar.type === "error" ?
                    <>
                        <SnackBar type={mySnackBar.type} message={mySnackBar.message} />
                    </>
                    : ""
            }
            {
                row.admin ?
                    <AdminBadge tiny="true" />
                    : ""
            }
            {
                !row.activated && !isExpired?
                    <Chip size="small" icon={<PauseCircleFilledIcon />} label="Not activated" disabled={true} style={{ marginRight: "10px" }}/>
                    : ""
            }
            {
                !row.activated && isExpired?
                <>
                        <Chip size="small" key="expired" icon={<WatchLaterIcon />} label="Expired" disabled={true} style={{ marginRight: "10px" }}/>
                        <Chip size="small" key="linkwassent" icon={linkWasResent ? <CheckCircleIcon /> : <SendIcon style={{ marginLeft: "10px" }} />} label={linkWasResent ? "email sent" : "Resend"} onClick={linkWasResent ? null : handleResendActivationLink} style={{marginRight: "10px", backgroundColor: "green"}} color="primary"/>
                </>
                : ""
            }
        </>
    )
}