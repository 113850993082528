export function parseInstrumentHardwareConfig(config){

    const configKey = { 
        T100: {
            orientations: [4,7],
            fullscales: [7,10], 
            gnss: [10,11], 
            interfaces: [11,14],
            options: [14,15],
            hwversion: [15,16],
        },
        T130: {
            orientations: [4,7],
            fullscales: [7,10], 
            gnss: [10,11], 
            interfaces: [11,14],
            options: [14,15],
            hwversion: [15,16],
        },
        default: {
            orientations: [4,7],
            fullscales: [7,10], 
            gnss: [10,11], 
            interfaces: [11,14],
            options: [14,15],
            hwversion: [15,16],
        }
    }

    function getConfigKey(instrumentType){
        if(instrumentType === "T100"){
            return configKey.T100
        } else if (instrumentType === "T130"){
            return configKey.T130
        } else {
            return configKey.default
        }
    }

    function countNumberOfAxis(str){
        return str.length - str.replace(/[^0]/g, "").length
    }

    function determineFullscaleUnit(str, type){
        let fsArr = [];
        if(type === "T130"){
            str.split("").forEach((d,i) => {
                if(d !== "0"){
                    fsArr.push("±"+d+"g")
                }
            })
        } else if(type === "T100") {
            str.split("").forEach((d,i) => {
                if(d !== "0"){
                    fsArr.push("±"+d+"g")
                }
            })
        } else {
            fsArr.push("unknown type")
        }
        return fsArr
    }

    function cleanOrientations(str){
        return str.replace(/[0]/g, "")
    }

    function hasGnss(str){
        if(str === "g"){
            return true
        } else {
            return false
        }
    }

    let parsedObject = {
        type: config.slice(0,4),
        orientations: config.slice(getConfigKey().orientations[0],getConfigKey().orientations[1]),
        fullscales: config.slice(getConfigKey().fullscales[0],getConfigKey().fullscales[1]),
        gnss: config.slice(getConfigKey().gnss[0],getConfigKey().gnss[1]),
        interfaces: config.slice(getConfigKey().interfaces[0],getConfigKey().interfaces[1]),
        options: config.slice(getConfigKey().options[0],getConfigKey().options[1]),
        hwversion: config.slice(getConfigKey().hwversion[0],getConfigKey().hwversion[1]),
    };

    let prettyObject = {
        numberOfAxis: countNumberOfAxis(parsedObject.orientations),
        fullScalesWithUnits: determineFullscaleUnit(parsedObject.fullscales, parsedObject.type),
        orientationsClean: cleanOrientations(parsedObject.orientations),
        hasGnss: hasGnss(parsedObject.gnss),
    }

    return {parsed: parsedObject, pretty: prettyObject}
}