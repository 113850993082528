import axios from "axios";

let source = axios.CancelToken.source();


const clientConfigApplication = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

const clientBufferConfigApplication = {
    responseType: 'arraybuffer',
    headers: {
        'Content-Type': 'application/vnd.fdsn.mseed',
        'Accept': 'application/msd'
    }
}

const backendApi = axios.create({
    // config
    cancelToken: source.token,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
});

backendApi.interceptors.request.use((config) => {
    // console.log("axios interceptor: request sent", config.url);
    config.headers.Authorization = sessionStorage.getItem("seisodinCloudJwtToken");
    // config.headers.Authorization = localStorage.getItem("seisodinCloudJwtToken");
    return config;
});

backendApi.interceptors.response.use((config) => {
    // console.log("axios interceptor: response received", config.url);
    return config;
}, (error) => {
    // Return any error which is not due to authentication (401 Unauthorized) back to the calling service
    if(error.response.status !== 401){
        return new Promise((resolve, reject) => {
            console.log("interceptor: error other than 401", error);
            reject(error);
        })
    }

    // Try request again with new token
    return renewToken().then((token) => {
        // New request with new token
        const config = error.config;
        config.headers['Authorization'] = token;

        return new Promise((resolve, reject) => {
            backendApi.request(config).then(response => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            })
        });

    }).catch((error) => {
        Promise.reject(error);
    });
});

async function renewToken() {
    const answer = backendApi.get("/api/token/renew").then((res) => {
        // console.log("axios interceptor: token was renewed", res.data.token);
        const newToken = res.data.token;
        sessionStorage.setItem("seisodinCloudJwtToken", newToken);
        // localStorage.setItem("seisodinCloudJwtToken", newToken);
        return res.data.token;
    }).catch((err) => {
        // error renewing 
        console.log("error renewing token")
        return err;
    })
    return answer;
}

export { backendApi, clientConfigApplication, clientBufferConfigApplication, source }