import React, { useEffect, useState, useRef, useCallback } from "react";
import Dygraph from "dygraphs";
import GridLayout from "react-grid-layout";
import {Responsive, WidthProvider} from 'react-grid-layout';

// MUI V5
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import "./modelStyles/react-grid-layout.css"
import "./modelStyles/react-resizable.css"
import "./modelStyles/proto-frontpage.css"

const ResponsiveReactGridLayout = WidthProvider(Responsive);

// FORMAT: {type: "ProtoSingleFileGraph", payload: {timeDomain: [], frequencyDomain: [], metaData: []}, config: {//specific to this page}, index: 1}]}

export default function ProtoSingleFileGraph(props){
    const [dygraphStateTime, setDygraphStateTime] = useState([])
    const [dygraphStateFFT, setDygraphStateFFT] = useState([])
    const [keyToForceReRender, setKeyToForceReRender] = useState("")
    const [stats, setStats] = useState([])
    const [graphSettings, setGraphSettings] = useState(
        {
          axes: {
            x: {
              drawGrid: true,
              drawAxis: true,
              gridLineWidth: 1,
              axisLineColor: "white",
              axisLineWidth: 1,
              gridLineColor: "#EEE",
              axisLineColor: "#666",
              logscale: false,
              // gridLinePattern: [5, 5],
            },
            y: {
              drawGrid: true,
              drawAxis: true,
              gridLineWidth: 1,
              gridLineColor: "#EEE",
            //   gridLinePattern: [5, 5],
              axisLineColor: "#666",
              axisLineWidth: 1,
            }
          },
          logscale: false,
          rollPeriod: 0,
          highlightCircleSize: 5,
          // labels: ["X", "Amplitude"],
          // timingName: "x",
        //   legendFormatter: legendFormatter,
          // legend: "never",
          strokeWidth: 1, // any other width than 1 will slow down rendering by 4-6 times
          fillGraph: false,
          colors: [
            "#1976d2",
            "#ff5a8f",
            "#ffa600",
            "#7f70d6",
            "#bd66cb",
            "#ec5bb1",
            "#ff6a67",
            "#ff863e",
          ],
          visibility: [true],
          animatedZooms: false,
          hideOverlayOnMouseOut: true,
          drawPoints: false,
          labelsUTC: true,
          width: -1,
          connectSeparatedPoints: true,
          // resizable: "vertical",
          interactionModel: {}, // disabled interaction model to disable zoom and pan
        }
      );

    let {
      page,
      pageCurrent,
      localCopyOfPageArray,
      setLocalCopyOfPageArray,
      paperFormat,
      localCopyOfPageArrayConfig,
      setLocalCopyOfPageArrayConfig,
    } = props;

    let refArr = useRef([]);
    let refArrFreq = useRef([]);
    let pageType = "ProtoSingleFileGraph"
    let graphDefaultHeight = 4; // in multiples of react-grid-layout defined height intervals
    let colorArr = [
      "#1976d2",
      "#ff5a8f",
      "#ffa600",
      "#7f70d6",
      "#bd66cb",
      "#ec5bb1",
      "#ff6a67",
      "#ff863e",
    ];

    useEffect(() => {
      console.log("A")

      // Setup standard config for localCopyOfPageArrayConfig
      let tempCopy1 = {...localCopyOfPageArrayConfig}
      let fixedCopy1 = handleMissingStdConfigParameters(tempCopy1)
      setLocalCopyOfPageArrayConfig(fixedCopy1)

      // Setup standard config for localCopyOfPageArray
      let tempCopy2 = [...localCopyOfPageArray]
      let fixedCopy2 = handleMissingIndividualPageConfig(tempCopy2, pageCurrent);
      setLocalCopyOfPageArray(fixedCopy2)
  
      return function cleanup() {
        destroyDygraphState();
      };
    }, [])

    useEffect(() => {
      console.log("C")
      setKeyToForceReRender(Date.now()) // MEGA HACK TO FORCE RERENDER  
      resize()
    // }, [paperFormat, pageCurrent, JSON.stringify(page.config.pageLayout), localCopyOfPageArrayConfig?.[pageType]?.fft, page?.dataviewerConfig?.visibleChannels, page?.dataviewerConfig?.fftFirstRender])
    }, [paperFormat, pageCurrent, JSON.stringify(page.config.pageLayout), localCopyOfPageArrayConfig?.[pageType]?.fft, page?.dataviewerConfig?.visibleChannels, page?.dataviewerConfig?.fftFirstRender])

    useEffect(() => {
      console.log("D")
      generateContentForStatsTable(page)
      createSplitGraphs(refArr, page.payload.timeDomain, page.payload.metaData, "time")
      // if(localCopyOfPageArrayConfig?.[pageType]?.fft) {
      if(page?.dataviewerConfig?.fftFirstRender) {
        createSplitGraphs(refArrFreq, page.payload.frequencyDomain, page.payload.metaData, "fft")
      }
      resize() // necessary to render change from 1ch file to 3ch file
    }, [ page.payload.timeDomain, page.payload.frequencyDomain, page.payload.metaData, JSON.stringify(localCopyOfPageArrayConfig[pageType])])

    useEffect(() => {
      console.log("E")
      createSplitGraphs(refArr, page.payload.timeDomain, page.payload.metaData, "time")
      // if(localCopyOfPageArrayConfig?.[pageType]?.fft) {
      if(page?.dataviewerConfig?.fftFirstRender) {
        createSplitGraphs(refArrFreq, page.payload.frequencyDomain, page.payload.metaData, "fft")
      }
      resize()
    }, [keyToForceReRender])

    useEffect(() => {
      console.log("F")
      annotationHiLo(localCopyOfPageArrayConfig?.[pageType]?.annotationHiLo)
    }, [localCopyOfPageArrayConfig?.[pageType]?.annotationHiLo])


    useEffect(() => {
      console.log("H")
      updateFFTLogScales()
      // dygraphStateFFT.forEach((dg, i) => {
      //   dygraphStateFFT[i].updateOptions({
      //     // logscale: localCopyOfPageArrayConfig?.[pageType]?.FFTLogY,
      //     logscale: localCopyOfPageArrayConfig?.ProtoSingleFileGraph?.FFTLogY,
      //     axes: {
      //       x: {
      //         // logscale: localCopyOfPageArrayConfig?.[pageType]?.FFTLogX,
      //         logscale: localCopyOfPageArrayConfig?.ProtoSingleFileGraph?.FFTLogX,
      //       },
      //     },
          
      //   })
      // })
    }, [localCopyOfPageArrayConfig?.ProtoSingleFileGraph?.FFTLogX, localCopyOfPageArrayConfig?.ProtoSingleFileGraph?.FFTLogY,])

    function resize(){
        dygraphStateTime.forEach((dg,i) => {
            dg.resize()
          })
        dygraphStateFFT.forEach((dg,i) => {
            dg.resize()
          })
    }

    function updateFFTLogScales(){
      dygraphStateFFT.forEach((dg, i) => {
        dygraphStateFFT[i].updateOptions({
          logscale: localCopyOfPageArrayConfig?.ProtoSingleFileGraph?.FFTLogY,
          axes: {
            x: {
              logscale: localCopyOfPageArrayConfig?.ProtoSingleFileGraph?.FFTLogX,
            },
          },
          
        })
      })
    }

    function handleMissingStdConfigParameters(obj){
      let tempCopy = {...obj}
      if(tempCopy[pageType] === undefined){
          tempCopy[pageType] = {};
      }
      if(tempCopy[pageType].graphHeader === undefined){
          tempCopy[pageType].graphHeader = false;
      }
      if(tempCopy[pageType].stats === undefined){
          tempCopy[pageType].stats = "";
      }
      if(tempCopy[pageType].fft === undefined){
          tempCopy[pageType].fft = false;
      }
      if(tempCopy[pageType].colors === undefined){
          tempCopy[pageType].colors = false;
      }
      if(tempCopy[pageType].statsBlock === undefined){
          tempCopy[pageType].statsBlock = false;
      }
      if(tempCopy[pageType].height === undefined){
          tempCopy[pageType].height = 120;
      }
      if(tempCopy[pageType].FFTLogY === undefined){
          tempCopy[pageType].FFTLogY = false;
      }
      if(tempCopy[pageType].FFTLogX === undefined){
          tempCopy[pageType].FFTLogX = false;
      }
      if(tempCopy[pageType].annotationHiLo === undefined){
          tempCopy[pageType].annotationHiLo = false;
      }
      return tempCopy
    }

    function handleMissingIndividualPageConfig(pageArr, pageIndex){
      let tempCopy = [...pageArr]
      if(tempCopy[pageIndex].config === undefined){
          tempCopy[pageIndex].config = {};
      }
      if(tempCopy[pageIndex].config.pageLayout === undefined){
        tempCopy[pageIndex].config.pageLayout = [];
        let counter = tempCopy?.[pageIndex]?.payload?.timeDomain?.length*2+1;
        console.log("COUNTER: " + counter)
        for(let i=0;i<counter;i++){
          tempCopy[pageIndex].config.pageLayout.push({ x: 1, y: graphDefaultHeight*i, w: 2, h: 4, minW: 1, maxW: 2, minH: 1 })
        }    
      }

      return tempCopy
    }

    function createSplitGraphs(ref, data, metaData, type){
 
        (data !== undefined && data.length > 0) ? data.forEach((v,i) => {
          if(true){ // <-- temp hack to make it work (draws everything again on every change)
          // if((type === "time" && dygraphStateTime[i] === undefined) || (type === "fft" && dygraphStateFFT[i] === undefined) ){
            console.log("Create new graph " + type)
            // generate new labels
            let label = "";
            label = "Amplitude"
    
            let tempState = (type === "time") ? dygraphStateTime : (type === "fft") ? dygraphStateFFT : [];

            if(page?.dataviewerConfig?.visibleChannels[i]){
              tempState[i] = new Dygraph(ref.current[i], data[i], graphSettings);
            }

            if(page?.dataviewerConfig?.visibleChannels[i]){
              tempState[i].updateOptions({
                // labels: ["X", label],
                file: data[i],
                color: localCopyOfPageArrayConfig?.[pageType]?.colors ? colorArr[i] : colorArr[0]
              })
            }

            annotationHiLo(localCopyOfPageArrayConfig?.[pageType]?.annotationHiLo)
                        
            if (type === "time"){
                console.log("time data")
                setDygraphStateTime(tempState)
            } else if (type === "fft"){
                console.log("FFT data")
                setDygraphStateFFT(tempState)
                updateFFTLogScales()
            }
            
          } else { 
            console.log("Update old graph")
            let label = "";
            label = "Amplitude";

            if (type === "time"){
                dygraphStateTime[i].updateOptions({
                    labels: ["X", label],
                    visibility: [true],
                    color: localCopyOfPageArrayConfig?.[pageType]?.colors ? colorArr[i] : colorArr[0],
                    file: data[i]
                  })
            } else if (type === "fft"){
                dygraphStateFFT[i].updateOptions({
                    labels: ["X", label],
                    visibility: [true],
                    file: data[i],
                    color: localCopyOfPageArrayConfig?.[pageType]?.colors ? colorArr[i] : colorArr[0],
                  })
            }
          }
        })
        : <></>
    }

    function annotationHiLo(state){

      if(dygraphStateTime.length > 0 && state === true){
        dygraphStateTime.forEach((dg,i) => {
          dygraphStateTime[i].setAnnotations([
            {
              series: "Y1",
              x: new Date(page?.payload?.metaData?.[i]?.tsStats.xMax).getTime(),
              shortText: "H",
              text: "High",
              cssClass: "annotationCss",
              tickColor: "black",
            },
            {
              series: "Y1",
              x: new Date(page?.payload?.metaData?.[i]?.tsStats.xMin).getTime(),
              shortText: "L",  
              text: "Low",
              cssClass: "annotationCss",
              tickColor: "black",
            }
          ]);
        })
      } else {
        dygraphStateTime.forEach((dg,i) => {
          var annotations = [];
          dygraphStateTime[i].setAnnotations(annotations);  // causes a redraw
        })

      }
    }

    function destroyDygraphState(){
      console.log("DESTROY")
      dygraphStateTime.forEach((v,i) => {
        dygraphStateTime[i].destroy(); 
      })
      dygraphStateFFT.forEach((v,i) => {
        dygraphStateFFT[i].destroy(); 
      })
    }

    const handleModify = (layouts, layout) => {
      console.log("changing page index " + pageCurrent + " from ")
      console.log(localCopyOfPageArray[pageCurrent].config.pageLayout)
      let tempCopy = [...localCopyOfPageArray]
      let fixedCopy = handleMissingIndividualPageConfig(tempCopy, pageCurrent);
      fixedCopy[pageCurrent].config.pageLayout = JSON.parse(JSON.stringify([...layouts]));
      setLocalCopyOfPageArray(fixedCopy)
      console.log("to :")
      console.log(JSON.parse(JSON.stringify([...layouts])))
      resize()
    }

    const columns = [
      // { field: 'id', headerName: 'ID', width: 50 },
      {
        field: 'channelName',
        headerName: 'Ch',
        width: 50,
        editable: false,
        sortable: false,
      },
      {
        field: 'rms',
        headerName: 'RMS',
        width: 100,
        editable: false,
        sortable: false,
      },
      {
        field: 'max',
        headerName: 'Max',
        width: 200,
        editable: false,
        sortable: false,
      },
      {
        field: 'min',
        headerName: 'Min',
        width: 200,
        editable: false,
        sortable: false,
      },
    ];
    
    function generateContentForStatsTable(inputPage){
      let newStats = [];

      inputPage?.payload?.timeDomain?.map((channel, channelIndex) => (
        newStats.push({
          id: channelIndex, 
          channelName: inputPage?.payload?.metaData?.[channelIndex].channelCode, 
          rms: inputPage?.payload?.metaData?.[channelIndex].rms > 0.000 ? Math.round(inputPage?.payload?.metaData?.[channelIndex].rms*100)/100 : inputPage?.payload?.metaData?.[channelIndex].rms.toFixed(5),
          min: inputPage?.payload?.metaData?.[channelIndex].min > 0.000 ? Math.round(inputPage?.payload?.metaData?.[channelIndex].min*100)/100 + "@" + new Date(inputPage?.payload?.metaData?.[channelIndex]?.tsStats?.xMin).toLocaleTimeString("en-US", {timeZone: "UTC"}) : inputPage?.payload?.metaData?.[channelIndex].min.toFixed(5) + "@" + new Date(inputPage?.payload?.metaData?.[channelIndex]?.tsStats?.xMin).toLocaleTimeString("en-US", {timeZone: "UTC"}),
          max: inputPage?.payload?.metaData?.[channelIndex].max > 0.000 ? Math.round(inputPage?.payload?.metaData?.[channelIndex].max*100)/100 + "@" + new Date(inputPage?.payload?.metaData?.[channelIndex]?.tsStats?.xMax).toLocaleTimeString("en-US", {timeZone: "UTC"}) : inputPage?.payload?.metaData?.[channelIndex].max.toFixed(5) + "@" + new Date(inputPage?.payload?.metaData?.[channelIndex]?.tsStats?.xMax).toLocaleTimeString("en-US", {timeZone: "UTC"}),

        })
      ))

      setStats(newStats)
    }

    
    return (
            <div key={"plotcontainer"} >
              <p style={{margin: "0px", padding: "0px"}}>{page?.payload?.metaData?.[0]?.fileName}</p>
              <ResponsiveReactGridLayout
                onLayoutChange={handleModify}
                verticalCompact={true}
                layout={page?.config?.pageLayout}

                // layout={localCopyOfPageArray?.[pageCurrent]?.config?.pageLayout}
                rowHeight={20}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                preventCollision={false}
                cols={{ lg: 2, md: 2, sm: 2, xs: 2, xxs: 2 }}
                margin={[0,20]}
                key={keyToForceReRender} // MONSTER HACK TO FORCE A RERENDER
                // key={"gridlayout"} 
              > 
                { localCopyOfPageArrayConfig?.[pageType]?.statsBlock ?
                  <div 
                    key={"statsblock"} data-grid={page?.config?.pageLayout?.[0].i === "placeholderForStatsBlock" ? { x: 1, y: 0, w: 2, h: 5, minW: 1, maxW: 2, minH: 1 } : page?.config?.pageLayout?.[0] } 
                  > 
                    <div className="dygraph-title">Statistics</div>
                    <Box sx={{ width: '100%' }}>
                      <DataGrid
                        rows={stats}
                        columns={columns}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        density="compact"
                        disableColumnMenu={true}
                        hideFooter={true}
                        autoHeight
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                      />
                    </Box>
                  </div>
                  :  <div key="placeholderForStatsBlock" data-grid={{ x: 1, y: 0, w: 2, h: 0, minW: 1, maxW: 2, minH: 0, static: true }} style={{backgroundColor: "red"}}/> // https://stackoverflow.com/questions/62841493/how-do-i-hide-the-div-that-is-encapsulating-a-component-from-an-onclick-inside-t
                }
                {   
                  page?.payload?.timeDomain?.map((channel, channelIndex) => (
                    page?.dataviewerConfig?.visibleChannels[channelIndex] ?
                    <div 
                      key={"timeseriescontainerF"+pageCurrent+"C"+channelIndex} 
                      data-grid={
                        page?.config?.pageLayout?.[channelIndex+1]?.i === "placeholderForF"+pageCurrent+"C"+channelIndex ? { x: 1, y: 0, w: 2, h: 4, minW: 1, maxW: 2, minH: 1 } : page?.config?.pageLayout?.[channelIndex+1] ?? { x: 1, y: graphDefaultHeight*(channelIndex+1), w: 2, h: 4, minW: 1, maxW: 2, minH: 1}
                      }
                      style={{display: page?.dataviewerConfig?.visibleChannels[channelIndex] ? ""  : "none"}}
                      > 
                          {
                            localCopyOfPageArrayConfig?.[pageType]?.graphHeader ? 
                            <div key={"tsheader"+channelIndex} className="dygraph-title">
                              <div>
                                {page?.payload?.metaData?.[channelIndex]?.networkCode +"."+ page?.payload?.metaData?.[channelIndex]?.stationCode +"."+ page?.payload?.metaData?.[channelIndex]?.locationCode +"."+ page?.payload?.metaData?.[channelIndex]?.channelCode}
                                {
                                  localCopyOfPageArrayConfig?.[pageType]?.stats ? 
                                  ( " || " + page?.payload?.metaData?.[channelIndex]?.sampleRate + "SPS"
                                  // + " || RMS " + Number(page?.payload?.metaData?.[channelIndex]?.rms).toFixed(2) 
                                  // + " || MAX " + Number(page?.payload?.metaData?.[channelIndex]?.max).toFixed(2)
                                  // + " || MIN " + Number(page?.payload?.metaData?.[channelIndex]?.min).toFixed(2)
                                  + " || UNIT " + page?.payload?.metaData?.[channelIndex]?.yUnit
                                  ) 
                                  : ""
                                }
                              </div>
                            </div> 
                            : <React.Fragment></React.Fragment>
                          }
                          <div 
                              key={"channelDiv"+channelIndex} 
                              id={"channelDiv"+channelIndex} 
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "100%",
                                visibility: "visible",
                              }}
                              ref={(el) => (refArr.current[channelIndex] = el)} 
                          />
                      </div>
                    : <div key={"placeholderForF"+pageCurrent+"C"+channelIndex} data-grid={{ x: 1, y: 0, w: 2, h: 0, minW: 1, maxW: 2, minH: 0, static: true }} style={{backgroundColor: "red"}}/> // https://stackoverflow.com/questions/62841493/how-do-i-hide-the-div-that-is-encapsulating-a-component-from-an-onclick-inside-t
                    ))
                }
                {
                    page?.payload?.frequencyDomain?.map((channel, channelIndex) => (
                      page?.dataviewerConfig?.visibleChannels[channelIndex] ?
                      <div 
                        key={"fftcontainerF"+pageCurrent+"C"+channelIndex}
                        data-grid={page?.config?.pageLayout?.[channelIndex+1+page?.payload?.timeDomain?.length]?.i === "placeholderForFFTF"+pageCurrent+"C"+channelIndex ? { x: 1, y: ((page?.payload?.timeDomain?.length+1)*graphDefaultHeight)+(graphDefaultHeight*channelIndex), w: 2, h: 4, minW: 1, maxW: 2, minH: 1 } :  page?.config?.pageLayout?.[(page?.payload?.timeDomain?.length+1)+channelIndex] ?? { x: 1, y: ((page?.payload?.timeDomain?.length+1)*graphDefaultHeight)+(graphDefaultHeight*channelIndex), w: 2, h: 4, minW: 1, maxW: 2, minH: 1 }} 
                        style={{display: (page.payload.frequencyDomain?.length > 0 && page?.dataviewerConfig?.fftFirstRender) ? "block" : "none" }}
                        // style={{display: (localCopyOfPageArrayConfig?.[pageType]?.fft && page.payload.frequencyDomain?.length > 0) ? "block" : "none" }}
                      >
                          {
                            localCopyOfPageArrayConfig?.[pageType]?.graphHeader ? 
                            <div key={"fftheader"+channelIndex} className="dygraph-title">{"FFT " + page?.payload?.metaData?.[channelIndex]?.networkCode +"."+ page?.payload?.metaData?.[channelIndex]?.stationCode +"."+ page?.payload?.metaData?.[channelIndex]?.locationCode +"."+ page?.payload?.metaData?.[channelIndex]?.channelCode}</div> 
                            : <React.Fragment></React.Fragment>
                          }
                          <div 
                              key={"channelFreqDiv"+channelIndex} 
                              id={"channelFreqDiv"+channelIndex} 
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "100%",
                                visibility: "visible",
                              }}
                              ref={(el) => (refArrFreq.current[channelIndex] = el)} 
                          />
                      </div>
                      : <div key={"placeholderForFFTF"+pageCurrent+"C"+channelIndex} data-grid={{ x: 1, y: 0, w: 2, h: 0, minW: 1, maxW: 2, minH: 0, static: true }} style={{backgroundColor: "red"}}/> // https://stackoverflow.com/questions/62841493/how-do-i-hide-the-div-that-is-encapsulating-a-component-from-an-onclick-inside-t
                    ))
                }
            
                
              </ResponsiveReactGridLayout>
            </div>
    )
}
