import React, {useEffect, useState} from 'react';
// MUI V5
import IconButton from "@mui/material/IconButton"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// ICONS
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


export default function OptionsMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    let {
        actionArray
    } = props;
  
    return (
      <div>
      <IconButton variant="outlined" size="small" onClick={handleClick}>
          <MoreHorizIcon/>
      </IconButton>
        <Menu
          id="demo-positioned-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {
                actionArray !== undefined && actionArray.length > 0 ? 
                actionArray.map((action, index) => (
                    <MenuItem 
                        onClick={() => {
                            handleClose()
                            action.fn()
                        }}
                        disabled={action.disabled}
                    >
                        {action.title}
                    </MenuItem>
                ))
                : ""

            }
        </Menu>
      </div>
    );
  }