export function strIsDefined(str){
    if(str !== undefined && str !== null && str){
        return str
    } else {
        return ""
    }
}

export function printExpiryDate(date){
    const month = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"];
    const expiryDate = new Date(parseInt(date) * 1000)
    let timeToExpiryString = "";
    let timeDifference = ((expiryDate - Date.now())/(1000))

    if(timeDifference < 86400  && timeDifference > 0){
        // if less than 1 day, then show in hours
        timeToExpiryString = (timeDifference / (24)).toFixed(0) + " hours"
        timeDifference.toFixed(0)
    } else {
        timeToExpiryString = (timeDifference / (3600 * 24)).toFixed(0) + " days"
    }

    const expDate = month[expiryDate.getMonth()] + " " + expiryDate.getDate() + ", " + expiryDate.getFullYear()
    return(
        expDate
    )
}

export function printTimeToExpiry(date){
    const expiryDate = new Date(parseInt(date) * 1000)
    let timeToExpiryString = "";
    let timeDifference = ((expiryDate - Date.now())/(1000))

    if(timeDifference < 86400  && timeDifference > 3600){
        // if less than 1 day, then show in hours
        timeToExpiryString = (timeDifference / (3600)).toFixed(0) + " hours"
        timeDifference.toFixed(0)
    } else if(timeDifference < 0) {
        timeToExpiryString = Math.abs((timeDifference / (3600 * 24)).toFixed(0)) + " days ago"
    } else if(timeDifference > 0 && timeDifference < 3600){
        timeToExpiryString = (timeDifference / (60)).toFixed(0) + " minutes"
    } else {
        timeToExpiryString = (timeDifference / (3600 * 24)).toFixed(0) + " days"
    }

    const expDate = timeToExpiryString
    return(
        expDate
    )
}