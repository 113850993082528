import React from 'react';

// MUI V5
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge';

// ICONS
import FindInPageIcon from '@mui/icons-material/FindInPage';

const UnsupportedFileType = (props) => {
    let { fileType } = props;

    return (
    <Box textAlign="center" sx={{ marginTop: "100px", marginBottom: "100px" }}>
        <React.Fragment>
                <FindInPageIcon sx={{ fontSize: 80, color: "#cfd8dc"}}/>
            <Typography variant="body1" sx={{color: "#cfd8dc"}}>
                {
                    fileType === null ? 
                    "No File Selected"
                    : "File type " + fileType + " not supported"
                }
            </Typography>
        </React.Fragment>
    </Box>
    );
};

export default UnsupportedFileType;