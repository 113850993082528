import React, {useEffect, useState, useContext} from 'react';

// OWN
import Widget from "../widget"
import OptionsMenu from '../optionMenu';
import { PageContext } from '../../../../context/pageContext';

// MUI V5
import IconButton from "@mui/material/IconButton"
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip'

// ICONS
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SvgInstrumentComponent from "../../../../graphics/instrument"
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';

const WidgetStorageQuota = (props) => {

    let {
        storageCount,
        storageQuota,
        loading,
    } = props;

    const Pcontext = useContext(PageContext);

    let quotaWarningPercentage = 80;

    return (
    <React.Fragment>
        <Widget 
            backgroundColor="" 
            icon={<CloudCircleIcon />}
            actionButton={<OptionsMenu actionArray={[{title: "Show Subscription", fn: () => {Pcontext.setPage("subscription")}}]}/>}
            title="Storage Quota"
            fixedHeight="130px"
        >
            {
                loading ? 
                <Skeleton variant="text" sx={{ fontSize: 'h5' }} /> 
                : <Box sx={{ width: '100%', mr: 1 }}>
                    <Tooltip title={storageCount.toFixed(2) + "GB of " + storageQuota.toFixed(2) + "GB used"} placement="bottom-end" >
                    <LinearProgress 
                        variant="determinate" 
                        color={storageCount/storageQuota*100 >= 100 ? "error" : storageCount/storageQuota*100 >= quotaWarningPercentage ? "warning" : "primary"}
                        sx={{ 
                            height: "20px",
                        }} 
                        value={
                            Math.floor(storageCount/storageQuota*100) > 100 
                            ? 100 
                            : Math.floor(storageCount/storageQuota*100)
                        }
                    />
                    </Tooltip>
                </Box>
            }
            {
                loading ? 
                <Skeleton variant="text" sx={{ fontSize: 'h5' }} /> 
                : <Box sx={{marginTop: "5px"}}>
                    <Stack direction="row">
                        {
                            storageCount/storageQuota*100 >= 100 ?
                            <ErrorIcon sx={{marginRight: "0.25rem"}} color="error"/>
                            : storageCount/storageQuota*100 >= quotaWarningPercentage ?
                            <WarningIcon sx={{marginRight: "0.25rem"}} color="warning"/>
                            : <></>
                        }
                        <Typography variant="button" sx={{color: storageCount/storageQuota*100 >= 100 ? "error.main" : storageCount/storageQuota*100 >= quotaWarningPercentage ? "warning.main" : "default", marginTop: "2px"}}>
                        {
                            storageCount/storageQuota*100 > 100 ? 
                            "Storage full" 
                            : Math.floor(storageCount/storageQuota*100) >= quotaWarningPercentage ? 
                            Math.floor(storageCount/storageQuota*100) + "% used" 
                            : Math.floor(storageCount/storageQuota*100) + "% used" 
                        }
                        </Typography>
                    </Stack>
                </Box>
                
            }            
        </Widget>
    </React.Fragment>
    );
};

export default WidgetStorageQuota;