import React, {useEffect, useState, useContext} from 'react';

// OWN
import Widget from "../widget"
import OptionsMenu from '../optionMenu';
import { PageContext } from '../../../../context/pageContext';

// MUI V5
import IconButton from "@mui/material/IconButton"
import Skeleton from '@mui/material/Skeleton';
import { Typography } from '@mui/material';

// ICONS
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SvgInstrumentComponent from "../../../../graphics/instrument"



const WidgetInstrumentQuota = (props) => {

    let {
        instrumentCount,
        instrumentQuota,
        loading,
    } = props;
    
    const Pcontext = useContext(PageContext);

    return (
    <React.Fragment>
        <Widget 
            backgroundColor="" 
            icon={<SvgInstrumentComponent fill={"#1976d2"} secondcolor="white" style={{width: "20px", marginBottom: "2px"}}/>}
            actionButton={<OptionsMenu actionArray={[{title: "Show Subscription", fn: () => {Pcontext.setPage("subscription")}}]}/>}
            title="Instruments"
            fixedHeight="130px"

        >
            {
                loading ? 
                <Skeleton variant="text" sx={{ fontSize: 'h5' }} /> 
                : <Typography variant ="h5">{instrumentCount}/{instrumentQuota}</Typography>
            }
            {
                loading ? 
                <Skeleton variant="text" sx={{ fontSize: 'h5' }} /> 
                : <Typography variant ="button" color="gray">Instruments</Typography>
            }            
        </Widget>
    </React.Fragment>
    );
};

export default WidgetInstrumentQuota;