import React, {useState, useEffect} from 'react';

// MUI V5
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';

export default function LinearProgressWithoutLabel(props) { 

    let {text, disabled} = props;
     
    return (
        <Tooltip title={text} placement="left" arrow={true}>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <Box sx={{ width: '62px', }}>
                    <LinearProgress 
                        {...props}
                        variant="determinate" 
                        sx={{
                            "& .MuiLinearProgress-barColorPrimary": {
                                backgroundColor: disabled ? "gray" : "default",
                            },
                        backgroundColor: disabled ? "lightgray" : "default",
                        height: "20px"
                        }}
                    
                    />
                </Box>
            </Box>
        </Tooltip>
    );
  }