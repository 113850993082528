import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Reset from "./components/auth/Reset"
import Confirm from "./components/auth/Confirm"
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

ReactDOM.render(
  // <React.StrictMode>
    <Router>
    <Switch>
      <Route path="/reset/:token">
        <Reset />
      </Route>
      <Route path="/confirmemail/:token">
        <Confirm />
      </Route>
      <Route path="/">
        <App />
      </Route>
    </Switch>
    </Router>,
  // </React.StrictMode>,
  document.getElementById('root')
);
