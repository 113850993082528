import React, {useEffect, useState} from 'react';

// OWN
import {FileExplorer} from "../../fileExplorer/index.js"
import { SeismicDataContext } from "../../../../context/seismicDataContext.js"

// MUI V5
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

// ICONS
import FileOpenIcon from '@mui/icons-material/FileOpen';

export default function ToolbarFile() {
    const [anchorEl, setAnchorEl] = useState(null);

    const SeisDataContext = React.useContext(SeismicDataContext);

    useEffect(() => {
      setAnchorEl(null);
    }, [SeisDataContext.openMultipleFiles])

  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    return (
      <div style={{marginLeft: "10px"}}>
        <Tooltip title="Open file">
        <span>
          <Button variant="contained" onClick={handleClick} disableElevation>
            <FileOpenIcon />
          </Button>
        </span>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div style={{width: "600px", height: "500px", paddingTop: "24px", paddingRight: "24px", paddingBottom: "60px", paddingLeft: "24px"}}>
            <FileExplorer hideLeftClickMenu={true}/>
          </div>
        </Popover>
      </div>
    );
  }