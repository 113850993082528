import React, { useCallback, useEffect } from 'react';

import { AuthContext } from "../../context/auth-context"
import { Dashboard } from "../content/dashboard/index"
import { Ping } from "../content/Ping"
import { Profile } from "../content/profile/Profile"
import FileExplorerContainer from "../content/fileExplorer/index"
import ProfileButton from "./ProfileButton"
import UserTable from "../content/users/index"
import Subscription from "../content/subscription/index"
import Instruments from "../content/instruments/index"
import DataViewer from "../content/dataViewer/index"
import EventCalendar from "../content/eventCalendar/index"
import { PageContext } from "../../context/pageContext"
import { QuerybarContext } from '../../context/querybarContext'
import SvgLogoComponent from '../../graphics/logo'
import SubscriptionExpired from './SubscriptionExpired'
import { backendApi, clientConfigApplication } from "../api/calls"

// MUI V5
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import LinearProgress from '@mui/material/LinearProgress';
import useMediaQuery from '@mui/material/useMediaQuery';


// ICONS
import SvgInstrumentComponent from "../../graphics/instrument"
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import HearingIcon from '@mui/icons-material/Hearing';
import PeopleIcon from '@mui/icons-material/People';
import LockIcon from '@mui/icons-material/Lock';
import BusinessIcon from '@mui/icons-material/Business';
import FolderIcon from '@mui/icons-material/Folder';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ErrorIcon from '@mui/icons-material/Error';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
  );

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


export default function PersistentDrawerLeft(props) {
    const [open, setOpen] = React.useState(true);
    const [canManageUsers, setCanManageUsers] = React.useState(false)

    const PContext = React.useContext(PageContext);
    const QueryContext = React.useContext(QuerybarContext);
    const AContext = React.useContext(AuthContext);
    const isAdmin = AContext.state.user.admin;
    // const canManageUsers = AContext.state.user.permissions.createUsers;
    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));


    useEffect(() => {
        AContext.state.user.permissions.createUsers !== undefined && AContext.state.user.permissions.createUsers === true ?
        setCanManageUsers(true)
        : setCanManageUsers(false)
    }, [AContext.state.user.permissions.createUsers, setCanManageUsers])

    // if(AContext.state.user.permissions.createUsers !== undefined && AContext.state.user.permissions.createUsers === true){
    //     canManageUsers = AContext.state.user.permissions.createUsers;
    // } else {
    //     canManageUsers = false;
    // }

    useEffect(() => {
        // this runs on first load and on browser refresh
        // look for active subscription 
        backendApi.get("/api/organization/subscription", clientConfigApplication)
        .then((res) => {
            AContext.setSubscriptionInfo({...res.data.subscriptionData.subscription, exp: Number(res.data.subscriptionData.subscription.exp)*1000})
        })
        .catch((err) => {
            console.log(err)
            // throw err
        })
    },[])

    const handlePageChange = useCallback((e) => {
        let p = e.currentTarget.getAttribute("value");
        PContext.setPage(p)
    }, [PContext]); 

    const handleToggleSidebar = useCallback(() => {
        let oldState = PContext.sidebarOpen
        PContext.setSidebarOpen(!oldState)
        setOpen(!oldState)
    }, [PContext]); 

    const renderSwitch = useCallback((p) => {
        switch (p) {
            case 'dashboard':
                return <Dashboard />;
            case 'users':
                return <UserTable />;
            case 'subscription':
                return <Subscription />;
            case 'instruments':
                return <Instruments />;
            case 'ping':
                return <Ping />;
            case 'profile':
                return <Profile />;
            case 'fileexplorer':
                return <FileExplorerContainer />;
            case 'dataviewer':
                return <DataViewer />;
            case 'eventcalendar':
                return <EventCalendar />;
            default:
                return 'foo';
        }
    }, []);


    return (
        <Box sx={{ display: 'flex', flexDirection: "row" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} elevation={0}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleToggleSidebar}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography 
                        variant="h6" 
                        noWrap 
                        component="div" 
                        sx={{ flexGrow: mdScreen ? 0 : 1 }}
                    >
                        Seisodin Cloud
                    </Typography>
                    {
                        mdScreen ? 
                        <span style={{marginLeft: "auto", marginRight: "auto"}}>
                            {
                                parseInt(AContext.subscriptionInfo.exp) > Date.now() ?
                                <></>
                                : <SubscriptionExpired />
                            }
                            
                        </span>
                        : <></>
                    }

                    <ProfileButton />
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader sx={{height: "67px"}}>
                    <SvgLogoComponent fill="#1976d2" style={{width: "60%", marginLeft: "10px"}}/>
                    <div style={{flexGrow: 1}}></div>
                    <IconButton onClick={handleToggleSidebar}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItemButton key={"dashboard"} name="dashboard" value="dashboard" onClick={handlePageChange}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Dashboard"} />
                    </ListItemButton>
                    <Divider textAlign="left"><Typography variant="overline">Account</Typography></Divider>
                    <ListItemButton key={"users"} name="users" value="users" onClick={handlePageChange} disabled={canManageUsers ? false : true}>
                        <ListItemIcon>
                            <Badge anchorOrigin={{ vertical: "top", horizontal: "right" }} badgeContent={<LockIcon fontSize="inherit"/>} >
                                <PeopleIcon />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={"Users"} color={canManageUsers ? "" : "disabled"}/>
                    </ListItemButton>
                    <ListItemButton key={"subscription"} name="subscription" value="subscription" onClick={handlePageChange} disabled={isAdmin ? false : true}>
                        <ListItemIcon>
                            <Badge anchorOrigin={{ vertical: "top", horizontal: "right" }} badgeContent={parseInt(AContext.subscriptionInfo.exp) > Date.now() ? <LockIcon fontSize="inherit"/> : "!"} color={parseInt(AContext.subscriptionInfo.exp) > Date.now() ? "default" : "error"}>
                                <CardMembershipIcon />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={"Subscription"} color={isAdmin ? "" : "disabled"} />
                    </ListItemButton>
                    <Divider textAlign="left"><Typography variant="overline">Tools</Typography></Divider>
                    <ListItemButton key={"instruments"} name="instruments" value="instruments" onClick={handlePageChange}>
                        <ListItemIcon>
                            {/* <StorageIcon /> */}
                            <SvgInstrumentComponent fill="gray" secondcolor="white" style={{width: "24px"}}/>
                        </ListItemIcon>
                        <ListItemText primary={"Instruments"} />
                    </ListItemButton>
                    {/* <ListItemButton key={"ping"} name="ping" value="ping" onClick={handlePageChange}>
                        <ListItemIcon>
                            <HearingIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Ping"} />
                    </ListItemButton> */}
                    <ListItemButton key={"fileexplorer"} name="fileexplorer" value="fileexplorer" onClick={handlePageChange} disabled={parseInt(AContext.subscriptionInfo.exp) > Date.now() ? false : true}>
                        <ListItemIcon>
                            <FolderIcon  />
                        </ListItemIcon>
                        <ListItemText primary={"File Explorer"} />
                    </ListItemButton>
                    <ListItemButton key={"dataviewer"} name="dataviewer" value="dataviewer" onClick={handlePageChange} disabled={parseInt(AContext.subscriptionInfo.exp) > Date.now() ? false : true}>
                        <ListItemIcon>
                            <AutoGraphIcon  />
                        </ListItemIcon>
                        <ListItemText primary={"Data Viewer"} />
                    </ListItemButton>
                    {/* <ListItemButton key={"eventcalendar"} name="eventcalendar" value="eventcalendar" onClick={handlePageChange} disabled={parseInt(AContext.subscriptionInfo.exp) > Date.now() ? false : true}>
                        <ListItemIcon>
                            <CalendarMonthIcon  />
                        </ListItemIcon>
                        <ListItemText primary={"Event Calendar"} />
                    </ListItemButton> */}
                </List>
            </Drawer>
            

            <Main open={open} sx={{flexGrow: 1}} style={{minWidth: 0}}>
                <DrawerHeader />
                    <LinearProgress sx={{margin: "-24px -24px 24px -24px"}} value={100} variant={(QueryContext.querybarActive !== undefined) ? (QueryContext.querybarActive === true) ? "indeterminate" : "determinate" : "determinate"}/>
                    <Box 
                        sx={{ flexGrow: 1, mt: "-24px", ml: "-24px", mr: "-24px", mb: "30px"}}
                    >
                        <CssBaseline />
                        <MuiAppBar 
                            position="static" 
                            elevation={0} 
                            sx={{background: "#dbdbdb"}}
                        >
                        <Toolbar variant="dense">
                            <Typography variant="button" noWrap component="div" sx={{ flexGrow: 1 }}>
                                {PContext.page.toUpperCase()}
                            </Typography>
                        </Toolbar>
                        </MuiAppBar>
                    </Box>
                {/* Decide what to render, depending on what is written in the state */}
                {renderSwitch(PContext.page)}
            </Main>
            
        </Box>
    );
}
