import React, { useState } from "react"

// MUI V5
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

// OWN
import { Transition } from "../styles/SlideTransition"
import "../styles/checkmarkStyles.css"


// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="down" ref={ref} {...props} />;
// });

export function CheckMarkDialog(props) {
    const [open, setOpen] = useState(true);


    const handleClose = () => {
        setOpen(false);
    };

    function timing(){
        setTimeout(function () {
            handleClose();
        }, 1500);
    }

    return (
        <div>
            {timing()}
            <Dialog 
                onClose={handleClose} 
                aria-labelledby="simple-dialog-title" 
                open={open} 
                TransitionComponent={Transition}
            >
                <CheckMark />
                <DialogTitle id="check-mark-dialog" style={{ color: "#7ac142" }}>{props.message}</DialogTitle>
            </Dialog>
        </div>
    );
}


export function CheckMark() {

    return (
        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
    )
}
