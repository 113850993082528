import React, { useContext } from 'react';
import qs from "querystring"

// OWN 
import { backendApi, clientConfigApplication } from "../../api/calls"
import { userListContext } from "./index"
import SnackBar from "../../layout/SnackBar"
import { CheckMarkDialog } from "../../layout/CheckMark"
import "../../styles/checkmarkStyles.css"
import ErrorDialog from "../../layout/ErrorDialog.js"


// MUI V5
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

// ICONS
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SaveIcon from '@mui/icons-material/Save';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function AddButton(props) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [error, setError] = React.useState({title: "test", e: ""});
    const [mySnackBar, setMySnackBar] = React.useState({ display: false, type: "", message: "" });

    const userContext = useContext(userListContext);

    const { organization } = props;

    function updateUserListState(newUser){
        let userList = userContext.state;
        userList.push(newUser);
        userContext.update([...userList])
    }

    function handleCreateUser() {
        // let updatedData = { name: name, email: email, password: password1, password2: password2 }
        let updatedData = { name: name, email: email }
        backendApi.post("/api/users/register", qs.stringify(updatedData), clientConfigApplication).then((res) => {
                let newUser = res.data.user;
                updateUserListState(newUser)
                setOpen(false);

                // Show check mark
                setMySnackBar({ display: true, type: "success", message: "Saved" });
                setTimeout(function () {
                    setMySnackBar({ display: false, type: "", message: "" });
                }, 2000);
                
                return res;
            }).catch((e) => {
                console.log(e)
                setError({ title: "Error saving new user", e: e});
                setErrorOpen(true)
                setOpen(false);
            })
    }

    function handleDialogClose() {
        setOpen(false);
    }

    return (
        <React.Fragment>
            <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
            {
                mySnackBar.display === true && mySnackBar.type === "success" ?
                    <CheckMarkDialog message="saved" />
                    : ""
            }
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <Box sx={{width: "100%", backgroundColor: "primary.light", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Stack>
                        {/* <AddCircleIcon style={{fontSize: 80}} sx={{ color: "primary.contrastText", marginTop: "20px", marginLeft: "auto", marginRight: "auto" }} /> */}
                        <PersonAddIcon sx={{ fontSize: 80, color: "primary.contrastText", marginTop: "20px", marginLeft: "auto", marginRight: "auto" }} />
                        <DialogTitle id="alert-dialog-title" sx={{color: "primary.contrastText"}}> Add New User </DialogTitle>
                    </Stack>
                </Box>
                <DialogContent>
                    <FormControl variant="filled" fullWidth>
                        <InputLabel htmlFor="component-filled">Organization</InputLabel>
                        <FilledInput id="component-filled" value={organization} disabled={true} />
                    </FormControl>
                    <TextField margin="normal" name="name" id="name" label="Name" type="text" fullWidth variant="outlined" onChange={(e) => setName(e.target.value)} />
                    <TextField margin="normal" name="email" id="email" label="Email Address" type="email" fullWidth variant="outlined" onChange={(e) => setEmail(e.target.value)} />
                    {/* <TextField margin="normal" name="password1" id="password1" label="Password" type="password" fullWidth variant="outlined" onChange={(e) => setPassword1(e.target.value)} />
                    <TextField margin="normal" name="password2" id="password2" label="Confirm Password" type="password" fullWidth variant="outlined" onChange={(e) => setPassword2(e.target.value)} /> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                        </Button>
                    <Button onClick={handleCreateUser} color="primary" variant="contained" startIcon={<SaveIcon />} >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Button onClick={() => setOpen(true)} color="primary" variant="contained" startIcon={<PersonAddIcon />} style={{ marginRight: "-1rem" }}>
                user
            </Button>
        </React.Fragment>
    )
}
