import React, { useEffect, useState, useContext, useLayoutEffect, useRef } from "react"
import _ from "lodash"
import PrintableDocument from "./utils/PrintModule/PrintableDocument.js"

// OWN
import Graph from "./Graph.js"
import {tsToRMS, minMaxOfTs} from "./utils/statisticsFunctions.js"
import { QuerybarContext } from '../../../../../context/querybarContext';
import ErrorDialog from "../../../../layout/ErrorDialog.js"
import FileNameChip from "./graphSubComponents/FileNameChip.js";
import {splitBlockette2000HeadersInArrays, blockette2000WasMadeBySeisodinAndContainsGainInformation} from "./utils/blockette2000.js"
import RightClickMenuTimeseries from "./graphSubComponents/RightClickMenuTimeseries.js";

// MUI V5
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

// ICONS
import FileOpenIcon from '@mui/icons-material/FileOpen';

const seisplot = require("seisplotjs")

export function DygraphsDataViewer(props) {

    const { 
        seismicData,
        linkTimeScales, 
        splitChannels, 
        setSplitChannels,
        configurableGraphSettings, 
        resetZoom, 
        setResetZoom,
        removeOffset,
        recallOriginalData,
        filter,
        // setFilter,
        fft,
        // setFft,
        graphRows,
        // setGraphRows,
        visibleChannels,
        setVisibleChannels,
        loadingGraphData,
        setLoadingGraphData,
        units,
        updateUnitsState,
        graphHeight,
        printMode,
        togglePrintMode,
    } = props

    // const [seisData, setSeisData] = useState()
    const [seisDataArr, setSeisDataArr] = useState()
    // const [data, setData] = useState([])
    const [dataArr, setDataArr] = useState([])
    // const [fftData, setFftData] = useState([])
    const [fftDataArr, setFftDataArr] = useState([])
    // const [metaData, setMetaData] = useState([])
    const [metaDataArr, setMetaDataArr] = useState([])
    const [errorOpen, setErrorOpen] = useState(false);
    const [error, setError] = useState({title: "test", e: ""});
    const [pageArray, setPageArray] = useState([{type: "ProtoFrontpage", payload: {title: "Dummy", subtitles: "Dummy text"}, config: {}, index: 0}]);
    const [pageArrayConfig, setPageArrayConfig] = useState({})

    const QueryContext = useContext(QuerybarContext);

    useLayoutEffect(() => {
        console.log("DATAVIEWER: layouteffect")
    }, [])

    useEffect(() => {
        console.log("DATAVIEWER: useEffect")
    }, [])

    useEffect(() => {
        // set local state with props data to force render
        console.log("DEBUG: [START] seismicData (_data_ passed as prop) changed")
        if(seismicData.length > 0 ){
            console.log("DEBUG: seismicData prop is longer than 0, so now setting SeisDataArr")
            setSeisDataArr(seismicData) // dev: only put something in SeisDataArr if there is more than one file
            // setSeisData(seismicData[0])   // show only the first file
        } else {
            console.log("DEBUG: seismicData prop has 0 length, so now setting SeisDataArr to []")
            setSeisDataArr([])
        }
        console.log("DEBUG: [END] seismicData (_data_ passed as prop) changed")
    }, [seismicData])

    
    useEffect(() => {
        const start = Date.now();
        console.log("DEBUG: [START] seisDataArr changed so now performing some stuff")

        if(seisDataArr && seisDataArr.length > 0 && seisDataArr !== undefined){
            let graphdataCollection = [];
            let channelMetaDataCollection = [];

            let previousData = dataArr;
            let previousMetaData = metaDataArr;

            // iterate over the selected files
            seisDataArr.forEach((file, fileIndex) => {
                // iterate over the channels in each file
                let metaDataPerFile = [];
                let graphDataPerFile = []
                
                file.forEach((v, channel) => {

                    // variables used to determine if time stamps need recalculation or not
                    let previousNumberOfSamples = previousData?.[fileIndex]?.[channel]?.length;
                    let previousSampleRate = previousMetaData?.[fileIndex]?.[channel]?.sampleRate;
                    let previousEndTime = new Date(previousMetaData?.[fileIndex]?.[channel]?.endTime);
                    let newNumberOfSamples = seisDataArr?.[fileIndex]?.[channel]?.y?.length;
                    let newSampleRate = seisDataArr?.[fileIndex]?.[channel]?.sampleRate;
                    let newEndTime = new Date(seisDataArr?.[fileIndex]?.[channel]?.endTime);

                    // variables for actual business
                    let graphdataPerChannel = [];   
                    let sampleRate = seisDataArr[fileIndex][channel].sampleRate;
                    var sTime = new Date(seisDataArr[fileIndex][channel].startTime);

                        try {
                            // This IF statement checks if we can avoid recalculating the time stamps to save time
                            if(previousNumberOfSamples === newNumberOfSamples && previousSampleRate === newSampleRate && previousEndTime.getTime() === newEndTime.getTime()){
                                console.log("start creating dygraph dataset with KNOWN timestamps [timestamp, y]")
                                Object.keys(seisDataArr[fileIndex][channel].y).forEach(function(key, sampleIndex) {
                                    graphdataPerChannel.push([previousData[fileIndex][channel][sampleIndex][0], seisDataArr[fileIndex][channel].y[sampleIndex]])
                                  });
                                console.log("done creating dygraph dataset with KNOWN timestamps [timestamp, y]")
                            } else {
                                console.log("start creating dygraph dataset [timestamp, y]")
                                Object.keys(seisDataArr[fileIndex][channel].y).forEach(function(key, sampleIndex) {
                                    // Calculate the time stamp for each sample based on start time and sample rate
                                    // var sTime = new Date(seisDataArr[fileIndex][channel].startTime);
                                    // sTime.setMilliseconds(sTime.getMilliseconds() + (1/sampleRate*1000*sampleIndex));
                                    sTime.setMilliseconds(sTime.getMilliseconds() + (1/sampleRate*1000));
                                    // graphdataPerChannel.push([new Date(sTime), seisDataArr[fileIndex][channel].y[index]])
                                    graphdataPerChannel.push([new Date(sTime), seisDataArr[fileIndex][channel].y[sampleIndex]])
                                  });
                                  console.log("done creating dygraph dataset [timestamp, y]")
                            }
                        } catch(e) {
                            console.log("A error on channel " + channel + " " + e)
                            e = {...e, response: {data: { message: e.message}}};
                            setError({ title: "Issue with miniseed file", e: e});
                            setErrorOpen(true)
                            console.log("failed creating dygraph dataset [timestamp, y]")
                        }

                    let SeismogramDisplayData = seisplot.seismogram.SeismogramDisplayData.fromSeismogram(seisDataArr[fileIndex][channel])
    
                    try {
                    // construct channelMetaDataCollection one channel at a time
                    let blockette2000SeisodinGainInformationHeaderKey = blockette2000WasMadeBySeisodinAndContainsGainInformation(seismicData[fileIndex][0].blockette2000Payload)

                      metaDataPerFile.push({
                        fileName: seismicData[fileIndex][0].fileName,
                        channelCode: seisDataArr[fileIndex][channel].channelCode,
                        endTime: seisDataArr[fileIndex][channel].endTime,
                        locationCode: seisDataArr[fileIndex][channel].locationCode,
                        networkCode: seisDataArr[fileIndex][channel].networkCode,
                        numPoints: seisDataArr[fileIndex][channel].numPoints,
                        sampleRate: seisDataArr[fileIndex][channel].sampleRate,
                        startTime: seisDataArr[fileIndex][channel].startTime,
                        stationCode: seisDataArr[fileIndex][channel].stationCode,
                        timeRange: seisDataArr[fileIndex][channel].timeRange,
                        yUnit: seisDataArr[fileIndex][channel].yUnit,
                        min: SeismogramDisplayData.min,
                        max: SeismogramDisplayData.max, 
                        mean: SeismogramDisplayData.mean,
                        rms: tsToRMS(seisDataArr[fileIndex][channel].y, ),
                        tsStats: minMaxOfTs(seisDataArr[fileIndex][channel].y, seisDataArr[fileIndex][channel].startTime, seisDataArr[fileIndex][channel].sampleRate),
                        nonContiguousDataRemovedFromFile: seismicData[fileIndex][0].nonContiguousDataRemovedFromFile,
                        blockette2000WasFoundInFile: seismicData[fileIndex][0].blockette2000WasFoundInFile,
                        blockette2000Payload: seismicData[fileIndex][0].blockette2000Payload,
                        blockette2000Headers: splitBlockette2000HeadersInArrays(seismicData[fileIndex][0].blockette2000Payload),
                        blockette2000SeisodinGainInformationHeaderKey: blockette2000SeisodinGainInformationHeaderKey,
                        blockette2000SeisodinSensitivity: blockette2000SeisodinGainInformationHeaderKey !== -1 ? JSON.parse(seismicData[fileIndex][0].blockette2000Payload[blockette2000SeisodinGainInformationHeaderKey]).countsPerUnit[channel] : 1,
                        // blockette2000SeisodinSensitivity: blockette2000SeisodinGainInformationHeaderKey,
                        blockette2000SeisodinPhysicalUnit: blockette2000SeisodinGainInformationHeaderKey !== -1 ? JSON.parse(seismicData[fileIndex][0].blockette2000Payload[blockette2000SeisodinGainInformationHeaderKey]).units[channel] : "no unit",
                      })
                      graphDataPerFile.push(graphdataPerChannel)
                    } catch(e){
                        console.log("B error on channel " + channel + " " + e)
                        e = {...e, response: {data: { message: e.message}}};
                        setError({ title: "Issue with miniseed file", e: e});
                        setErrorOpen(true)
                    }
    

                         
                })
                graphdataCollection.push(graphDataPerFile);
                channelMetaDataCollection.push(metaDataPerFile)
            })
            // set data state to updated graphdataCollection
            setDataArr(graphdataCollection)
            // set metaData state to updated channelMetaDataCollection
            setMetaDataArr(channelMetaDataCollection)

        } 

        const millis = Date.now() - start;
        console.log("DEBUG: [END] seisDataArr changed so now performing some stuff " + millis + "ms")

    }, [seisDataArr])  


    // Removes offset from all channels in all
    // files when a stateFlipper flag changes
    // in removeOffset state.
    ////////////////////////////////////////////
    useEffect(() => {
        
        let newSeisDataArr = [];
        if(seisDataArr && seisDataArr !== null && seisDataArr !== undefined && seisDataArr.length >0){
            console.log("DEBUG [START] remove offset")
            seisDataArr.forEach((file,fileIndex) => {
                let eachFile = [];
                file.forEach((channel, channelIndex) => {
                    eachFile[channelIndex] = seisplot.filter.rMean(seisDataArr[fileIndex][channelIndex])
                })
                newSeisDataArr.push(eachFile)
            })
            // setSeisData(newSeisDataArr[0])
            setSeisDataArr(newSeisDataArr)
            console.log("DEBUG [END] remove offset")
        }

    }, [removeOffset])

    // Gain compensates data in all
    // files when a stateFlipper flag changes
    // in physicalUnits state.
    ////////////////////////////////////////////
    useEffect(() => {

        let newSeisDataArr = [];
        if(seisDataArr && seisDataArr !== null && seisDataArr !== undefined && seisDataArr.length >0){
            console.log("DEBUG [START] converting between counts and physical units")
            seisDataArr.forEach((file,fileIndex) => {
                let eachFile = [];
                file.forEach((channel, channelIndex) => {
                    let instrumentSensitivity = Number(metaDataArr?.[fileIndex]?.[channelIndex]?.blockette2000SeisodinSensitivity)
                    let instrumentPhysicalUnits= metaDataArr?.[fileIndex]?.[channelIndex]?.blockette2000SeisodinPhysicalUnit

                    if(units.prev.length < 1 && units.current.length > 0) {
                        // This is probably the first load
                        console.log("DEBUG: units, first load of file"+fileIndex+"ch"+channelIndex)
                        eachFile[channelIndex] = seisplot.filter.gainCorrect(seisDataArr[fileIndex][channelIndex], {sensitivity: 1, inputUnits: channel.yUnit})
                    } else if (units.prev.length < 1 && units.current.length < 1) {
                        // do nothing
                        console.log("DEBUG: units state prev or current is empty for file"+fileIndex+"ch"+channelIndex)
                    } else if (units.prev[fileIndex][channelIndex].applyPhysicalUnits === true && units.current[fileIndex][channelIndex].applyPhysicalUnits === false){
                        // A: changed from true to false
                        console.log("DEBUG: Units state changed from true to false for file"+fileIndex+"ch"+channelIndex)
                        eachFile[channelIndex] = seisplot.filter.gainCorrect(seisDataArr[fileIndex][channelIndex], {sensitivity: 1/instrumentSensitivity, inputUnits: "count"})
                    } else if(units.prev[fileIndex][channelIndex].applyPhysicalUnits === false && units.current[fileIndex][channelIndex].applyPhysicalUnits === true){
                        // B: changed from false to true
                        console.log("DEBUG: Units state changed from false to true for file"+fileIndex+"ch"+channelIndex)
                        eachFile[channelIndex] = seisplot.filter.gainCorrect(seisDataArr[fileIndex][channelIndex], {sensitivity: instrumentSensitivity, inputUnits: instrumentPhysicalUnits})
                    } else if(units.prev[fileIndex][channelIndex].applyPhysicalUnits === units.current[fileIndex][channelIndex].applyPhysicalUnits){
                        // C: unchanged
                        console.log("DEBUG: Units state unchanged for file"+fileIndex+"ch"+channelIndex)
                        eachFile[channelIndex] = seisDataArr[fileIndex][channelIndex]; // just copy the old data so we dont have to calculate new data with gianCorrect
                    } 
                })
                newSeisDataArr.push(eachFile)
            })
            // setSeisData(newSeisDataArr[0])
            setSeisDataArr(newSeisDataArr)
            console.log("DEBUG [END] converting between counts and physical units")
        }
    }, [units])

    // Recalls the original data when a flag 
    // changes in recallOriginalData state
    ////////////////////////////////////////////
    useEffect(() => {
        console.log("DEBUG [START] original data recall")
        
        // disable units on all channels to avoid flipping the unit state flags
        const updatedState = _.cloneDeep(units.current);
        updatedState.forEach((file, fileIndex) => {
            file.forEach((channel, channelIndex) => {
                updatedState[fileIndex][channelIndex].applyPhysicalUnits = false;
            })
        })

        // update states
        updateUnitsState(updatedState, updatedState)
        setSeisDataArr(seismicData) 
        console.log("DEBUG [END] generating filter data")
        // setSeisData(seismicData[0]) 
    }, [recallOriginalData])

    // Applies a filter to ALL data when 
    // filter.stateFlipper changes in the state
    // the filter configuration is stored in 
    // state (filter). It is applied only once
    // if another filter is applied, it will 
    // be applied on top of the already filtered data.
    ////////////////////////////////////////////
    useEffect(() => {
        let newSeisDataArr = [];
        if(seisDataArr && seisDataArr !== null && seisDataArr !== undefined && seisDataArr.length >0){
            console.log("DEBUG [START] generating filter data")
            seisDataArr.forEach((file, fileIndex) => {
                let filteredDataForEachFile = [];
                file.forEach((channel, channelIndex) => {
                    let filterConfig;

                    if(filter.name === "butterworth"){
                        filterConfig = seisplot.filter.createButterworth(
                            filter.poles, // poles
                            (   filter.type === "bp" ? seisplot.filter.BAND_PASS : 
                                filter.type === "lp" ? seisplot.filter.LOW_PASS : 
                                filter.type === "hp" ? seisplot.filter.HIGH_PASS : 
                                seisplot.filter.LOW_PASS
                            ),
                            filter.lowCorner, // low corner
                            filter.highCorner, // high corner
                            1/channel.sampleRate // delta (period)
                        );
                    } else if ( filter.name === "chebyshev1"){
                        filterConfig = seisplot.filter.createChebyshevI(
                            filter.poles, // poles
                            filter.epsilon, // Chebyshev Epsilon value
                            (   filter.type === "bp" ? seisplot.filter.BAND_PASS : 
                                filter.type === "lp" ? seisplot.filter.LOW_PASS : 
                                filter.type === "hp" ? seisplot.filter.HIGH_PASS : 
                                seisplot.filter.LOW_PASS
                            ),
                            filter.lowCorner, // low corner
                            filter.highCorner, // high corner
                            1/channel.sampleRate // delta (period)
                        );
                    } else if ( filter.name === "chebyshev2"){
                        filterConfig = seisplot.filter.createChebyshevII(
                            filter.poles, // poles
                            filter.epsilon, // Chebyshev Epsilon value
                            (   filter.type === "bp" ? seisplot.filter.BAND_PASS : 
                                filter.type === "lp" ? seisplot.filter.LOW_PASS : 
                                filter.type === "hp" ? seisplot.filter.HIGH_PASS : 
                                seisplot.filter.LOW_PASS
                            ),
                            filter.lowCorner, // low corner
                            filter.highCorner, // high corner
                            1/channel.sampleRate // delta (period)
                        );
                    } 
    
                    let rmeanSeis = seisplot.filter.rMean(channel);
                    let filteredSeis = seisplot.filter.applyFilter(filterConfig, rmeanSeis);
                    filteredDataForEachFile.push(filteredSeis)
                })
                // add the filtered channel for the entire file to an array
                newSeisDataArr.push(filteredDataForEachFile)
            })
            // setSeisData(newSeisDataArr[0])
            setSeisDataArr(newSeisDataArr)
            console.log("DEBUG [END] generating filter data")
        }
    }, [filter.stateFlipper])


    useEffect(() => {
        if(seisDataArr && seisDataArr !== null && seisDataArr !== undefined && seisDataArr.length >0 && fft.firstRender && fft.live){
            console.log("DEBUG [START] generating fftDataArr")
            let fftList = [];
            let newfftDataArr = [];

            seisDataArr.forEach((file, fileIndex) => {

                let newFftDataArrayPerFile = [];

                file.forEach((sdd, i) => {
                    fftList[i] = seisplot.fft.fftForward(seisplot.taper.taper(sdd, fft.windowlength, fft.window)) 
                    let temp = []
                    fftList[i].amp.forEach((v,j) => {
                        if(fft.logscaleX === true){
                            temp.push([(j+1)*fftList[i].fundamentalFrequency, fftList[i].amp[j]])
                        } else {
                            temp.push([(j+1)*fftList[i].fundamentalFrequency, fftList[i].amp[j]])
                        }
                    })
                    newFftDataArrayPerFile[i] = temp;
                })
                newfftDataArr.push(newFftDataArrayPerFile)
            })

            setFftDataArr(newfftDataArr)
            console.log("DEBUG [END] generating fftDataArr")
        }
    }, [fft.stateFlipper, fft.logscaleX, dataArr])

    // Update pageArray for print module every 
    // time the data changes.
    ////////////////////////////////////////////
    useEffect(() => {

        console.log("updating pageArray behind the scenes")
            let newPageArray = [];

            dataArr.forEach((file, fileIndex) => {
                newPageArray.push({
                    type: "ProtoSingleFileGraph", 
                    payload: {
                        timeDomain: file, 
                        frequencyDomain: fftDataArr[fileIndex], 
                        metaData: metaDataArr[fileIndex]}, 
                    config: {}, 
                    dataviewerConfig: {
                        visibleChannels: visibleChannels[fileIndex],
                        fftFirstRender: fft.firstRender,
                    },
                })
            })

            setPageArray(newPageArray)
        
    }, [dataArr, fftDataArr, metaDataArr, fft.firstRender])

    function handlePrintMode(){
        togglePrintMode()
    }

    
    // function countSelectedFiles(){
    //     let count = 0;
    //     if(seisDataArr !== undefined && seisDataArr !== null && seisDataArr !== ""){
    //         count = seisDataArr.length
    //     }
    //     return count
    // }


    return (
        <Box style={{height: printMode ? "calc(100vh - 136px)" : ""}}>
            <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
            {
                printMode ?
                <div style={{height: "100%", width: "100%"}}>
                    <PrintableDocument closePrintDialogCallback={handlePrintMode} pageArray={pageArray} pageArrayConfig={pageArrayConfig}/>
                </div>
                : <React.Fragment>
                        <Stack id="report">
                            {
                                (dataArr === undefined || dataArr === null || dataArr === "" || dataArr.length < 1) ? 
                                <Box textAlign="center" >
                                    <React.Fragment>
                                        <FileOpenIcon sx={{ fontSize: 80, color: "#cfd8dc", marginTop: "100px" }}/>
                                        <Typography variant="body1" sx={{color: "#cfd8dc", marginBottom: "100px"}}>Select a file to display</Typography>
                                    </React.Fragment>
                                </Box>
                                : ""
                            }
                            {
                                dataArr.length > 0? 
                                <>
                                {
                                    dataArr.map((fileData, fileIndex) => 
                                    <React.Fragment key={"FileContainerFragment"+fileIndex}>
                                        {/* <p>{JSON.stringify(units)}</p> */}
                                        {
                                            graphRows ? 
                                            <></>
                                            : <Box sx={{height: "24px", backgroundColor: "lightgray", paddingLeft: "1rem"}}>
                                                <FileNameChip metaData={metaDataArr[fileIndex]} fileName={String(metaDataArr[fileIndex][0].fileName)} loadingGraphData={loadingGraphData}/>
                                            </Box>
                                        }
                                        
                                        <Stack direction={graphRows? "column" : "row"} id="report2" key={"stack"+fileIndex}>
                                            <RightClickMenuTimeseries 
                                                data={seismicData} // must be in array to simulate a structure of files
                                                units={units} 
                                                updateUnitsState={updateUnitsState}
                                                visibleChannels={visibleChannels}
                                                setVisibleChannels={setVisibleChannels}
                                            >
                                                <Graph 
                                                    key={"timeseriesGraphs"+fileIndex}
                                                    uid={"file"+fileIndex}
                                                    title={"timeseries"} 
                                                    data={fileData} // time series data
                                                    metaData={metaDataArr[fileIndex]}
                                                    linkTimeScales={linkTimeScales}
                                                    splitChannels={splitChannels}
                                                    setSplitChannels={setSplitChannels}
                                                    configurableGraphSettings={configurableGraphSettings}
                                                    resetZoom={resetZoom}
                                                    setResetZoom={setResetZoom}
                                                    graphRows={graphRows}
                                                    setGraphRows={graphRows}
                                                    visibleChannels={visibleChannels[fileIndex]}
                                                    setVisibleChannels={setVisibleChannels}
                                                    positionOnPage={fileIndex}
                                                    loadingGraphData={loadingGraphData}
                                                    setLoadingGraphData={setLoadingGraphData}
                                                    units={units}
                                                    graphHeight={graphHeight}
                                                    updateUnitsState={updateUnitsState}
                                                    printMode={printMode}
                                                    // fft={fft}
                                                />
                                            </RightClickMenuTimeseries>
                                                {
                                                    fftDataArr.length > 0 ?
                                                    <React.Fragment>
                                                        <Graph
                                                            key={"FftGraphs"+fileIndex}
                                                            uid={"fft"+fileIndex}
                                                            title={"FFT"}
                                                            data={fftDataArr[fileIndex]} // must be FFT data
                                                            metaData={metaDataArr[fileIndex]}
                                                            linkTimeScales={linkTimeScales}
                                                            splitChannels={splitChannels}
                                                            setSplitChannels={setSplitChannels}
                                                            configurableGraphSettings={configurableGraphSettings}
                                                            resetZoom={resetZoom}
                                                            setResetZoom={setResetZoom}
                                                            graphRows={graphRows}
                                                            setGraphRows={graphRows}
                                                            visibleChannels={visibleChannels[fileIndex]}
                                                            setVisibleChannels={setVisibleChannels}
                                                            positionOnPage={fileIndex}
                                                            loadingGraphData={loadingGraphData}
                                                            setLoadingGraphData={setLoadingGraphData}
                                                            units={units}
                                                            graphHeight={graphHeight}
                                                            updateUnitsState={updateUnitsState}
                                                            printMode={printMode}
                                                            fft={fft}
                                                        />
                                                    </React.Fragment>
                                                    : <React.Fragment></React.Fragment>
                                                }
                                        </Stack>

                                    </React.Fragment>
                                    )
                                }
                                </>
                                : <>
                                </>
                            }
                        </Stack>
                </React.Fragment>

            }
        </Box>
    )
}
