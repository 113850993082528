import React, {useEffect, useState, useContext} from 'react';
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import beautify from "js-beautify"

// OWN
import ErrorDialog from '../../../../layout/ErrorDialog';
import {INTERFACE_downloadFile} from "../../../fileExplorer/interface"
import FileTabPanel from './fileTabPanel';
import { QuerybarContext } from '../../../../../context/querybarContext';
import JSONSettingsToolbar from "./jsonSettingsToolbar"

// MUI V5
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"

import "prismjs/components/prism-json"

function CodeArea({ code, language}) {
    useEffect(() => {
        Prism.highlightAll();
    }, [language, code]);

  
    return (
      <div className="Code">
        <pre style={{margin: 0, padding: 0}}>
          {/* <code className={'language-log'} code={code}>{code}</code> */}
          <code className={`language-${language}`}>{code}</code>
          {/* <code className={`language-${language}`}>{code}</code> */}
        </pre>
      </div>
    );
}

const JSONViewer = (props) => {
    const [data, setData] = useState(`{test: "ok"}`)
    const [fileIndex, setFileIndex] = useState(0);
    const [fileNames, setFileNames] = useState([]);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [error, setError] = React.useState({title: "test", e: ""});

    let {
        path,
        pathArr,
    } = props;

    const QueryContext = useContext(QuerybarContext);

    useEffect(async () => {
        QueryContext.setQuerybarActive(true)
        // Check if multiple files were selected, or just one
        if(countSelectedFiles() > 0){
            // if multiple files were selected
            console.log("Treating render as a multiple files")
            let newDataArr = [];
            let listOfNewFilesnames = [];
            let r = await Promise.all(pathArr.map(async (dir, dirIndex) => {
                return await Promise.all(dir.files.map(async (file, fileIndex) => {
                    return await INTERFACE_downloadFile(dir.path+file.name).then(res => {
                        listOfNewFilesnames.push(getFileExtension(res?.config?.params?.path)) // save the file names
                        var enc = new TextDecoder("utf-8"); 
                        var arr = new Uint8Array(res.data);
                        let str = enc.decode(arr); // convert ArrayBuffer to string
                        newDataArr.push(str)
                        return 0
                    }).catch(e => {
                        console.log("failed loading from pathArr")
                        setError({ title: "Error downloading files from server", e: e});
                        setErrorOpen(true)
                        QueryContext.setQuerybarActive(false)
                    })
                }))
            }))
            QueryContext.setQuerybarActive(false)
            setFileNames(listOfNewFilesnames) // save to state
            setData(newDataArr) // save to state
            setFileIndex(0) // save to state
        } else {
            // if just one file was selected
            console.log("Treating render as a single file")
            await INTERFACE_downloadFile(path).then(res => {
                let fileName = res?.config?.params?.path // save the file name
                setFileNames([getFileExtension(fileName)])
                var enc = new TextDecoder("utf-8");
                var arr = new Uint8Array(res.data);
                let str = enc.decode(arr); // convert ArrayBuffer to string
                setData([str]) // save to state
                QueryContext.setQuerybarActive(false)
            }).catch(e => {
                console.log("error downloading file")
                console.log(e)
                setError({ title: "Error downloading file from server", e: e});
                setErrorOpen(true)
                QueryContext.setQuerybarActive(false)
            })
        }
    },[])

    function countSelectedFiles(){
        let count = 0;
        pathArr.forEach((v, i) => {
            if(v.files.length !== undefined){
                count = count + v.files.length
            } else {
                count = count + 0;
            }
        })
        return count
    }

    function getFileExtension(filepath){
        // https://stackoverflow.com/questions/680929/how-to-extract-extension-from-filename-string-in-javascript
        var filename = filepath.replace(/^.*[\\\/]/, '')
        return filename
    }

    return (
        <React.Fragment>
            <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
            <Box>
                <JSONSettingsToolbar />
                <FileTabPanel 
                    fileIndex={fileIndex} 
                    fileNames={fileNames}
                    setFileIndex={setFileIndex}
                />
                <Box 
                    sx={{
                        backgroundColor: "#2d2d2d", 
                        color: "white", 
                        margin: "0px -24px -24px 0px", 
                        height: "calc(100vh - 220px)", 
                    }} 
                    style={{
                        overflow: "auto",
                    }}
                > 
                    <CodeArea code={beautify(data[fileIndex])} language={"json"}/> 
                </Box>
            </Box>
        </React.Fragment>


    );
};

export default JSONViewer;