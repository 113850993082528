import React, {useState, useEffect, useRef} from 'react';
import { useReactToPrint } from 'react-to-print';
import $ from "jquery"
import "./styles/paperstyle.css"
import ControlPanel from './ControlPanel';
import PaperContent from './PaperContent';
import PaperControlBar from './subComponents/PaperControlBar';

// MUI V5
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


// Create Document Component
const PrintableDocument = (props) => {
    const [dummy, setDummy] = useState(false)
    const [uiW, setUiW] = useState()
    const [uiH, setUiH] = useState()
    const [elW, setElW] = useState()
    const [elH, setElH] = useState()
    const [paperFormat, setPaperFormat] = useState("A4");
    const [margins, setMargins] = useState({top: 10, right: 10, bottom: 10, left: 10, unit: "mm"})
    const [localCopyOfPageArray, setLocalCopyOfPageArray] = useState([])
    const [localCopyOfPageArrayConfig, setLocalCopyOfPageArrayConfig] = useState({})
    const [internalPageArray, setInternalPageArray] = useState([]);
    const [pageCurrent, setPageCurrent] = useState(0);
    

    let {
        closePrintDialogCallback, // call this function to leave this print view
        pageArray, // [{type: "frontpage", payload: {}, config: {}, index: 0},{...}]
        pageArrayConfig,
    } = props;

    const containerRef = useRef();
    const previewElementRef = useRef();
    const printableElementRef = useRef();

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const paperDimensions = {
        A4: {width: 210,  height: 297, unit: "mm"},
        A3: {width: 297,  height: 420, unit: "mm"},
        LETTER: {width: 215.9,  height: 279.4, unit: "mm"},
        LEGAL: {width: 215.9,  height: 355.6, unit: "mm"},
    }

    var $specificDesign = $("#very-specific-design");
    var $printWrapper = $("#print-wrapper");

    var elHeight = $specificDesign.outerHeight();
    var elWidth = $specificDesign.outerWidth();

    const handlePrint = useReactToPrint({
      content: () => printableElementRef.current,
      onBeforeGetContent: beforeGetContent,
      onBeforePrint: beforePrint,
      onAfterPrint: afterPrintCleanup,
    });

    function beforePrint(){
        doResize()
        document.title = "Seisodin Cloud Report " + new Date(Date.now()).toDateString()
    }

    function afterPrintCleanup() {
        document.title = "Seisodin Cloud"
        $printWrapper.css({
            display: "block"
        });
    }

    function beforeGetContent() {
        $printWrapper.css({
            display: "block"
        });
    }

    function print () {
        $printWrapper.css({
            display: "block"
        });
        handlePrint()
    }

      // This function calculates width and height of the list
    const getContainerSize = () => {
        const newWidth = containerRef?.current?.clientWidth;
        setUiW(newWidth);

        const newHeight = containerRef?.current?.clientHeight;
        setUiH(newHeight);
    };

    const getPrintableElementSize = () => {
        const newWidth = previewElementRef?.current?.clientWidth;
        setElW(newWidth);

        const newHeight = previewElementRef?.current?.clientHeight;
        setElH(newHeight);
    };

    function resizeCallback(){
        getContainerSize()
        doResize()
    }

    useEffect(() => {
        // any settings the user may want to transfer from dataview is copied here, only once
        setLocalCopyOfPageArrayConfig({...pageArrayConfig})
    },[])

    useEffect(() => {      

        console.log("(old) localCopyOfPageArray: ")
        console.log(localCopyOfPageArray)
        console.log("NEW pageArray: ")
        console.log(pageArray)
        mergeExternalPagesWithInternalPages()
        // setLocalCopyOfPageArrayConfig({...pageArrayConfig})
    }, [pageArray, internalPageArray])
    
    useEffect(() => {
        console.log("localCopyOfPageArray changed")
        setDummy(!dummy)
        doResize();
    }, [localCopyOfPageArray])


    useEffect(() => {
        // Update 'width' and 'height' when the window resizes
        window.addEventListener("resize", resizeCallback);
    }, []);

    useEffect(() => {
        doResize()
    }, [dummy])

    useEffect(() => {
        getContainerSize()
        getPrintableElementSize()
        doResize()
    },[])

    useEffect(() => {
        getContainerSize()
        setDummy(!dummy)
        doResize()
    }, [elH, elW])

    useEffect(() => {
        setDummy(!dummy)
        doResize()
    }, [uiW, uiH])

    useEffect(() => {
        setDummy(!dummy)
        doResize()
    }, [paperFormat, margins])

    function doResize() {
        var scale;

        scale = Math.min(
            uiW / elWidth,    
            uiH / elHeight
        );

        $specificDesign.css({
            transform: "scale(" + scale + ")"
        });
    }

    function mergeExternalPagesWithInternalPages(){
        function findHighestPosition(internalPageArray) {
            // page may desire a higher position than the total number of pages
            let maxDesiredPosition = 0;
            internalPageArray.forEach((internalPage, index) => {
                if(internalPage.config.desiredPosition > maxDesiredPosition){
                    maxDesiredPosition = internalPage.config.desiredPosition;
                }
            })
            return maxDesiredPosition
        }

        let highestDesiredPosition = findHighestPosition(internalPageArray);

        // if desired position is larger than number of pages, then make an array that goes to that number and remove empty slots later
        let internalPageCount = (internalPageArray.length < highestDesiredPosition) ? highestDesiredPosition : internalPageArray.length; 
        let externalPageCount = pageArray.length;

        console.log("internalPageCount: " + internalPageCount)
        console.log("externalPageCount: " + externalPageCount)

        let mergedPageArray = new Array(externalPageCount+internalPageCount).fill(undefined);
        console.log("mergedPageArray")
        console.log(mergedPageArray)


        console.log("mergedPageArray L:" + mergedPageArray.length + ":")
        console.log(mergedPageArray)

        function availablePosition(internalPage) {
            // Check if desired position is available. 
            // If not, then assign the nearest available slot.
            if(mergedPageArray[internalPage.config.desiredPosition] === undefined){
                return internalPage.config.desiredPosition
            } else {
                let i = internalPage.config.desiredPosition;
                while(mergedPageArray[i] !== undefined && i < 1000){
                    console.log("while loop")
                    i++
                }
                return i
            }
        };

        // First map the internal pages because they have prefered positions in the array
        internalPageArray.forEach((internalPage, internalPageIndex) => {
            // mergedPageArray[internalPage.config.desiredPosition] = internalPage;
            mergedPageArray[availablePosition(internalPage)] = internalPage;
        })

        // Find empty slots to use for external array
        let externalArrayIndex = 0;
        mergedPageArray.forEach((emptySlot, emptySlotIndex) => {
            if(emptySlot === undefined){
                mergedPageArray[emptySlotIndex] = pageArray[externalArrayIndex]
                externalArrayIndex=externalArrayIndex+1;
            }
        })

        // clean up the merged array by removing the empty slots
        mergedPageArray = mergedPageArray.filter(Boolean);

        // give new desired positions to internalPages to reflect the new combination
        let newInternalPageArr = []
        mergedPageArray.forEach((page, newIndex) => {
            if(page?.internal === true){
                let modifiedInternalPage = page;
                modifiedInternalPage.config.desiredPosition = newIndex;
                newInternalPageArr.push(modifiedInternalPage)
            }
        })

        console.log("Finished merged array:")
        console.log(mergedPageArray)

        setLocalCopyOfPageArray([...mergedPageArray])
    }

    return (
        <div  style={{height: "100%", width: "100%", overflow: "hidden"}}>
        {/* <div  style={{height: "100%", width: "100%",}}> */}
            <Stack direction={smallScreen ? "row" : "column"} spacing={0} style={{height: "100%", width: "100%", padding: 0, margin: 0, minWidth: 0}}>
                <Stack direction="column" spacing={0} class="wrapper" style={{height: smallScreen ? "100%" :"50%", width: smallScreen ? "50%" : "100%", padding: 0, margin: 0, minWidth: 0}}>
                    <PaperControlBar printCall={print} paperFormat={paperFormat} setPaperFormat={setPaperFormat} pageCurrent={pageCurrent} setPageCurrent={setPageCurrent} pageCounter={localCopyOfPageArray?.length} margins={margins} setMargins={setMargins}/>
                    <div ref={containerRef} id="wrapper" class="wrapper">
                        <div ref={previewElementRef} id="very-specific-design" class ="very-specific-design" style={{paddingTop: margins?.top+margins?.unit, paddingRight: margins?.right+margins?.unit, paddingBottom: margins?.bottom+margins?.unit, paddingLeft: margins?.left+margins?.unit, width: paperDimensions[paperFormat]?.width+margins?.right+margins?.left+paperDimensions[paperFormat]?.unit, height: paperDimensions[paperFormat]?.height+margins?.top+margins?.bottom+paperDimensions[paperFormat]?.unit}}>
                            <PaperContent 
                                page={localCopyOfPageArray[pageCurrent]} 
                                pageCurrent={pageCurrent}
                                localCopyOfPageArray={localCopyOfPageArray}
                                setLocalCopyOfPageArray={setLocalCopyOfPageArray}
                                localCopyOfPageArrayConfig={localCopyOfPageArrayConfig} 
                                setLocalCopyOfPageArrayConfig={setLocalCopyOfPageArrayConfig}
                                internalPageArray={internalPageArray}
                                paperDimensions={paperDimensions} 
                                paperFormat={paperFormat} 
                                margins={margins}
                            />
                        </div>
                    </div>
                </Stack>
                <div class="control-panel" style={{height: smallScreen ? "100%" :"50%", width: smallScreen ? "50%" : "100%", padding: 0, margin: 0, minWidth: 0}}>
                    <ControlPanel 
                        localCopyOfPageArray={localCopyOfPageArray} 
                        setLocalCopyOfPageArray={setLocalCopyOfPageArray} 
                        localCopyOfPageArrayConfig={localCopyOfPageArrayConfig} 
                        setLocalCopyOfPageArrayConfig={setLocalCopyOfPageArrayConfig} 
                        printCall={print} 
                        closePrintDialogCallback={closePrintDialogCallback} 
                        pageCurrent={pageCurrent}
                        internalPageArray={internalPageArray}
                        setInternalPageArray={setInternalPageArray}
                    />
                </div>
            </Stack>
            <div id="print-wrapper" ref={printableElementRef}>
                {
                    localCopyOfPageArray.map((page, pageIndex) => (
                        <div id={"p"+pageIndex} class="print-design" style={{width: paperDimensions[paperFormat].width+paperDimensions[paperFormat].unit, height: paperDimensions[paperFormat].height+paperDimensions[paperFormat].unit}}>
                            <PaperContent 
                                page={page} 
                                pageCurrent={pageCurrent}
                                localCopyOfPageArray={localCopyOfPageArray}
                                setLocalCopyOfPageArray={setLocalCopyOfPageArray}
                                localCopyOfPageArrayConfig={localCopyOfPageArrayConfig} 
                                setLocalCopyOfPageArrayConfig={setLocalCopyOfPageArrayConfig}
                                internalPageArray={internalPageArray}
                                paperDimensions={paperDimensions} 
                                paperFormat={paperFormat} 
                                margins={margins}
                            />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
;

export default PrintableDocument;