import React, {useState} from 'react'


const QuerybarContext = React.createContext()

function QuerybarProvider({ children }) {
    const [querybarActive, setQuerybarActive] = useState(false);

    const value = { querybarActive, setQuerybarActive }


    return (
        <QuerybarContext.Provider value={value}>
            {children}
        </QuerybarContext.Provider>
    )
}


export { QuerybarProvider, QuerybarContext }