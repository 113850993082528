import React, {useState} from 'react';

// OWN
import {UnitsMenuItems} from "../toolbars/ToolbarUnits.js"
import { ChannelsMenuItems } from '../toolbars/ToolbarChannels.js';

// MUI V5
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import { styled, alpha } from '@mui/material/styles';
import { Typography } from '@mui/material';

// ICONS
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StraightenIcon from '@mui/icons-material/Straighten';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


export default function RightClickMenuTimeseries(props) {
  const [contextMenu, setContextMenu] = useState(null);
  const [collapseUnitsMenu, setCollapseUnitsMenu] = useState(false)
  const [collapseOpen, setcollapseOpen] = useState({visibility: false, units: false})

  let {
    data, 
    units, 
    updateUnitsState,
    visibleChannels,
    setVisibleChannels,
  } = props;

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleCollapseUnitsMenu = () => {
    setCollapseUnitsMenu(prevState => !prevState)
  }

  

  return (
    <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu', width: "100%" }} >
      { props.children }
      <StyledMenu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem key="visibilityMenuItem" onClick={() => {setcollapseOpen(prevState => ({...prevState, visibility: !collapseOpen.visibility}))}}>
          <VisibilityIcon />
          <Typography sx={{flexGrow: 1}}>Visibility</Typography>
          { collapseOpen.visibility ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
        </MenuItem>
        <Collapse in={collapseOpen.visibility} key="unitsCollapse">
          <ChannelsMenuItems 
            data={data}
            visibleChannels={visibleChannels}
            setVisibleChannels={setVisibleChannels}
          />
        </Collapse>
        <MenuItem key="unitsMenuItem" onClick={() => {setcollapseOpen(prevState => ({...prevState, units: !collapseOpen.units}))}}>
          <StraightenIcon />
          <Typography sx={{flexGrow: 1}}>Units</Typography>
          { collapseOpen.units ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
        </MenuItem>
        <Collapse in={collapseOpen.units} key="unitsCollapse">
          <UnitsMenuItems 
            data={data} 
            units={units} 
            updateUnitsState={updateUnitsState}
          />
        </Collapse>
      </StyledMenu>
    </div>
  );
}
