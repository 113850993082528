import React, {useState, useEffect} from 'react';
import { Transition } from "../styles/SlideTransition.js"


// MUI imports

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'

// Icons
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function ErrorDialog(props) {

    const [errorMessage, setErrorMessage] = useState("")
    const [statusText, setStatusText] = useState("")
    const [statusCode, setStatusCode] = useState("")
    const [title, setTitle] = useState("")


    const { open, setOpen, dialogContent } = props;

    useEffect(() => {
        setTitle(dialogContent.title ? dialogContent.title : "Unknown Error");
        setStatusCode(!dialogContent.e.response ? "no response" : dialogContent.e.response.status ? dialogContent.e.response.status : "no response");    
        setStatusText(!dialogContent.e.response ? "no status text" : !dialogContent.e.response.statusText ? "no error text" : dialogContent.e.response.statusText);
        // error message can be either in e.message or e.response.data.message
        setErrorMessage(!dialogContent.e.response ? "no message" : !dialogContent.e.response.data.message ? !dialogContent.e.message ? "no message" : dialogContent.e.message : dialogContent.e.response.data.message)
      },[dialogContent])

    const handleClose = () => {
        setOpen(false);
    };


    return (
    <React.Fragment>
        <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
            <Box sx={{width: "100%", backgroundColor: "error.light", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Stack>
                    <ErrorOutlineIcon style={{ fontSize: 80, color: "white", marginTop: "20px", marginLeft: "auto", marginRight: "auto" }} />
                    <DialogTitle id="alert-dialog-title" sx={{color: "error.contrastText"}}> {title} </DialogTitle>
                </Stack>
            </Box>
            <Box sx={{width: "100%"}}>
                <DialogContent sx={{textAlign: "center"}}>
                    <Chip color="error" label={statusCode ? statusText ? statusCode + " " + statusText : statusCode : "unknown"} sx={{marginLeft: "auto", marginRight: "auto"}}/>
                </DialogContent>
                <DialogContent>
                    <Alert severity="error">
                        {errorMessage}
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Box>
        </Dialog>
    </React.Fragment>

    );
  }