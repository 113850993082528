import React, {useState, useEffect} from 'react'
import copy from 'copy-to-clipboard';

// OWN
import { strIsDefined  } from "../../../../helpers.js";
import Blockette2000ListItemButton from "./Blockette2000ListItemButton.js"

// MUI V5
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Snackbar from '@mui/material/Snackbar';

// ICONS
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export default function FileNameChip(props){

    const [anchorPopoverEl, setAnchorPopoverEl] = useState(null);
    const [copyNotice, setCopyNotice] = useState(false)

    let {
       fileName,
       loadingGraphData,
       metaData,
    } = props;

    const handlePopoverOpen = (event) => {
        setAnchorPopoverEl(event.currentTarget);
    };    
    
    const handlePopoverClose = () => {
        setAnchorPopoverEl(null);
    };  
    
    const open = Boolean(anchorPopoverEl);
    const id = open ? 'simple-popover' : undefined;
    
    return (
        <React.Fragment>
            <Chip 
                icon={<InsertDriveFileIcon sx={{fontSize: "14px", marginTop: "-2px"}}/>}
                style={{fontSize: "12px", padding: "0px", height: "16px", margin: "2px 5px 2px 0px", paddingTop: "2px"}}
                onClick={handlePopoverOpen} 
                label={
                    loadingGraphData ? 
                    <Skeleton animation="wave" height={10} width="80px"/>
                    : <>
                        <Typography variant="caption" style={{opacity: "0.8"}}>{fileName}</Typography>
                    </>
                }
            />
            <Popover
                id={id}
                elevation={1}
                open={open}
                onClose={handlePopoverClose}
                anchorEl={anchorPopoverEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >{
                metaData !== undefined ?
                <List dense={true} hover={true} component="nav">
                    <ListItemButton
                        key={1} 
                        onClick={() => {
                            copy(metaData[0].networkCode+"."+metaData[0].stationCode+"."+metaData[0].locationCode)
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={metaData[0].networkCode+"."+metaData[0].stationCode+"."+metaData[0].locationCode} secondary="Network, Station, Location" />
                    </ListItemButton>
                    <ListItemButton
                        key={2} 
                        // onClick={() => {
                        //     navigator.clipboard.writeText(JSON.stringify(metaData[0].nonContiguousDataRemovedFromFile))
                        //     setCopyNotice(true)
                        // }}
                    >
                        <ListItemText primary={metaData[0].nonContiguousDataRemovedFromFile ? "Yes" : "No"} secondary="Was Non-contiguous Data found in file?" />
                    </ListItemButton>
                    <ListItemButton
                        key={3} 
                        // onClick={() => {
                        //     console.log(metaData[0].blockette2000Payload)
                        // }}
                    >
                        <ListItemText primary={metaData[0].blockette2000WasFoundInFile ? "Yes" : "No"} secondary="Was Blockette2000 Data found in file?" />
                    </ListItemButton>
                    {
                        metaData[0].blockette2000WasFoundInFile ?
                        <Blockette2000ListItemButton blockette2000={metaData[0].blockette2000Payload}/>
                        : <React.Fragment></React.Fragment>
                    }
                </List>
                : <></>
            }

            </Popover>
            <Snackbar
                open={copyNotice}
                autoHideDuration={1000}
                onClose={() => {setCopyNotice(false)}}
                message="Copied"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                 }}
                 ContentProps={{
                    sx: {
                      display: 'block',
                      textAlign: "center"
                    }
                  }}
            />
        </React.Fragment>

    )
}