import React from 'react'

// own imports
import { AuthProvider, useAuthState } from "./context/auth-context"
import UnauthenticatedApp from "./UnauthenticatedApp"
import AuthenticatedApp from "./AuthenticatedApp"

//Material-ui imports
import { ThemeProvider, createTheme } from '@mui/material/styles';


const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: "#c3dbcd"
  //   },
  // },
});



function Home() {
  const { user } = useAuthState()
  return user ? <AuthenticatedApp /> : <UnauthenticatedApp />
}

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Home />
      </ThemeProvider>
    </AuthProvider>
  )
}

export default App