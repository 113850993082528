import React, {useEffect, useState} from "react"

// OWN
import FrontpageSettings from "./controlPanelComponents/FrontpageSettings";
import ProtoSingleFileGraphSettings from "./controlPanelComponents/ProtoSingleGraphSettings";

// MUI V5
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// ICONS
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function ControlPanel(props) {

    let {
        printCall, // call this function to activate the browser print mechanism
        closePrintDialogCallback, // call this function to leave this print view
        localCopyOfPageArray, // array of pages to read
        setLocalCopyOfPageArray, // directly change content of pageArray
        pageCurrent, // currently shown page in page viewer
        localCopyOfPageArrayConfig, // object of configuration settings general to all pages
        setLocalCopyOfPageArrayConfig, // directly change content of localCopyOfPageArrayConfig
        internalPageArray, // array of internal pages, like front page etc
        setInternalPageArray, // directly change internalPageArray
    } = props;

    return (
        <React.Fragment>
            <Box sx={{padding: "0 1rem 1rem 1rem", marginTop: 0}}>
                <h1 style={{marginTop: 0}}>Settings</h1>
                <div>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header" >
                            <MenuBookIcon style={{color: "#666", marginRight: "1rem"}}/>
                            <Typography>Front Page</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                                <FrontpageSettings 
                                    localCopyOfPageArrayConfig={localCopyOfPageArrayConfig} 
                                    setLocalCopyOfPageArrayConfig={setLocalCopyOfPageArrayConfig}
                                    internalPageArray={internalPageArray}
                                    setInternalPageArray={setInternalPageArray}
                                /> 
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel2a-header" >
                            <AutoGraphIcon style={{color: "#666", marginRight: "1rem"}}/>
                            <Typography>Graph Settings</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ProtoSingleFileGraphSettings localCopyOfPageArray={localCopyOfPageArray} setLocalCopyOfPageArray={setLocalCopyOfPageArray} pageCurrent={pageCurrent} localCopyOfPageArrayConfig={localCopyOfPageArrayConfig} setLocalCopyOfPageArrayConfig={setLocalCopyOfPageArrayConfig}/>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <Box sx={{marginTop: "2rem"}}>
                    <Button onClick={closePrintDialogCallback} variant="outlined"><ArrowBackIcon sx={{marginRight: "0.5rem"}}/> Return to Data Viewer</Button>
                </Box>
            </Box>
        </React.Fragment>
    )
}