import React, {useEffect, useState, useContext} from 'react';
import TimeAgo from 'timeago-react'; // var TimeAgo = require('timeago-react');
import * as timeago from 'timeago.js';

// OWN
import Widget from "../widget"
import OptionsMenu from '../optionMenu';
import { PageContext } from '../../../../context/pageContext';

// MUI V5
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';

// ICONS
import SvgInstrumentComponent from "../../../../graphics/instrument"

const WidgetInstrumentList = (props) => {

    const [statusType, setStatusType] = useState(0)

    let {
        instruments,
        loadingInstrumentList,
    } = props;

    const Pcontext = useContext(PageContext);

    return (
    <React.Fragment>
        <Widget 
            backgroundColor="" 
            icon={<SvgInstrumentComponent fill={"#1976d2"} secondcolor="white" style={{width: "20px", marginBottom: "2px"}}/>}
            actionButton={<OptionsMenu actionArray={[{title: "Instruments", fn: () => {Pcontext.setPage("instruments")}}]}/>}
            title="Instruments"
        >
                <Box sx={{ width: '100%', mr: 1 }}>
                    <TabPanel instruments={instruments} statusType={statusType} setStatusType={setStatusType} loadingInstrumentList={loadingInstrumentList}/>
                </Box>
        </Widget>
    </React.Fragment>
    );
};

function TabPanel(props) {

    let {
        statusType, 
        setStatusType,
        instruments,
        loadingInstrumentList,
    } = props;
  
    const handleChange = (event, newValue) => {
      console.log(newValue)
        setStatusType(newValue);
    };
  
    return (
      <Box sx={{ width: '100%' }}>    
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={statusType} onChange={handleChange} variant="scrollable">
                <Tab label={"ACTIVE"} id={`simple-tab-${0}`} sx={{width: "33.3%"}}/>
                <Tab label={"PASSIVE"} id={`simple-tab-${1}`} sx={{width: "33.3%"}}/>
                <Tab label={"ALL"} id={`simple-tab-${2}`} sx={{width: "33.3%"}}/>
          </Tabs>
        </Box>
        <Box 
          sx={{
            maxHeight: "600px",           
            overflow: "hidden",
            overflowY: "auto",
          }}
        >
          <List dense disablePadding sx={{padding: "0px !important"}}>
          {
            !loadingInstrumentList ?
              instruments.map((instrument, i) => (
                <ListItem 
                  key={"instrumentItem"+i} 
                  style={{display: statusType === 2 ? "block" : statusType === 0 && instrument?.activated ? "block" : statusType === 1 && !instrument?.activated ? "block" : "none"}} 
                  disableGutters={true} 
                  disablePadding={true} 
                  divider={true} 
                  dense={true} 
                  sx={{padding: "0px !important"}}
                >
                  <ListItemButton sx={{padding: "0px"}}>                  
                    <ListItemIcon sx={{marginRight: "-12px"}}>
                      <Badge 
                        variant="dot" 
                        invisible={instrument?.activated ? false : true}
                        sx={{
                          '& .MuiBadge-badge': {
                            top: 4,
                            right: 3,
                            color: "lightgreen",
                            backgroundColor: (instrument.hbt === undefined || instrument?.nextHbt*1000+5000 <= Date.now()) ? "gray" : JSON.parse(instrument?.hbt)?.err === 0 ? "green" : JSON.parse(instrument?.hbt)?.err === 1 ? "orange" : "red"
                          },
                        }}
                      >
                        <Icon>
                          <SvgInstrumentComponent fill={instrument.activated ? "gray" : "silver"} secondcolor="white" style={{width: "20px", marginBottom: "2px"}}/>
                        </Icon>
                      </Badge>
                    </ListItemIcon>
                    <ListItemText primary={instrument?.serialNumber} sx={{color: instrument.activated ? "gray" : "silver"}}/>
                    <ListItemText align="right" sx={{color: instrument.activated ? "gray" : "silver"}}>
                      <TimeAgo datetime={instrument?.lastContact} locale='en_short'/>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))
            : <Skeleton variant="h5" sx={{marginTop: "24px"}}></Skeleton>
          }

          </List>
        </Box>
      </Box>
    );
  }

export default WidgetInstrumentList;