import React, { useState, useContext } from 'react';
import qs from "querystring"

// Own
import { backendApi, clientConfigApplication } from "../../api/calls"
import { Transition } from "../../styles/SlideTransition"
import { AuthContext } from "../../../context/auth-context"
import SnackBar from "../../layout/SnackBar"
import { CheckMarkDialog } from "../../layout/CheckMark"
import "../../styles/checkmarkStyles.css"

// MUI V5
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// ICONS
import LockIcon from '@mui/icons-material/Lock';


export default function PasswordDialog() {
    const [open, setOpen] = useState(false);
    const [mySnackBar, setMySnackBar] = useState({ display: false, type: "", message: "" });
    const context = useContext(AuthContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {

        setOpen(false);
    };

    async function requestPasswordReset(){
        const requestBody = {
            email: context.state.user.email
        }

        backendApi.post("/api/forgot/password", qs.stringify(requestBody), clientConfigApplication)
            .then((res) => {
                setOpen(false);
                setMySnackBar({ display: true, type: "success", message: "Email sent" });
                setTimeout(function () {
                    setMySnackBar({ display: false, type: "", message: "" });
                }, 2000);
            }).catch(e => {
                setMySnackBar({ display: true, type: "error", message: e.response.data.message });
                setTimeout(function () {
                    setMySnackBar({ display: false, type: "", message: "" });
                }, 5000);
            })
    }

    return (
        <Box sx={{marginTop: "20px"}}>
            {
                mySnackBar.display === true && mySnackBar.type === "error" ?
                    <>
                        <SnackBar type={mySnackBar.type} message={mySnackBar.message} />
                    </>
                    : ""
            }
            {
                mySnackBar.display === true && mySnackBar.type === "success" ?
                    <CheckMarkDialog message="Email sent" />
                    : ""
            }
            {/* <Typography variant="h5" style={{marginBottom: "20px"}}>
                Password
            </Typography> */}
            <Box sx={{display: "flex", justifyContent: "center", width: "100%"}}>
                <Button variant="contained" color="primary" onClick={handleClickOpen} startIcon={<LockIcon />} style={{marginBottom: "20px"}}>
                    Change Password
                </Button>
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                transition={Transition}
            >
                <DialogTitle id="ChangePasswordDialog">{"Change Password?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="ChangePasswordDialogText">
                        If you wish to change your password, then click the "change password" button below. 
                        You will receive an email with a link. Follow the link in the email to change your password.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={requestPasswordReset} color="primary" autoFocus startIcon={<LockIcon />}>
                        Change password
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}