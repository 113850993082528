import React, {useEffect, useState} from 'react';
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";


// MUI V5
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// ICONS
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export function CodeArea({ code, language }) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <div className="Code">
      <pre>
        <code className={`language-${language}`}>{code}</code>
      </pre>
    </div>
  );
}

export default function Blockette2000ListItemButton(props) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [copyNotice, setCopyNotice] = useState(false)

  let {blockette2000} = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function TabPanel(props) {
    const { children, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }



  return (
    <div>
        <ListItemButton
            key={2} 
            onClick={handleClickOpen}
        >
            <ListItemText primary="Show content" secondary="Blockette2000"/>
        </ListItemButton>
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth={false}
        >
            <DialogTitle id="responsive-dialog-title">
                {"Blockette 2000 content"}
            </DialogTitle>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="scrollable force tabs example"
                    >
                        {
                            Object.entries(blockette2000).map(([key, value], i) => (
                                <Tab value={i} label={"Header "+(i+1)} key={"tab"+i}/>
                            ))
                        }
                    </Tabs>
                </Box>
                {
                    Object.entries(blockette2000).map(([key, value], i) => (
                        <TabPanel index={i}>
                            <Box sx={{width: "100%"}}>
                            <Stack direction="row" spacing={1}>
                              <Typography variant="button" sx={{backgroundColor: "primary.dark", color: "white", padding: "4px 16px 4px 16px", flexGrow: 1}}>Header Content</Typography>
                              <Button 
                                size="small" 
                                variant="contained" 
                                startIcon={<ContentCopyIcon />}
                                onClick={() => {
                                  navigator.clipboard.writeText(key)
                                  setCopyNotice(true)
                                }}
                              >
                                Copy Header
                              </Button>
                            </Stack>
                                    {
                                        <List dense sx={{width: "100%", margin: "0px"}}>
                                            {/* <ListItem sx={{backgroundColor: "primary.main", color: "white"}}>Header Content</ListItem> */}
                                            {/* <Divider /> */}
                                            {key.split("~").map((d,i) => (<ListItemButton>{d.length > 0 ? d : "[empty]"}</ListItemButton>))}
                                            <ListItemButton key={"listitem"+i}>{key}</ListItemButton>
                                        </List>

                                    }
                            </Box>
                            <Stack direction="row" spacing={1}>
                              <Typography variant="button" sx={{backgroundColor: "primary.dark", color: "white", padding: "4px 16px 4px 16px", flexGrow: 1}}>Blockette Payload</Typography>
                              <Button 
                                size="small" 
                                variant="contained" 
                                startIcon={<ContentCopyIcon />}
                                onClick={() => {
                                  navigator.clipboard.writeText(value)
                                  setCopyNotice(true)
                                }}
                              >
                                Copy Payload
                              </Button>
                            </Stack>
                            <CodeArea code={value} language={"javascript"}/>
                        </TabPanel>
                    ))
                }
            </Box>
            <DialogActions>
            <Button onClick={handleClose} autoFocus>
                Close
            </Button>
            </DialogActions>
        </Dialog>
        <Snackbar
                open={copyNotice}
                autoHideDuration={1000}
                onClose={() => {setCopyNotice(false)}}
                message="Copied"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                 }}
                 ContentProps={{
                    sx: {
                      display: 'block',
                      textAlign: "center"
                    }
                  }}
            />
    </div>
  );
}