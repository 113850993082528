import React, {useState, useEffect, useContext} from 'react';
import qs from "querystring"
import TimeAgo from 'timeago-react'; // var TimeAgo = require('timeago-react');
import * as timeago from 'timeago.js';

// OWN 
import {printExpiryDate, printTimeToExpiry} from "../helpers.js"
import { timeSince } from "./helpers"
import { instrumentListContext } from "./index"
import { AuthContext } from "../../../context/auth-context"
import LinearProgressWithoutLabel from "./subComponents/LinearProgressWithoutLabel"
import {parseInstrumentHardwareConfig} from "../../utils/parseInstrumentHardwareConfig"
import { backendApi, clientConfigApplication } from "../../api/calls"
import ErrorDialog from "../../layout/ErrorDialog"
import DialogDeleteInstrument from "./subComponents/DialogDeleteInstrument.js"
import DialogEmail from "./subComponents/DialogEmail.js"
import DialogSOH from "./subComponents/DialogSOH.js"
import RightClickMenu from './subComponents/RightClickMenu.js';

// MUI V5
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { DataGrid } from '@mui/x-data-grid';
import { SvgIcon } from '@mui/material';


// ICONS
import SvgInstrumentComponent from "../../../graphics/instrument"
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import CircleIcon from '@mui/icons-material/Circle';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SettingsIcon from '@mui/icons-material/Settings';

import en_short from 'timeago.js/lib/lang/en_short';
timeago.register('en_short', en_short);


function DataTable(props) {

    const [rows, setRows] = useState([
        { id: 1, icon: "", serialnumber: 24121988, name: "Tilia", network: "XX", location: "00"},
    ]);
    const [errorOpen, setErrorOpen] = useState(false);
    const [error, setError] = useState({title: "test", e: ""});
    const [showSohDialog, setShowSohDialog] =  useState(false)
    const [sohDialogSerial, setSohDialogSerial] = useState("0")
    const [contextMenu, setContextMenu] = useState(null);
    const [selectionModel, setSelectionModel] = useState([]);
    const [lastClickedInstrument, setLastClickedInstrument] = useState({})
    let {activeInstruments} = props;
    let instrumentsContext = useContext(instrumentListContext);
    const usercontext = useContext(AuthContext);
    const userCanManageInstruments = usercontext.state.user.permissions.addInstruments;

    const columns = [
    //   { field: 'id', headerName: 'ID', width: 90 },
      {
        field: 'icon',
        headerName: '',
        width: 30,
        renderCell: (params) => {
            return <Tooltip title={(params.row.nextHbt*1000 <= Date.now() && params.row.activated) ? <>Missed Heartbeat <TimeAgo datetime={params.row.nextHbt*1000} locale='en_short'/></> : ""} placement="left">
            <Badge 
                variant="dot" 
                invisible={activeInstruments ? false : true}
                sx={{
                  "& .MuiBadge-badge": {
                    color: "lightgreen",
                    backgroundColor: (params.row.icon === -1 || params.row.nextHbt*1000+5000 <= Date.now()) ? "gray" : params.row.icon === 0 ? "green" : params.row.icon === 1 ? "orange" : "red"
                  }
                }}
              >
                <SvgInstrumentComponent fill={(params.row.nextHbt*1000+5000 <= Date.now() && params.row.activated) ? "silver" : "gray"} secondcolor="white" sx={{marginTop: "4px"}} style={{width: "18px"}}/>
              
            </Badge>
            </Tooltip>
          }
      },
      {
        field: 'serialnumber',
        headerName: 'Serial Number',
        width: 120,

      },
      {
        field: 'name',
        headerName: 'Name',
        width: 80,
      },
      {
        field: 'network',
        headerName: 'Network',
        width: 80,
      },
      {
        field: 'location',
        headerName: 'Location',
        width: 80,
      },
      {
        field: 'type',
        headerName: 'Type',
        width: 80,
      },
      {
        field: 'sensor',
        headerName: 'Sensor',
        width: 150,
      },
      {
        field: 'time',
        headerName: 'Time',
        width: 100,
      },
      {
        field: 'memory',
        headerName: 'Memory',
        width: 80,
        renderCell: (params) => {
            return <LinearProgressWithoutLabel disabled={!params.row.activated} text={params.row.memory + "% free"} value={100-params.row.memory}/>
          }
      },
      {
        field: 'lastcontact',
        headerName: 'Heartbeat',
        width: 100,
        renderCell: (params) => {
          return (
          <Tooltip key="socketStatus" title={JSON.stringify(new Date(parseInt(params.row.lastcontact)))} placement="left" arrow={true}>
            <p><TimeAgo datetime={params.row.lastcontact} locale='en_short'/></p>
            {/* <Chip label={timeSince(params.row.lastcontact)} variant="outlined" color="default" size="small" disabled={timeSince(params.row.lastcontact) === "n.a." ? true : false}/> */}
          </Tooltip>
          )
        }
      },
      {
        field: 'activated',
        headerName: 'Active',
        width: 70,
        renderCell: (params) => {
          return (
            <Switch
              edge="end"
              id={"activated"+params.row.serialnumber}
              key={"activated"+params.row.serialnumber}
              disabled={userCanManageInstruments ? false : true}
              // onChange virker ikke fordi backend api kun understøtter permissionToUpload, og ikke activated
              onChange={() => handleToggleBooleanSetting("activated", params.row.serialnumber, params.row.activated)}
              checked={params.row.activated}
              color="primary"
              size="small"
            />
          )
        }
      },
      {
        field: 'permissiontoupload',
        headerName: 'Upload',
        width: 70,
        renderCell: (params) => {
          return (
            <Switch
              edge="end"
              id={params.row.serialnumber}
              key={params.row.serialnumber}
              disabled={userCanManageInstruments ? false : true}
              onChange={() => handleToggleBooleanSetting("permissionToUpload", params.row.serialnumber, params.row.permissiontoupload)}
              checked={params.row.permissiontoupload}
              color="primary"
              size="small"
            />
          )
        }
      },
      {
        field: 'emailonevent',
        headerName: 'Email',
        width: 80,
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Tooltip title={params.row?.emailList?.length < 1 && params.row.emailonevent === true ? "No recipients have been configured yet" : ""} >
                <Switch
                  edge="end"
                  id={"email"+params.row.serialnumber}
                  key={"email"+params.row.serialnumber}
                  disabled={userCanManageInstruments ? false : true}
                  onChange={() => handleToggleBooleanSetting("emailonevent", params.row.serialnumber, params.row.emailonevent)}
                  checked={params.row.emailonevent}
                  color={params.row?.emailList?.length < 1 ? "warning" : "primary"}
                  size="small"
              />
              </Tooltip>
              <DialogEmail handleEmailListUpdate={handleEmailListUpdate} emailList={params.row.emailList} selectedSerialNumber={params.row.delete} emailOnEvent={params.row.emailonevent}/>
              {/* <IconButton disabled={!params.row.emailonevent} size="small" sx={{marginLeft: "5px", marginTop: "4px", display: params.row.emailonevent ? "block" : "none"}} >
                <SettingsIcon fontSize="inherit"/>
              </IconButton> */}
            </React.Fragment>
          )
        }
      },
      {
        field: 'soh',
        headerName: 'Health',
        width: 100,
        renderCell: (params) => {
          return (
          <Button 
            disabled={!params.row.activated} 
            variant="outlined" 
            size="small" 
            disableElevation 
            style={{height: "24px", padding: "0 !important"}}
            onClick={() => handleSohDialog(params.row.serialnumber)}
          >
            Report
            {/* <HealthAndSafetyIcon/> */}
          </Button>
          )
        }
      },
      {
        field: 'delete',
        headerName: "Disconnect",
        width: 120,
        hide: activeInstruments,
        renderCell: (params) => {
          return (
            <DialogDeleteInstrument selectedSerialNumber={params.row.delete}/>
          )
        }
      }

    ];

    useEffect(() => {
            let instrumentArary = [];

            instrumentsContext.instruments.map((instrument, i) => {
              if(activeInstruments === instrument.activated){
                instrumentArary.push({
                  id: i,
                  icon: instrument.hbt !== undefined ? JSON.parse(instrument.hbt)?.err : -1,
                  nextHbt: instrument.nextHbt !== undefined ? instrument.nextHbt : -1,
                  name: instrument.hbt !== undefined ? JSON.parse(instrument.hbt)?.instrumentName : "n/a",
                  network: instrument.hbt !== undefined ? JSON.parse(instrument.hbt)?.instrumentSeismicNetwork : "n/a",
                  location: instrument.hbt !== undefined ? JSON.parse(instrument.hbt)?.instrumentLocation : "n/a",
                  sensor: instrument.hbt !== undefined ? parseInstrumentHardwareConfig(JSON.parse(instrument?.hbt)?.instrumentType).pretty.fullScalesWithUnits : "n/a",
                  time: instrument.hbt !== undefined ? JSON.parse(instrument.hbt)?.timesource : "n/a", 
                  memory: instrument.hbt !== undefined ? parseInt(JSON.parse(instrument.hbt)?.memory) : 0,
                  heartbeat: 0,
                  serialnumber: instrument.serialNumber,
                  // type: fullScalesWithUnits,
                  type: instrument.hbt !== undefined ? parseInstrumentHardwareConfig(JSON.parse(instrument?.hbt)?.instrumentType).parsed.type : "n/a",
                  permissiontoupload: instrument.permissionToUpload,
                  emailonevent: instrument.emailonevent,
                  emailList: instrument.emailList ? instrument.emailList : [],
                  permissionexp: instrument.permissionExp,
                  organization: instrument.organization,
                  lastcontact: instrument.lastContact,
                  adddate: instrument.addDate,
                  activated: instrument.activated,
                  delete: instrument.serialNumber,
                })
              }
            })

            setRows(instrumentArary)
    }, [instrumentsContext.instruments])

    const handleDeleteInstrument = (serialnumber) => {
      console.log("you requested to decouple the instrument " + serialnumber)
    }

    const handleSohDialog = (sn) => {
      console.log("setting soh dialog sn to ", sn)
      setSohDialogSerial(sn)
      setShowSohDialog(!showSohDialog)
    }

    const handleToggleBooleanSetting = (setting, serialnumber, originalSetting) => {

      let url = "";
      // determine endpoint depending on setting
      if(setting === "permissionToUpload"){
        url = "/api/instruments/update/";
      } else if (setting === "activated"){
        url = "/api/instruments/activate/";
      } else if (setting === "emailonevent"){
        url = "/api/instruments/emailonevent/";
      } 

      let payload = { [setting]: !originalSetting };
      
      // change the view first thing, so it feels responsive
      let instrumentList = instrumentsContext.instruments;
      const iterator = instrumentsContext.instruments.entries();
      for (const instrument of iterator) {
          if (instrument[1].serialNumber === serialnumber) {
              // update the specific one
              let instrumentIndex = instrument[0];
              instrumentList[instrumentIndex][setting] = !originalSetting;
          }
      }
      instrumentsContext.setInstruments([...instrumentList])

      return backendApi.post(`${url}${serialnumber}`, qs.stringify(payload), clientConfigApplication)
          .then((res) => {
              return res;
          })
          .catch(e => {
            // Reset the setting to original because error
            const iterator = instrumentsContext.instruments.entries();
            for (const instrument of iterator) {
                if (instrument[1].serialNumber === serialnumber) {
                    // update the specific one
                    let instrumentIndex = instrument[0];
                    instrumentList[instrumentIndex][setting] = originalSetting;
                }
            }
            instrumentsContext.setInstruments([...instrumentList])

            setError({ title: "Error changing instrument upload permission", e: e});
            setErrorOpen(true)
          })
    };
    
    const handleEmailListUpdate = (id, serialnumber) => {

      let url = "/api/instruments/emaillist/";
      let payload;
      let originalSetting;
      
      // change the view first thing, so it feels responsive
      let instrumentList = instrumentsContext.instruments;
      const iterator = instrumentsContext.instruments.entries();
      for (const instrument of iterator) {
        if (instrument[1].serialNumber === serialnumber) {
            // update the specific one
            let instrumentIndex = instrument[0];

            originalSetting = instrumentList[instrumentIndex].emailList;

            // this part is in case the instrumentContext has no emailList property for this instrument yet
            if(instrumentList[instrumentIndex].emailList === undefined){
                instrumentList[instrumentIndex].emailList = [] // create an empty array
            }

            console.log("emailList: " + JSON.stringify(instrumentList[instrumentIndex].emailList))
            // console.log(instrumentList[instrumentIndex].emailList?.includes(id) ? "already in the list" : "not in the list")

            console.log("trying to save: " + id)

            console.log(instrumentList[instrumentIndex].emailList)

            if(instrumentList[instrumentIndex].emailList?.includes(id)){
                console.log("already found")
                // search the array and remove the instances, then save
                const index = instrumentList[instrumentIndex].emailList.indexOf(id);
                instrumentList[instrumentIndex].emailList.splice(index, 1);                   
            } else {
                console.log("not found")
                instrumentList[instrumentIndex].emailList.push(id)
            }

            payload = { emailList: JSON.stringify(instrumentList[instrumentIndex].emailList) } // hide the array as a string, otherwise the array will be turned into a string by axios

            // instrumentList[instrumentIndex].emailList = instrumentList[instrumentIndex].emailList.concat(id);
        }
    }
      instrumentsContext.setInstruments([...instrumentList])


      return backendApi.post(`${url}${serialnumber}`, qs.stringify(payload), clientConfigApplication)
          .then((res) => {
              return res;
          })
          .catch(e => {
            // Reset the setting to original because error
            const iterator = instrumentsContext.instruments.entries();
            for (const instrument of iterator) {
                if (instrument[1].serialNumber === serialnumber) {
                    // update the specific one
                    let instrumentIndex = instrument[0];
                    instrumentList[instrumentIndex].emailList = originalSetting;
                }
            }
            instrumentsContext.setInstruments([...instrumentList])

            console.log(e)
            setError({ title: "Error changing instrument email settings", e: e});
            setErrorOpen(true)
          })
    };

    const handleContextMenu = (event) => {
      if(true){
        setLastClickedInstrument(rows[Number(event.currentTarget.getAttribute('data-id'))-1])
        event.preventDefault();
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
              }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
              null,
        );
      } else {
        // do nothing
        event.preventDefault();
      }
  
    };

  return (
    <React.Fragment>
      <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
      <DialogSOH instruments={instrumentsContext.instruments} serialnumber={sohDialogSerial} open={showSohDialog} setOpen={setShowSohDialog}/>
      <Box sx={{ width: '100%' }}>
      <DataGrid
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important'},
            borderRadius: "0",
            borderWidth: "0px 0px 0px 0px", 
            borderColor: "#D3D3D3",
            color: activeInstruments ? "" : "gray",
            cursor: "pointer",
            ".grayout": {
              color: "gray",
              bgcolor: "#FBFBFB"
            },
            margin: "0px -24px -24px -24px",
            padding: "0"
        }}
        rows={rows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        SelectionUnit="FullRow"
        checkboxSelection={false}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        hideFooterPagination
        hideFooterSelectedRowCount
        autoHeight={true}
        density="compact" 
        getRowClassName={(params) => {
          return (params.row.nextHbt*1000 <= Date.now() && activeInstruments)? "grayout" : "";
        }}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        componentsProps={{
          row: {
            onContextMenu: handleContextMenu,
            style: { cursor: 'context-menu' },
          },
        }}
      />
    </Box>
    <RightClickMenu 
            contextMenu={contextMenu}
            setContextMenu={setContextMenu}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            lastClickedInstrument={lastClickedInstrument}
            handleSohDialog={handleSohDialog}
      />
    </React.Fragment>
  );
}


export default function InstrumentTable(props) {
    
    const { subscriptionData, activeInstruments, socketIsConnected, socketioSession } = props

    return (
      <>
        <Stack direction="row" sx={{marginLeft: "-12px", marginTop: "6px", marginBottom: "6px"}}>
          <SvgInstrumentComponent fill="rgb(161,161,161)" secondcolor="white" sx={{marginTop: "4px"}} style={{width: "24px"}}/>
          <Typography variant="overline" sx={{paddingLeft: "12px", color: "gray"}}>{activeInstruments ? "Active Instruments" : "Passive Instruments"}</Typography>
          <Box style={{flexGrow: 1}}></Box>
          {
            activeInstruments ?
            <Box sx={{marginRight: "-1rem"}}>
              <Tooltip title={"socket id:"+socketioSession} >
                  <CircleIcon color={socketIsConnected ? "success" : "error"} sx={{fontSize: "8px"}}/>
              </Tooltip>
            </Box>
            : <></>
          }

        </Stack>
        <DataTable {...props}/>
      </>
    )
}