import React, {useState, useEffect} from 'react';

// MUI V5
import Box from '@mui/material/Box'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';

const Widget = (props) => {
    let {
        backgroundColor,
        icon,
        title,
        actionButton,
        fixedHeight,
    } = props;

    return (
    <Card 
        sx={{ 
            minWidth: 225, 
            backgroundColor: backgroundColor+".main", 
            color: backgroundColor+".contrastText",
            height: fixedHeight ? fixedHeight : "100%"
        }}
    >
        <CardContent>
            <Stack direction="row">
                <Box sx={{flexGrow: 1}}>
                    <Stack direction="row">
                        {
                            icon ?
                            <Icon sx={{marginRight: "1rem"}} color={backgroundColor === "" || backgroundColor === undefined ? "primary" : backgroundColor+".contrastText"}>
                                {icon}
                            </Icon>
                            : <></>
                        }
                        {
                            title ?
                            <Typography variant="overline" color={backgroundColor === "" || backgroundColor === undefined ? "primary" : backgroundColor+".contrastText"} sx={{marginTop: "-2px"}}>
                                {title}
                            </Typography>
                            : <></>
                        }
                    </Stack>
                </Box>
                <Box>
                    {actionButton}
                </Box>
            </Stack>
            {props.children}
        </CardContent>
    </Card>
    );
};

export default Widget;