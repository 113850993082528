import React from 'react';

// MUI V5
import Tooltip from '@mui/material/Tooltip';
import { keyframes } from '@mui/system';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';


export default function RefreshButton(props){

    let { 
        refreshDirList, 
        refreshLoading, 
    } = props;

    const spin = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `;
    
    return (
        <Tooltip title="Refresh" placement="left">
            <RefreshIcon 
                fontSize="small" 
                sx={{
                marginTop: "5px", 
                marginRight: "8px", 
                cursor: "pointer",
                animation: refreshLoading ? `${spin} 0.5s ease` : ""
                }} 
                color="action" 
                onClick={refreshDirList}
            />
        </Tooltip>
    )
}