import React from 'react';

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 986.33 827.67"
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-1{fill:${props.fill}}.cls-1,.cls-4{stroke:white}.cls-1,.cls-2,.cls-3,.cls-4{stroke-miterlimit:10}.cls-2,.cls-3,.cls-4{fill:${props.fill}}}.cls-2,.cls-3{stroke:white}.cls-3{stroke-width:3px}"
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_2-2" data-name="Layer 2">
        <path
          className="cls-1"
          d="M945.83 772.5v-739c-.6-19-9-30.24-26.14-33H78S44.83.5 44.83 37.68V772.5Z"
        />
        <g id="Layer_3" data-name="Layer 3">
          <path
            className="cls-2"
            fill={props.secondcolor}
            d="M640.86 712.06h140.23l68.74-119.49-65.56-122.74H644.5l-74.67 119.02 71.03 123.21zM217.39 712.94h140.77l69.01-119.48-65.81-122.74H221.05L146.1 589.74l71.29 123.2zM457.22 737.3h80.53l39.48-68.36-37.65-70.22h-80.27l-42.88 68.09 40.79 70.49z"
          />
        </g>
        <circle className="cls-1" cx={286.63} cy={591.83} r={102.67} />
        <circle className="cls-1" cx={709.83} cy={590.94} r={102.67} />
        <path className="cls-3" d="M44.83 412.5h901M44.67 42.17h901" fill={props.secondcolor}/>
        <circle className="cls-2" cx={100.5} cy={212.5} r={26.8} fill={props.secondcolor}/>
        <circle className="cls-2" cx={888.17} cy={209.5} r={26.8} fill={props.secondcolor}/>
        <circle className="cls-1" cx={498.5} cy={668.01} r={52.17} />
        <path className="cls-1" d="M44.24 772.5H.5v54.67h985.33V772.5H44.24z" />
      </g>
      <g id="Layer_6" data-name="Layer 6">
        <path
          className="cls-4"
          fill={props.secondcolor}
          d="M236.63 620.83v-58h23.33V551.5h7.34v-12.67h36v12.5h8.66v11.5h24.67v57l-100 1zM659.83 620.83v-58h23.34V551.5h7.33v-12.67h36v12.5h8.67v11.5h24.66v57l-100 1z"
        />
      </g>
    </g>
  </svg>
)

export default SvgComponent
