import React, {useEffect, useState, useContext} from 'react';
import TimeAgo from 'timeago-react'; // var TimeAgo = require('timeago-react');
import * as timeago from 'timeago.js';

// OWN
import Widget from "../widget"
import OptionsMenu from '../optionMenu';
import { PageContext } from '../../../../context/pageContext';
import { SeismicDataContext } from '../../../../context/seismicDataContext';
import { INTERFACE_downloadFile } from "../../fileExplorer/interface"

// MUI V5
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack'
import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// Icons
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import DescriptionIcon from '@mui/icons-material/Description';

const WidgetLatestFilesList = (props) => {

    const [fileType, setFileType] = useState(0)

    let {
        latestFiles,
        latestEvtFiles,
        latestRbfFiles,
        latestLogFiles,
        latestHbtFiles,
        loadingLatestFilesList,
    } = props;

    const Pcontext = useContext(PageContext);

    return (
    <React.Fragment>
        <Widget 
            backgroundColor="" 
            icon={<DescriptionIcon />}
            actionButton={<OptionsMenu actionArray={[{title: "File Explorer", fn: () => {Pcontext.setPage("fileexplorer")}}]}/>}
            title="Latest Files"
        >
                <Box sx={{ width: '100%', mr: 1 }}>
                    <TabPanel latestFiles={latestFiles} latestEvtFiles={latestEvtFiles} latestRbfFiles={latestRbfFiles} latestLogFiles={latestLogFiles} latestHbtFiles={latestHbtFiles} fileType={fileType} setFileType={setFileType} loadingLatestFilesList={loadingLatestFilesList}/>
                </Box>
        </Widget>
    </React.Fragment>
    );
};

function TabPanel(props) {

    const [rows, setRows] = useState([])
    const [lastClickedFile, setLastClickedFile] = useState({});
    const [contextMenu, setContextMenu] = useState(null);
    const [selectionModel, setSelectionModel] = useState([]);
    const [sortModel, setSortModel] = useState([
      {
        field: 'time',
        sort: 'desc',
      },
    ]);

    let {
        fileType, 
        setFileType,
        latestEvtFiles,
        latestRbfFiles,
        latestLogFiles,
        latestHbtFiles,
        loadingLatestFilesList,
    } = props;

    const Pcontext = useContext(PageContext);
    const SeismicContext = useContext(SeismicDataContext);

    useEffect(() => {
      let newArray = []
      let latestFiles = [latestEvtFiles, latestRbfFiles, latestLogFiles, latestHbtFiles]
      if(latestFiles[fileType]) {
        latestFiles[fileType].forEach((file, index) => {
          if(fileType === 0 && file[2] === "EVT"){
            newArray.push({ id: index, filename: file[0], path: file[1], type: file[2], time: file[3], size: file?.[4]},)
          } else if (fileType === 1 && file[2] === "RBF"){
            newArray.push({ id: index, filename: file[0], path: file[1], type: file[2], time: file[3], size: file?.[4]},)
          } else if (fileType === 2 && file[2] === "LOG"){
            newArray.push({ id: index, filename: file[0], path: file[1], type: file[2], time: file[3], size: file?.[4]},)
          } else if (fileType === 3 && file[2] === "HBT"){
            newArray.push({ id: index, filename: file[0], path: file[1], type: file[2], time: file[3], size: file?.[4]},)
          }
        }) 
      }

      setRows(newArray)

    }, [latestEvtFiles, latestRbfFiles, latestLogFiles, latestHbtFiles, fileType])
  
    const handleChangeFileType = (event, newValue) => {
        setFileType(newValue);
    };

    const columns = [
      { 
        field: 'id', 
        headerName: '', 
        width: 40,
        renderCell: (params) => (
          <DescriptionIcon sx={{color: "gray", marginLeft: "10px"}}/>
        ) 
      },
      {
        field: 'filename',
        headerName: 'Name',
        width: 250,
        editable: false,
        flex: 1,
      },
      {
        field: 'time',
        headerName: 'Time',
        width: 80,
        editable: false,
        renderCell: (params) => (
          <TimeAgo datetime={params.row.time} locale='en_short'/>
        )
      },
      {
        field: 'size',
        headerName: 'Size',
        width: 80,
        editable: false,
        renderCell: (params) => (
          <span align="right">{(Math.ceil(parseInt(params.row.size)/1000)).toFixed(0)} kb</span>
        )
      },
    ];

    const handleContextMenu = (event) => {
      // PROBLEM!!!
      // SORT MODEL DESTROYS USE OF SELECTION MODEL !!
        setLastClickedFile(rows[Number(event.currentTarget.getAttribute('data-id'))])
        event.preventDefault();
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
              }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
              null,
        );  
    };

    const handleRowClick = (params, event) => {
        setLastClickedFile(rows[Number(params.row.id)])
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
              }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
              null,
        ); 
    };

    return (
      <Box sx={{ width: '100%' }}>    
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={fileType} onChange={handleChangeFileType} variant="scrollable">
                <Tab label={"EVT"} id={`simple-tab-${0}`} sx={{width: "25%"}}/>
                <Tab label={"RBF"} id={`simple-tab-${1}`} sx={{width: "25%"}}/>
                <Tab label={"LOG"} id={`simple-tab-${2}`} sx={{width: "25%"}}/>
                <Tab label={"HBT"} id={`simple-tab-${3}`} sx={{width: "25%"}}/>
          </Tabs>
        </Box>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            density={"compact"}
            disableSelectionOnClick
            checkboxSelection={false}
            sortModel={sortModel}
            autoHeight
            headerHeight={0}
            components={{
              NoRowsOverlay: () => (
                <p>
                <Stack height="100%" alignItems="center" justifyContent="center" sx={{marginTop: "1.5rem"}}>
                  No Files
                </Stack>
                </p>
              )
            }}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            componentsProps={{
              row: {
                onContextMenu: handleContextMenu,
                style: { cursor: 'context-menu' },
              },
            }}
            onRowClick={handleRowClick}
            sx={{
              '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important'},
                borderRadius: "0",
                borderWidth: "0px 0px 0px 0px", 
                borderColor: "#D3D3D3",
                cursor: "pointer",
                ".grayout": {
                  color: "gray",
                  bgcolor: "#FBFBFB"
                },
                margin: "-1px -16px 0px -16px",
                padding: "0"
            }}
          />
          <RightClickMenu 
            contextMenu={contextMenu}
            setContextMenu={setContextMenu}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            lastClickedFile={lastClickedFile}
          />
        </Box>
      </Box>
    );
  }





function RightClickMenu(props) {

  let {
    contextMenu,
    setContextMenu,
    setSelectionModel,
    lastClickedFile,
  } = props;

  const Pcontext = useContext(PageContext);
  const SeismicContext = useContext(SeismicDataContext);

  const handleOpenFile = () => {
    if(lastClickedFile.type === "EVT" || lastClickedFile.type === "RBF" || lastClickedFile.type === "LOG"   ){
      SeismicContext.setPath(lastClickedFile.path)
      Pcontext.setPage("dataviewer")
    }
    setContextMenu(null);
  };

  const handleDownloadFile = async () => {

    if(lastClickedFile.type === "EVT" || lastClickedFile.type === "RBF" || lastClickedFile.type === "LOG"   ){
    
    return await INTERFACE_downloadFile(lastClickedFile.path).then((response) => response.data)
          .then((blob) => {
              // Create blob link to download
              const url = window.URL.createObjectURL(
                new Blob([blob]),
              );
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                lastClickedFile.filename,
              );
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              // QueryContext.setQuerybarActive(false)
          }).catch(e => {
            console.log("error downloading multi files")
            console.log(e)
            // QueryContext.setQuerybarActive(false)
          })
    }

    setContextMenu(null);
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

    return (
      <>
        <MuiMenu
          open={contextMenu !== null}
          onClose={handleCloseContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem 
            disabled={lastClickedFile.type !== "HBT" ? false : true}
            onClick={() => {
              handleOpenFile()
            }} 
          >
            <ListItemIcon>
              <AutoGraphIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              Open
            </ListItemText>
          </MenuItem>
          <MenuItem 
            disabled={lastClickedFile.type !== "HBT" ? false : true}
            onClick={() => {
              handleDownloadFile()
            }} 
          >
            <ListItemIcon>
              <CloudDownloadIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
             Download
            </ListItemText>
          </MenuItem>
        </MuiMenu>
        </>
    );
  }

export default WidgetLatestFilesList;