import React from "react"

// Own
import { AuthContext, useAuthState } from "../../context/auth-context"
import { PageContext } from "../../context/pageContext";

// MUI V5
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import { styled, alpha } from '@mui/material/styles';

//ICONS
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AdminBadge } from "./AdminBadge";
import LogoutIcon from '@mui/icons-material/Logout';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 80,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));


export default function ProfileButton() {
    // const classes = useStyles();
    const { isAuthenticated } = useAuthState()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const AuthenticationContext = React.useContext(AuthContext);
    const Pcontext = React.useContext(PageContext);

    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        AuthenticationContext.logout();
    }

    const handlePageChange = e => {
        let p = e.currentTarget.getAttribute("value");
        handleClose();
        Pcontext.setPage(p);
    }
    

    return (
        <>  
        {/* <PageProvider.Consumer > */}
                {isAuthenticated && (
                    <div>
                        
                        <div style={{display: "inline-block", verticalAlign: "middle"}}>
                            <Stack direction="row">
                                {/* <AdminBadge tiny="true" /> */}
                                <Typography display="inline" align="right" variant="body2" style={{ display: "block", marginBottom: "-5px" }}>
                                    {mdScreen ? AuthenticationContext.state.user.name : ""}
                                </Typography>
                            </Stack>
                            <Typography display="inline" align="right" variant="caption" style={{ display: "block" }}>
                                {mdScreen ? AuthenticationContext.state.user.organization : ""}
                            </Typography>
                        </div>
                        <IconButton onClick={handleMenu} color="inherit" >
                            <AccountCircleIcon />
                        </IconButton>
                        <StyledMenu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handlePageChange} value="profile">
                                <AccountCircleIcon />
                                Profile
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>
                                <LogoutIcon />
                                Log out
                            </MenuItem>
                        </StyledMenu>
                    </div>
                )}
        {/* </PageProvider.Consumer> */}
        </>
    )
}
