import React, { useState, useEffect, useContext } from "react"

// MUI V5
import Box from '@mui/material/Box';

// OWN
import { SeismicDataContext } from "../../../context/seismicDataContext";
import { SeismicDataPlot } from "./viewers/miniseedViewer/DataviewerBusinessLogic"
import CSVViewer from "./viewers/csvViewer/csvViewer"
import UnsupportedFileType from "./viewers/unsupportedFileTypesViewer/unsupportedFileType";
import MultipleFileTypes from "./viewers/multimpleFileTypesViewer/multipleFileTypes";
import JSONViewer from "./viewers/jsonViewer/jsonViewer";

export default function DataViewer(link) {

    const [path, setPath] = useState("");
    const [pathArr, setPathArr] = useState([]);
    const [fileType, setFileType] = useState(null)

    const SeisDataContext = useContext(SeismicDataContext);

    useEffect(() => {
        console.log("DEBUG SeisDataContext.path changed")
        // if a path is defined, then set state
        if(SeisDataContext.path !== undefined && SeisDataContext.path !== null && SeisDataContext.path !== ""){
            setFileType(getFileExtension(SeisDataContext.path) !== undefined ? getFileExtension(SeisDataContext.path) : null)
            setPath(SeisDataContext.path)
        }
    }, [SeisDataContext.path])

    useEffect(() => {
        console.log("DEBUG SeisDataContext.pathArr changed")

        // determine if all the selected files are of the same type
        let extensions = [];
        SeisDataContext.pathArr.forEach((dir, di) => {
            dir.files.forEach((file, fi) => {
                if(file.kind === "file"){
                    let ext = getFileExtension(file.name);
                    if(ext === "msd"){
                        ext = "mseed"
                    };
                    extensions.push(ext)
                }
            })
        })
        const allEqual = extensions.every( v => v === extensions[0] )
        console.log(extensions)
        console.log(allEqual)
        if(allEqual === true && extensions.length > 0){
            setFileType(extensions[0])
        } else {
            if(extensions.length > 0){
                setFileType("multi")
            }
        }

        // update the path array
        setPathArr(SeisDataContext.pathArr)
    }, [SeisDataContext.openMultipleFiles])
    // }, [SeisDataContext.pathArr])


    function getFileExtension(filepath){
        // https://stackoverflow.com/questions/680929/how-to-extract-extension-from-filename-string-in-javascript
        var regex = /(?:\.([^.]+))?$/;
        var extension = regex.exec(filepath)[1];
        return extension
    }

    // function countSelectedFiles(){
    //     let count = 0;
    //     SeisDataContext.pathArr.forEach((v, i) => {
    //       count = count + v.files.length
    //     })
    //     return count
    // }


    return (
    <Box sx={{ mt: "-25px", ml: "-25px", mr: "0", mb: "30px"}}>
        {
            fileType === null ? 
            <UnsupportedFileType fileType={null}/>
            : fileType === "mseed" || fileType === "msd" ? 
            <SeismicDataPlot path={path} pathArr={pathArr}/>
            : fileType === "csv" ?
            <CSVViewer path={path} pathArr={pathArr} openMultipleFiles={SeisDataContext.openMultipleFiles}/>
            : fileType === "json" ?
            <JSONViewer path={path} pathArr={pathArr}/>
            : fileType === "multi" ?
            <MultipleFileTypes />
            : <UnsupportedFileType fileType={fileType}/>
        }
        {/* <SeismicDataPlot path={path} pathArr={pathArr}/> */}
    </Box>)
}
