import React, {useState, useEffect} from 'react'
import copy from 'copy-to-clipboard';

// OWN
import { strIsDefined  } from "../../../../helpers.js";

// MUI V5
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Snackbar from '@mui/material/Snackbar';
import Divider from '@mui/material/Divider';

// ICONS
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

export default function ChannelInfoChip(props){

    const [anchorPopoverEl, setAnchorPopoverEl] = useState(null);
    const [copyNotice, setCopyNotice] = useState(false)

    let {
        metaData,
        channelIndex,
        loadingGraphData,
        colorArr,
        splitChannels,
        visibleChannels,
        zoomDetails,
    } = props;

    const handlePopoverOpen = (event) => {
        setAnchorPopoverEl(event.currentTarget);
    };    
    
    const handlePopoverClose = () => {
        setAnchorPopoverEl(null);
    };
    
    const open = Boolean(anchorPopoverEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        console.log("visibleChannels changed in channelInfoChip")
    },[JSON.stringify(visibleChannels)])

    useEffect(() => {
        console.log(metaData)
    },[metaData])

    useEffect(() => {
        console.log("zoomDetails changed")
    },[JSON.stringify(zoomDetails)])

    return (
        <React.Fragment>
            {/* <p>{JSON.stringify(visibleChannels)}</p>
            <p>{channelIndex}</p>
            <p>{JSON.stringify(Array.isArray(visibleChannels) && visibleChannels[channelIndex] === undefined ? "undef" : "def")}</p> */}
            <Chip 
            // the condition to render the icon fails when switching from 2 files to 1 file, therefore disabled for now
                icon={(Array.isArray(visibleChannels) && visibleChannels[channelIndex] !== undefined && visibleChannels[channelIndex] === false) ? <VisibilityOffIcon sx={{fontSize: "14px", marginTop: "-2px"}}/> : <React.Fragment></React.Fragment>}
                style={{fontSize: "12px", padding: "0px", height: "16px", margin: "2px 5px 2px 0px", cursor: "pointer", paddingTop: "2px"}}
                label={
                    <>
                        {
                            loadingGraphData ? 
                            <Skeleton animation="wave" height={10} width="250px"/>
                            : <React.Fragment>
                                <Typography variant="caption" style={{opacity: "0.8", color: (visibleChannels[channelIndex] === false) ? "gray" : (splitChannels === true) ? colorArr[0] : colorArr[channelIndex]}}>{strIsDefined(metaData[channelIndex].channelCode + " ")}</Typography>
                                {
                                    (visibleChannels[channelIndex] === true) ?
                                    <Typography variant="caption" style={{opacity: "0.8"}}>{strIsDefined(metaData[channelIndex].sampleRate)+"SPS"}</Typography>  
                                    : <React.Fragment></React.Fragment>
                                }
                                              
                            </React.Fragment>
                        }
                        
                    </>
                }
                onClick={handlePopoverOpen} 
            />
            <Popover
                id={id}
                elevation={1}
                open={open}
                onClose={handlePopoverClose}
                anchorEl={anchorPopoverEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List dense={true} hover={true} component="nav">
                    <ListItemButton
                        key={1} 
                        onClick={() => {
                            copy(metaData[channelIndex].networkCode+"."+metaData[channelIndex].stationCode+"."+metaData[channelIndex].locationCode)
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={metaData[channelIndex].networkCode+"."+metaData[channelIndex].stationCode+"."+metaData[channelIndex].locationCode + "." + metaData[channelIndex].channelCode} secondary="Station & Channel" />
                    </ListItemButton>
                    <ListItemButton
                        key={2}  
                        onClick={() => {
                            copy(String(((new Date(metaData[channelIndex].endTime)-(new Date(metaData[channelIndex].startTime)))/1000)+(1/metaData[channelIndex].sampleRate)) + " seconds")
                            // navigator.clipboard.writeText(String((new Date(metaData[channelIndex].endTime)-(new Date(metaData[channelIndex].startTime)))/1000) + " seconds")
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={String(((new Date(metaData[channelIndex].endTime)-(new Date(metaData[channelIndex].startTime)))/1000)+(1/metaData[channelIndex].sampleRate)) + " seconds"} secondary="Duration" />
                        {/* <ListItemText primary={String((new Date(metaData[channelIndex].endTime)-(new Date(metaData[channelIndex].startTime)))/1000) + " seconds"} secondary="Duration" /> */}
                    </ListItemButton>
                    <ListItemButton 
                        key={3} 
                        onClick={() => {
                            copy(String(new Date(metaData[channelIndex].startTime)))
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={String(new Date(metaData[channelIndex].startTime))} secondary="Start Time" />
                    </ListItemButton>
                    <ListItemButton 
                        key={4} 
                        onClick={() => {
                            copy(String(new Date(metaData[channelIndex].endTime)))
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={String(new Date(metaData[channelIndex].endTime))} secondary="End Time" />
                    </ListItemButton>
                    <ListItemButton
                        key={5}  
                        onClick={() => {
                            copy(metaData[channelIndex].sampleRate+"SPS")
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={metaData[channelIndex].sampleRate+"SPS"} secondary="Sample Rate" />
                    </ListItemButton>
                    <ListItemButton 
                        key={6} 
                        onClick={() => {
                            copy(metaData[channelIndex].yUnit)
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={metaData[channelIndex].yUnit} secondary="Unit" />
                    </ListItemButton>
                    <Divider>Statistics of entire data set</Divider>
                    <ListItemButton 
                        key={7} 
                        onClick={() => {
                            copy(metaData[channelIndex].min)
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={metaData[channelIndex].min} secondary="Minimum" />
                    </ListItemButton>
                    <ListItemButton 
                        key={8} 
                        onClick={() => {
                            copy(metaData[channelIndex].max)
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={metaData[channelIndex].max} secondary="Maximum" />
                    </ListItemButton>
                    <ListItemButton 
                        key={9} 
                        onClick={() => {
                            copy(String(metaData[channelIndex].max-metaData[channelIndex].min))
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={String(metaData[channelIndex].max-metaData[channelIndex].min)} secondary="Peak-Peak" />
                    </ListItemButton>
                    <ListItemButton 
                        key={10} 
                        onClick={() => {
                            copy(metaData[channelIndex].mean)
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={metaData[channelIndex].mean} secondary="Mean" />
                    </ListItemButton>
                    <ListItemButton 
                        key={11} 
                        onClick={() => {
                            copy(metaData[channelIndex].rms)
                            setCopyNotice(true)
                        }}
                    >
                        <ListItemText primary={metaData[channelIndex].rms} secondary="RMS" />
                    </ListItemButton>
                    <Divider>Statistics of zoomed area</Divider>
                    <ListItemButton 
                        key={12} 
                    >
                        <ListItemText primary={JSON.stringify(zoomDetails?.[!splitChannels ? 0 : channelIndex]?.[1]?.[!splitChannels ? channelIndex : 0])} secondary="Time Window" />
                    </ListItemButton>
                    <ListItemButton 
                        key={13} 
                    >
                        <ListItemText primary={JSON.stringify(zoomDetails?.[!splitChannels ? 0 : channelIndex]?.[2]?.[!splitChannels ? channelIndex : 0]?.zoomedMin)} secondary="Min of zoomed area" />
                    </ListItemButton>
                    <ListItemButton 
                        key={14} 
                    >
                        {/* zoomDetails?.[channelIndex]?.[2]?.[0]?.zoomedMax */}
                        <ListItemText primary={JSON.stringify(zoomDetails?.[!splitChannels ? 0 : channelIndex]?.[2]?.[!splitChannels ? channelIndex : 0]?.zoomedMax)} secondary="Max of zoomed area" />
                    </ListItemButton>
                    <ListItemButton 
                        key={15} 
                    >
                        <ListItemText primary={JSON.stringify((zoomDetails?.[!splitChannels ? 0 : channelIndex]?.[2]?.[!splitChannels ? channelIndex : 0]?.zoomedMax-zoomDetails?.[!splitChannels ? 0 : channelIndex]?.[2]?.[!splitChannels ? channelIndex : 0]?.zoomedMin))} secondary="Peak-Peak of zoomed area" />
                    </ListItemButton>
                    <ListItemButton 
                        key={16} 
                    >
                        <ListItemText primary={JSON.stringify(zoomDetails?.[!splitChannels ? 0 : channelIndex]?.[2]?.[!splitChannels ? channelIndex : 0]?.zoomedRMS)} secondary="RMS of zoomed area" />
                    </ListItemButton>

                </List>
            </Popover>
            <Snackbar
                open={copyNotice}
                autoHideDuration={1000}
                onClose={() => {setCopyNotice(false)}}
                message="Copied"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                 }}
                 ContentProps={{
                    sx: {
                      display: 'block',
                      textAlign: "center"
                    }
                  }}
            />
        </React.Fragment>

    )
}