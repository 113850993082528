import React, { useContext } from 'react';

// OWN 
import { backendApi, clientConfigApplication } from "../../api/calls"
import { userListContext } from "./index"
import ErrorDialog from "../../layout/ErrorDialog.js"

// MUI V5
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

// ICONS
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function DeleteButton(props) {
    const [open, setOpen] = React.useState(false);
    const [confirmEmail, setConfirmEmail] = React.useState("");
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [error, setError] = React.useState({title: "test", e: ""});
    const [mySnackBar, setMySnackBar] = React.useState({ display: false, type: "", message: "" });
    const userContext = useContext(userListContext);
    

    function handleConfirmDelete() {
        setOpen(true);
    }

    function handleDialogClose() {
        setConfirmEmail("");
        setOpen(false);
    }

    let removeUserFromVisualList = (id) => {
        let userList = userContext.state;
        const iterator = userContext.state.entries();
        for (const user of iterator) {
            if(user[1]._id === id){
                userList.splice(user[0], 1)
            }
        }
        userContext.update([...userList])
    }

    let handleDelete = (id, e) => {
        e.preventDefault();
        backendApi.delete(`/api/users/delete/${id}`, clientConfigApplication).then(d => {
            setOpen(false);
            setConfirmEmail("");
            removeUserFromVisualList(id);
            return
        }).catch(e => {
            setConfirmEmail("");
            setError({ title: "Error deleting user", e: e});
            setErrorOpen(true)
            setOpen(false);
        })
    }

    return (
        <>
            <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDialogClose}
            >
                <Box sx={{width: "100%", backgroundColor: "primary.light", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Stack>
                        <PersonRemoveIcon style={{fontSize: 80}} sx={{ color: "primary.contrastText", marginTop: "20px", marginLeft: "auto", marginRight: "auto" }} />
                        <DialogTitle id="alert-dialog-title" sx={{color: "primary.contrastText"}}> Remove {props.user.email} </DialogTitle>
                    </Stack>
                </Box>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Confirm by entering the email address of the user you are deleting.
                    </DialogContentText>
                    <TextField margin="dense" id={props.user.email} label="Email Address" type="email" fullWidth variant="outlined" onChange={(e) => setConfirmEmail(e.target.value)}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={(e) => handleDelete(props.id, e)} color="primary" variant="contained" startIcon={<DeleteIcon />} disabled={confirmEmail === props.user.email ? false : true}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
                {/* <IconButton edge="end" aria-label="delete" id={props.id} key={props.id} onClick={handleConfirmDelete} disabled={props.user.admin ? true : false}>
                    <DeleteOutlineIcon />
                </IconButton> */}
                <Button variant="contained" size="small" id={props.id} key={props.id} onClick={handleConfirmDelete} disabled={props.user.admin ? true : false} startIcon={<DeleteIcon />}>
                    Remove
                </Button>
        </>
    )
}
