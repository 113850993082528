import React, {useEffect, useState} from 'react';
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// MUI V5
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import "prismjs/components/prism-log"
import "prismjs/components/prism-powershell"
import "prismjs/components/prism-prolog"
import "prismjs/components/prism-protobuf"
import "prismjs/components/prism-sass"
import "prismjs/components/prism-sas"
import "prismjs/components/prism-scss"

// var loadLanguages = require('prismjs/components/index.js');
// loadLanguages(['log', 'powershell']);


export function CodeArea({ code, language, socketIsConnected}) {

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));


    useEffect(() => {
        Prism.highlightAll();
    }, [language, code, socketIsConnected]);

  
    return (
      <div className="Code">
        <pre style={{margin: 0, padding: 0, fontSize: !largeScreen ? "10px" : ""}}>
          {/* <code className={'language-log'} code={code}>{code}</code> */}
          <code className={`language-${language}`}>{code}</code>
          {/* <code className={`language-${language}`}>{code}</code> */}
        </pre>
      </div>
    );
}

const TerminalViewer = (props) => {
    let {
        messages,
        socketIsConnected,
    } = props;

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box 
            sx={{
                backgroundColor: "#2d2d2d", 
                color: "white", 
                margin: "0px -24px 0px -24px", 
                height: "200px", 
                // overflowY: "scroll"
            }} 
            style={{
                overflow: "auto",
                display: "flex",
                flexDirection: "column-reverse",
            }}
        >
            <Stack direction="column">     
                {
                    messages.map((message, i) => (
                        <CodeArea code={message} language={"sass"} socketIsConnected={socketIsConnected} key={"line"+i}/> 
                    ))
                }
            </Stack>
        </Box>
    );
};

export default TerminalViewer;