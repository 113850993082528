import React, {useState} from "react"
import qs from "querystring"

// OWN 
import { backendApi, clientConfigApplication} from "../../api/calls"
import { AuthContext } from "../../../context/auth-context"
import { useAuthState } from "../../../context/auth-context"
import {AdminBadge} from "../../layout/AdminBadge"
import { CheckMarkDialog } from "../../layout/CheckMark"
import "../../styles/checkmarkStyles.css"
import ErrorDialog from "../../layout/ErrorDialog.js"
import logo from "../../../graphics/logoAlone"

// MUI V5
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// ICONS
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';

export default function PersonalDetails() {
    const context = React.useContext(AuthContext);
    
    const [edit, setEdit]   = useState(false);
    const [email] = useState(context.state.user.email);
    const [name, setName]   = useState(context.state.user.name);
    const [phone, setPhone]   = useState(context.state.user.phone);
    const [newName, setNewName] = useState(context.state.user.name);
    const [emailChangedMessage, setEmailChangedMessage] = useState("");
    const [newEmail, setNewEmail] = useState(context.state.user.email);
    const [newPhone, setNewPhone] = useState(context.state.user.phone);
    const [mySnackBar, setMySnackBar] = useState({display: false, type: "", message: ""});
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [error, setError] = React.useState({title: "test", e: ""});

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));


    const changeName = (e) => {
        // setName(e.target.value);
        setNewName(e.target.value);
    }

    const changeEmail = (e) => {
        setNewEmail(e.target.value);
    }

    const changePhone = (e) => {
        setNewPhone(e.target.value);
    }

    const toggleEdit = () => {
        setEdit(!edit)
    }

    async function submitEmailChangeRequest() {

        return backendApi.post("/api/users/issueemailchangecode", qs.stringify({ email: newEmail }), clientConfigApplication).then(d => {
            // do something
            setEmailChangedMessage(<Chip size="small" label={"Confirmation sent to " + newEmail} style={{marginBottom: "2px"}}/>)
            return "ok"
        }).catch(e => {
            // reset the new email to equal the old one
            setNewEmail(email);
            throw new Error(e.response.data.message);
        })
    }

    const saveNewUserData = () => {

        const updatedUserData = { id: context.state.user.id, email: email, name: newName, phone: newPhone}

        backendApi.post("/api/users/update", qs.stringify(updatedUserData), clientConfigApplication)
        .then((res) => {
            
            setName(newName);
            setPhone(newPhone);
            toggleEdit();

            if (newEmail !== email){
                submitEmailChangeRequest().then(d => {
                    setMySnackBar({ display: true, type: "success", message: "Saved" });
                    setTimeout(function () {
                        setMySnackBar({ display: false, type: "", message: "" });
                    }, 2000);
                }).catch(e => {
                    setMySnackBar({ display: true, type: "error", message: e.message });
                    setTimeout(function () {
                        setMySnackBar({ display: false, type: "", message: "" });
                    }, 10000);
                })
            } else {
                setMySnackBar({ display: true, type: "success", message: "Saved" });
                setTimeout(function () {
                    setMySnackBar({ display: false, type: "", message: "" });
                }, 2000);

                sessionStorage.setItem("seisodinCloudJwtToken", res.data.token)
                // localStorage.setItem("seisodinCloudJwtToken", res.data.token)
                // run the login method to update the context state 
                // with the new data from the returned token
                // this methods doesnt actually log the user in, but sets the context state based on Token
                context.login();

                return res;
            }
        }).catch(e => {
            toggleEdit();
            setError({ title: "Error updating user details", e: e});
            setErrorOpen(true)
        })
    }


    return (
        <div>
            <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
            {
                mySnackBar.display === true && mySnackBar.type === "success" ?
                    <CheckMarkDialog message="saved" />
                    : ""
            }

                <MuiAppBar
                    style={{
                        position: "-webkit-sticky",
                        position: "sticky", // eslint-disable-line
                        top: largeScreen ? theme.spacing(8) : theme.spacing(7),
                        marginTop: "-78px"
                    }} 
                    elevation={0} 
                    sx={{background: "transparent"}}
                >
                    <Toolbar variant="dense">
                        <Box style={{flexGrow: 1}}>
                        </Box>
                        <Stack direction="row" spacing={1}>
                            <Tooltip title="Edit user information">
                            {edit 
                            ? <Button variant="contained" startIcon={<SaveIcon />} color="primary" onClick={saveNewUserData} > Save </Button>
                            : <Button variant="contained" startIcon={<EditIcon />} color="primary" onClick={toggleEdit} > Edit </Button>
                            }                            
                            </Tooltip>
                        </Stack>
                    </Toolbar>
                </MuiAppBar>
            
            <div>
            <Box>
                <Box sx={{
                    backgroundColor: "#dbdbdb",
                    height: "100px",
                    marginTop: "-30px",
                    marginLeft: "-24px",
                    marginRight: "-24px",
                }}>
                    
                </Box>
                <center>
                <Avatar 
                    alt="Jonathan Naundrup" 
                    src="./logo512.png" 
                    // src="https://www.seisodin.com/wp-content/uploads/2020/12/cropped-Icon-Black-RGB-1.png" 
                    sx={{
                        width: "150px",
                        height: "150px",
                        boxShadow: 2,
                        padding: "10px",
                        backgroundColor: "white",
                        marginTop: "-75px"
                    }}
                />
                </center>
                <Box sx={{
                    // backgroundColor: "red",
                    marginTop: "-75px",
                    paddingTop: "90px"
                }}>
                    <center>
                            {
                                edit
                                ? <div>
                                    <TextField 
                                    id="name2" 
                                    style={{width: "100%", maxWidth: "300px"}} 
                                    sx={{input: {textAlign: "center"}}}
                                    onChange={changeName} 
                                    // label={name} 
                                    defaultValue={name} 
                                    variant="outlined" 
                                    key="name2"/>
                                </div>
                                : <Typography variant="h6">{name}</Typography>
                            }
                            <Typography variant="caption">{context.state.user.organization}</Typography>
                            <div>
                                <AdminBadge tiny="true"/>
                            </div>
                    </center>
                </Box>
            </Box>
            </div>
            <Box sx={{ width: '100%', typography: 'body1', marginBottom: "20px", marginTop: "24px"}}>
                <Stack direction={largeScreen ? "row" : "column"} spacing={2} justifyContent="center">
                    <Card variant="outlined" sx={{ height: "100%", minWidth: "250px", maxWidth: largeScreen ? "300px" : "100%"}}>
                    <CardContent>
                        <List sx={{padding: "0px"}}>
                            <ListItem sx={{padding: "0px"}}>
                                <ListItemAvatar>
                                    <Tooltip title="E-mail">
                                        <Avatar sx={{ backgroundColor: (edit) ? "primary.main" : "", }}>
                                            <EmailIcon />
                                        </Avatar>
                                    </Tooltip>
                                </ListItemAvatar>
                                {edit
                                    ? <TextField id="email" style={{width: "100%", maxWidth: "300px"}} onChange={changeEmail} label={email} variant="outlined" key="email"/>
                                    : <ListItemText primary="Email Address" secondary={<span><span style={{marginRight: "10px"}}>{email}</span><Typography component="span" variant="caption">{emailChangedMessage}</Typography></span>} />
                                }
                            </ListItem>
                        </List>
                    </CardContent>
                    </Card>
                    <Card variant="outlined" sx={{ height: "100%", minWidth: "250px", maxWidth: largeScreen ? "300px" : "100%"}}>
                    <CardContent>
                        <List sx={{padding: "0px"}}>
                            <ListItem sx={{padding: "0px"}}>
                                <ListItemAvatar>
                                    <Tooltip title="Cellphone">
                                        <Avatar sx={{ backgroundColor: (edit) ? "primary.main" : "" }}>
                                            <PhoneIcon />
                                        </Avatar>
                                    </Tooltip>
                                </ListItemAvatar>
                                {edit
                                    ? <TextField id="cellphone" style={{width: "100%", maxWidth: "300px"}} onChange={changePhone} label={phone} variant="outlined" key="phone"/>
                                    : <ListItemText primary="Cellphone" secondary={phone} />
                                }
                            </ListItem>
                        </List>
                    </CardContent>
                    </Card>
                </Stack>
              </Box>
        </div>
    )
}
