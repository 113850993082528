import * as React from 'react';

// OWN
import MarginsMenu from './MarginsMenu.js';
import "../styles/papercontrolbarstyles.css"

// MUI V5
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';

// ICONS
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import PrintIcon from '@mui/icons-material/Print';

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    background-color: ${({theme, value}) => 
      !value && "#888"};
  }
`

export default function PaperControlBar(props) {

    let {
        printCall,
        paperFormat,
        setPaperFormat,
        pageCounter,
        pageCurrent,
        setPageCurrent,
        margins, 
        setMargins,
    } = props;


    function handleChangeOfPaperFormat(event){
        setPaperFormat(event.target.value)
    }

    function handlePreviousPage(){
        setPageCurrent(pageCurrent-1)
    }

    function handleNextPage(){
        setPageCurrent(pageCurrent+1)
    }

  return (
    <div class="control-bar">
        <div style={{flexGrow: 1}}></div>
        <Stack spacing={1} direction="row" sx={{marginRight: "1rem"}}>
            <Box>
                <Typography style={{color: "#888"}}>{pageCurrent+1}/{pageCounter}</Typography>
            </Box>
            <Tooltip title="Previous Page">
                <span>
                    <Button variant="contained" style={{backgroundColor: "#666"}} onClick={handlePreviousPage} disabled={pageCurrent === 0 ? true : false}><ArrowCircleLeftIcon style={{color: pageCurrent === 0 ? "#888" : "white"}}/></Button>
                </span>
            </Tooltip>
            <Tooltip title="Next Page">
                <span>
                    <Button variant="contained" style={{backgroundColor: "#666"}} onClick={handleNextPage} disabled={pageCurrent+1 === pageCounter ? true : false}><ArrowCircleRightIcon style={{color: pageCurrent+1 === pageCounter ? "#888" : "white"}}/></Button>
                </span>
            </Tooltip>
            <Select
                value={paperFormat}
                size="small"
                displayEmpty
                onChange={handleChangeOfPaperFormat}
                style={{backgroundColor: "#888", color: "white", height: "36px"}}
            >
                <MenuItem value={"A4"}>A4</MenuItem>
                <MenuItem value={"A3"}>A3</MenuItem>
                <MenuItem value={"LETTER"}>Letter</MenuItem>
                <MenuItem value={"LEGAL"}>Legal</MenuItem>
            </Select>
            <MarginsMenu margins={margins} setMargins={setMargins}/>
            <Tooltip title="Print">
                <span>
                    <Button variant="contained" style={{backgroundColor: "#666"}} onClick={printCall}><PrintIcon style={{color: "white"}}/></Button>
                </span>
            </Tooltip>
        </Stack>
    </div>
  )
}