import React, {useState} from "react"

// OWN 
import PasswordDialog from "./PasswordDialog"
import Permissions from "./Permissions"
import PersonalDetails from "./PersonalDetails"


export function Profile() {

    return (
    <div>
        <PersonalDetails />
        <Permissions />
        <PasswordDialog />       
        {/* <ShowToken /> */}
    </div>
    
    )
}