import React, {useState, useEffect} from "react"
import { io } from "socket.io-client";

// OWN
import { backendApi, clientConfigApplication} from "../../api/calls"
import DashboardGrid from "./dashboardGrid";
import ErrorDialog from "../../layout/ErrorDialog"
import WidgetUserQuota from "./widgets/userQuota"
import WidgetInstrumentQuota from "./widgets/instrumentQuota"
import WidgetStorageQuota from "./widgets/storageQuota"
import WidgetConnectionStatus from "./widgets/connectionStatus"
import WidgetEventCalendar from "./widgets/eventCalendar";

// MUI V5
import Box from '@mui/material/Box'
import Grid from "@mui/material/Grid"

export async function fetchData() {
    return await backendApi.get("/api/organization/settings", clientConfigApplication)
    .then(d => {
        return d;
    }).catch(e => {
        throw new Error(e.message)
    })
}

async function getInstruments() {
    return await backendApi.get("/api/organization/instruments", clientConfigApplication)
        .then(d => {
            return d;
        })
        .catch(e => {
            throw new Error(e.message)
        })
}

async function getInstrumentsTerminalHistory() {
    return await backendApi.get("/api/organization/instrumentsTerminalHistory", clientConfigApplication)
        .then(d => {
            return d;
        })
        .catch(e => {
            throw new Error(e.message)
        })
}

async function getFilesHistory() {
    return await backendApi.get("/api/organization/filesHistory", clientConfigApplication)
        .then(d => {
            return d;
        })
        .catch(e => {
            throw new Error(e.message)
        })
}

async function getLastEventHistory() {
    return await backendApi.get("/api/organization/lastEventHistory", clientConfigApplication)
        .then(d => {
            console.log("last event history")
            console.log(d)
            return d;
        })
        .catch(e => {
            throw new Error(e.message)
        })
}


export function Dashboard() {
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [error, setError] = React.useState({title: "test", e: ""});
    const [subscriptionData, setSubscriptionData] = useState()
    const [loadingSubscriptionData, setLoadingSubscriptionData] = useState(false)
    const [loadingInstrumentList, setLoadingInstrumentList] = useState(false)
    const [loadingLatestFilesList, setLoadingLatestFilesList] = useState(false)
    const [loadingLastEventFile, setLoadingLastEventFile] = useState(false)
    // Socket-io related
    const [socketIsConnected, setSocketIsConnected] = useState(false);
    const [socketioSession, setSocketioSession] = useState("");
    const [instrumentTerminal, setInstrumentTerminal] = useState([]);
    const [latestFiles, setLatestFiles] = useState([]);
    const [latestEvtFiles, setLatestEvtFiles] = useState([]);
    const [latestRbfFiles, setLatestRbfFiles] = useState([]);
    const [latestLogFiles, setLatestLogFiles] = useState([]);
    const [latestHbtFiles, setLatestHbtFiles] = useState([]);
    const [lastEvent, setLastEvent] = useState({})
    const [socket, setSocket] = useState(null)
    const [instruments, setInstruments] = useState([""]);
    const [authToken, setAuthToken] = useState(null)
    const [socketLoading, setSocketLoading] = useState(false)

    useEffect(() => {
        setLoadingSubscriptionData(true)
        setLoadingInstrumentList(true)
        setLoadingLastEventFile(true)
        newSocket()

        fetchData().then(d => {
            setSubscriptionData(d.data)
            setLoadingSubscriptionData(false)
        }).catch(e => {
            setError({ title: "Error loading subscription data for dashboard", e: e});
            setErrorOpen(true)
            setLoadingSubscriptionData(false)
        })

        getInstruments().then(d => {
            setInstruments(d?.data?.instruments)
            setLoadingInstrumentList(false)
        }).catch(e => {
            setError({ title: "Error loading instrument data for dashboard", e: e});
            setErrorOpen(true)
            setLoadingInstrumentList(false)
        })

        getInstrumentsTerminalHistory().then(d => {
            setInstrumentTerminal(d?.data?.history.reverse())
        }).catch(e => {
            console.log("error getting instrument terminal history")
            console.log(e)
        })
        
        getFilesHistory().then(d => {
            let newArr = [];
            d.data.history.forEach((fileTypeArray, fileTypeIndex) => {
                newArr.push([])
                fileTypeArray.forEach(file => {
                    newArr[fileTypeIndex].push(JSON.parse(file))
                })
            })
            setLatestEvtFiles(newArr[0])
            setLatestRbfFiles(newArr[1])
            setLatestLogFiles(newArr[2])
            setLatestHbtFiles(newArr[3])
        }).catch(e => {
            console.log("error getting file history")
            console.log(e)
        })

        getLastEventHistory().then(d => {
            setLastEvent(d?.data?.history)
            setLoadingLastEventFile(false)
        }).catch(e => {
            setLoadingLastEventFile(false)
            console.log("error getting Last Event History")
            console.log(e)
        })
    },[])

    useEffect(() => {
        const onConnect = () => {
            console.log("SOCKET connected")
            setSocketIsConnected(true);

            // Write to instrumentTerminal
            setInstrumentTerminal((currentMessages) => {
                let newArr = [...currentMessages];
                let event = new Date(Date.now())
                if(newArr.length >= 100){
                    newArr.shift()
                    newArr.push(event.toISOString() + " Connection Established")
                } else {
                    newArr.push(event.toISOString() + " Connection Established")
                }
                return ([...newArr])
                // return ([...currentMessages, value])
            });
        }
        
        const onDisconnect = () => {
            console.log("SOCKET disconnected")
            setSocketIsConnected(false);

            // Write to instrumentTerminal
            setInstrumentTerminal((currentMessages) => {
                let newArr = [...currentMessages];
                let event = new Date(Date.now())
                if(newArr.length >= 100){
                    newArr.shift()
                    newArr.push(event.toISOString() + " Connection Closed")
                } else {
                    newArr.push(event.toISOString() + " Connection Closed")
                }
                return ([...newArr])
                // return ([...currentMessages, value])
            });

            // Set retry
            setTimeout(() => {
                socket.connect("",{
                    reconnection: false,
                    autoConnect: false,
                    auth: { token: sessionStorage.getItem("seisodinCloudJwtToken") }
                });
              }, 5000);// attempt reconnecting in 5 seconds
        }
    
        const onWelcomeEvent = (value) => {
            // console.log("SOCKET welcome")
            setSocketioSession(value);
        }
        
        const onInstrumentChanges = (value) => {
            // console.log("SOCKET instrument change")
            setInstruments([...value])
        }

        const onInstrumentTerminal = (value) => {
            console.log("SOCKET onInstrumentTerminal")
            setInstrumentTerminal((currentMessages) => {
                let newArr = [...currentMessages];
                if(newArr.length >= 100){
                    newArr.shift()
                    newArr.push(value)
                } else {
                    newArr.push(value)
                }
                return ([...newArr])
                // return ([...currentMessages, value])
            });

        }

        const onLatestFilesList = (value) => {
            // console.log("SOCKET instrument terminal change")
            setLatestFiles((currentMessages) => {
                let newArr = [...currentMessages];
                if(newArr.length >= 50){
                    newArr.shift()
                    newArr.push(value)
                } else {
                    newArr.push(value)
                }
                return ([...newArr])
                // return ([...currentMessages, value])
            });
        }

        const onLatestEvtFilesList = (value) => {
            console.log("SOCKET onLatestEvtFilesList")
            setLatestEvtFiles((currentMessages) => {
                let newArr = [...currentMessages];
                if(newArr.length >= 50){
                    newArr.shift()
                    newArr.push(value)
                } else {
                    newArr.push(value)
                }
                return ([...newArr])
                // return ([...currentMessages, value])
            });
        }

        const onLatestRbfFilesList = (value) => {
            // console.log("SOCKET instrument terminal change")
            setLatestRbfFiles((currentMessages) => {
                let newArr = [...currentMessages];
                if(newArr.length >= 50){
                    newArr.shift()
                    newArr.push(value)
                } else {
                    newArr.push(value)
                }
                return ([...newArr])
                // return ([...currentMessages, value])
            });
        }

        const onLatestLogFilesList = (value) => {
            console.log("SOCKET onLatestLogFilesList")
            setLatestLogFiles((currentMessages) => {
                let newArr = [...currentMessages];
                if(newArr.length >= 50){
                    newArr.shift()
                    newArr.push(value)
                } else {
                    newArr.push(value)
                }
                return ([...newArr])
                // return ([...currentMessages, value])
            });
        }

        const onLatestHbtFilesList = (value) => {
            console.log("SOCKET onLatestHbtFilesList")
            setLatestHbtFiles((currentMessages) => {
                let newArr = [...currentMessages];
                if(newArr.length >= 50){
                    newArr.shift()
                    newArr.push(value)
                } else {
                    newArr.push(value)
                }
                return ([...newArr])
                // return ([...currentMessages, value])
            });
        }

        const onLatestEvtFile = (value) => {
            setLastEvent(value)
        }

        const onConnectError = (err) => {
            setSocketIsConnected(false);

                console.log(`socket connect_error due to ${err.message}`);
                if(err.message === "xhr poll error"){
                    console.log("polling error indicates the server may not be accessible")
                    console.log("Attempting to start connection in 5sec")
                    setTimeout(() => {
                        socket.connect("",{
                            reconnection: false,
                            autoConnect: false,
                            auth: { token: sessionStorage.getItem("seisodinCloudJwtToken") }
                        });
                      }, 5000);// attempt reconnecting in 5 seconds
                } else if (err.message === "timeout"){
                    console.log("timeout error indicates the server may not be accessible")
                    console.log("Attempting to start connection in 5sec")
                    setTimeout(() => {
                        socket.connect("",{
                            reconnection: false,
                            autoConnect: false,
                            auth: { token: sessionStorage.getItem("seisodinCloudJwtToken") }
                        });
                      }, 5000);// attempt reconnecting in 5 seconds
                } else if(err.message === "jwt expired"){
                    console.log("connection refused because jwt expired")    
                    // requestPing()
                    cleanupSocket()
                    newSocket()
                    setTimeout(() => {
                        socket.connect("",{
                            reconnection: false,
                            autoConnect: false,
                            auth: { token: sessionStorage.getItem("seisodinCloudJwtToken") }
                            // auth: { token: sessionStorage.getItem("seisodinCloudJwtToken") }
                        });
                      }, 5000);// attempt reconnecting in 5 seconds
                } else {
                    console.log("error type will not be handled")
                }
        }

        const onReconnectionAttempt = () => {
            setSocketIsConnected(false)
            console.log("socket attempting reconnecting")
        }

        const onReconnectSuccess = () => {
            setSocketIsConnected(true)
            console.log("socket reconnected with id",socket.id)
        }


        if(socket !== null){
            socket.connect("",{
                // reconnectionDelayMax: 10000,
                // forceNew: true,
                autoConnect: false,
                auth: { token: authToken }
            });    

            socket.on('connect', onConnect);
            socket.on('disconnect', onDisconnect);
            socket.on('welcome', onWelcomeEvent);
            socket.on('instrumentChanges', onInstrumentChanges);
            socket.on('instrumentTerminal', onInstrumentTerminal);
            socket.on('latestFilesList', onLatestFilesList);
            socket.on('latestEvtFilesList', onLatestEvtFilesList);
            socket.on('latestRbfFilesList', onLatestRbfFilesList);
            socket.on('latestLogFilesList', onLatestLogFilesList);
            socket.on('latestHbtFilesList', onLatestHbtFilesList);
            socket.on('latestEvtFile', onLatestEvtFile);
            socket.on('connect_error', onConnectError);
            socket.on("reconnection_attempt", onReconnectionAttempt);
            socket.on("reconnect", onReconnectSuccess);
        } 

        return () => {
            if(socket !== null){
                socket.off('connect', onConnect);
                socket.off('disconnect', onDisconnect);
                socket.off('welcome', onWelcomeEvent);
                socket.off('instrumentChanges', onInstrumentChanges);
                socket.off('instrumentTerminal', onInstrumentTerminal);
                socket.off('latestFilesList', onLatestFilesList);
                socket.off('latestEvtFilesList', onLatestEvtFilesList);
                socket.off('latestRbfFilesList', onLatestRbfFilesList);
                socket.off('latestLogFilesList', onLatestLogFilesList);
                socket.off('latestHbtFilesList', onLatestHbtFilesList);
                socket.off('latestEvtFile', onLatestEvtFile);
                socket.off('connect_error', onConnectError);
                socket.off("reconnection_attempt", onReconnectionAttempt);
                socket.off("reconnect", onReconnectSuccess);
                // socket.disconnect(); // kill the websocket between frontend and backend
                cleanupSocket()
            }
        };
      }, [socket]);

    function newSocket(){
        requestPing().then(d => {
            getToken().then(t => {
                console.log("Now creating new socket")
                setSocket(io(
                    "",
                    {
                        reconnection: false,
                        autoConnect: false,
                        auth: { token: t },
                        // auth: { token: sessionStorage.getItem("seisodinCloudJwtToken") },
                    },
                  ))
            }).catch(e => {
                console.log("unable to retrieve token, so no new socket was made")
            })
        }).catch(e => {
            console.log("error pinging or creating socket")
        })
    }

    function cleanupSocket(){
        if(socket !== null){
            console.log("cleanupSocket")
            socket.removeAllListeners(); // instead of individual socket.off. Necessary to stop listeners after leaving page
            socket.disconnect(); // kill the websocket between frontend and backend
            setSocket(null)
        }
    }

    async function requestPing(){
        return await backendApi.get("/api/test/ping").then((res) => {
            console.log("ping backend")
            return res;
        })
    }

    async function getToken(){
        let token = await asyncSessionStorage.getItem("seisodinCloudJwtToken")
        return token
    }

    const asyncSessionStorage = {
        setItem: function (key, value) {
            return Promise.resolve().then(function () {
                sessionStorage.setItem(key, value);
            });
        },
        getItem: function (key) {
            return Promise.resolve().then(function () {
                return sessionStorage.getItem(key);
            });
        }
    }

    return (
        <DashboardGrid 
            errorOpen={errorOpen}
            setErrorOpen={setErrorOpen}
            error={error}
            subscriptionData={subscriptionData}
            loadingSubscriptionData={loadingSubscriptionData}
            socketIsConnected={socketIsConnected}
            socketioSession={socketioSession}
            socketLoading={socketLoading}
            instruments={instruments}
            instrumentTerminal={instrumentTerminal}
            loadingInstrumentList={loadingInstrumentList}
            latestFiles={latestFiles}
            latestEvtFiles={latestEvtFiles}
            latestRbfFiles={latestRbfFiles}
            latestLogFiles={latestLogFiles}
            latestHbtFiles={latestHbtFiles}
            loadingLatestFilesList={loadingLatestFilesList}
            lastEvent={lastEvent}
            loadingLastEventFile={loadingLastEventFile}
        />
    )
}