import React from 'react'
// import { AuthProvider, useAuthState } from "./context/auth-context"

import DashboardFrame from "./components/layout/DashboardFrame"
import {PageProvider} from "./context/pageContext"
import {SeismicDataProvider} from "./context/seismicDataContext"
import { QuerybarProvider } from './context/querybarContext'


export default function AuthenticatedApp() {
    return (
        <>
        <PageProvider >
            <QuerybarProvider>
                <SeismicDataProvider>
                    <DashboardFrame />
                </SeismicDataProvider>
            </QuerybarProvider>
        </PageProvider>
        </>
    )
}
