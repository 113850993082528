import React, {useState} from 'react';
import axios from "axios";
import qs from "querystring"
import validator  from "validator";
import { useParams } from "react-router-dom";

import LinkButton from '../layout/LinkButton'
import SnackBar from "../layout/SnackBar"
import { CheckMarkDialog } from "../layout/CheckMark"
import "../styles/checkmarkStyles.css"

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Typography } from '@material-ui/core';

import seisodinIconBlack from "../../graphics/seisodinIconBlack.png"

// styles should eventually be moved to a different file for better readability
const useStyles = makeStyles((theme) => ({
    root: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(4),

        '& > *': {
            margin: theme.spacing(1),
            padding: theme.spacing(2),
            maxWidth: "400px"
        },
    },
    loginform: {
        width: "100%",
        paddingBottom: "15px",
        webkitAppearance: "textfield",
    },
    logo: {
        width: "50%",
        paddingBottom: "1rem",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto"
    },
    title: {
        width: "100%",
        textAlign: "center",
        paddingBottom: "1rem",
    },
    passwordTextRed: {
        color: "red"
    },
    passwordTextOrange: {
        color: "orange"
    },
    passwordTextGreen: {
        color: "green"
    },
}));

export function LoginPageButton() {

    return (
        <LinkButton to='/' endIcon={<ExitToAppIcon />}>Go to login page </LinkButton>
    )
}


export default function Reset(props) {
    let [password1, setPassword1] = useState("");
    let [password2, setPassword2] = useState("");
    let [passwordStrength, setPasswordStrength] = useState(0);
    let [resetSucessful, setResetSucessful] = useState(false);
    const [mySnackBar, setMySnackBar] = useState({ display: false, type: "", message: "" });


    let { token } = useParams();

    const classes = useStyles();

    const onChangePassword1 = e => {
        setPassword1(e.target.value);
        validatePassword(e.target.value);
    };

    const onChangePassword2 = e => {
        setPassword2(e.target.value);
    };

    const validatePassword = (p) => {
        const passwordSettings = {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
            returnScore: true,
            pointsPerUnique: 2,
            pointsPerRepeat: 1,
            pointsForContainingLower: 20,
            pointsForContainingUpper: 20,
            pointsForContainingNumber: 20,
            pointsForContainingSymbol: 20
        }
       
        const strength = validator.isStrongPassword(p, passwordSettings)
        if(strength<100){
            setPasswordStrength(strength);
        } else {
            setPasswordStrength(100);
        }
        return strength
    }

    function submitNewPassword(event) {
        event.preventDefault();
        const url = `/api/forgot/reset/${token}`;

        const requestBody = {
            password1: password1,
            password2: password2
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        axios.post(url, qs.stringify(requestBody), config)
            .then((res) => {
                if(res.status === 200){
                    // do something
                    setMySnackBar({ display: true, type: "success", message: "Saved" });
                    setTimeout(function () {
                        setMySnackBar({ display: false, type: "", message: "" });
                        setResetSucessful(true);
                    }, 2000);
                } else {
                    // show an error message of some kind
                    throw new Error(res.status + " : " + res.message)
                }
            })
            .catch((e) => {
                // Do somthing
                let errorMessage = e.response.data.message;
                setMySnackBar({ display: true, type: "error", message: `${errorMessage ? `${errorMessage}.` : ""}` });
                setTimeout(function () {
                    setMySnackBar({ display: false, type: "", message: "" });
                }, 5000);
            })
    }


    return (
        <>
            {
                mySnackBar.display === true && mySnackBar.type === "error" ?
                    <>
                    <SnackBar type={mySnackBar.type} message={mySnackBar.message} />
                    </>
                    : ""
            }
            {
                mySnackBar.display === true && mySnackBar.type === "success" ?
                    <CheckMarkDialog message="saved" />
                    : ""
            }
        <div>
            <Container className={classes.root} disableGutters align="center">
                    <div>
                        <img src={seisodinIconBlack} alt="seisodin logo" className={classes.logo} />
                    </div>
            </Container>
            <Container className={classes.root} disableGutters align="center">
                {
                    !resetSucessful ?
                    // render form to change password
                    <Paper variant="outlined">
                        <div>
                            <Typography variant="h5" className={classes.title}>
                                New Password
                            </Typography>
                            <Typography align="center" variant="subtitle2" className={passwordStrength <= 0 ? "" : passwordStrength < 70 ? classes.passwordTextRed : passwordStrength <= 99 ? classes.passwordTextOrange : passwordStrength >= 100 ? classes.passwordTextGreen : ""}>
                                {passwordStrength <= 0 ? "Pick a strong password" : passwordStrength < 70 ? "weak password" : passwordStrength <= 99 ? "acceptable password" : passwordStrength >= 100 ? "strong password" : ""}
                            </Typography>
                        </div>
                        <form noValidate autoComplete="off">
                            <div>
                                <TextField id="password1" label="New Password" onChange={onChangePassword1} variant="outlined" required fullWidth margin="normal" type="password" className={classes.loginform} autoComplete='new-password'/>
                                <LinearProgress variant="determinate" value={passwordStrength} style={{ marginTop: "-1.1rem" }} />
                            </div>
                            <div>
                                <TextField id="password2" label="Repeat New Password" onChange={onChangePassword2} variant="outlined" required fullWidth margin="normal" type="password" className={classes.loginform} autoComplete='new-password'/>
                            </div>
                            <div>
                                <Button onClick={submitNewPassword} disabled={passwordStrength >= 70 ? false : true} size="large" variant="contained" color="primary" className={classes.loginform}>Save New Password</Button>
                            </div>
                        </form>
                    </Paper>
                    // render button with link to login page
                    : <LoginPageButton />
                }  
            </Container>
        </div>
        </>
    )
}



