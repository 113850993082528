import React, {useEffect, useState, useCallback} from 'react';
import { useParams } from "react-router-dom";

// Own
import { backendApi, clientConfigApplication } from "../api/calls"
import LinkButton from '../layout/LinkButton'
import SnackBar from "../layout/SnackBar"
import { CheckMarkDialog } from "../layout/CheckMark"
import "../styles/checkmarkStyles.css"
import seisodinIconBlack from "../../graphics/seisodinIconBlack.png"

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Typography } from '@material-ui/core';

// styles should eventually be moved to a different file for better readability
const useStyles = makeStyles((theme) => ({
    root: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(4),

        '& > *': {
            margin: theme.spacing(1),
            padding: theme.spacing(2),
            maxWidth: "400px"
        },
    },
    logo: {
        width: "50%",
        paddingBottom: "1rem",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto"
    },
    title: {
        width: "100%",
        textAlign: "center",
        paddingBottom: "1rem",
    },
    errorTitle: {
        width: "100%",
        textAlign: "center",
        paddingBottom: "1rem",
        color: "red"
    }
}));

export function LoginPageButton() {

    return (
        <LinkButton to='/' endIcon={<ExitToAppIcon />}>Go to login page </LinkButton>
    )
}


export default function Confirm(props) {
    let [confirmationSucessful, setConfirmationSucessful] = useState(false);
    const [mySnackBar, setMySnackBar] = useState({ display: false, type: "", message: "" });

    let { token } = useParams();

    console.log(token)

    const classes = useStyles();

    const checkToken = useCallback(async () => {
        return await backendApi.get(`/api/users/changeemail/${token}`, clientConfigApplication).then(d => {
            if(!d){
                console.log("d", d)
                throw new Error("Token not found")
            } else {
                setConfirmationSucessful(true)
                return {success: true}
            }
        }).catch(e => {
            console.log(e.response)
        })
    }, [token])

    useEffect(() => {
        checkToken().then(d => {
            // setConfirmationSucessful(true)
        }).catch(e => {
            setMySnackBar({ display: true, type: "error", message: e.message });
            setTimeout(function () {
                setMySnackBar({ display: false, type: "", message: "" });
            }, 5000);
            throw new Error(e.message)
        })
    }, [checkToken])


    return (
        <>
            {
                mySnackBar.display === true && mySnackBar.type === "error" ?
                    <>
                    <SnackBar type={mySnackBar.type} message={mySnackBar.message} />
                    </>
                    : ""
            }
            {
                mySnackBar.display === true && mySnackBar.type === "success" ?
                    <CheckMarkDialog message="saved" />
                    : ""
            }
        <div>
            <Container className={classes.root} disableGutters align="center">
                    <div>
                        <img src={seisodinIconBlack} alt="seisodin logo" className={classes.logo} />
                    </div>
            </Container>
            <Container className={classes.root} disableGutters align="center">
                {
                    <Paper variant="outlined" >
                        
                            {
                                confirmationSucessful ?
                                    <div>
                                        <Typography variant="h5" className={classes.title}>
                                            Email Succesfully Changed
                                        </Typography>
                                        <LoginPageButton />
                                    </div>
                                :           
                                    <div>
                                        <Typography variant="h5" className={classes.errorTitle}>
                                            Link is invalid or expired
                                        </Typography>
                                        <LoginPageButton />
                                    </div>
                            } 
                    </Paper>
                }  
            </Container>
        </div>
        </>
    )
}



