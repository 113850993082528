import React, {useState, useEffect} from 'react';

// MUI V5
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearProgressWithLabel(props) { 

    let {labelFirst, text, disabled} = props;
     
    return (
        <>
        {
            labelFirst ?
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: 1 }}>
                    <Typography variant="body2" color="text.secondary">{text}</Typography>
                </Box>
                <Box sx={{ width: '100px'}}>
                    <LinearProgress 
                        {...props}
                        variant="determinate" 
                        sx={{
                            "& .MuiLinearProgress-barColorPrimary": {
                                backgroundColor: disabled ? "gray" : "default",
                            },
                        backgroundColor: disabled ? "lightgray" : "default",
                        height: "20px"
                        }}
                    
                    />
                </Box>
            </Box>
          : <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress 
                        {...props}
                        variant="determinate" 
                        sx={{
                            "& .MuiLinearProgress-barColorPrimary": {
                                backgroundColor: disabled ? "gray" : "default",
                            },
                        backgroundColor: disabled ? "lightgray" : "default",
                        height: "20px"
                        }}
                    />
                </Box>
                <Box >
                    <Typography variant="body2" color="text.secondary">{text}</Typography>
                </Box>
            </Box>
        }
        </>
    );
  }