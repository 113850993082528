import React from 'react'
import LoginForm from "./components/auth/Login"

export default function UnauthenticatedApp() {
    return (
        <>
            <LoginForm />
        </>
    )
}
