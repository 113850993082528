import React from 'react'

// MUI V5
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


// ICONS
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CancelIcon from '@mui/icons-material/Cancel';

export default function SubscriptionExpired(props){

    let {visibility} = props;

    return (
        <React.Fragment>
            <Paper 
                sx={{
                    marginBottom: "0px",
                    // backgroundColor: "#424242",
                    backgroundColor: "white",
                    padding: "10px",
                    color: "primary.main",
                }}

                style={{visibility: visibility}}
            >
                <Grid container spacing={1} alignItems="center" >
                    <Grid item xs={12} sm={12} container alignItems="flex-start" justify="flex-start">
                        <Stack direction="row">
                            {/* <Badge badgeContent={<CancelIcon fontSize="small" color="error"/>}> */}
                                <CardMembershipIcon />
                            {/* </Badge> */}
                            <Typography variant="button" sx={{marginLeft: "20px"}}>Subscription Expired</Typography>
                            
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>

            
        </React.Fragment>
    )
}