import React, {useEffect, useState, useContext} from 'react';
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-log"
import "prismjs/components/prism-powershell"
import "prismjs/components/prism-prolog"
import "prismjs/components/prism-protobuf"
import "prismjs/components/prism-sass"
import "prismjs/components/prism-sas"
import "prismjs/components/prism-scss"

// OWN
import Widget from "../widget"
import "./instrumentTerminal.css"
import { PageContext } from '../../../../context/pageContext';
import OptionsMenu from '../optionMenu';

// MUI V5
import Box from "@mui/material/Box"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';

// ICONS
import TerminalIcon from '@mui/icons-material/Terminal';

const WidgetInstrumentTerminal = (props) => {

    const [statusType, setStatusType] = useState(0)

    let {
      messages,
      socketIsConnected
    } = props;

    const Pcontext = useContext(PageContext);

    let quotaWarningPercentage = 80;

    return (
    <React.Fragment>
        <Widget 
            backgroundColor="" 
            icon={<TerminalIcon />}
            actionButton={<OptionsMenu actionArray={[{title: "File Explorer", fn: () => {Pcontext.setPage("fileexplorer")}},{title: "Instruments", fn: () => {Pcontext.setPage("instruments")}}]}/>}
            title="Terminal"
        >
                <Box sx={{ width: '100%', mr: 1 }}>
                    <TerminalViewer 
                      messages={messages}
                      socketIsConnected={socketIsConnected} 
                    />
                </Box>
        </Widget>
    </React.Fragment>
    );
};

export function CodeArea({ code, language, socketIsConnected}) {

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('xl'));


  useEffect(() => {
      Prism.highlightAll();
  }, [language, code, socketIsConnected]);


  return (
    <div className="Code">
      <pre style={{wordBreak: "break-word !important", whiteSpace: "normal !important", margin: 0, padding: 0, fontSize: largeScreen ? "" : "11px"}}>
        <code className={`language-${language}`} style={{wordBreak: "break-word !important", whiteSpace: "normal !important"}}><div>{code}</div></code>
      </pre>
    </div>
  );
}

const TerminalViewer = (props) => {
  let {
      messages,
      socketIsConnected,
  } = props;

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
      <Box 
          sx={{
              backgroundColor: "#2d2d2d", 
              color: "white", 
              margin: "0px -16px 0px -16px", 
              height: "300px", 
              // overflowY: "scroll"
          }} 
          style={{
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
          }}
      >
          <Stack direction="column">     
              {
                  messages.map((message, i) => (
                      <CodeArea key={"codearea"+i} code={message} language={"sass"} socketIsConnected={socketIsConnected}/> 
                  ))
              }
          </Stack>
      </Box>
  );
};


export default WidgetInstrumentTerminal;