import React, {useState, useEffect, useContext, useRef} from "react"
import dayjs from 'dayjs';

// OWN
import { backendApi, clientConfigApplication } from "../../api/calls"
import { QuerybarContext } from '../../../context/querybarContext'

// MUI V5
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';

const initialValue = dayjs();

function getRandomNumber(min, max) {
    return Math.round(Math.random() * (max - min) + min);
}
  
/**
 * Mimic fetch with abort controller https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
 * ⚠️ No IE11 support
 */
function fakeFetch(date, { signal }) {
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
        const daysInMonth = date.daysInMonth();
        const daysToHighlight = [1, 2, 3].map(() => getRandomNumber(1, daysInMonth));

        resolve({ daysToHighlight });
        }, 500);

        signal.onabort = () => {
        clearTimeout(timeout);
        reject(new DOMException('aborted', 'AbortError'));
        };
    });
}

async function fetchData(config) {
    console.log("config: ")
    console.log(config)
    return await backendApi.get("/api/organization/events", config).then(d => {
        console.log("success getting events")
        console.log(d)
        return d;
    }).catch(e => {
        console.log("Fail getting events")
        console.log(e)
        throw new Error(e.message)
    })
}

  
  
function ServerDay(props) {
    const { 
        highlightedDays = [], 
        day, 
        outsideCurrentMonth, 
        ...other 
    } = props;

    const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

    return (
        <Badge
            key={props.day.toString()}
            color="error"
            variant={isSelected ? 'dot' : undefined}
            overlap="circular"
            // badgeContent={isSelected ? '•' : undefined}
            anchorOrigin = {{
                vertical: 'bottom',
                    horizontal: 'right'
            }}
            sx = {{
                '& .MuiBadge-badge': {
                    right: '50%'
                }
            }}
        >
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
        </Badge>
    );
}
  
export default function EventCalendar() {
    const requestAbortController = React.useRef(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15]);

    const QueryContext = React.useContext(QuerybarContext);

    const fetchHighlightedDays = (date) => {

        const controller = new AbortController();
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            signal: controller.signal,
            params: {
                date: date
            }
        }

        fetchData(config)
        fakeFetch(date, { signal: controller.signal })
        .then(({ daysToHighlight }) => {
            setHighlightedDays(daysToHighlight);
            setIsLoading(false);
        })
        .catch((error) => {
            // ignore the error if it's caused by `controller.abort`
            if (error.name !== 'AbortError') {
                throw error;
            }
        });

        requestAbortController.current = controller;
    };

    React.useEffect(() => {
        fetchHighlightedDays(initialValue);
        // abort request on unmount
        return () => requestAbortController.current?.abort();
    }, []);

    const handleMonthChange = (date) => {
        if (requestAbortController.current) {
            // make sure that you are aborting useless requests
            // because it is possible to switch between months pretty quickly
            requestAbortController.current.abort();
        }

        setIsLoading(true);
        setHighlightedDays([]);
        fetchHighlightedDays(date);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
            defaultValue={initialValue}
            loading={isLoading}
            onMonthChange={handleMonthChange}
            renderLoading={() => <DayCalendarSkeleton />}
            slots={{
                day: ServerDay,
            }}
            slotProps={{
                day: {
                    highlightedDays,
                },
            }}
        />
        </LocalizationProvider>
    );
}
