import React, { useState } from "react";
import { strIsDefined  } from "../../../../helpers.js";
import ChannelInfoChip from "./ChannelInfoChip.js"
import FileNameChip from "./FileNameChip.js"
import ChannelStatsChip from "./ChannelStatsChip.js"

import "../dygraph.css";

// MUI V5
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';


// ICONS
import LinkIcon from '@mui/icons-material/Link';
import ErrorIcon from '@mui/icons-material/Error';

export default function GraphHeader(props) {

    const [openGraphDetailsDialog, setOpenGraphDetailsDialog] = useState(false)

    let { 
        metaData,
        channelIndex,
        visibleChannels,
        uid,
        fft,
        splitChannels,
        colorArr,
        graphRows,
        linkTimeScales,
        loadingGraphData,
        zoomDetails,
    } = props;

    const theme = useTheme();
    const mediumScreen = useMediaQuery(theme.breakpoints.up('sm'));
    


  return (
<Box key={"innerbox"+channelIndex+uid} sx={{display: "flex", flexDirection: "row" }}>
    {
    fft === undefined ? 
    // A1 if not FFT plot
        (splitChannels) ? 
        // B1 if split channels
        <div className="dygraph-title" style={{flexGrow: 1}}>
            {
                metaData[0].nonContiguousDataRemovedFromFile === true ?
                <Tooltip title="WARNING: The file contains non-contigous data which cannot be rendered." arrow placement="bottom-start">
                <IconButton color="error" sx={{height: "16px", width: "16px"}} component="label">
                    <ErrorIcon sx={{height: "16px", width: "16px", marginRight: "5px"}}/> 
                </IconButton>
                </Tooltip>
                : <React.Fragment></React.Fragment>
            }
            {
                mediumScreen && graphRows ? 
                <FileNameChip metaData={metaData} fileName={String(metaData[channelIndex]?.fileName)} loadingGraphData={loadingGraphData}/>
                : <React.Fragment></React.Fragment>
            }

            <ChannelInfoChip key={"splitchannelpopover"+channelIndex+uid} zoomDetails={zoomDetails} colorArr={colorArr} metaData={metaData} channelIndex={channelIndex} loadingGraphData={loadingGraphData} splitChannels={splitChannels} visibleChannels={visibleChannels}/>
            <ChannelStatsChip metaData={metaData} zoomDetails={zoomDetails} graphRows={graphRows} colorArr={colorArr} loadingGraphData={loadingGraphData} channelIndex={channelIndex}/>
        </div>

        : 
        // B2 if not split channels
        <div className="dygraph-title" style={{flexGrow: 1}}>
            {
                metaData[0].nonContiguousDataRemovedFromFile === true ?
                <Tooltip title="WARNING: The file contains non-contigous data which can not be rendered." arrow placement="bottom-start">
                <IconButton color="error" sx={{height: "16px", width: "16px"}} component="label">
                    <ErrorIcon sx={{height: "16px", width: "16px", marginRight: "5px"}}/> 
                </IconButton>
                </Tooltip>
                : <React.Fragment></React.Fragment>
            }
            {
                mediumScreen && graphRows? 
                <FileNameChip metaData={metaData} fileName={String(metaData[channelIndex]?.fileName)} loadingGraphData={loadingGraphData}/>
                : <></>
            }
            {
                metaData.map((graph, metaChannelIndex) => {
                    return (
                        <ChannelInfoChip key={"combinedchannelpopover"+metaChannelIndex} zoomDetails={zoomDetails} colorArr={colorArr} metaData={metaData} channelIndex={metaChannelIndex} loadingGraphData={loadingGraphData} splitChannels={splitChannels} visibleChannels={visibleChannels}/>
                    )
                })
            }
        </div>
    : 
    // A2 if FFT plot

    <div className="dygraph-title" style={{flexGrow: 1}}>
        {
            mediumScreen && graphRows ? 
            <FileNameChip metaData={metaData} fileName={String(metaData[channelIndex]?.fileName)} loadingGraphData={loadingGraphData}/>
            : <></>
        }

        <Chip 
            style={{fontSize: "12px", padding: "0px", height: "16px", margin: "2px 5px 2px 0px", paddingTop: "2px"}}
            label={
                loadingGraphData ? 
                <Skeleton animation="wave" height={10} width="80px"/>
                : <>
                    <Typography variant="caption" style={{opacity: "0.8", color: colorArr[0]}}>{strIsDefined(metaData[channelIndex]?.channelCode + " FFT")}</Typography>
                </>
        }
        />
    </div>
    }
    

    <div style={{margin: "0px"}} className="dygraph-title">
        <Tooltip title="Timescales linked">
            <IconButton style={{height: "20px", width: "20px", marginRight: "8px", display: linkTimeScales ? "" : "none"}} onClick={() => {}}><LinkIcon style={{height: "18px", width: "18px"}}/></IconButton>
        </Tooltip>
        {/* <Chip 
            label={<i>DEBUG</i>} 
            style={{fontSize: "12px", padding: "0px", height: "16px", margin: "2px 5px 2px 0px", paddingTop: "2px"}} 
            // deleteIcon={<InfoIcon style={{height: "16px", width: "16px"}}/>} 
            // onDelete={() => {setOpenGraphDetailsDialog(true)}}
            onClick={() => {setOpenGraphDetailsDialog(true)}}
        /> */}
        {/* <IconButton style={{height: "20px", width: "20px", marginRight: "8px"}} onClick={() => {setOpenGraphDetailsDialog(true)}}><InfoIcon style={{height: "18px", width: "18px"}}/></IconButton> */}
    </div>
</Box>
  );
}
