import React, {useEffect, useState, useContext} from 'react';

// OWN
import Widget from "../widget"
import OptionsMenu from '../optionMenu';
import { PageContext } from '../../../../context/pageContext';

// MUI V5
import Skeleton from '@mui/material/Skeleton';

// ICONS
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Typography } from '@mui/material';


const WidgetUserQuota = (props) => {

    let {
        userCount,
        userQuota,
        loading,
    } = props;

    const Pcontext = useContext(PageContext);

    return (
    <React.Fragment>
        <Widget 
            backgroundColor="" 
            icon={<PeopleAltIcon />}
            actionButton={<OptionsMenu actionArray={[{title: "Show Subscription", fn: () => {Pcontext.setPage("subscription")}}]}/>}
            title="User Quota"
            fixedHeight="130px"
        >
            {
                loading ? 
                <Skeleton variant="text" sx={{ fontSize: 'h5' }} /> 
                : <Typography variant ="h5">{userCount}/{userQuota}</Typography>
            }
            {
                loading ? 
                <Skeleton variant="text" sx={{ fontSize: 'h5' }} /> 
                : <Typography variant ="button" color="gray">Users</Typography>
            }            
        </Widget>
    </React.Fragment>
    );
};

export default WidgetUserQuota;