import React, {useState, useEffect} from 'react';

// MUI V5
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

// Icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function BreadCrumbs(props){

  const [copyOfCurrentPath, setCopyOfCurrentPath] = useState();

  let {
    currentPath, 
    setCurrentPath,
  } = props;

  useEffect(() => {
    setCopyOfCurrentPath(currentPath)
  }, [currentPath])

  function handleClickBreadCrumb(indexToRemoveFrom){
    let path = currentPath;
    path = path.slice(0, indexToRemoveFrom+1)
    setCurrentPath([...path])
  }
    
    return (
      <>
        <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            style={{borderStyle: "solid", borderWidth: "0px 0px 1px 0px", borderColor: "#D3D3D3", paddingLeft: "15px", flexGrow: 1}}
        >
          {
            (copyOfCurrentPath && copyOfCurrentPath !== undefined && copyOfCurrentPath.length > 0 && copyOfCurrentPath !== null) ? 
            copyOfCurrentPath.map((dir, i) => {
              return (
              <Typography key={"bc"+dir+i} variant="overline" style={{margin: 0}} onClick={() => handleClickBreadCrumb(i)} sx={{cursor: "pointer"}}>
                {
                  (dir === "" || dir === "/" )? 
                  "home"
                  : dir
                }
              </Typography >
              )
            })
            : ""
          }
        </Breadcrumbs>
        
      </>

    )
}