import React, {useState} from "react"

// OWN 
import { useAuthState } from "../../../context/auth-context"
import "../../styles/checkmarkStyles.css"

// MUI V5
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


export default function Permissions() {
    const { user } = useAuthState();

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));


    const permissionTexts = {
        removeFiles: "Remove Files", 
        downloadFiles: "Download Files", 
        createUsers: "Manage Users",
        addInstruments: "Add Instruments"
    }

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%"}}>
            <Card variant="outlined" sx={{ height: "100%", width: largeScreen ? "300px" : "100%"}}>
                <CardContent>
                    <Typography sx={{ fontSize: 14, color: "primary.main", mb: "15px" }} gutterBottom>
                    Permissions
                    </Typography>
                    {
                        Object.entries(user.permissions).map(([key, value], i) => {
                            return (
                                <Stack direction="row">
                                    <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center"}}>
                                    <Typography  variant="body2">
                                        {permissionTexts[key]}
                                    </Typography>
                                    </Box>
                                    <Box>
                                    <FormLabel 
                                        key={key} 
                                        component="legend" 
                                        style={{
                                            fontSize: "0.8rem", 
                                            align: "center"
                                        }}>
                                            {value ? "allowed" : "not allowed"} 
                                            <Switch checked={value} disabled />
                                    </FormLabel>
                                    </Box>
                                </Stack>
                            )
                        })
                    }
                </CardContent>
            </Card>
        </Box>
        
    )
}