import React, { useState, useEffect} from 'react'

const SeismicDataContext = React.createContext()

function SeismicDataProvider({ children }) {
    const [url, setUrl] = useState("test1");
    const [urlArr, setUrlArr] = useState([]);

    // contains the path for a single file
    const [path, setPath] = useState("");

    // contains an array of paths and names for selected files
    const [pathArr, setPathArr] = useState([]);

    // state-change used to indicate if multiple selected files should be downloaded and opened by the data viewer
    const [openMultipleFiles, setOpenMultipleFiles] = useState(true);

    const value = { url, setUrl, urlArr, setUrlArr, path, setPath, pathArr, setPathArr, openMultipleFiles, setOpenMultipleFiles }

    return (
        <SeismicDataContext.Provider value={value}>
            {children}
        </SeismicDataContext.Provider>
    )
}


export { SeismicDataProvider, SeismicDataContext }