import React, {useEffect, useState} from "react"

// MUI V5
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';



export default function ProtoSingleFileGraphSettings(props) {

    const [graphHeader, setGraphHeader] = useState(false)
    const [isFftDataPresent, setIsFftDataPresent] = useState([])
    const [fft, setFft] = useState(false)
    const [stats, setStats] = useState(false)
    const [colors, setColors] = useState(false)
    const [FFTLogX, setFFTLogX] = useState(false)
    const [FFTLogY, setFFTLogY] = useState(false)
    const [statsBlock, setStatsBlock] = useState(false)
    const [annotationHiLo, setAnnotationHiLo] = useState(false)
    const [graphHeight, setGraphHeight] = useState(120)

    let {
        localCopyOfPageArray, 
        setLocalCopyOfPageArray,
        pageCurrent,
        localCopyOfPageArrayConfig,
        setLocalCopyOfPageArrayConfig,
    } = props;

    let pageType = "ProtoSingleFileGraph"

    useEffect(() => {
        let tempCopy = {...localCopyOfPageArrayConfig}
        let fixedCopy = handleMissingConfigParameters(tempCopy)
        setLocalCopyOfPageArrayConfig(fixedCopy)
    }, [])

    // Handle missing config settings
    ///////////////////////////////////////////////////
    useEffect(() => {
        let tempCopy = {...localCopyOfPageArrayConfig}
        let fixedCopy = handleMissingConfigParameters(tempCopy)
        setLocalCopyOfPageArrayConfig(fixedCopy)
        checkIfFftDataIsPresent()
    }, [localCopyOfPageArray])  // <-- NOT IDEAL SOLUTION. VERY LARGE OBJECT TO COMPARE !!


    function handleMissingConfigParameters(obj){
        let tempCopy = {...obj}
        if(tempCopy[pageType] === undefined){
            tempCopy[pageType] = {};
        }
        if(tempCopy[pageType].graphHeader === undefined){
            tempCopy[pageType].graphHeader = false;
        }
        if(tempCopy[pageType].stats === undefined){
            tempCopy[pageType].stats = "";
        }
        if(tempCopy[pageType].fft === undefined){
            tempCopy[pageType].fft = false;
        }
        if(tempCopy[pageType].colors === undefined){
            tempCopy[pageType].colors = false;
        }
        if(tempCopy[pageType].statsBlock === undefined){
            tempCopy[pageType].statsBlock = false;
        }
        if(tempCopy[pageType].height === undefined){
            tempCopy[pageType].height = 120;
        }
        if(tempCopy[pageType].FFTLogY === undefined){
            tempCopy[pageType].FFTLogY = false;
        }
        if(tempCopy[pageType].FFTLogX === undefined){
            tempCopy[pageType].FFTLogX = false;
        }
        if(tempCopy[pageType].annotationHiLo === undefined){
            tempCopy[pageType].annotationHiLo = false;
        }
        return tempCopy
    }

    function handleGraphHeader(event){
        setGraphHeader(event.target.checked)
        if(event.target.checked === false){
            handleStats(event)
        }
        // search the entire page array and modify the setting for each page of this type
        let tempCopy = {...localCopyOfPageArrayConfig}
        tempCopy[pageType].graphHeader = event.target.checked;
        setLocalCopyOfPageArrayConfig(tempCopy)
    }

    function handleStats(event){
        if(event.target.checked === true){
            handleGraphHeader(event) // enable the header, otherwise it makes no sense
        }
        setStats(event.target.checked)
        // search the entire page array and modify the setting for each page of this type
        let tempCopy = {...localCopyOfPageArrayConfig}
        tempCopy[pageType].stats = event.target.checked;
        setLocalCopyOfPageArrayConfig(tempCopy)
    }

    function handleColors(event){
        setColors(event.target.checked)
        // search the entire page array and modify the setting for each page of this type
        let tempCopy = {...localCopyOfPageArrayConfig}
        tempCopy[pageType].colors = event.target.checked;
        setLocalCopyOfPageArrayConfig(tempCopy)
    }

    function handleStatsBlock(event){
        setStatsBlock(event.target.checked)
        // search the entire page array and modify the setting for each page of this type
        let tempCopy = {...localCopyOfPageArrayConfig}
        tempCopy[pageType].statsBlock = event.target.checked;
        setLocalCopyOfPageArrayConfig(tempCopy)
    }

    function handleAnnotationHiLo(event){
        setAnnotationHiLo(event.target.checked)
        // search the entire page array and modify the setting for each page of this type
        let tempCopy = {...localCopyOfPageArrayConfig}
        tempCopy[pageType].annotationHiLo = event.target.checked;
        setLocalCopyOfPageArrayConfig(tempCopy)
    }

    function checkIfFftDataIsPresent(){
        let newArr = []
        localCopyOfPageArray.forEach((page, pageIndex) => {
            if(page?.type === pageType && localCopyOfPageArray[pageIndex]?.payload?.frequencyDomain?.length > 0){
                newArr.push(localCopyOfPageArray[pageIndex]?.payload?.metaData?.[0]?.fileName)
            }
        })
        setIsFftDataPresent(newArr)
    }

    const handleSliderChange = (event, newValue) => {
        setGraphHeight(newValue);
        let tempCopy = {...localCopyOfPageArrayConfig}
        tempCopy[pageType].height = graphHeight+"mm"
        setLocalCopyOfPageArrayConfig(tempCopy)
      };

    function handleFFTLogX(event){
        setFFTLogX(event.target.checked)
        // search the entire page array and modify the setting for each page of this type
        let tempCopy = {...localCopyOfPageArrayConfig}
        tempCopy[pageType].FFTLogX = event.target.checked;
        setLocalCopyOfPageArrayConfig(tempCopy)
    }

    function handleFFTLogY(event){
        setFFTLogY(event.target.checked)
        // search the entire page array and modify the setting for each page of this type
        let tempCopy = {...localCopyOfPageArrayConfig}
        tempCopy[pageType].FFTLogY = event.target.checked;
        setLocalCopyOfPageArrayConfig(tempCopy)
    }

    return (
        <div>
        <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={6} xl={4}>
                <Card variant="outlined" sx={{ height: "100%", minWidth: "250px" }}>
                    <CardContent>
                    <Typography sx={{ fontSize: 14, color: "primary.main", mb: "15px" }} gutterBottom>
                        Visual
                    </Typography>
                    <FormGroup sx={{marginBottom: "1rem"}}>
                        <FormControlLabel control={<Switch checked={graphHeader} onChange={handleGraphHeader} />} label={"Graph Header"} />
                        <FormControlLabel control={<Switch checked={stats} onChange={handleStats} />} label={"Record Info"} />
                        <FormControlLabel control={<Switch checked={colors} onChange={handleColors} />} label={"Color Graphs"} />
                        <FormControlLabel control={<Switch checked={statsBlock} onChange={handleStatsBlock} />} label={"Statistics"} />
                        <FormControlLabel control={<Switch checked={annotationHiLo} onChange={handleAnnotationHiLo} />} label={"Hi/Lo Annotations"} />
                    </FormGroup>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item sm={12} md={12} lg={6} xl={4}>
                <Card variant="outlined" sx={{ height: "100%", minWidth: "250px" }}>
                    <CardContent>
                    <Typography sx={{ fontSize: 14, color: "primary.main", mb: "15px" }} gutterBottom>
                        FFT
                    </Typography>
                    <FormGroup sx={{marginBottom: "1rem"}}>
                        <FormControlLabel control={<Switch disabled={(isFftDataPresent.length > 0) ? false : true} checked={FFTLogX} onChange={handleFFTLogX} />} label={"LOG(X)"} />
                        <FormControlLabel control={<Switch disabled={(isFftDataPresent.length > 0) ? false : true} checked={FFTLogY} onChange={handleFFTLogY} />} label={"LOG(Y)"} />
                    </FormGroup>
                    </CardContent>
                </Card> 
            </Grid>
        </Grid>
        </Box>
        </div>
    )
}