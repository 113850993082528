import React, {useState, useEffect} from "react"

// OWN
import PieExample from "./legacy/PieChart"
import SubscriptionTable from "./SubscriptionTable";
import SubscriptionTableMobile from "./SubscriptionTableMobile";
import { AdminBadge } from "../../layout/AdminBadge"
import { backendApi, clientConfigApplication } from "../../api/calls"
import { QuerybarContext } from '../../../context/querybarContext'
import ErrorDialog from "../../layout/ErrorDialog.js"
import { AuthContext } from "../../../context/auth-context"



// MUI V5
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


export async function fetchData() {
    return await backendApi.get("/api/organization/settings", clientConfigApplication).then(d => {
        return d;
    }).catch(e => {
        throw new Error(e.message)
    })
}


export default function Subscription(){

    const [subscriptionData, setSubscriptionData] = useState({})
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [error, setError] = React.useState({title: "test", e: ""});

    const QueryContext = React.useContext(QuerybarContext);
    const AContext = React.useContext(AuthContext);
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        QueryContext.setQuerybarActive(true)
        fetchData().then(d => {
            console.log("fetched data")
            setSubscriptionData(d.data.settings)
            QueryContext.setQuerybarActive(false)
        }).catch(e => {
            QueryContext.setQuerybarActive(false)
            setError({ title: "Error loading subscription data", e: e});
            setErrorOpen(true)
        })
    },[])

    useEffect(() => {
        console.log("changed state exp")
        console.log(subscriptionData)
    },[subscriptionData])


    return (
        <div>
            <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
            <AdminBadge />
            {
                largeScreen ? 
                <SubscriptionTable subscriptionData={subscriptionData}/>
                // : <PieExample />
                : <SubscriptionTableMobile subscriptionData={subscriptionData}/>
            }
        </div>
    )
}