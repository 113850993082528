import React, { useRef, useLayoutEffect, useContext, useEffect } from 'react';
import qs from "querystring"

// OWN
import { backendApi, clientConfigApplication } from "../../api/calls"
import { userListContext } from "./index"
import { AuthContext } from "../../../context/auth-context"
import SnackBar from "../../layout/SnackBar"
import { CheckMarkDialog } from "../../layout/CheckMark"
import ErrorDialog from "../../layout/ErrorDialog.js"
import "../../styles/checkmarkStyles.css"


// MUI V5
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';


// ICONS
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HelpIcon from '@mui/icons-material/Help';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';


export default function SwitchListSecondary(props) {
    const [userPermissions, setUserPermissions] = React.useState(props.user.permissions);
    const [visualUserPermissions, setVisualUserPermissions] = React.useState(props.user.permissions);
    const [oldUserPermissions, setOldUserPermissions] = React.useState(props.user.permissions);
    const [user] = React.useState(props.user);
    const [mySnackBar, setMySnackBar] = React.useState({ display: false, type: "", message: "" });
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [error, setError] = React.useState({title: "test", e: ""});

    const firstUpdate   = useRef(true);
    const userContext   = useContext(userListContext);
    const authcontext   = useContext(AuthContext);

    useEffect(() => {
        setOldUserPermissions({...props.user.permissions})
    }, [])

    const permissionTexts = {
        removeFiles: "Remove Files",
        downloadFiles: "Download Files",
        createUsers: "Manage Users",
        addInstruments: "Manage Instruments"
    }

    const ShowPermissionIcons = (props) => {
        const { permissionKey } = props;
        const { permissionEnabled } = props;
 
        switch(permissionKey){
            case "removeFiles": 
                return <DeleteIcon color={!permissionEnabled ? "disabled" : "inherit"} />
            case "downloadFiles":
                return <CloudDownloadIcon color={!permissionEnabled ? "disabled" : "inherit"} />
            case "createUsers":
                return <PersonAddIcon color={!permissionEnabled ? "disabled" : "inherit"} />
            case "addInstruments":
                return <AddCircleIcon color={!permissionEnabled ? "disabled" : "inherit"} />
            default: 
                return <HelpIcon color={permissionEnabled ? "disabled" : "inherit"} />
        }

    }

    let updateParentUserList = (id, key, originalValue) => {
        let userList = userContext.state;
        const iterator = userContext.state.entries();
        for (const user of iterator) {
            if (user[1]._id === id) {
                let userIndex = user[0];
                userList[userIndex].permissions[[key]] = !originalValue;

                // If user changes his own settings, then update authContext too
                if (authcontext.state.user.id === id && authcontext.state.user.permissions.createUsers) {
                    let newAuthContext = {...authcontext.state};
                    newAuthContext.user.permissions[[key]] = !originalValue;
                    authcontext.setState(newAuthContext)
                }
            }
        }
        userContext.update([...userList])
    }

    const handleAdminPermissions = () => {
        setMySnackBar({ display: true, type: "error", message: "You can not change the administrator permissions" });
        setTimeout(function () {
            setMySnackBar({ display: false, type: "", message: "" });
        }, 5000);
    }

    const isUserChangingOwnPermissions = (key, originalValue) => () => {
        if (authcontext.state.user.id === user._id && authcontext.state.user.permissions.createUsers) {
            setMySnackBar({ display: true, type: "error", message: "You can not change your own permissions" });
            setTimeout(function () {
                setMySnackBar({ display: false, type: "", message: "" });
            }, 5000);
        } else {
            handleToggle(key, originalValue)();
        }
    }

    const handleToggle = (key, originalValue) => () => {
        setUserPermissions(state => ({...state, [key]: !originalValue}));
        setVisualUserPermissions(state => ({...state, [key]: !originalValue}));
        updateParentUserList(user._id, key, originalValue)
    };

    useLayoutEffect(() => {
        let mounted = true;

        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        let updatedData = { userId: user._id, ...userPermissions}
        backendApi.post("/api/permissions/update", qs.stringify(updatedData), clientConfigApplication)
                .then((res) => {
                    if(mounted){
                        setVisualUserPermissions(userPermissions)
                        return res;
                    }
                })
                .catch((e) => {
                    console.log(userPermissions)
                    console.log(oldUserPermissions)
                        setVisualUserPermissions({...oldUserPermissions})
                        setError({ title: "Error changing user permissions", e: e});
                        setErrorOpen(true)
                })
        return () => mounted = false;
    }, [userPermissions, user])



    return (
        <React.Fragment key="SwitchList">
            <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
            {
                mySnackBar.display === true && mySnackBar.type === "success" ?
                    <CheckMarkDialog message="saved" />
                    : ""
            }
        <List key={user._id+"list"}>
                {
                    Object.entries(visualUserPermissions).map(([permkey, value], i) => {
                        return (
                            <React.Fragment key={user._id + permkey +"frag"}>
                                <ListItem key={user._id + permkey} sx={{padding: "0px"}}>
                                    <ListItemIcon >
                                        <ShowPermissionIcons permissionKey={permkey} permissionEnabled={value} />
                                    </ListItemIcon>
                                    <ListItemText id={permkey} primary={permissionTexts[permkey]} disableTypography={true} style={{color: !value ? "gray" : ""}} />
                                    <ListItemSecondaryAction >
                                        <Switch
                                            edge="end"
                                            id={permkey}
                                            // key={user.id_+permkey+"sw"}
                                            disabled={authcontext.state.user.permissions.createUsers ? false : true}
                                            onChange={user.admin ? handleAdminPermissions : isUserChangingOwnPermissions(permkey, value)}
                                            checked={value}
                                            color="primary"
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </React.Fragment>
                        )
                    })
                }
        </List>
        </React.Fragment>
    );
}