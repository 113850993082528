import React, {useState, useEffect, useContext} from 'react';
import { usePapaParse } from 'react-papaparse';

// OWN
import ErrorDialog from '../../../../layout/ErrorDialog';
import FileTabPanel from './fileTabPanel';
import {INTERFACE_downloadFile} from "../../../fileExplorer/interface"
import CSVSettingsToolbar from "./csvSettingsToolbar"
import { QuerybarContext } from '../../../../../context/querybarContext';

// MUI V5
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

export default function CSVViewer(props) {
    const [logData, setLogData] = useState({});
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [headerRow, setHeaderRow] = useState(true);
    const [fileIndex, setFileIndex] = useState(0);
    const [fileNames, setFileNames] = useState([]);
    const [errorOpen, setErrorOpen] = useState(false);
    const [error, setError] = useState({title: "test", e: ""});
    const [renderDummy, setRenderDummy] = useState(false)

    let {
        path,
        pathArr,
        openMultipleFiles,
    } = props;

    const { readString } = usePapaParse();
    const QueryContext = useContext(QuerybarContext);

    useEffect(() => {
        setRenderDummy(!renderDummy)
    }, [])

    useEffect(() => {
        setRenderDummy(!renderDummy)
    }, [openMultipleFiles])

    useEffect(async () => {
        QueryContext.setQuerybarActive(true)
        // Check if multiple files were selected, or just one
        if(countSelectedFiles() > 0){
            // if multiple files were selected
            console.log("Treating render as a multiple files")
            let newDataArr = [];
            let listOfNewFilesnames = [];
            let r = await Promise.all(pathArr.map(async (dir, dirIndex) => {
                return await Promise.all(dir.files.map(async (file, fileIndex) => {
                    return await INTERFACE_downloadFile(dir.path+file.name).then(res => {
                        listOfNewFilesnames.push(getFileExtension(res?.config?.params?.path)) // save the file names
                        var enc = new TextDecoder("utf-8"); 
                        var arr = new Uint8Array(res.data);
                        let strCSV = enc.decode(arr); // convert ArrayBuffer to string
                        readString(strCSV, {
                            worker: true,
                            complete: (results) => {
                                newDataArr.push(results)
                            },
                          });
                        return 0
                    }).catch(e => {
                        console.log("failed loading from pathArr")
                        setError({ title: "Error downloading files from server", e: e});
                        setErrorOpen(true)
                        QueryContext.setQuerybarActive(false)
                    })
                }))
            }))
            QueryContext.setQuerybarActive(false)
            setFileNames(listOfNewFilesnames) // save to state
            setLogData(newDataArr) // save to state
            setFileIndex(0) // save to state
        } else {
            // if just one file was selected
            console.log("Treating render as a single file")
            await INTERFACE_downloadFile(path).then(res => {
                let fileName = res?.config?.params?.path // save the file name
                setFileNames([getFileExtension(fileName)])
                var enc = new TextDecoder("utf-8");
                var arr = new Uint8Array(res.data);
                let strCSV = enc.decode(arr); // convert ArrayBuffer to string
                readString(strCSV, {
                    worker: true,
                    complete: (results) => {
                      setLogData([results]) // save to state
                      QueryContext.setQuerybarActive(false)
                    },
                  });
            }).catch(e => {
                console.log("error downloading file")
                console.log(e)
                setError({ title: "Error downloading file from server", e: e});
                setErrorOpen(true)
                QueryContext.setQuerybarActive(false)
            })
        }
    },[renderDummy])

    useEffect(() => {
        console.log("logData changed")

        if(logData[fileIndex]?.data?.length > 0){
            let lines = [];
            let cols = [{field: "id", headerName: "#", maxWidth: 70}];
            let maxCols = 1;

            // Create ROWS
            logData[fileIndex].data.forEach((line, lineIndex) => {
                // check the number of columns in the row
                if(line.length > maxCols){
                    maxCols = line.length;
                }

                // Create each row
                let lineObject = {id: lineIndex};
                line.forEach((lData, ci) => {
                    lineObject[headerRow ? logData[fileIndex].data[0][ci] : ci] = lData
                })
                lines.push(lineObject)
            })

            // Create COLUMNS
            for(let i = 0; i<maxCols;i++){
                cols.push({field: headerRow ? logData[fileIndex].data[0][i] : i, flex: i === 5 ? 1 : 0})
                // cols.push({field: i, resizable: true, flex: 1, maxWidth: i === 0 ? 100 : i === 1 ? 120 : i === 2 ? 50 : i === 3 ? 50 : i === 4 ? 50 : ""})
            }

            // Set state
            setRows(lines)
            setColumns(cols)
        }
        

    }, [JSON.stringify(logData), headerRow, fileIndex])


    function countSelectedFiles(){
        let count = 0;
        pathArr.forEach((v, i) => {
            if(v.files.length !== undefined){
                count = count + v.files.length
            } else {
                count = count + 0;
            }
        })
        return count
    }

    function getFileExtension(filepath){
        // https://stackoverflow.com/questions/680929/how-to-extract-extension-from-filename-string-in-javascript
        var filename = filepath.replace(/^.*[\\\/]/, '')
        return filename
    }


  return (
    <React.Fragment>
            <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
            <Box>
                <CSVSettingsToolbar 
                    headerRow={headerRow} 
                    setHeaderRow={setHeaderRow}
                />
                <Box 
                    sx={{ 
                        height: "calc(100vh - 220px)", 
                        width: 'calc(100% + 1rem)' 
                    }}
                >
                    <FileTabPanel 
                        fileIndex={fileIndex} 
                        fileNames={fileNames}
                        setFileIndex={setFileIndex}
                    />
                    <DataGrid
                        rowHeight={30}
                        rows={rows}
                        columns={columns}
                        key={"datagrid"+fileIndex}
                    />
                </Box>
            </Box>
    </React.Fragment>

  );
}