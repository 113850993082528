import React, {useState, useEffect} from 'react';

// OWN
import CSVSettingMenu from "./csvSettingsMenu"
import ToolbarFile from "../../toolbars/ToolbarFile"

// MUI V5
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';


export default function CSVSettingsToolbar(props) {
  let {
    headerRow,
    setHeaderRow,
  } = props;

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));


  return (
    <MuiAppBar 
        style={{
            position: "-webkit-sticky",
            position: "sticky", // eslint-disable-line
            top: largeScreen ? theme.spacing(8) : theme.spacing(7),
            marginTop: "-78px"
        }} 
        elevation={0} 
        sx={{background: "transparent"}}
    >    
        <Toolbar variant="dense">
            {/* <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                test
            </Typography> */}
            <div style={{flexGrow: 1}}></div>
            <CSVSettingMenu headerRow={headerRow} setHeaderRow={setHeaderRow}/>
            <ToolbarFile /> 
        </Toolbar>
    </MuiAppBar>
  );
}