import React, {useState, useEffect} from 'react'
import SohPanelTableRow from "./SohPanelTableRow";

// MUI imports
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';



// Icons
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function GroupRow(props){
    const [submenuOpen, setSubmenuOpen] = useState(false);

    let {
        item,
        toggleSwitch,
        setToggleSwitch,
    } = props;

    return (
    <React.Fragment>
        <TableRow onClick={() => setSubmenuOpen(!submenuOpen)} sx={{ cursor: "pointer", '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: item.status === 1 ? "warning.main" : item.status === 2 ? "error.main" : "" }} key="header">
            <TableCell style={{width: "30px", color: "white"}}>{item.status === 0 ? <CheckCircleIcon style={{color: "white"}}/> : item.status === 1 ? <WarningIcon style={{color: "white"}}/> : item.status === 2 ? <ErrorIcon style={{color: "white"}}/> : "?"}</TableCell>
            <TableCell style={{maxWidth: "200px", color: "white"}}>{item.str}</TableCell>
            <TableCell style={{maxWidth: "200px", color: "white"}}>{item.dsc}</TableCell>
            <TableCell></TableCell>
        </TableRow>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: item.status === 1 ? "warning.light" : item.status === 2 ? "error.light" : "primary.light"}} key="content">
            <TableCell style={{color: "white", paddingBottom: 0, paddingTop: 0}} colSpan={4}>
                <Collapse in={submenuOpen} unmountOnExit>
                    <Table size="small">
                        <TableBody>
                            {
                                (item.details !== undefined && item.details !== null) ? // check that details is not empty
                                        Object.entries(item.details).map(([key, value]) => (
                                            (Array.isArray(value)) ? 
                                            <SohPanelTableRow keyName={key} key={"tablerow1"+key} value={value} color={item.status === 1 ? "warning.light" : item.status === 2 ? "error.light" : "primary.light"}/>
                                            : <TableRow sx={{'&:last-child td, &:last-child th': { border: 0 }}} key={"tablerow2"+key}>
                                                <TableCell style={{color: "white"}}>
                                                    {key}
                                                </TableCell>
                                                <TableCell style={{textColor: "white"}}>
                                                    <Chip label={value} sx={{color: "white"}}/>
                                                </TableCell>
                                                <TableCell sx={{width: "100%"}}></TableCell>
                                            </TableRow>
                                        ))
                                    : <React.Fragment></React.Fragment>
                            }
                            {/* <SohPanelActionRow item={item} toggleSwitch={toggleSwitch} setToggleSwitch={setToggleSwitch}/> */}
                        </TableBody>
                    </Table>
                </Collapse>
            </TableCell>
        </TableRow> 
    </React.Fragment>
    )
}

export default function SohPanel(props){

    const [open, setOpen] = useState(false);
    
    let {
        soh,
        toggleSwitch,
        setToggleSwitch,
    } = props;

    // useEffect(() => {
    //     if(soh.errors !== undefined && soh.warnings !== undefined && soh.errors > 0 || soh.warnings > 0){
    //         setOpen(true)
    //     } 
    // }, [soh.errors, soh.warnings])

    return (
        <Card sx={{ bgcolor: 'primary.main', marginBottom: "30px" }}>
        <CardContent>
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <HealthAndSafetyIcon style={{color: "white"}}/>
            <span>
              <Typography variant="overline" noWrap component="div" sx={{ color: 'primary.contrastText', marginLeft: "10px" }}>
                State of Health
              </Typography>
            </span>
            {/* <IconButton sx={{marginLeft: 'auto'}} onClick={() => setOpen(!open)}>
                { open ? <ExpandLessIcon style={{ color: 'white' }}/> : <ExpandMoreIcon style={{ color: 'white' }}/>} 
            </IconButton> */}
          </div>  
        </CardContent>
        <Collapse in={true} unmountOnExit>
            <Grid container>
                <Table size="small">
                    <TableBody>
                        {
                            (soh.soh !== undefined) ? 
                            soh.soh.map((item, index) => (
                                <GroupRow key={index} item={item} toggleSwitch={toggleSwitch} setToggleSwitch={setToggleSwitch}/>
                            ))
                            : <></>
                        }
                    </TableBody>
                </Table>
            </Grid>
        </Collapse>
      </Card>
    )
}