import React from 'react';

// MUI V5
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge';

// ICONS
import CategoryIcon from '@mui/icons-material/Category';

const MultipleFileTypes = (props) => {

    return (
    <Box textAlign="center" sx={{ marginTop: "100px", marginBottom: "100px" }}>
        <React.Fragment>
                <CategoryIcon sx={{ fontSize: 80, color: "#cfd8dc"}}/>
            <Typography variant="body1" sx={{color: "#cfd8dc"}}>Can't open multiple file types at the same time</Typography>
        </React.Fragment>
    </Box>
    );
};

export default MultipleFileTypes;