import React from 'react';

const SvgComponent = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="220 220 500 500"
    {...props}
  >
{/* <rect x="220" y="220" class="st0" width="560" height="560"/> */}
<path d="M700,500c0,2.3-0.08,4.59-0.15,6.89C696.25,614.12,608.15,700,500,700c-83.43,0-154.84-51.05-184.85-123.61
	c-3.75-8.96-6.81-18.22-9.18-27.78l82.89,0.08h222.27v41.64H388.86v-13.93h-43.25c28.09,56.79,86.64,95.83,154.38,95.83
	c92.84,0,168.47-73.4,172.06-165.33h-60.93v13.93H301.15c-0.77-6.81-1.15-13.78-1.15-20.82c0-2.3,0.08-4.67,0.15-6.97
	C303.75,385.8,391.85,300,500,300c83.35,0,154.84,51.05,184.85,123.54c3.67,8.96,6.81,18.22,9.18,27.78H388.86v-41.64h222.27v13.85
	h43.17c-28.09-56.72-86.64-95.75-154.31-95.75c-92.84,0-168.39,73.33-172.06,165.25h60.93V479.1h309.99
	C699.62,485.92,700,492.96,700,500z"/>
</svg>
)

export default SvgComponent
