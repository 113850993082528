import React from "react";

// OWN
import {strIsDefined} from "../../../../helpers.js"

// MUI V5
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

// ICONS
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function ToolbarChannels(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  let {disableMenuButton} = props;

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{marginLeft: "10px"}}>
      <Tooltip title="Select Channels">
        <span>
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            disabled={disableMenuButton}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Channels
          </Button>
        </span>
      </Tooltip>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <ChannelsMenuItems {...props}/>
        </StyledMenu>
      
    </div>
  );
}

export function ChannelsMenuItems(props) {

    let { 
      data,
      visibleChannels,
      setVisibleChannels,
      // disableMenuButton,
    } = props;

    function toggleChannelVisibility(file, i){
        let tempStateCopy = [...visibleChannels];
        tempStateCopy[file][i] = !visibleChannels[file][i]
        setVisibleChannels(tempStateCopy)
    }

    function enableAllChannels(){
        let tempStateCopy = [...visibleChannels];
        visibleChannels.forEach((file,fileIndex) => {
          file.forEach((channel, channelIndex) => {
            tempStateCopy[fileIndex][channelIndex] = true;
          })
            
        })
        setVisibleChannels(tempStateCopy)
    }

  return (
    <>
    {
        data.map((file,fileIndex) => {
          return file.map((channel, channelIndex) => {
            return(
              <>
              { channelIndex === 0 ?
                <Tooltip title={strIsDefined(file[0].fileName)} placement="right" key={"filenametooltip"+fileIndex+channelIndex}>
                  <Divider textAlign="center" key={"filenamedivider"+fileIndex+channelIndex}>
                    <Typography variant="caption" sx={{color: "text.secondary"}} key={"filename"+fileIndex+channelIndex}>
                      {strIsDefined(file[0].fileName).slice(0,28)+"..."}
                    </Typography>
                  </Divider>
                  </Tooltip>
                : <></>
              }
              <MenuItem key={"channelmenuitem"+fileIndex + channelIndex}>
              <FormGroup key={"formgroup"+channelIndex}>
                <FormControlLabel 
                  key={"CHID"+channelIndex}
                  control={<Switch key={"switch"+channelIndex} checked={visibleChannels[fileIndex][channelIndex] ? visibleChannels[fileIndex][channelIndex] : false} onChange={() => {toggleChannelVisibility(fileIndex, channelIndex)}}/>} 
                  label={data[fileIndex][channelIndex].channelCode} 
                />
              </FormGroup>
            </MenuItem>
            </>
            )
          })
        })
    }
    <Divider key={"showallchannelsdivider"}/>
    <MenuItem key="showallchannels" onClick={enableAllChannels}>
        Show All Channels
    </MenuItem>
    
    {/* Enabled below to debug */}
    {/* <MenuItem>
      {JSON.stringify(visibleChannels)}
    </MenuItem> */}
    </>
  );
}