import React from 'react';

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 599.99 128.69"
    {...props}
  >
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path d="m161 88.75 3.34-7C169.06 86 177.1 89 185.23 89c10.93 0 15.63-4.25 15.63-9.76 0-15.45-38.31-5.69-38.31-29.19 0-9.76 7.59-18.07 24.22-18.07 7.41 0 15.09 2 20.33 5.6l-3 7.23a32.7 32.7 0 0 0-17.35-5.15c-10.75 0-15.36 4.52-15.36 10 0 15.45 38.31 5.78 38.31 29 0 9.67-7.77 18-24.49 18-9.65.04-19.13-3.21-24.21-7.91ZM270.28 88.12V96h-45.9V32.73H269v7.86h-35.6V60h31.72v7.7h-31.7v20.42ZM284.91 32.72h9V96h-9ZM308.58 88.75l3.34-7C316.62 86 324.66 89 332.79 89c10.93 0 15.63-4.25 15.63-9.76 0-15.45-38.31-5.69-38.31-29.19 0-9.76 7.59-18.07 24.22-18.07 7.41 0 15.09 2 20.33 5.6l-3 7.23a32.7 32.7 0 0 0-17.35-5.15c-10.75 0-15.36 4.52-15.36 10 0 15.45 38.31 5.78 38.31 29 0 9.67-7.77 18-24.49 18-9.64.04-19.13-3.21-24.19-7.91ZM364.61 64.35c0-18.52 14.28-32.35 33.71-32.35 19.25 0 33.53 13.74 33.53 32.35S417.57 96.7 398.32 96.7c-19.44 0-33.71-13.83-33.71-32.35Zm58.19 0c0-14-10.48-24.31-24.49-24.31-14.19 0-24.67 10.3-24.67 24.31s10.48 24.31 24.67 24.31c14.01 0 24.49-10.3 24.49-24.31ZM446.47 32.72h26.66c20.33 0 34.16 12.83 34.16 31.63S493.46 96 473.13 96h-26.66Zm26.12 55.4c15.63 0 25.66-9.58 25.66-23.77s-10-23.77-25.66-23.77h-17.08v47.53h17.08ZM521.92 32.72h9V96h-9ZM600 32.72V96h-7.41l-38-47.17V96h-9V32.72H553l38 47.17V32.72ZM128.67 64.35v2.22A64.33 64.33 0 0 1 4.87 88.92 60.38 60.38 0 0 1 1.92 80h98.17v13.39h-71.5v-4.48H14.68a55.4 55.4 0 0 0 105-22.35h-19.6V71H.37A59.13 59.13 0 0 1 0 64.34c0-.74 0-1.5.05-2.24a64.33 64.33 0 0 1 126.7-13.42H28.58V35.29h71.5v4.46H114A55.4 55.4 0 0 0 9 62.11h19.6v-4.48h99.7a61.91 61.91 0 0 1 .37 6.72Z" />
      </g>
    </g>
  </svg>
)

export default SvgComponent
