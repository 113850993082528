import React, {useContext} from 'react';
import { AuthContext } from "../../../../context/auth-context"
import { SeismicDataContext } from "../../../../context/seismicDataContext";
import { Transition } from "../../../styles/SlideTransition"
import {source} from "../../../api/calls.js"

// MUI V5
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';


// ICONS
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';

export default function DeleteDialog(props) {

    let {
        openDialog, 
        setOpenDialog,
        setConfirmFileDelete,
        setConfirmMultipleFileDelete,
        selectedFilesCount,
        confirmMultipleFileDelete,
        remainingFilesToDelete,
    } = props;

    const SeisDataContext = React.useContext(SeismicDataContext);
    const authcontext   = useContext(AuthContext);

    function handleClose(){
        setConfirmMultipleFileDelete(false)
        setOpenDialog(false)
        // if (source) {
        //     source.cancel("Cancelled requests");
        // }
    }

    function handleDelete(){
        if(selectedFilesCount > 0){
            // actual delete operation is handled by a useEffect that listens to confirmMultipleFileDelete
            setConfirmMultipleFileDelete(true)
        } else {
            // actual delete operation is handled by a useEffect that listens to confirmFileDelete
            setConfirmFileDelete(true)
        }
    }
    

    return (
        <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        >
            <Box sx={{width: "100%", backgroundColor: "primary.light", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Stack>
                    <DeleteOutlineIcon style={{fontSize: 80}} sx={{ color: "primary.contrastText", marginTop: "20px", marginLeft: "auto", marginRight: "auto" }} />
                    <DialogTitle id="alert-dialog-title" sx={{color: "primary.contrastText"}}> Delete {selectedFilesCount} File{selectedFilesCount > 1 ? "s" : ""} </DialogTitle>
                </Stack>
            </Box>
            <DialogContent>

                {
                    confirmMultipleFileDelete ?
                    <div>
                        <LinearProgress variant="determinate" value={((selectedFilesCount-remainingFilesToDelete)/selectedFilesCount)*100}/>
                        <DialogContentText id="alert-dialog-slide-description" sx={{textAlign: "center", marginBottom: "20px"}}>
                            <Typography>
                                {remainingFilesToDelete} files remaining
                            </Typography>
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-slide-description" sx={{textAlign: "center", marginBottom: "20px"}}>
                            <Typography>
                                Now deleting files. Don't leave the page.
                            </Typography>
                        </DialogContentText>
                    </div>
                    : <div>
                    <DialogContentText id="alert-dialog-slide-description" sx={{textAlign: "center"}}>
                        Are you sure you want to delete {selectedFilesCount > 1 ? "these files" : "this file"} ?
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" sx={{textAlign: "center", marginBottom: "20px"}}>
                        <Typography variant="caption">
                            Deleted files cannot be restored
                        </Typography>
                    </DialogContentText>
                    {
                        selectedFilesCount > 1 ? 
                        <>
                        {
                            SeisDataContext.pathArr.map((dir, dirIndex) => (
                                <Stack key={dirIndex} spacing={1} direction="column" sx={{marginBottom: "8px"}}>
                                {
                                    dir.files.map((file, fileIndex) => (
                                        <Chip key={dirIndex+fileIndex} icon={<DescriptionIcon />} label={file.name}/>
                                    ))
                                }
                                </Stack>
                            ))
                        }
                        </>
                        : <Stack key="singleChipStack" spacing={1}>
                            <Chip key="singleFileChip" icon={<DescriptionIcon />} label={SeisDataContext.path.replace(/^.*\/(.*)$/, "$1")}/>
                        </Stack> 
                    }
                </div>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" >
                    Cancel
                </Button>
                <Button onClick={handleDelete} color="primary" variant="contained" startIcon={<DeleteIcon />} disabled={confirmMultipleFileDelete || authcontext.state.user.permissions.removeFiles === false}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
  }

