import React, {useState, useEffect} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


export default function FileTabPanel(props) {
  let {
    fileIndex, 
    setFileIndex,
    fileNames,
  } = props;

  const handleChange = (event, newValue) => {
    setFileIndex(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>    
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={fileIndex} onChange={handleChange} variant="scrollable">
            {
                fileNames.map((file, fileIndex) => (
                    <Tab label={file} id={`simple-tab-${fileIndex}`}/>
                ))
            }
        </Tabs>
      </Box>
    </Box>
  );
}