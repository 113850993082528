import React, { useEffect } from "react"
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Select from '@mui/material/Select';

export default function FFTDialog(props) {
  let { 
    shouldDialogOpen,
    setDialogOpen,
    fft, 
    setFft,
  } = props;


useEffect(() => {
    if(shouldDialogOpen){
        setDialogOpen(true)
    } else {
        setDialogOpen(false)
    }
}, [shouldDialogOpen])

function handleDialogClose(){
  setDialogOpen(false);
}

function handleApplyFilter(){
  setFft(prevState => ({
    ...prevState,
    firstRender: true,
    stateFlipper: !fft.stateFlipper,
  }))
  setDialogOpen(false);
}

function handleFftSettingsWindow(e){
  setFft(prevState => ({
    ...prevState,
    window: e.target.value,
  }))
}

function handleFftSettingsWindowlength(e){
  setFft(prevState => ({
    ...prevState,
    windowlength: e.target.value,
  }))
}





  return (
      <Dialog open={shouldDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>FFT Configuration</DialogTitle>
        <DialogContent>
        <FormControl fullWidth margin="normal">
            <Select
              key="fftwindowfunctionselector"
              value={fft.window}
              onChange={handleFftSettingsWindow}
            >
              <MenuItem value={"HANNING"}>Hanning</MenuItem>
              <MenuItem value={"HAMMING"}>Hamming</MenuItem>
            </Select>
          </FormControl>
          <TextField
            onChange={handleFftSettingsWindowlength}
            key="Window Width"
            margin="normal"
            label="Window Width (fraction of record)"
            type="number"
            fullWidth
            variant="outlined"
            inputProps={{
              min: "0", 
              max: "0.5",
              defaultValue: fft.windowlength,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleApplyFilter} variant="contained">Perform FFT</Button>
        </DialogActions>
      </Dialog>
  );
}