import React, { useState } from "react"

// OWN
import FilterDialog from "./dialogs/filterDialog.js"
import FFTDialog from "./dialogs/fftDialog.js"

// MUI V5
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

// ICONS
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function ToolbarAnalyze(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  let { disableMenuButton } = props;

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div style={{marginLeft: "10px"}}>
      <Tooltip title="Functions to analyze data">
        <span>
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            disabled={disableMenuButton}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Analyze
          </Button>
        </span>
      </Tooltip>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <AnalyzeMenuItems {...props}/>
      </StyledMenu>
      
    </div>
  );
}

export function AnalyzeMenuItems(props) {
  const [filterDialogOpen, setFilterDialogOpen] = useState(false)
  const [fftDialogOpen, setFftDialogOpen] = useState(false)
  let { 
    removeOffset, 
    setRemoveOffset,
    recallOriginalData,
    setRecallOriginalData, 
    filter, 
    setFilter,
    fft, 
    setFft,
    // disableMenuButton,
  } = props;

  function handleRemoveOffset(){
      setRemoveOffset(!removeOffset)
  }

  function handleRecallOriginalData(){
    setRecallOriginalData(!recallOriginalData)
  }

  function handleDialogOpen(filtertype){
    setFilter(prevState => ({
      ...prevState,
      type: filtertype,
    }))
    setFilterDialogOpen(true);
  }

  function handleFftDialogOpen(){
    setFftDialogOpen(true);
  }

  function handleFftLogscaleY(){
    setFft(prevState => ({
      ...prevState,
      logscaleY: !fft.logscaleY
    }))
  }

  function handleFftLogscaleX(){
    setFft(prevState => ({
      ...prevState,
      logscaleX: !fft.logscaleX
    }))
  }

  function handleFftLive(){
    setFft(prevState => ({
      ...prevState,
      stateFlipper: !fft.stateFlipper,
      live: !fft.live
    }))
  }

  function handleRemoveFft(){
    setFft(prevState => ({
      ...prevState,
      firstRender: false,
      stateFlipper: !fft.stateFlipper,
    }))
  }


  return (
    <>
        <Divider sx={{ my: 0.5 }}>Utils</Divider>
        <MenuItem onClick={handleRemoveOffset}>
            Remove Offset
        </MenuItem>
        <MenuItem onClick={handleRecallOriginalData}>
            Recall Original Data
        </MenuItem>
        <Divider sx={{ my: 0.5 }}>Filters</Divider>
        <MenuItem onClick={() => handleDialogOpen("lp")}>
            Low Pass
        </MenuItem>
        <MenuItem onClick={() => handleDialogOpen("hp")}>
            High Pass
        </MenuItem>
        <MenuItem onClick={() => handleDialogOpen("bp")}>
            Band Pass
        </MenuItem>
        <Divider sx={{ my: 0.5 }}>FFT</Divider>
        {
          fft.firstRender ?
          <MenuItem onClick={handleFftDialogOpen}>
            {/* <EditIcon fontSize="large"/> */}
            Edit FFT
            </MenuItem>
          : <MenuItem onClick={handleFftDialogOpen}>
            Perform FFT
            </MenuItem>
        }
        {
          fft.firstRender ? 
          <MenuItem onClick={handleRemoveFft}>
            {/* <DeleteIcon fontSize="large"/> */}
            Remove FFT
          </MenuItem>
          :""
        }
        <MenuItem dense={true} disabled={props.printMode}>
          <FormGroup>
            <FormControlLabel 
              key="LOGY"
              control={<Switch checked={fft.logscaleY} onChange={handleFftLogscaleY} />} 
              label="LOG Y" 
            />
          </FormGroup>
        </MenuItem>
        <MenuItem dense={true} disabled={props.printMode}>
          <FormGroup>
            <FormControlLabel 
              key="LOGX"
              control={<Switch checked={fft.logscaleX} onChange={handleFftLogscaleX} />} 
              label="LOG X" 
            />
          </FormGroup>
        </MenuItem>
        <MenuItem dense={true} disabled={props.printMode}>
          <FormGroup>
            <FormControlLabel 
              key="LiveFft"
              control={<Switch checked={fft.live} onChange={handleFftLive} />} 
              label="Live FFT" 
            />
          </FormGroup>
        </MenuItem>

      <FilterDialog 
        shouldDialogOpen={filterDialogOpen}
        setDialogOpen={setFilterDialogOpen}
        filter={filter}
        setFilter={setFilter}
      />
      
      <FFTDialog 
        shouldDialogOpen={fftDialogOpen}
        setDialogOpen={setFftDialogOpen}
        fft={fft}
        setFft={setFft}
      />
    </>
  );
}

