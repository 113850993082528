import React, {useContext, useState, useEffect, useRef} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Dygraph from "dygraphs";
import TimeAgo from 'timeago-react'; // var TimeAgo = require('timeago-react');
import * as timeago from 'timeago.js';

import "./dygraph.css";

// OWN 
import { backendApi, clientConfigApplication } from "../../../api/calls"
import { Transition } from "../../../styles/SlideTransition"
import "../../../styles/checkmarkStyles.css"
import SvgInstrumentComponent from "../../../../graphics/instrument"
import ErrorDialog from "../../../layout/ErrorDialog.js"
import { instrumentListContext } from "../index"
import SohPanel from "./SohPanel.js"

// MUI V5
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

// ICONS
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';


export default function DialogSOH(props) {
    // const [open, setOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [error, setError] = useState({title: "test", e: ""});
    const [selectedInstrument, setSelectedInstrument] = useState(null);
    const [timeSeriesData, setTimeseriesData] = useState([]);

    let {open, setOpen} = props;
    let {instruments, serialnumber} = props;

    let instrumentsContext = useContext(instrumentListContext);

    useEffect(() => {
        let timeSeries = [];
        if(selectedInstrument?.hbtHist !== undefined && selectedInstrument?.hbtHist !== null){
            selectedInstrument?.hbtHist.forEach((d => {
                timeSeries.push({x: new Date(d.time*1000), y: d.err})
            }))
        }

        console.log(timeSeries)
        setTimeseriesData(timeSeries)
    
    }, [selectedInstrument])

    useEffect(() => {
        let foundInstrument = instruments.find(matchSerialNumber)
        setSelectedInstrument(foundInstrument)
    }, [instruments, serialnumber])

    function handleDialogClose() {
        setOpen(false);
    }

    function matchSerialNumber(instrument) {
        return instrument.serialNumber === serialnumber;
    }




    return (
        <React.Fragment>
            <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
            <Dialog 
                open={open} 
                TransitionComponent={Transition} 
                keepMounted 
                onClose={handleDialogClose} 
                fullWidth
                PaperProps={{
                    sx: {
                    //   width: "50%",
                      height: "80vh"
                    }
                  }}
            >
                <Box sx={{width: "100%", backgroundColor: "primary.main", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Stack>
                        <DialogTitle id="alert-dialog-title" sx={{color: "primary.contrastText"}}> Health Report for {serialnumber}  </DialogTitle>
                    </Stack>
                </Box>
                
                <DialogContent>
                {
                    selectedInstrument?.soh !== undefined && selectedInstrument?.soh !== null && selectedInstrument?.soh.length >= 1?
                    <>
                        <SohPanel soh={JSON.parse(selectedInstrument.soh)} toggleSwitch={true} setToggleSwitch={() => {}}/>
                        {
                            selectedInstrument?.sohTimeStamp !== undefined && selectedInstrument?.sohTimeStamp !== null ?
                            <Typography variant="caption">
                                Report generated: <TimeAgo datetime={parseInt(selectedInstrument?.sohTimeStamp)*1000} locale='en_short'/>
                                <br/>
                                {new Date(selectedInstrument?.sohTimeStamp*1000).toISOString("en-US")} 
                                <br/>
                                Next expected heartbeat: <TimeAgo datetime={parseInt(selectedInstrument?.nextHbt)*1000} locale='en_short'/> 
                                <br />
                                {new Date(selectedInstrument?.nextHbt*1000).toISOString("en-US") ?? "unknown"} 
                            </Typography>
                            : <Typography variant="caption">
                                Report generated: unknown
                            </Typography>
                        }

                    </>
                    : <div>
                        <Stack orientation="vertical">
                            <Chip label="NO HEALTH REPORT AVAILABLE" color="primary"></Chip>
                            <Typography variant="caption">There is no state-of-health data to display. Please enable state-of-health report generation in the instrument's configuration, and configure it to upload the report to the cloud.</Typography>
                        </Stack>
                    </div>                
                }
                <div style={{marginTop: "12px"}}>
                    <Typography variant="subtitle">SOH History</Typography>
                    <HbtPlot values={selectedInstrument?.hbtHist}/>
                </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>

            </Dialog>
        </React.Fragment>
    )
}

function HbtPlot(props) {
    const [timeSeriesData, setTimeseriesData] = useState([]);
    let {values} = props;
    const graphEl = useRef(null);

    useEffect(() => {
        let timeSeries = [];
        if(values !== undefined && values !== null){
            values.forEach((d => {
                timeSeries.push([new Date(d.time*1000), d.err])
            }))
            timeSeries.push([new Date(Date.now()),3])
        }

        console.log(timeSeries)
        setTimeseriesData(timeSeries)
    
    }, [values])

    useEffect(() => {


        new Dygraph(
            graphEl.current,
            timeSeriesData,
            {
                axes: {
                    x: {
                    drawGrid: false,
                    drawAxis: true,
                    axisLineColor: "white",
                    axisLineWidth: 1.5,
                    gridLineColor: "#d1d1d1",
                    // gridLinePattern: [5, 5],
                    },
                    y: {
                    drawGrid: false,
                    drawAxis: false,
                    }
                },
                drawPoints: true,
                strokeWidth: 0,
                pointSize: 5,
                labelsUTC: true,
                connectSeparatedPoints: false,
                height: "100px",
                valueRange: [-1, 4],
                // plotter: barChartPlotter,
                drawPointCallback: coloredCircle,
            }
            );
    },[timeSeriesData]);

    function coloredCircle(g, series, ctx, cx, cy, color, radius, idx) {
        var y = g.getValue(idx, 1);
        var pointColor = y < 1 ? 'green' : y === 1 ? 'orange' : y === 2 ? 'red' : 'blue';
        ctx.save();
        ctx.fillStyle = pointColor;
        ctx.beginPath();
        ctx.arc(cx, cy, radius, 2 * Math.PI, false);
        ctx.closePath();
        ctx.fill();
      }

    return (
      <div>
        <div ref={graphEl} style={{height: "50px", width: "100%"}}/>
      </div>
    );
  }
  