export function tsToRMS(ts){

    let sumOfSquares = 0;

    ts.forEach((v,i) => {
        sumOfSquares = sumOfSquares + Math.pow(v, 2)
    })

    return Math.sqrt(sumOfSquares/ts.length)
}

export function minMaxOfTs(ts, startTime, sampleRate){
    const originalLength = ts.length;
    let len = ts.length;
    let max = -Infinity;
    let min = Infinity;
    let xMax = 0;
    let xMin = 0;

    var sTime = new Date(startTime);
    var sTimeMax = new Date(startTime);
    var sTimeMin = new Date(startTime);

    while (len--) {
        max = ts[len] > max ? ts[len] : max;
        min = ts[len] < min ? ts[len] : min;
        xMax = max === ts[len] ? len : xMax;
        xMin = min === ts[len] ? len : xMin;
    }

    
    sTimeMax.setMilliseconds(sTimeMax.getMilliseconds() + ((1/sampleRate*1000)*xMax));
    sTimeMin.setMilliseconds(sTimeMin.getMilliseconds() + ((1/sampleRate*1000)*xMin));
    
    return {max: max, min: min, xMax: new Date(sTimeMax), xMin: new Date(sTimeMin)};
}

