import * as React from 'react';

// MUI V5
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// ICONS
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

export default function ToolbarGraphSettings(props) {

    let { resetZoom, setResetZoom, disableMenuButton } = props;
  
    function handleZoomReset(){
        setResetZoom(!resetZoom)
    }
  
    return (
      <div style={{marginLeft: "10px"}}>
        <Tooltip title="Reset Zoom">
          {/* <IconButton color="primary" component="label">
              <ZoomInIcon />
          </IconButton>
          <IconButton color="primary" component="label">
              <ZoomOutIcon />
          </IconButton> */}
          <IconButton color="primary" component="label" onClick={handleZoomReset} disabled={disableMenuButton}>
              <ZoomOutMapIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }