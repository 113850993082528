import React, {useState, useEffect} from "react"
import { io } from "socket.io-client";

// OWN
import { backendApi, clientConfigApplication} from "../../api/calls"
import ErrorDialog from "../../layout/ErrorDialog"
import WidgetUserQuota from "./widgets/userQuota"
import WidgetInstrumentQuota from "./widgets/instrumentQuota"
import WidgetStorageQuota from "./widgets/storageQuota"
import WidgetConnectionStatus from "./widgets/connectionStatus"
import WidgetInstrumentsStatus from "./widgets/instrumentsStatus";
import WidgetInstrumentTerminal from "./widgets/instrumentTerminal";
import WidgetInstrumentList from "./widgets/instrumentsList";
import WidgetLatestFilesList from "./widgets/latestFilesList";
import WidgetLatestEventFile from "./widgets/latestEventfile";
import WidgetLatestEventFileSimple from "./widgets/latestEventfileSimple";
import WidgetLatestEventFileWithStats from "./widgets/latestEventfileWithStats";
import WidgetEventCalendar from "./widgets/eventCalendar";

// MUI V5
import Box from '@mui/material/Box'
import Grid from "@mui/material/Grid"

export default function DashboardGrid(props) {

    let {
        errorOpen,
        setErrorOpen,
        error,
        subscriptionData,
        loadingSubscriptionData,
        socketIsConnected,
        socketioSession,
        socketLoading,
        instruments,
        instrumentTerminal,
        loadingInstrumentList,
        latestFiles,
        loadingLatestFilesList,
        lastEvent,
        loadingLastEventFile,
        latestEvtFiles,
        latestRbfFiles,
        latestLogFiles,
        latestHbtFiles,
    } = props;

    return (
    <Box sx={{ flexGrow: 1 }}>
        <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
        <Grid container spacing={2} sx={{marginLeft: "0.25rem"}} alignItems="flex-start">
            {/* LEFT COL */}
            <Grid container xs={12} lg={6} spacing={2} sx={{marginRight: "1rem"}}>
                <Grid item xs={12} lg={6} >
                    <WidgetConnectionStatus 
                        connectionStatus={socketIsConnected} 
                        socketId={socketioSession}
                        loading={socketLoading}
                    />
                </Grid>
                <Grid item xs={12} lg={6} >
                    <WidgetStorageQuota 
                        storageCount={Number(subscriptionData?.settings?.usedStorage?.shareUsageBytes)/1000000000 ?? 0} 
                        storageQuota={Number(subscriptionData?.settings?.settings?.storage?.quota) ?? 0}
                        loading={loadingSubscriptionData}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <WidgetUserQuota 
                        userCount={subscriptionData?.settings?.userCount} 
                        userQuota={subscriptionData?.settings?.settings?.maxUsers}
                        loading={loadingSubscriptionData}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <WidgetInstrumentQuota 
                        instrumentCount={subscriptionData?.settings?.instrumentCount} 
                        instrumentQuota={subscriptionData?.settings?.settings?.maxInstruments}
                        loading={loadingSubscriptionData}
                    />
                </Grid>
                <Grid item xs={12} >
                    <WidgetInstrumentTerminal 
                        messages={instrumentTerminal}
                        socketIsConnected={socketIsConnected} 
                    />
                </Grid>
            </Grid>
            {/* RIGHT COL */}
            <Grid container xs={12} lg={6} spacing={2}>
                <Grid item xs={12} lg={12}>
                    <WidgetLatestEventFileWithStats 
                        lastEvent={lastEvent}
                        loadingLastEventFile={loadingLastEventFile}
                    />
                </Grid>
                <Grid item xs={12} xl={12}>
                    <WidgetInstrumentsStatus 
                        instruments={instruments} 
                        loadingInstrumentList={loadingInstrumentList}
                    />
                </Grid>
                <Grid item xs={12} xl={6}>
                    <WidgetInstrumentList 
                        instruments={instruments} 
                        loadingInstrumen
                        tList={loadingInstrumentList}
                    />
                </Grid>
                <Grid item xs={12} xl={6}>
                    <WidgetLatestFilesList 
                        latestFiles={latestFiles} 
                        latestEvtFiles={latestEvtFiles}
                        latestRbfFiles={latestRbfFiles}
                        latestLogFiles={latestLogFiles}
                        latestHbtFiles={latestHbtFiles}
                        loadingLatestFilesList={loadingLatestFilesList}
                    />
                </Grid>
                {/* <Grid item xs={12} xl={6}>
                    <WidgetEventCalendar 
                    />
                </Grid> */}
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                <WidgetLatestEventFile 
                    lastEvent={lastEvent}
                    loadingLastEventFile={loadingLastEventFile}
                />
            </Grid> */}
        </Grid>
    </Box>
    )
}