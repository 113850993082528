import React, {useState, useEffect} from "react"
import GridLayout from "react-grid-layout";
import {Responsive, WidthProvider} from 'react-grid-layout';

import "./modelStyles/react-grid-layout.css"
import "./modelStyles/react-resizable.css"

// FORMAT: {"type":"ProtoFrontpage","payload":{"title":"header here","subtitles":"subtitle here"},"config":{//specific to this page},"index":0}

export default function ProtoFrontpage(props){

    return (
            <div style={{marginTop: "30%"}}>
                <center>
                    <ReactGridLayout {...props} />
                </center>
            </div>  
    )
}



const ResponsiveReactGridLayout = WidthProvider(Responsive);

const ReactGridLayout = (props) => {
    const [keyToForceReRender, setKeyToForceReRender] = useState("")

    let {
        page, 
        localCopyOfPageArrayConfig,
        setLocalCopyOfPageArrayConfig,
    } = props;

    let pageType = "ProtoFrontpage";
    let debugColor = "transparent"

    useEffect(() => {
        let tempCopy = {...localCopyOfPageArrayConfig}
        let fixedCopy = handleMissingConfigParameters(tempCopy)
        setLocalCopyOfPageArrayConfig(fixedCopy)
        setKeyToForceReRender(Date.now()) // MEGA HACK TO FORCE RERENDER
    }, [])  // <-- NOT IDEAL SOLUTION. VERY LARGE OBJECT TO COMPARE !!

    useEffect(() => {
        console.log("CHANGE")
        console.log(localCopyOfPageArrayConfig?.[pageType]?.elementLayout[1])
        setKeyToForceReRender(Date.now()) // MEGA HACK TO FORCE RERENDER
    }, [JSON.stringify(localCopyOfPageArrayConfig?.ProtoFrontpage?.elementLayout)])


    const handleModify = (layouts, layout) => {
        let tempCopy = {...localCopyOfPageArrayConfig}
        tempCopy = handleMissingConfigParameters(tempCopy)        
        tempCopy[pageType].elementLayout = layouts;
        setLocalCopyOfPageArrayConfig(tempCopy)
    };

    function handleMissingConfigParameters(obj){
        let tempCopy = {...obj}
        if(tempCopy[pageType] === undefined){
            tempCopy[pageType] = {};
        }
        if(tempCopy[pageType].title === undefined){
            tempCopy[pageType].title = "Title";
        }
        if(tempCopy[pageType].subtitles === undefined){
            tempCopy[pageType].subtitles = "Subtitle";
        }
        if(tempCopy[pageType].author === undefined){
            tempCopy[pageType].author = "Name";
        }
        if(tempCopy[pageType].additionalText === undefined){
            tempCopy[pageType].additionalText = "";
        }
        if(tempCopy[pageType].logo === undefined){
            tempCopy[pageType].logo = "";
        }
        if(tempCopy[pageType].date === undefined){
            tempCopy[pageType].date = new Date(Date.now()).toDateString();
        }
        if(tempCopy[pageType].elementLayout === undefined){
            tempCopy[pageType].elementLayout = [
                { w: 1, h: 3, x: 1, y: 0, minW: 1, minH: 3 }, 
                { w: 1, h: 2, x: 1, y: 1, minW: 1, minH: 2 }, 
                { w: 1, h: 1, x: 1, y: 2, minW: 1, minH: 1 }, 
                { w: 1, h: 1, x: 1, y: 3, minW: 1, minH: 1 }, 
                { w: 1, h: 1, x: 1, y: 4, minW: 1, minH: 1 }, 
                { w: 1, h: 1, x: 1, y: 5, minW: 1, minH: 1 }, 
            ];
        }
        return tempCopy
    }

    

    return (
        <div>
        <ResponsiveReactGridLayout
            onLayoutChange={handleModify}
            verticalCompact={true}
            layout={localCopyOfPageArrayConfig?.ProtoFrontpage?.elementLayout}
            rowHeight={24}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            preventCollision={false}
            cols={{ lg: 1, md: 1, sm: 1, xs: 1, xxs: 1 }}
            key={keyToForceReRender} // MONSTER HACK TO FORCE A RERENDER
        >
            <div key="logo" data-grid={localCopyOfPageArrayConfig?.ProtoFrontpage?.elementLayout[0]} style={{backgroundColor: debugColor}}>
                { localCopyOfPageArrayConfig[pageType]?.logo !== "" ? <img src={localCopyOfPageArrayConfig[pageType]?.logo} style={{marginBottom: "100px", maxWidth: "300px", maxHeight: "300px"}}/> : <React.Fragment></React.Fragment> }
            </div>
            <div key="title" data-grid={localCopyOfPageArrayConfig?.ProtoFrontpage?.elementLayout[1]} style={{backgroundColor: debugColor}}>
                <h1>{localCopyOfPageArrayConfig[pageType]?.title}</h1>
            </div>
            <div key="subtitles" data-grid={localCopyOfPageArrayConfig?.ProtoFrontpage?.elementLayout[2]} style={{backgroundColor: debugColor}}>
                <p>{localCopyOfPageArrayConfig[pageType]?.subtitles}</p>
            </div>
            <div key="author" data-grid={localCopyOfPageArrayConfig?.ProtoFrontpage?.elementLayout[3]} style={{backgroundColor: debugColor}}>
                <p>Prepared by: {localCopyOfPageArrayConfig[pageType]?.author}</p>
            </div>
            <div key="date" data-grid={localCopyOfPageArrayConfig?.ProtoFrontpage?.elementLayout[4]} style={{backgroundColor: debugColor}}>
                <p>Date: {new Date(localCopyOfPageArrayConfig[pageType]?.date).toDateString()}</p>
            </div>
            <div key="other" data-grid={localCopyOfPageArrayConfig?.ProtoFrontpage?.elementLayout[5 ]} style={{backgroundColor: debugColor}}>
                <p>{localCopyOfPageArrayConfig[pageType]?.additionalText}</p>
            </div>
        </ResponsiveReactGridLayout>
        </div>
    );
};



