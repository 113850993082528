import React, {useState, useEffect} from 'react'

// MUI imports
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';



export default function SohPanelTableRow(props){

    let {keyName, value, color} = props;
    
    return (
        <>
            <TableRow sx={{'&:last-child td, &:last-child th': { border: 0 }}}>
                <TableCell style={{color: "white"}} colSpan={3}>
                    <TableContainer component={Paper} sx={{backgroundColor: "rgba(25, 118, 210, 0.5)"}}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {
                                        value[0].map((tableHeader, index) => (
                                            <TableCell style={{color: "white"}} key={index}>{tableHeader}</TableCell>
                                        ))
                                    }
                                    <TableCell sx={{width: "100%"}}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    value.slice(1).map((tableEntries, EntriesIndex) => (
                                        <TableRow sx={{'&:last-child td, &:last-child th': { border: 0 }}} key={EntriesIndex}>
                                        {
                                            tableEntries.map((tableEntry, EntryIndex) => (
                                                <TableCell style={{color: "white"}} key={"EntryIndex"+EntryIndex}><Chip size="small" label={tableEntry} sx={{color: "white", backgroundColor: color}}/></TableCell>
                                            ))
                                        }
                                        <TableCell sx={{width: "100%"}}></TableCell>
                                        </TableRow> 
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TableCell>
                {/* <TableCell sx={{width: "100%"}}></TableCell> */}
            </TableRow>
        </>
    )
}