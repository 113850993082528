export function removeBlockette2000(records){
    let recordsWithoutBlockette2000 = [];
    let blockette2000Records = []
    let blockette2000ByteOffset = [];

    if (records.length > 0){
        records.forEach((record, recordIndex) => {
            let foundBlockette2000InRecord = false;

            record?.header?.blocketteList.forEach((blockette, blocketteIndex) => {
                if(blockette?.type === 2000){
                    foundBlockette2000InRecord = true;
                    blockette2000Records.push(record)
                    blockette2000ByteOffset.push(record?.header?.blocketteList[blocketteIndex]?.body?.byteOffset)
                }
            })

            if(!foundBlockette2000InRecord){
                recordsWithoutBlockette2000.push(record)
            }


        })
    }
    
    return {
        recordsWithoutBlockette2000: recordsWithoutBlockette2000, 
        blockette2000Records: blockette2000Records, 
        blockette2000ByteOffset: blockette2000ByteOffset,
    }
}

function nthIndex(str, pat, n){
    var L= str.length, i= -1;
    while(n-- && i++<L){
        i= str.indexOf(pat, i);
        if (i < 0) break;
    }
    return i;
}

export function Blockette2000Payload(dataRecordsWithBlockette2000, arrOfBlockette2000ByteOffsets){

    var enc = new TextDecoder("utf-8");

    let payloadType = "ASCII"; // hardcoded for now

    let blockette2000HeaderArray = [];
    let blockette2000PayloadArray = [];

    dataRecordsWithBlockette2000?.forEach((record, recordIndex) => {

        let offset = 0;
        record?.header?.blocketteList.forEach((blockette, blocketteIndex) => {
            if(blockette?.type === 2000){
                offset = record?.header?.blocketteList[blocketteIndex]?.body?.byteOffset;
                console.log(offset)
            }
        })

        let blocketteType = record?.data?.getInt16(offset-dataRecordsWithBlockette2000[recordIndex]?.data?.byteOffset)
        let nextBlockettesBytNumber = record?.data?.getInt16(offset-dataRecordsWithBlockette2000[recordIndex]?.data?.byteOffset+2)
        let totalBlocketteLengthInBytes = record?.data?.getInt16(offset-dataRecordsWithBlockette2000[recordIndex]?.data?.byteOffset+4)
        let offsetToOpaqueData = record?.data?.getInt16(offset-dataRecordsWithBlockette2000[recordIndex]?.data?.byteOffset+6)
        let recordNumber = record?.data?.getInt32(offset-dataRecordsWithBlockette2000[recordIndex]?.data?.byteOffset+8)
        let dataWordOrder = record?.data?.getInt8(offset-dataRecordsWithBlockette2000[recordIndex]?.data?.byteOffset+12)
        let opaqueDataFlags = record?.data?.getInt8(offset-dataRecordsWithBlockette2000[recordIndex]?.data?.byteOffset+13)
        let numberOfOpaqueHeaderFields = record?.data?.getInt8(offset-dataRecordsWithBlockette2000[recordIndex]?.data?.byteOffset+14)
    
        let dataviewUTF8 = enc.decode(new DataView(record?.data.buffer, arrOfBlockette2000ByteOffsets[recordIndex] + 15, totalBlocketteLengthInBytes - 15));
    
        let lastByteOfOpaqueHeader = nthIndex(dataviewUTF8, "~", numberOfOpaqueHeaderFields)
        let lengthOfOpaqueHeader = lastByteOfOpaqueHeader+1;
        let offsetToOpaquePayload = lastByteOfOpaqueHeader+1;
        let header = dataviewUTF8.slice(0,lengthOfOpaqueHeader);
        
        let positionOfHeaderInArray = blockette2000HeaderArray.indexOf(header);
        if(positionOfHeaderInArray === -1) {
            blockette2000HeaderArray.push(header) // add a new header
            blockette2000PayloadArray.push([]) // add a new array to the array containing payload data
        } else {console.log("header duplicate")}
    
        let payload = new DataView(record?.data.buffer, arrOfBlockette2000ByteOffsets[recordIndex] + 15 + offsetToOpaquePayload, totalBlocketteLengthInBytes - 15 - lengthOfOpaqueHeader);
    
        blockette2000PayloadArray[blockette2000HeaderArray.indexOf(header)].push({recordNumber: recordNumber, payload: enc.decode(payload)})
    })

    // Sort the payload data by record number, to ensure that they are in the correct order before joining the data
    blockette2000PayloadArray.forEach((headerGroup, indexOfHeader) => {
        blockette2000PayloadArray[indexOfHeader].sort((r1, r2) => (r1.recordNumber > r2.recordNumber) ? 1 : (r1.recordNumber < r2.recordNumber) ? -1 : 0);
    })
    
    // create an object, with a key-pair for each header/payload set
    let result = {};    
    if(payloadType === "ASCII"){
        blockette2000HeaderArray.forEach((header, headerIndex) => {
            // Now, turn the array of partial strings into one combined string
            let stringifiedPayload = "";
            blockette2000PayloadArray[headerIndex].forEach((payloadSection, payloadSectionIndex) => {
                stringifiedPayload = stringifiedPayload.concat(payloadSection.payload)
            })

            Object.assign(result, {[header]: stringifiedPayload}) 
        })
    } else {
        blockette2000HeaderArray.forEach((header, headerIndex) => {
            Object.assign(result, {[header]: "unknown data type"}) 
        })
    }
   
    return result
}

export function splitBlockette2000HeadersInArrays(input){
    let arr = [];
    Object.entries(input).forEach(([key, value], i) => {
        let parsedHeader = key.split("~")
        parsedHeader.pop() // remove last element which is always empty
        arr.push(parsedHeader)
    })
    return arr;
}

export function blockette2000WasMadeBySeisodin(input){
    let result = false;
    let arrOfHeaders = splitBlockette2000HeadersInArrays(input)
    arrOfHeaders.forEach((header, headerIndex) => {
        if(header[1] === "SEISODIN"){
            result = true;
        }
    })
    return result
}

export function blockette2000WasMadeBySeisodinAndContainsGainInformation(input){
    let result = -1;
    // let arrOfHeaders = splitBlockette2000HeadersInArrays(input)

    Object.entries(input).forEach(([key, value], i) => {
        let parsedHeader = key.split("~")
        parsedHeader.pop() // remove last element which is always empty
        if(parsedHeader[0] === "GAIN" && parsedHeader[1] === "SEISODIN"){
            result = key;
        }

    })

    return result
}