import React, {useState, useEffect} from "react";
import _ from "lodash"

// OWN
import {strIsDefined} from "../../../../helpers.js"

// MUI V5
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

// ICONS
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function ToolbarUnits(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  let {disableMenuButton} = props;

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{marginLeft: "10px"}}>
      <Tooltip title="Select Channels">
        <span>
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            disabled={disableMenuButton}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Units
          </Button>
        </span>
      </Tooltip>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <UnitsMenuItems {...props}/>
        </StyledMenu>
      
    </div>
  );
}

export function UnitsMenuItems(props) {
    const [anyChannelHasSeisodinSensitivity, setAnyChannelHasSeisodinSensitivity] = useState(false)
    let { 
      data,
      units,
      updateUnitsState,
    } = props;

    function toggleChannelUnits(file, channel){
        const oldState = _.cloneDeep(units.current);
        let newState = [...units.current];
        newState[file][channel].applyPhysicalUnits = !newState[file][channel].applyPhysicalUnits
        updateUnitsState(oldState, newState)
    }

    useEffect(() => {
      setAnyChannelHasSeisodinSensitivity(false) // start from blank
      data.forEach((file,fileIndex) => {
        file.forEach((channel, channelIndex) => {
          if(units.current[fileIndex][channelIndex]?.hasSeisodinBlockette2000 === true){
            setAnyChannelHasSeisodinSensitivity(true) // set true if we encounter a channel in ANY file which has seisodin blockette2000
          }
        })
      })
    }, [data, units])

  return (
    <>
    {
      
        data.map((file,fileIndex) => {
          return file.map((channel, channelIndex) => {
            return(
              <>
              { channelIndex === 0 ?
                <Tooltip title={strIsDefined(file[0].fileName)} placement="right" key={"filenametooltip"+fileIndex+channelIndex}>
                  <Divider textAlign="center" key={"filenamedivider"+fileIndex+channelIndex}>
                    <Typography variant="caption" sx={{color: "text.secondary"}} key={"filename"+fileIndex+channelIndex}>
                      {strIsDefined(file[0].fileName).slice(0,28)+"..."}
                    </Typography>
                  </Divider>
                  </Tooltip>
                : <></>
              }
              {
                units.current[fileIndex][channelIndex]?.hasSeisodinBlockette2000 ?
                <MenuItem key={"channelmenuitem"+fileIndex + channelIndex}>
                  <FormGroup key={"formgroup"+channelIndex}>
                    <FormControlLabel 
                      key={"CHID"+channelIndex}
                      control={<Switch key={"switch"+channelIndex} checked={units.current[fileIndex][channelIndex].applyPhysicalUnits ? units.current[fileIndex][channelIndex].applyPhysicalUnits : false} onChange={() => {toggleChannelUnits(fileIndex, channelIndex)}}/>} 
                      label={<Typography >{data[fileIndex][channelIndex].channelCode + " Physical Units"}</Typography>} 
                    />
                  </FormGroup>
                </MenuItem>
                : <MenuItem key={"channelmenuitemUnknownSensitivity"+fileIndex + channelIndex}>
                  Sensitivity unknown
                </MenuItem>
              }

              </>
            )
          })
        })
    }
    <Divider key={"showallchannelsdivider"}/>
    
    {/* Enabled below to debug */}

    {/* <MenuItem>
      {JSON.stringify(units)}
    </MenuItem> */}

    {/* <MenuItem>
      {JSON.stringify(data)}
      {console.log(data)}
    </MenuItem> */}

    {/* <MenuItem>
      {JSON.stringify(visibleChannels)}
    </MenuItem> */}
    </>
  );
}