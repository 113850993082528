import React, {useEffect, useState, useContext} from 'react';

// OWN
import Widget from "../widget"
import OptionsMenu from '../optionMenu';
import { PageContext } from '../../../../context/pageContext';

// MUI V5
import IconButton from "@mui/material/IconButton"
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// ICONS
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

const WidgetInstrumentsStatus = (props) => {

    const [statusType, setStatusType] = useState(0)
    const [okCount, setOkCount] = useState(0)
    const [warningCount, setWarningCount] = useState(0)
    const [missingContactCount, setMissingContactCount] = useState(0)
    const [errorCount, setErrorCount] = useState(0)

    let {
        instruments,
        loadingInstrumentList,
    } = props;

    const Pcontext = useContext(PageContext);


    useEffect(async () => {
      let ok = 0;
      let war = 0;
      let err = 0;
      let lostcontact = 0;

      await instruments.forEach((instrument) => {

        let lastContact = new Date(parseInt(instrument.lastContact))

        if(instrument.hbt !== undefined && instrument.activated){

          let healthStatus = Number(JSON.parse(instrument?.hbt)?.err)

          if(instrument?.nextHbt*1000+5000 <= Date.now()) {
            lostcontact = lostcontact+1
          } else if (healthStatus === 0){
            ok = ok+1
          } else if(healthStatus === 1){
            war = war+1
          } else if(healthStatus === 2){
            err = err+1
          } else {
            // nothing
          }
        }

      })

      setOkCount(ok)
      setWarningCount(war)
      setErrorCount(err)
      setMissingContactCount(lostcontact)

    },[instruments])

    return (
    <React.Fragment>
        <Widget 
            backgroundColor="" 
            icon={<HealthAndSafetyIcon />}
            actionButton={<OptionsMenu actionArray={[{title: "Instruments", fn: () => {Pcontext.setPage("instruments")}}]}/>}
            title="Instrument Health"
            fixedHeight="165px"
        >
          <Box sx={{ width: '100%', mr: 1 }}>
              <TabPanel 
                okCount={okCount} 
                warningCount={warningCount} 
                errorCount={errorCount} 
                missingContactCount={missingContactCount}
                loadingInstrumentList={loadingInstrumentList}
              />
          </Box>
        </Widget>
    </React.Fragment>
    );
};

function TabPanel(props) {

  let {
    okCount,
    warningCount, 
    errorCount,
    missingContactCount,
    loadingInstrumentList,
  } = props

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));


    return (
      <Box sx={{ width: '100%'}} >    
          <Table sx={{width: "100%", borderWidth: "0px" }}>
            <TableHead>
              <TableRow sx={{width: "100%"}}>
                <TableCell align="center" sx={{width: "25%", color: "gray", borderWidth: "2px", borderColor: "success.main", backgroundColor: "#e9f7e9"}}>
                  <Typography variant="h5">
                    {
                      loadingInstrumentList ?
                      <Skeleton />
                      : okCount
                    }
                  </Typography>
                  {
                    largeScreen ?
                    <Typography variant="caption">OK</Typography>
                    : <CheckCircleIcon />
                  }
                </TableCell>
                <TableCell align="center" sx={{width: "25%", color: "gray", borderWidth: "2px", borderColor: "warning.main", backgroundColor: "#fffbf5"}}>
                  <Typography variant="h5">
                    {
                      loadingInstrumentList ?
                      <Skeleton />
                      : warningCount
                    }
                  </Typography>
                  {
                    largeScreen ?
                    <Typography variant="caption">WARNING</Typography>
                    : <WarningIcon />
                  }
                </TableCell>
                <TableCell align="center" sx={{width: "25%", color: "gray", borderWidth: "2px", borderColor: "error.main", backgroundColor: "#fff5f5"}}>
                  <Typography variant="h5">
                    {
                      loadingInstrumentList ?
                      <Skeleton />
                      : errorCount
                    }
                  </Typography>
                  {
                    largeScreen ?
                    <Typography variant="caption">ERROR</Typography>
                    : <ErrorIcon />
                  }
                </TableCell>
                <TableCell align="center" sx={{width: "25%", color: "gray", borderWidth: "2px", borderColor: "gray", backgroundColor: "#f5f5f5"}}>
                  <Typography variant="h5">
                    {
                      loadingInstrumentList ?
                      <Skeleton />
                      : missingContactCount
                    }
                  </Typography>
                  {
                    largeScreen ?
                    <Typography variant="caption">UNKNOWN</Typography>
                    : <AccessTimeFilledIcon />
                  }
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
      </Box>
    );
  }

export default WidgetInstrumentsStatus;