import React, {useContext} from 'react';

// OWN
import { SeismicDataContext } from "../../../../context/seismicDataContext";
import { AuthContext } from "../../../../context/auth-context"

// MUI imports
import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import CircularProgress from '@mui/material/CircularProgress';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ClearIcon from '@mui/icons-material/Clear';
import FolderZipIcon from '@mui/icons-material/FolderZip';

export default function RightClickMenu(props) {

  let {
    contextMenu,
    setContextMenu,
    // selectionModel,
    setSelectionModel,
    handleDataViewer,
    handleLogfileViewer,
    handleFileDownload,
    handleOpenDeleteDialog,
    selectedFilesCount,
    handleMultipleFileDownload,
    handleZipDownload,
    // openDialog,
    // setOpenDialog,
    targetConfiguration,
    downloadInProgess,
  } = props;

  const SeisDataContext = React.useContext(SeismicDataContext);
  const authcontext   = useContext(AuthContext);
  // use authcontext to render based on permissions
  // it is safe bacause permissions are checked server side on requests

  function clearSelections(){
    handleCloseContextMenu()
    setSelectionModel([])
    SeisDataContext.setPathArr([])     
  }

  const handleCloseContextMenu = () => {
    setContextMenu(null);
    // console.log(authcontext.state.user.permissions.downloadFiles)
  };

    return (
      <>
        <MuiMenu
          open={contextMenu !== null}
          onClose={handleCloseContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >

            {
              selectedFilesCount > 0 ?
              ""
              : targetConfiguration.enableDataViewerMenu ?
              <MenuItem onClick={handleDataViewer} disabled={!authcontext.state.user.permissions.downloadFiles}>
                  <ListItemIcon>
                    <AutoGraphIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    Open
                  </ListItemText>
                </MenuItem>
                : <></>
            }
            {
              selectedFilesCount > 0 ? 
              <MenuItem onClick={handleZipDownload} disabled={!authcontext.state.user.permissions.removeFiles || downloadInProgess}>
                <ListItemIcon>
                  <Badge badgeContent={Number(selectedFilesCount)} color="primary" sx={{ "& .MuiBadge-badge": { fontSize: 10, height: 16, minWidth: 16 } }}>
                    {
                      downloadInProgess ? <CircularProgress size={20}/> : <CloudDownloadIcon fontSize="small" />
                    }
                    
                  </Badge>
                </ListItemIcon>
                <ListItemText>
                  {
                    downloadInProgess ? "Download in progress" : "Download "+selectedFilesCount+" files"
                  }
                </ListItemText>
              </MenuItem>
            : ""
            }
            {/* {
              selectedFilesCount > 0 ?
                <MenuItem onClick={handleMultipleFileDownload} disabled={!authcontext.state.user.permissions.removeFiles}>
                  <ListItemIcon>
                    <Badge badgeContent={Number(selectedFilesCount)} color="primary" sx={{ "& .MuiBadge-badge": { fontSize: 10, height: 16, minWidth: 16 } }}>
                      <CloudDownloadIcon fontSize="small" />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText>
                    Download {selectedFilesCount} files
                  </ListItemText>
                </MenuItem>
              : <MenuItem onClick={handleFileDownload} disabled={!authcontext.state.user.permissions.downloadFiles}>
                  <ListItemIcon>
                    <CloudDownloadIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    Download
                  </ListItemText>
                </MenuItem>
            } */}
            {
              selectedFilesCount > 0 ?
                <MenuItem onClick={handleOpenDeleteDialog} disabled={!authcontext.state.user.permissions.removeFiles || downloadInProgess}>
                  <ListItemIcon>
                    <Badge badgeContent={Number(selectedFilesCount)} color="primary" sx={{ "& .MuiBadge-badge": { fontSize: 10, height: 16, minWidth: 16 } }}>
                      <DeleteIcon fontSize="small" />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText>
                    Delete {selectedFilesCount} files
                  </ListItemText>
                </MenuItem>
              : <MenuItem onClick={handleOpenDeleteDialog} disabled={!authcontext.state.user.permissions.removeFiles}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    Delete
                  </ListItemText>
                </MenuItem>
            }
                        {
              selectedFilesCount > 0 ?
                <MenuItem onClick={clearSelections} disabled={downloadInProgess}>
                  <ListItemIcon>
                      <ClearIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    Clear Selection
                  </ListItemText>
                </MenuItem>
              : ""
            }
        </MuiMenu>
        </>
    );
  }