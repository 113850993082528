import React, {useContext} from 'react';

// Own 
import { AuthContext } from "../../context/auth-context"

// MUI V5
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


// ICONS
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

export function AdminBadge(props) {
    const context = useContext(AuthContext)

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <React.Fragment>
        { 
            props.tiny === "true" ?
                <React.Fragment>
                    {context.state.user.admin ?
                            // <Chip icon={<VerifiedUserIcon style={{fontSize: "12px"}} />} label="ADMIN" sx={{fontSize: "10px", height: "17px", color: "primary.main", backgroundColor: "white"}} size="small" />
                            <Chip icon={<VerifiedUserIcon />} label="admin" size="small" color="primary" />
                    : ""}
                </React.Fragment>
            : 
                <React.Fragment>
                    {context.state.user.admin ?
                            <Paper 
                                sx={{
                                    marginBottom: "30px",
                                    // backgroundColor: "#424242",
                                    backgroundColor: "primary.main",
                                    padding: "10px",
                                    color: "white",
                                }}
                            >
                                <Grid container spacing={1} alignItems="center" >
                                    <Grid item xs={12} sm={12} container alignItems="flex-start" justify="flex-start">
                                        <Stack direction="row">
                                            <VerifiedUserIcon /> 
                                            <Typography sx={{marginLeft: "20px"}}>{largeScreen ? "You are administrator of this organization" : "You are administrator"}</Typography>
                                           
                                        </Stack>
                                    </Grid>
                                    {/* <Grid item xs={11} sm={11} container alignItems="flex-start" justify="flex-start">
                                        You are administrator of this organization
                                    </Grid> */}
                                </Grid>
                            </Paper>
                    : ""}
                </React.Fragment>

        }
        </React.Fragment>
    )
}