import React, {useState, useEffect} from 'react';

// OWN
import {printExpiryDate, printTimeToExpiry} from "../helpers.js"
import LinearProgressWithLabel from './subComponents.js/LinearProgressWithLabel.js';

// MUI V5
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

// ICONS
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';


function DataTable(props) {

    const [sub, setSub] = useState(
        { 
            settings: {
                subscription: {
                    exp: 1, 
                    active: false
                },
                storage: {
                  quota: 0
                },
                maxInstruments: 0, 
                maxUsers: 0,
                accountActive: true,
                name: "test"
            }, 
            usedStorage: {
                shareUsageBytes: 0
            }, 
            instrumentCount: 0, 
            userCount: 0
        }
    )

    let { subscriptionData } = props;

    useEffect(() => {
      console.log(subscriptionData)
      if(subscriptionData !== undefined && Object.keys(subscriptionData).length !== 0){
        setSub(subscriptionData)
      }
    }, [props.subscriptionData])


  return (
    <Box sx={{ width: '100%' }}>
        <List>
          <ListItem disablePadding sx={{borderBottom: 1, borderColor: "lightgray"}}>
            <ListItemButton>
              <ListItemText primary="Plan" />
                <div><Typography><b>Seisodin Cloud</b></Typography></div>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{borderBottom: 1, borderColor: "lightgray"}}>
            <ListItemButton>
              <ListItemText primary="Status" />
                <div>
                  <Chip 
                    label={(sub.settings.subscription.exp*1000) > Date.now() ? "active" : "expired"} 
                    size="small" 
                    sx={{
                      color: "white",
                      backgroundColor: sub.settings.subscription.exp*1000 > Date.now() ? 
                      "primary.main" 
                      : "gray"
                    }} 
                    avatar={
                      sub.settings.subscription.exp*1000 > Date.now() ? 
                      <CheckCircleIcon style={{color: "white"}}/> 
                      : <AccessTimeFilledIcon style={{color: "white"}}/>
                    }
                  />
                </div>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{borderBottom: 1, borderColor: "lightgray"}}>
            <ListItemButton>
              <ListItemText primary="Expiry" />
                <div><Stack direction="column"><Tooltip title={new Date(sub.settings.subscription.exp*1000).toUTCString()}><Typography variant="button" style={{cursor: "pointer", textAlign: "right"}}>{printTimeToExpiry(sub.settings.subscription.exp)}</Typography></Tooltip><Typography variant="caption">{printExpiryDate(sub.settings.subscription.exp)}</Typography></Stack></div>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{borderBottom: 1, borderColor: "lightgray"}}>
            <ListItemButton>
              <ListItemText primary="Storage" />
                <div><Tooltip title={(sub.usedStorage.shareUsageBytes/1000000000) + "GB used (" + ((Number(sub.usedStorage.shareUsageBytes/1000000000)/Number(sub.settings.storage.quota))*100).toFixed(2) + "%)" }><span style={{width: "100%"}}><LinearProgressWithLabel labelFirst={true} disabled={(sub.settings.subscription.exp*1000) > Date.now() ? false : true} value={(Number(sub.usedStorage.shareUsageBytes/1000000000)/Number(sub.settings.storage.quota))*100} variant="determinate" sx={{width: "100%"}} text={(sub.usedStorage.shareUsageBytes/1000000000).toFixed(1) + "/" + sub.settings.storage.quota +"GB"}/></span></Tooltip></div>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{borderBottom: 1, borderColor: "lightgray"}}>
            <ListItemButton>
              <ListItemText primary="Instruments" />
                <span><LinearProgressWithLabel labelFirst={true} disabled={(sub.settings.subscription.exp*1000) > Date.now() ? false : true} value={(Number(sub.instrumentCount)/Number(sub.settings.maxInstruments))*100} variant="determinate" sx={{width: "100%"}} text={sub.instrumentCount + "/" + sub.settings.maxInstruments}/></span>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{borderBottom: 1, borderColor: "lightgray"}}>
            <ListItemButton>
              <ListItemText primary="Users" />
                <span><LinearProgressWithLabel labelFirst={true} disabled={(sub.settings.subscription.exp*1000) > Date.now() ? false : true} value={(Number(sub.userCount)/Number(sub.settings.maxUsers))*100} variant="determinate" sx={{width: "100%"}} text={sub.userCount + "/" + sub.settings.maxUsers}/></span>
              </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{borderBottom: 1, borderColor: "lightgray"}}>
            <ListItemButton>
              <ListItemText primary="Auto Renew" />
                <div>NO</div>
              </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{borderBottom: 0, borderColor: "lightgray"}}>
            <ListItemButton>
              <ListItemText primary="Price" />
                <div>$0</div>
              </ListItemButton>
          </ListItem>
        </List>
    </Box>
  );
}


export default function SubscriptionTableMobile(props) {
    
    const { subscriptionData } = props

    return (
        <Card sx={{ width: "100%"}}>
            <CardContent >
                <CardHeader 
                    style={{        
                        padding: 0,
                        marginTop: "-5px",
                        marginBottom: "-10px",
                        color: "#a1a1a1",
                        textColor: "#a1a1a1"
                    }} 
                    avatar={<CardMembershipIcon style={{ marginTop: "4px" }} />} 
                    title="Subscription" 
                />
            </CardContent>
            <DataTable subscriptionData={subscriptionData}/>
        </Card>
    )
}