import React from 'react';
import axios from "axios";
import qs from "querystring"

// OWN
import ForgottenPasswordDialog from "./ForgottenPassword"
import SnackBar from "../layout/SnackBar"
import { AuthContext } from "../../context/auth-context"

// MUI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import SvgComponent from '../../graphics/logo.js';


export default function LoginForm() {
    let [email, setEmail] = React.useState("");
    let [password, setPassword] = React.useState("");
    let [forgottenPasswordDialog, setForgottenPasswordDialog] = React.useState(false)
    const [mySnackBar, setMySnackBar] = React.useState({ display: false, type: "", message: "" });


    const AContext = React.useContext(AuthContext);


    const handleForgotPassword = (event) => {
        event.preventDefault();
        // open a dialog
        setForgottenPasswordDialog(!forgottenPasswordDialog);
    };
    
    const onChangeEmail = e => {
        setEmail(e.target.value);
    };

    const onChangePassword = e => {
        setPassword(e.target.value);
    };

    function submitLogin() {
        const url = `/api/users/login`;
        // const url = process.env.backendip;

        const requestBody = {
            email: email,
            password: password
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        axios.post(url, qs.stringify(requestBody), config)
            .then((res) => {
                const { token, organizationSubscription } = res.data;
                console.log(res.data)
                // Set token to localStorage
                sessionStorage.setItem("seisodinCloudJwtToken", token);
                // localStorage.setItem("seisodinCloudJwtToken", token);
                AContext.setSubscriptionInfo({...organizationSubscription, exp: Number(organizationSubscription.exp)*1000})
                AContext.login();
            })
            .catch((err) => {
                // Do somthing
                setMySnackBar({ display: true, type: "error", message: err.response.data.message });
                setTimeout(function () {
                    setMySnackBar({ display: false, type: "", message: "" });
                }, 5000);
            })
    }

    return (
        <div>
        {
            forgottenPasswordDialog 
            ? <ForgottenPasswordDialog />
            : ""
        }
        {
            mySnackBar.display === true && mySnackBar.type === "error" 
            ? <React.Fragment>
                    <SnackBar type={mySnackBar.type} message={mySnackBar.message} />
              </React.Fragment>
            : ""
        }
        <Box
            sx={{
            backgroundColor: 'primary.main',
            width: "100%",
            height: 100,
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 'modal',
            }}
        >
            <Container align="center" maxWidth="xs" sx={{padding: 5}}>
                <Paper variant="outlined" >
                    <Box p={3} >
                    <div>
                        <SvgComponent style={{display: 'flex', height: 'inherit', width: 'inherit', marginBottom: '10px'}}/>
                        <Typography variant="h6" >
                            Cloud Login
                        </Typography>
                    </div>
                    <form autoComplete="off">
                        <div>
                            <TextField 
                                id="email" 
                                label="Email" 
                                variant="outlined" 
                                required 
                                fullWidth 
                                margin="normal" 
                                type="text"
                                onChange={onChangeEmail} 
                            />
                        </div>
                        <div>
                            <TextField 
                                id="password" 
                                label="Password" 
                                variant="outlined" 
                                required 
                                fullWidth 
                                margin="normal" 
                                type="password"
                                sx={{marginTop: "-1px"}}
                                onChange={onChangePassword} 
                            />
                        </div>
                        <div>
                            <Button 
                                size="large" 
                                variant="contained" 
                                color="primary" 
                                fullWidth
                                onClick={submitLogin}
                            >
                                Log in
                            </Button>
                        </div>
                    </form>
                    <Typography sx={{marginTop: "5px"}}>
                        <Link href="#" onClick={handleForgotPassword}>
                            I forgot my password
                        </Link>
                    </Typography>
                    </Box>
                </Paper>
            </Container>
        </Box>
    </div>
    );
}

