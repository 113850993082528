import React, { useEffect, useState } from "react"
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Select from '@mui/material/Select';

export default function FilterDialog(props) {

  const [buttonDisabled, setButtonDisabled] = useState(false);

  let { 
    shouldDialogOpen,
    setDialogOpen,
    filter, 
    setFilter,
  } = props;

  useEffect(() => {
    console.log("shouldDialogOpen " + JSON.stringify(shouldDialogOpen))
  }, [shouldDialogOpen])


  function handleDialogClose(){
    setDialogOpen(false);
  }

  useEffect(() => {
    if(filter.type === "lp" && filter.highCorner > 0 && filter.poles >= 1 && filter.poles < 101 && Number.isInteger(filter.poles)){
      setButtonDisabled(false)
    } else if(filter.type === "hp" && filter.lowCorner > 0 && filter.poles >= 1 && filter.poles < 101 && Number.isInteger(filter.poles)){
      setButtonDisabled(false)
    } else if(filter.type === "bp" && filter.lowCorner > 0 && filter.highCorner > 0 && filter.lowCorner < filter.highCorner && filter.lowCorner !== filter.highCorner && filter.poles >= 1 && filter.poles < 101 && Number.isInteger(filter.poles)){
      setButtonDisabled(false)
    } else {
      setButtonDisabled(true)
    }
  }, [filter, shouldDialogOpen])

  function handleApplyFilter(){
    setFilter(prevState => ({
        ...prevState,
        stateFlipper: !filter.stateFlipper,
      }))
      setDialogOpen(false);
  } 

function handleFilterSettingsType(e){
  setFilter(prevState => ({
    ...prevState,
    type: e.target.value,
  }))
}

function handleFilterSettingsName(e){
  setFilter(prevState => ({
    ...prevState,
    name: e.target.value,
  }))
}

function handleFilterSettingsPoles(e){
  if(!Number.isNaN(e.target.value)){
    // if(!Number.isNaN(e.target.value) && Number(e.target.value)>0 && Number(e.target.value)<101 && Number.isInteger(e.target.value))
    setFilter(prevState => ({
      ...prevState,
      poles: Number(e.target.value),
    }))
  }
}

function handleFilterSettingsEpsilon(e){
  if(!Number.isNaN(e.target.value))
    setFilter(prevState => ({
      ...prevState,
      epsilon: Number(e.target.value),
    }))
}

function handleFilterSettingsLowerCorner(e){
  if(!Number.isNaN(e.target.value)){
    setFilter(prevState => ({
      ...prevState,
      lowCorner: Number(e.target.value),
    }))
  }
}

function handleFilterSettingsHigherCorner(e){
  if(!Number.isNaN(e.target.value)){
    setFilter(prevState => ({
      ...prevState,
      highCorner: Number(e.target.value),
    }))
  }
}


  return (
      <Dialog open={shouldDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Filter Configuration</DialogTitle>
        <DialogContent>
        <FormControl fullWidth margin="normal">
            <Select
              key="filtertypeselector"
              value={filter.type ? filter.type : "lp"}
              onChange={handleFilterSettingsType}
            >
              <MenuItem value={"lp"}>Low Pass</MenuItem>
              <MenuItem value={"hp"}>High Pass</MenuItem>
              <MenuItem value={"bp"}>Band Pass</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Select
              key="filtername"
              value={filter.name}
              onChange={handleFilterSettingsName}
            >
              <MenuItem value={"butterworth"}>Butterworth</MenuItem>
              <MenuItem value={"chebyshev1"}>Chebyshev I</MenuItem>
              <MenuItem value={"chebyshev2"}>Chebyshev II</MenuItem>
            </Select>
          </FormControl>
          <TextField
            onChange={handleFilterSettingsPoles}
            key="Poles"
            margin="normal"
            label="Number of poles"
            type="number"
            fullWidth
            variant="outlined"
            value={filter.poles}
            inputProps={{
              min: "1", 
              max: "100",
              // defaultValue: filter.poles,
            }}
            error={
              Number(filter.poles) < 1 || Number(filter.poles) > 100 || !Number.isInteger(filter.poles)? 
              true 
              : false
            }
            helperText={
              Number(filter.poles) < 1 || Number(filter.poles) > 100 || !Number.isInteger(filter.poles)? 
              "Value must be an integer between 1 and 100" 
              : ""
            }
          />

          {
            filter.name === "chebyshev1" || filter.name === "chebyshev2" ? 
            <TextField
              onChange={handleFilterSettingsEpsilon}
              margin="normal"
              key="epsilon"
              label="Epsilon"
              type="number"
              fullWidth
              variant="outlined"
              value={filter.epsilon}
              inputProps={{
                min: "0.001", 
                max: 1,
                // defaultValue: filter.epsilon,
              }}
              error={
                Number(filter.epsilon) < 0.001 || Number(filter.epsilon) > 1 ? 
                true 
                : false
              }
              helperText={
                Number(filter.epsilon) < 0.001 || Number(filter.epsilon) > 1 ? 
                "Value must be between 0.001 and 1" 
                : ""
              }
            />
            : <React.Fragment></React.Fragment>
          }

          {
            filter.type === "bp" || filter.type === "hp" ?
            <TextField
              onChange={handleFilterSettingsLowerCorner}
              margin="normal"
              key="LoFreqCorner"
              label="Lower Frequency Corner"
              type="number"
              fullWidth
              variant="outlined"
              value={filter.lowCorner}
              // inputProps={{
              //   min: "0", 
              //   // defaultValue: filter.lowCorner,
              // }}
              error={
                Number(filter.lowCorner) < 0.0000000000000000001 ? 
                true 
                : false
              }
              helperText={
                Number(filter.lowCorner) < 0.0000000000000000001 ? 
                "Value must be bigger than 0" 
                : ""
              }
            />
            : <React.Fragment></React.Fragment>
          }

          {
            filter.type === "bp" || filter.type === "lp" ?
            <TextField
              onChange={handleFilterSettingsHigherCorner}
              margin="normal"
              key="HiFreqCorner"
              label="Upper Frequency Corner"
              type="number"
              fullWidth
              variant="outlined"
              value={filter.highCorner}
              // inputProps={{
              //   min: "0.0000000000000000001",
              //   // defaultValue: filter.highCorner,
              // }}
              error={
                Number(filter.highCorner) < 0.0000000000000000001 ? 
                true:
                (filter.type === "bp" && Number(filter.lowCorner) >= Number(filter.highCorner)) ?
                true
                : false
              }
              helperText={
                Number(filter.highCorner) < 0.0000000000000000001 ? 
                "Value must be bigger than 0" :
                (filter.type === "bp" && Number(filter.lowCorner) >= Number(filter.highCorner)) ?
                "Higher corner must be bigger than Lower corner"
                : ""
              }
          />
          : <React.Fragment></React.Fragment>
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleApplyFilter} variant="contained" disabled={buttonDisabled}>Apply Filter</Button>
        </DialogActions>
      </Dialog>
  );
}