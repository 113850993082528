import React, {useEffect, useState, useRef, useCallback} from 'react';
import Dygraph from "dygraphs";
import "./latestEventfile.css";

// OWN
import Widget from "../widget"
import { INTERFACE_downloadFile } from '../../fileExplorer/interface';
import { removeBlockette2000, Blockette2000Payload, blockette2000WasMadeBySeisodin, blockette2000WasMadeBySeisodinAndContainsGainInformation, splitBlockette2000HeadersInArrays } from "../../dataViewer/viewers/miniseedViewer/utils/blockette2000.js"
import {tsToRMS, minMaxOfTs} from "../../dataViewer/viewers/miniseedViewer/utils/statisticsFunctions.js"

// MUI V5
import IconButton from "@mui/material/IconButton"
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


// ICONS
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SensorsIcon from '@mui/icons-material/Sensors';

const seisplot = require("seisplotjs");
// http://crotwell.github.io/seisplotjs/api/index.html


const WidgetLatestEventFile = (props) => {

    let {
      lastEvent,
      loadingLastEventFile,
    } = props;

    useEffect(() => {
      console.log("lastEvent changed")
      console.log(lastEvent)
    }, [lastEvent])

    return (
    <React.Fragment>
        <Widget 
            backgroundColor="" 
            icon={<SensorsIcon />}
            actionButton={<IconButton variant="outlined" size="small"><MoreHorizIcon/></IconButton>}
            title="Latest Event"
        >
                {
                  loadingLastEventFile ?
                  <Skeleton variant="rectangular" style={{width: "100%"}} height={200} animation="wave" />
                  : <Box sx={{ width: '100%', height: "200px", mr: 1, backgroundColor: "darkgray", color: "white", justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <Graph lastEvent={lastEvent}/>
                  </Box>
                }
                <Box sx={{ width: '100%' }}>    
                  <Table sx={{width: "100%", borderWidth: "0px", marginTop: "1rem"}}>
                    <TableHead>
                      <TableRow sx={{width: "100%"}}>
                        <TableCell align="center" sx={{width: "33.3%", color: "gray", borderWidth: "2px", borderColor: "primary.main", backgroundColor: ""}}>
                          <Typography variant="button">
                            Peak
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={{width: "33.3%", color: "gray", borderWidth: "2px", borderColor: "primary.light", backgroundColor: ""}}>
                          <Typography variant="button">
                            RMS
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={{width: "33.3%", color: "gray", borderWidth: "2px", borderColor: "primary.main", backgroundColor: ""}}>
                          <Typography variant="button">
                            TIME
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{justifyContent: "center"}}>
                          <TableCell align="center" sx={{width: "33.3%", borderWidth: "0px", color: "gray"}}>
                              <Typography variant="h5">0.153</Typography> 
                              <Typography variant="caption">G</Typography> 
                          </TableCell>
                          <TableCell align="center" sx={{width: "33.3%", borderWidth: "0px", color: "gray"}}>
                            <Typography variant="h5">0.0179</Typography> 
                            <Typography variant="caption">G</Typography> 
                          </TableCell>
                          <TableCell align="center" sx={{width: "33.3%", borderWidth: "0px", color: "gray"}}>
                            <Typography variant="h5">8H </Typography> 
                            <Typography variant="caption">ago</Typography> 
                          </TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </Box>
        </Widget>
    </React.Fragment>
    );
};

const Graph = (props) => {
  const [seisDataArr, setSeisDataArr] = useState([]);
  const [values, setValues] = useState([])
  const [metaDataArr, setMetaDataArr] = useState([])
  const [path, setPath] = useState("")

  let {
    lastEvent
  } = props;

  const graphEl = useRef(null);

  useEffect(() => {
    // load the file from the file server and set the timeSeriesData
    setPath(lastEvent.path)
  }, [lastEvent])

  useEffect(() => {
    let data = getDataFromPath().then(d => {
      console.log("serisDataArr")
      console.log([d])
      setSeisDataArr([d])
    }).catch(e => {
      console.log("error getting timeseries data")
    })
    
  }, [path])


  useEffect(() => {
    console.log("values")
    console.log(values)
    if(values.length > 0){
      new Dygraph(
        graphEl.current,
        values?.[0]?.[0],
        // "Date,Series1,Series2\n" +
        // "1247382000,100,200\n" +
        // "1247986800,150,201\n",
      );
    }

},[values]);

const getDataFromPath = useCallback (async () => {
  console.log("DEBUG: getDataFromPath callback was run (depends on path)")
  return await INTERFACE_downloadFile(path).then(res => {
      // parse arrayBuffer into an array of DataRecords
      var datarecords = seisplot.miniseed.parseDataRecords(res.data)
                  
      // handle possible blockette2000
      var datarecordsWithBlockette2000Extracted = removeBlockette2000(datarecords)
       
      // splits the DataRecords by channel and creates a single Seismogram for each channel.
      var seismogramsperchannel = seisplot.miniseed.seismogramPerChannel(datarecordsWithBlockette2000Extracted.recordsWithoutBlockette2000)

      if(seismogramsperchannel.length < 1 || !Array.isArray(seismogramsperchannel)){
          throw new Error("File has no data channels")
      }

      // flags
      let nonContiguousDataRemovedFromFile = false;
      let blockette2000WasFoundInFile = false;
      let b2000Payload = {};

      // remove channels which are non-contigous
      seismogramsperchannel.forEach((d, i) => {
          // if channel is non-contigous, then remove it from the array
          if(!d.isContiguous()){
              console.log("non-contiguous data found:")
              console.log(d)
              seismogramsperchannel.splice(i,1)
              nonContiguousDataRemovedFromFile = true;
          }
      })

      // set a flag if Blockette2000 data was found in the file
      if(datarecordsWithBlockette2000Extracted.blockette2000Records.length > 0){
          blockette2000WasFoundInFile = true
          b2000Payload = Blockette2000Payload(datarecordsWithBlockette2000Extracted.blockette2000Records, datarecordsWithBlockette2000Extracted.blockette2000ByteOffset)
      } else {
          blockette2000WasFoundInFile = false
      }

      // add the filename into the seismogram so it can be used later
      seismogramsperchannel.forEach((d, i) => {
              seismogramsperchannel[i].fileName = path.substring(path.lastIndexOf("/") + 1);
              seismogramsperchannel[i].nonContiguousDataRemovedFromFile = nonContiguousDataRemovedFromFile;
              seismogramsperchannel[i].blockette2000WasFoundInFile = blockette2000WasFoundInFile;
              seismogramsperchannel[i].blockette2000Payload = b2000Payload;
              seismogramsperchannel[i].blockette2000BySeisodin = blockette2000WasFoundInFile ? blockette2000WasMadeBySeisodin(b2000Payload) : false;
      })

      return seismogramsperchannel
  }).catch(e => {
      throw Error(e)
  })

}, [path])

useEffect(() => {
  if(seisDataArr && seisDataArr.length > 0 && seisDataArr !== undefined){
      let graphdataCollection = [];
      let channelMetaDataCollection = [];

      // let previousData = dataArr;
      // let previousMetaData = metaDataArr;

      // iterate over the selected files
      seisDataArr.forEach((file, fileIndex) => {
        console.log("file " + fileIndex)
          // iterate over the channels in each file
          let metaDataPerFile = [];
          let graphDataPerFile = []
          
          file.forEach((seismicData, channel) => {
            console.log("channel " + channel)
              // variables used to determine if time stamps need recalculation or not
              // let previousNumberOfSamples = previousData?.[fileIndex]?.[channel]?.length;
              // let previousSampleRate = previousMetaData?.[fileIndex]?.[channel]?.sampleRate;
              // let previousEndTime = new Date(previousMetaData?.[fileIndex]?.[channel]?.endTime);
              // let newNumberOfSamples = seisDataArr?.[fileIndex]?.[channel]?.y?.length;
              // let newSampleRate = seisDataArr?.[fileIndex]?.[channel]?.sampleRate;
              // let newEndTime = new Date(seisDataArr?.[fileIndex]?.[channel]?.endTime);

              // variables for actual business
              let graphdataPerChannel = [];   
              let sampleRate = seisDataArr[fileIndex][channel].sampleRate;
              var sTime = new Date(seisDataArr[fileIndex][channel].startTime);

                  try {
                      // This IF statement checks if we can avoid recalculating the time stamps to save time
                          console.log("start creating dygraph dataset [timestamp, y]")
                          Object.keys(seisDataArr[fileIndex][channel].y).forEach(function(key, sampleIndex) {
                              // Calculate the time stamp for each sample based on start time and sample rate
                              // var sTime = new Date(seisDataArr[fileIndex][channel].startTime);
                              // sTime.setMilliseconds(sTime.getMilliseconds() + (1/sampleRate*1000*sampleIndex));
                              sTime.setMilliseconds(sTime.getMilliseconds() + (1/sampleRate*1000));
                              // graphdataPerChannel.push([new Date(sTime), seisDataArr[fileIndex][channel].y[index]])
                              graphdataPerChannel.push([new Date(sTime), seisDataArr[fileIndex][channel].y[sampleIndex]])
                            });
                            console.log("done creating dygraph dataset [timestamp, y]")
                  } catch(e) {
                      console.log("A error on channel " + channel + " " + e)
                      e = {...e, response: {data: { message: e.message}}};
                      console.log("failed creating dygraph dataset [timestamp, y]")
                  }

              let SeismogramDisplayData = seisplot.seismogram.SeismogramDisplayData.fromSeismogram(seisDataArr[fileIndex][channel])

              try {
              // construct channelMetaDataCollection one channel at a time
              console.log(seismicData)
              let blockette2000SeisodinGainInformationHeaderKey = blockette2000WasMadeBySeisodinAndContainsGainInformation(seismicData.blockette2000Payload)

                metaDataPerFile.push({
                  fileName: seismicData.fileName,
                  channelCode: seisDataArr[fileIndex][channel].channelCode,
                  endTime: seisDataArr[fileIndex][channel].endTime,
                  locationCode: seisDataArr[fileIndex][channel].locationCode,
                  networkCode: seisDataArr[fileIndex][channel].networkCode,
                  numPoints: seisDataArr[fileIndex][channel].numPoints,
                  sampleRate: seisDataArr[fileIndex][channel].sampleRate,
                  startTime: seisDataArr[fileIndex][channel].startTime,
                  stationCode: seisDataArr[fileIndex][channel].stationCode,
                  timeRange: seisDataArr[fileIndex][channel].timeRange,
                  yUnit: seisDataArr[fileIndex][channel].yUnit,
                  min: SeismogramDisplayData.min,
                  max: SeismogramDisplayData.max, 
                  mean: SeismogramDisplayData.mean,
                  rms: tsToRMS(seisDataArr[fileIndex][channel].y, ),
                  tsStats: minMaxOfTs(seisDataArr[fileIndex][channel].y, seisDataArr[fileIndex][channel].startTime, seisDataArr[fileIndex][channel].sampleRate),
                  nonContiguousDataRemovedFromFile: seismicData.nonContiguousDataRemovedFromFile,
                  blockette2000WasFoundInFile: seismicData.blockette2000WasFoundInFile,
                  blockette2000Payload: seismicData.blockette2000Payload,
                  blockette2000Headers: splitBlockette2000HeadersInArrays(seismicData.blockette2000Payload),
                  blockette2000SeisodinGainInformationHeaderKey: blockette2000SeisodinGainInformationHeaderKey,
                  blockette2000SeisodinSensitivity: blockette2000SeisodinGainInformationHeaderKey !== -1 ? JSON.parse(seismicData.blockette2000Payload[blockette2000SeisodinGainInformationHeaderKey]).countsPerUnit[channel] : 1,
                  // blockette2000SeisodinSensitivity: blockette2000SeisodinGainInformationHeaderKey,
                  blockette2000SeisodinPhysicalUnit: blockette2000SeisodinGainInformationHeaderKey !== -1 ? JSON.parse(seismicData.blockette2000Payload[blockette2000SeisodinGainInformationHeaderKey]).units[channel] : "no unit",
                })

                console.log("graphdataPerChannel")
                console.log(graphdataPerChannel)
                graphDataPerFile.push(graphdataPerChannel)
              } catch(e){
                  console.log("B error on channel " + channel + " " + e)
                  e = {...e, response: {data: { message: e.message}}};
              }


                   
          })
          graphdataCollection.push(graphDataPerFile);
          channelMetaDataCollection.push(metaDataPerFile)
      })
      // set data state to updated graphdataCollection
      console.log("graphdataCollection")
      console.log(graphdataCollection)
      setValues(graphdataCollection)
      // set metaData state to updated channelMetaDataCollection
      setMetaDataArr(channelMetaDataCollection)

  } 

}, [seisDataArr])  

  return (
    <React.Fragment>
      {/* <p>Graph plot</p> */}
      <div style={{position: "relative", width: "100%", height: "200px"}}>
        <div ref={graphEl} style={{height: "200px", width: "100%", position: "absolute"}}/>
        {/* <div>
          {JSON.stringify(values)}
        </div> */}
      </div>
        <Box sx={{ width: '100%' }}>    
          <Table sx={{width: "100%", borderWidth: "0px", marginTop: "1rem"}}>
            <TableHead>
              <TableRow sx={{width: "100%"}}>
                <TableCell align="center" sx={{width: "33.3%", color: "gray", borderWidth: "2px", borderColor: "primary.main", backgroundColor: ""}}>
                  <Typography variant="button">
                    Peak
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{width: "33.3%", color: "gray", borderWidth: "2px", borderColor: "primary.light", backgroundColor: ""}}>
                  <Typography variant="button">
                    RMS
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{width: "33.3%", color: "gray", borderWidth: "2px", borderColor: "primary.main", backgroundColor: ""}}>
                  <Typography variant="button">
                    TIME
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                <TableRow sx={{justifyContent: "center"}}>
                  <TableCell align="center" sx={{width: "33.3%", borderWidth: "0px", color: "gray"}}>
                      <Typography variant="h5">0.153</Typography> 
                      <Typography variant="caption">G</Typography> 
                  </TableCell>
                  <TableCell align="center" sx={{width: "33.3%", borderWidth: "0px", color: "gray"}}>
                    <Typography variant="h5">{metaDataArr[0]?.[0]?.rms}</Typography> 
                    <Typography variant="caption">{metaDataArr[0]?.[0]?.yUnit}</Typography> 
                  </TableCell>
                  <TableCell align="center" sx={{width: "33.3%", borderWidth: "0px", color: "gray"}}>
                    <Typography variant="h5">8H </Typography> 
                    <Typography variant="caption">ago</Typography> 
                  </TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </Box>
    </React.Fragment>
  )
}


export default WidgetLatestEventFile;