import React, {useContext, useState, useEffect} from 'react';

// OWN 
import { backendApi, clientConfigApplication } from "../../../api/calls"
import { Transition } from "../../../styles/SlideTransition"
import "../../../styles/checkmarkStyles.css"
import ErrorDialog from "../../../layout/ErrorDialog.js"
import { instrumentListContext } from "../index"

// MUI V5
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';

// ICONS
import SettingsIcon from '@mui/icons-material/Settings';
import EmailIcon from '@mui/icons-material/Email';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function DialogEmail(props) {
    const [open, setOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [error, setError] = useState({title: "test", e: ""});
    const [users, setUsers] = useState([]);
    const [unregisteredEmailsList, setUnregisteredEmailsList] = useState([])

    let {
        emailList,
        selectedSerialNumber, 
        emailOnEvent, 
        handleEmailListUpdate
    } = props;

    let instrumentsContext = useContext(instrumentListContext);
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        let userIdList = []
        let unregisteredEmails = []

        users.forEach((user) => {
            userIdList.push(user?._id)
        })

        emailList.forEach(id => {
            if(userIdList.findIndex(userId => userId === id) === -1){
                unregisteredEmails.push(id)
            }
        })

        setUnregisteredEmailsList(unregisteredEmails)

    }, [emailList, users])

    function handleDialogClose() {
        setOpen(false);
    }

    function handleChange(event, id){
        handleEmailListUpdate(id, selectedSerialNumber)
    }

    async function getUserList() {
        backendApi.get(`/api/organization/users/`, clientConfigApplication)
            .then((res) => {
                setUsers(res.data.users)
                return res;
            })
            .catch((e) => {
                console.log(e)
                setError({ title: "Error getting user list", e: e});
                setErrorOpen(true)
                setOpen(false)
            })
    }


    return (
        <React.Fragment>
        <ErrorDialog open={errorOpen} setOpen={setErrorOpen} dialogContent={error} />
            <Dialog 
                open={open} 
                TransitionComponent={Transition} 
                keepMounted onClose={handleDialogClose} 
            >
                <Box sx={{width: "100%", backgroundColor: "primary.light", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Stack>
                        <EmailIcon style={{fontSize: 80}} sx={{ color: "primary.contrastText", marginTop: "20px", marginLeft: "auto", marginRight: "auto" }} />
                        <DialogTitle id="alert-dialog-title" sx={{color: "primary.contrastText"}}> E-mail settings for {selectedSerialNumber}  </DialogTitle>
                    </Stack>
                </Box>
                <DialogContent>
                    <List
                        dense={true}
                        sx={{ width: '100%'}}
                        subheader={
                            <ListSubheader>When receiving an event file, send an e-mail to:</ListSubheader>
                        }
                    >              
                        {/* VALID EMAILS           */}
                        {
                            users.map((user, index) => (
                                <ListItem key={user._id}>
                                    {
                                        largeScreen ?
                                        <ListItemIcon>
                                            <EmailIcon />
                                        </ListItemIcon>
                                        : <></>
                                    }
                                    
                                    <ListItemText primary={user.name} secondary={user.email}/>
                                    <Switch
                                        edge="end"
                                        checked={emailList.includes(user._id)} 
                                        key={user._id} 
                                        onChange={(e) => handleChange(e, user._id)}
                                    />
                                </ListItem>
                            ))
                        }
                        {/* INVALID EMAILS           */}
                        {
                            unregisteredEmailsList.map(id => (

                                <ListItem key={id}>
                                    {
                                        largeScreen ?
                                        <Tooltip title="This user is no longer registered, so no e-mails can be sent. Please remove the user from the list.">
                                            <ListItemIcon>
                                                <Badge color="error" badgeContent="!">
                                                    <EmailIcon />
                                                </Badge>
                                            </ListItemIcon>
                                        </Tooltip>
                                        : <></>
                                    }

                                    <ListItemText primary={id} secondary={"No longer registered"}/>
                                    <Tooltip title="Delete unregistered recipient">
                                        <IconButton 
                                            onClick={(e) => handleChange(e, id)}
                                            edge="end"
                                        >
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </Tooltip>
                                </ListItem>
                                
                            ))
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {
                emailOnEvent ?
                <IconButton 
                    onClick={() => {
                        setOpen(true)
                        getUserList()
                    }} 
                    size="small"
                >
                    <SettingsIcon fontSize="inherit" />
                </IconButton>
                : <></>
            }

        </React.Fragment>
    )
}
